import {Action, view, css} from '@sail/ui';
import * as React from 'react';
import {defineMessages, injectIntl} from 'react-intl';

import {
  inlineBlock,
  tBoldL,
  tSmall,
  table,
  tableCell,
  tableRow,
  tapHighlightColorTransparent,
} from 'gelato/frontend/src/components/stylesV2';
import {InputMethod} from 'gelato/frontend/src/controllers/states/DocumentState';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

import type {IntlProps} from 'gelato/frontend/src/components/Message';

const {useCallback, useState} = React;

export const Messages = defineMessages({
  automatic: {
    defaultMessage: 'Auto',
    description: 'Label for the toggle to enable automatic document capture',
    id: 'verification.documentManualCaptureToggle.automatic',
  },
  manual: {
    defaultMessage: 'Manual',
    description: 'Label for the toggle to enable manual document capture',
    id: 'verification.documentManualCaptureToggle.manual',
  },
  switchToAutomatic: {
    defaultMessage: 'Switch to automatic document capture',
    description: 'Label for the toggle to enable automatic document capture',
    id: 'verification.documentManualCaptureToggle.switchToAutomatic',
  },
  switchToManual: {
    defaultMessage: 'Switch to manual document capture',
    description: 'Label for the toggle to enable manual document capture',
    id: 'verification.documentManualCaptureToggle.switchToManual',
  },
});

const Styles = {
  active: css({
    font: 'label.medium.emphasized',
    lineHeight: '100%',
  }),
  grid: css({
    pointerEvents: 'none',
    position: 'relative',
    userSelect: 'none',
    zIndex: 2,
  }),
  gridCell: css({
    paddingX: 'medium',
    whiteSpace: 'nowrap',
  }),
  gridCellHidden: css({
    lineHeight: 0,
    overflow: 'hidden',
    height: 'space.1',
    visibility: 'hidden',
  }),
  gridCellVisible: css({
    textColor: 'text',
    lineHeight: '100%',
    paddingY: 'space.75',
    textAlign: 'center',
  }),
  highlight: css({
    backgroundColor: '#fff',
    borderRadius: '25px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
    left: 'space.0',
    lineHeight: '100%',
    paddingX: 'medium',
    height: 'fill',
    pointerEvents: 'none',
    position: 'absolute',
    top: 'space.1',
    transition: 'transform 200ms cubic-bezier(.77, 0, .175, 1)',
    width: '1/2',
    zIndex: 0,
  }),
  highlightAutomatic: css({
    transform: 'translate3d(0%, -1px, 0)',
  }),
  highlightManual: css({
    transform: 'translate3d(100%, -1px, 0)',
  }),
  inactive: css({
    font: 'label.medium',
    lineHeight: '100%',
  }),
  main: css({
    userSelect: 'none',
    transition: 'opacity 500ms cubic-bezier(.77, 0, .175, 1)',
    position: 'relative',
    opacity: 0,
    marginY: 'small',
    cursor: 'pointer',
    boxShadow: '0 0 0px 3px #eee',
    borderRadius: '25px',
    backgroundColor: '#eee',
  }),
  mainEnabled: css({
    opacity: 1,
  }),
};

/**
 * Th grid that is used to construct the spacing needed for the toggle.
 */
function Grid(props: {children: React.ReactNode}): JSX.Element {
  const {children} = props;
  // To prevent the screen reader from reading the grid, we utilize
  // `aria-hidden`.
  // It's important to note that the grid is solely employed for layout
  // spacing purposes.
  return (
    <view.table aria-hidden role="presentation" uses={[Styles.grid, table]}>
      <view.tbody>{children}</view.tbody>
    </view.table>
  );
}

function GridRow(props: {children: React.ReactNode}): JSX.Element {
  const {children} = props;
  return <view.tr uses={[tableRow]}>{children}</view.tr>;
}

function GridCell(props: {
  children: React.ReactNode;
  hidden?: boolean;
}): JSX.Element {
  const {children, hidden} = props;
  return (
    <view.td uses={[tableCell, tSmall, tBoldL, Styles.gridCell]}>
      <view.div
        uses={[hidden ? Styles.gridCellHidden : Styles.gridCellVisible]}
      >
        {children}
      </view.div>
    </view.td>
  );
}

/**
 * The highlight that moves to indicate the current selection. Automatic or
 * Manual.
 */
function Highlight(): JSX.Element {
  const {appState} = useAppController();
  const {workingInputMethod} = appState.document;
  const manualCapture = workingInputMethod === InputMethod.cameraManualCapture;
  return (
    <view.div
      uses={[
        Styles.highlight,
        manualCapture ? Styles.highlightManual : Styles.highlightAutomatic,
      ]}
    >
      &nbsp;
    </view.div>
  );
}

/**
 * The component that let the user toggle between manual and automatic document
 * capture.
 */
function DocumentManualCaptureToggle({intl}: IntlProps): JSX.Element {
  const {appState, appController} = useAppController();

  const {workingInputMethod} = appState.document;
  const manualCapture = workingInputMethod === InputMethod.cameraManualCapture;

  const handleToggle = useCallback(async () => {
    const method = manualCapture
      ? InputMethod.cameraAutoCapture
      : InputMethod.cameraManualCapture;

    await appController.setDocumentInputMethod({method});
  }, [appController, manualCapture]);

  const handleContextMenu = useCallback((event) => {
    // Prevent the context menu from showing up when the user right clicks on
    // desktop or long presses on mobile.
    event.preventDefault();
  }, []);

  const enabled = !!appState.camera.stream;

  const label = manualCapture
    ? intl.formatMessage(Messages.switchToAutomatic)
    : intl.formatMessage(Messages.switchToManual);

  const labelAutomatic = (
    <view.span uses={[!manualCapture ? Styles.active : Styles.inactive]}>
      {intl.formatMessage(Messages.automatic)}
    </view.span>
  );

  const labelManual = (
    <view.span uses={[manualCapture ? Styles.active : Styles.inactive]}>
      {intl.formatMessage(Messages.manual)}
    </view.span>
  );

  // To determine the width of the toggle, we need to consider the longer label.
  // Since we can't predict whether 'labelAutomatic' or 'labelManual' is wider,
  // we use <Grid /> to calculate the width based on the longest label's content.

  const styles = [inlineBlock, Styles.main, tapHighlightColorTransparent];
  if (enabled) {
    styles.push(Styles.mainEnabled);
  }

  return (
    <Action
      aria-label={label}
      onContextMenu={handleContextMenu}
      onPress={handleToggle}
      uses={styles}
    >
      <Highlight />
      <Grid>
        <GridRow>
          <GridCell hidden>{labelAutomatic}</GridCell>
          <GridCell hidden>{labelAutomatic}</GridCell>
        </GridRow>
        <GridRow>
          <GridCell>{labelAutomatic}</GridCell>
          <GridCell>{labelManual}</GridCell>
        </GridRow>
        <GridRow>
          <GridCell hidden>{labelManual}</GridCell>
          <GridCell hidden>{labelManual}</GridCell>
        </GridRow>
      </Grid>
    </Action>
  );
}

export default injectIntl(DocumentManualCaptureToggle);
