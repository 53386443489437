import {dispute} from '@sail/icons/react/Icon';
import {ButtonGroup} from '@sail/ui';
import * as React from 'react';

import Button from 'gelato/frontend/src/components/ButtonV2';
import messages from 'gelato/frontend/src/components/Invalid/messages';
import Message, {
  FormattedMarkdownMessage,
} from 'gelato/frontend/src/components/Message';
import {isMobileDevice} from 'gelato/frontend/src/lib/device';
import experiments from 'gelato/frontend/src/lib/experiments';
import {useRouter, useSession} from 'gelato/frontend/src/lib/hooks';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import {
  isChromeUA,
  isFirefoxUA,
  isSafariUA,
} from 'gelato/frontend/src/lib/userAgent';
import {InlineLink} from 'sail/InlineLink';

const PersmissionsHelp = () => {
  const mobile = isMobileDevice();
  const permissionHelpLink = (() => {
    if (mobile && isChromeUA()) {
      return 'https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DAndroid';
    } else if (!mobile && isChromeUA()) {
      return 'https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop';
    } else if (isFirefoxUA()) {
      return 'https://support.mozilla.org/en-US/kb/site-information-panel';
    } else if (!mobile && isSafariUA()) {
      return 'https://support.apple.com/guide/safari/customize-settings-per-website-ibrw7f78f7fe/mac';
    } else {
      return undefined;
    }
  })();

  if (permissionHelpLink) {
    const PermissionHelpLink = (text: any) => (
      <InlineLink
        href={permissionHelpLink}
        target={mobile ? undefined : '_blank'}
      >
        {text}
      </InlineLink>
    );
    return (
      <FormattedMarkdownMessage
        {...messages.permissionDeniedHelp}
        values={{PermissionHelpLink}}
      />
    );
  } else {
    return null;
  }
};

const Footer = () => {
  const session = useSession();
  const router = useRouter();

  React.useEffect(() => {
    postIframeEvent('STRIPE_IDENTITY_ERROR', {
      type: 'device_error',
      code: 'device_unsupported',
    });
  });

  let unsupportedReason;
  let operatingMode;

  if (session) {
    unsupportedReason = session.lastErrorDetails;
    operatingMode = session.operatingMode;
  }
  const onReloadPage = React.useCallback(async () => {
    // Reload the web page to ensure that the permissions are applied properly.
    // To communicate with the owner element of the containing iframe while
    // reloading the page, we need a safe and efficient solution that
    // minimizes wait times. Our approach is to create a new, lightweight
    // page that can quickly reload and effectively communicate with the
    // owner element of the containing iframe.
    router.push('/reload');
  }, [router]);

  const handleHandoff = () => router.push('/handoff');

  if (!operatingMode) {
    return null;
  }

  const buttons = [];

  if (unsupportedReason === 'permission_denied') {
    // In the permission denied case we prefer to have the user fix camera permissions rather than do a handoff
    buttons.push(
      <Button onClick={onReloadPage} id="camera_enabled">
        <Message {...messages.enabledCamera} />
      </Button>,
    );
    if (operatingMode === 'primary' && !experiments.isActive('no_handoff')) {
      buttons.push(
        <Button
          onClick={handleHandoff}
          type={buttons.length === 0 ? 'primary' : 'secondary'}
          id="use_different_browser"
        >
          <Message {...messages.useDifferentBrowser} />
        </Button>,
      );
    }
  } else {
    // otherwise the primary suuggestion is to perform aa handoff to a different device
    // we still offer the ive enabled camera button just in case but it is not the expected path
    if (operatingMode === 'primary' && !experiments.isActive('no_handoff')) {
      buttons.push(
        <Button onClick={handleHandoff} id="use_different_browser">
          <Message {...messages.useDifferentBrowser} />
        </Button>,
      );
    }
    if (unsupportedReason !== 'no_web_rtc') {
      buttons.push(
        <Button
          onClick={onReloadPage}
          id="camera_enabled"
          type={buttons.length === 0 ? 'primary' : 'secondary'}
        >
          <Message {...messages.enabledCamera} />
        </Button>,
      );
    }
  }

  return (
    <ButtonGroup direction="column" css={{gap: 'space.150'}}>
      {buttons}
    </ButtonGroup>
  );
};

const Title = () => {
  const session = useSession();
  let unsupportedReason;
  if (session) {
    unsupportedReason = session.lastErrorDetails;
  }
  const msg = (() => {
    if (unsupportedReason === 'no_webcam') {
      return messages.noWebcamTitle;
    } else if (unsupportedReason === 'permission_denied') {
      return messages.permissionDeniedTitle;
    } else if (unsupportedReason === 'no_web_rtc') {
      return messages.browserUnsupportedTitle;
    } else {
      return messages.deviceUnsupportedTitle;
    }
  })();
  return <Message {...msg} />;
};

const Description = () => {
  const session = useSession();
  let unsupportedReason;
  let operatingMode;

  if (session) {
    unsupportedReason = session.lastErrorDetails;
    operatingMode = session.operatingMode;
  }

  if (unsupportedReason === 'permission_denied') {
    return <PersmissionsHelp />;
  }

  const msg = (() => {
    if (unsupportedReason === 'no_webcam') {
      return operatingMode === 'primary'
        ? messages.noWebcamBody
        : messages.noWebcamBodySecondary;
    } else if (unsupportedReason === 'no_web_rtc') {
      return operatingMode === 'primary'
        ? messages.browserUnsupportedBody
        : messages.browserUnsupportedBodySecondary;
    } else {
      return operatingMode === 'primary'
        ? messages.deviceUnsupportedBody
        : messages.deviceUnsupportedBodySecondary;
    }
  })();

  return <Message {...msg} />;
};

export const generateDeviceUnsupportedContent = () => {
  return {
    icon: dispute,
    footer: <Footer />,
    title: <Title />,
    description: <Description />,
  };
};
