import clsx from 'clsx';
import * as React from 'react';
import {Helmet} from 'react-helmet';
import {defineMessages, injectIntl} from 'react-intl';

import Message from 'gelato/frontend/src/components/Message';
import useSubmitMutation from 'gelato/frontend/src/graphql/mutations/useSubmitMutation';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import {SetPageCardPropsContext} from 'gelato/frontend/src/lib/contexts';
import {nextDataPageForSession} from 'gelato/frontend/src/lib/dataRouting';
import flags from 'gelato/frontend/src/lib/flags';
import {
  useConnectIframe,
  useRouter,
  useSession,
} from 'gelato/frontend/src/lib/hooks';
import Button from 'sail/Button';
import {ErrorState, LoadingState} from 'sail/Content';

import styles from './submit.module.css';

import type {PageProps, LocalRouter} from 'gelato/frontend/src/lib/localRouter';
import type {IntlShape} from 'react-intl';

const messages = defineMessages({
  documentTitle: {
    id: 'page.submit.documentTitle',
    description: 'HTML title for verification submission page',
    defaultMessage: 'Submitting your verification | Stripe',
  },
  loadingTitle: {
    id: 'page.submit.loadingTitle',
    description: 'Message shown while submitting verification',
    defaultMessage: 'Submitting...',
  },
  errorTitle: {
    id: 'page.submit.errorTitle',
    description:
      'Error title when when there is an error submitting the verification',
    defaultMessage: 'Something went wrong',
  },
  errorDescription: {
    id: 'page.submit.errorDescription',
    description:
      'Error message for there is an error submitting the verification',
    defaultMessage: 'We were unable to submit your verification.',
  },
  errorAction: {
    id: 'page.submit.errorAction',
    description: 'Label on button allowing user to retry submission',
    defaultMessage: 'Try again',
  },
});

type Props = PageProps & {
  intl: IntlShape;
};

/**
 * Navigate to a route if it is not the current route.
 * @param router The router to use for navigation
 * @param route The route to navigate to.
 */
function gotoRoute(router: LocalRouter, route: string) {
  if (router.currentPath !== route) {
    router.push(route);
  }
}

const SubmitPage = (props: Props) => {
  const setPageCardProps = React.useContext(SetPageCardPropsContext);
  React.useEffect(() => {
    setPageCardProps({
      showBackLink: false,
      showPageHeader: true,
      size: 'single',
    });
  }, [setPageCardProps]);

  const {
    intl: {formatMessage},
    params,
  } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const forceAsyncUrlParam = urlParams.get('forceAsync') !== null;
  const forceDelay = !!params?.forceAsync || forceAsyncUrlParam;
  const isConnectIframe = useConnectIframe();
  const router = useRouter();
  const [submitVerification, {called, loading}] = useSubmitMutation();

  const session = useSession();

  const [retry, setRetry] = React.useState(false);

  const doSubmit = React.useCallback(
    async (forceDelay: boolean) => {
      if (loading) {
        return;
      }
      try {
        setRetry(false);
        const response = await submitVerification({
          variables: {forceDelay},
        });
        const submitData = response?.data?.submit;

        if (submitData) {
          if (!submitData.success) {
            setRetry(true);
          } else if (submitData.session.closed) {
            gotoRoute(router, '/success');
          } else {
            const {missingFields, requiredFields} = submitData.session;
            const nextPage = await nextDataPageForSession({
              missingFields,
              requiredFields,
              rLCapture: session?.rLCapture || false,
            });
            gotoRoute(router, nextPage);
          }
        }
      } catch (err: any) {
        setRetry(true);
      }
    },
    [submitVerification, router, loading, session],
  );

  // this useEffect handles calling the submitMutation to get submission going
  React.useEffect(() => {
    if (!session) {
      return;
    }
    if (session.closed) {
      gotoRoute(router, '/success');
    } else if (session.submitted && session.missingFields.length > 0) {
      nextDataPageForSession(session).then((route) => {
        gotoRoute(router, route);
      });
    } else if (session.missingFields.length > 0) {
      nextDataPageForSession(session).then((route) => {
        gotoRoute(router, route);
      });
    } else if (!called) {
      doSubmit(forceDelay);
    }
  }, [session, router, called, forceDelay, doSubmit]);

  const renderContent = () => {
    if (retry) {
      return (
        <ErrorState
          actions={
            <Button
              disabled={loading}
              icon="refresh"
              iconPosition="right"
              label={<Message {...messages.errorAction} />}
              onClick={() => doSubmit(false)}
            />
          }
          description={<Message {...messages.errorDescription} />}
          // @ts-expect-error - TS2769 - No overload matches this call.
          scope="page"
          title={<Message {...messages.errorTitle} />}
        />
      );
    } else {
      return (
        <LoadingState
          className={clsx(styles.submit, {
            [styles.seamless]: isConnectIframe,
          })}
          // @ts-expect-error - TS2769 - No overload matches this call.
          scope="page"
          title={<Message {...messages.loadingTitle} />}
        />
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>{formatMessage(messages.documentTitle)}</title>
      </Helmet>
      {renderContent()}
    </>
  );
};

setComponentConfig(SubmitPage, {componentName: 'submit'});

export default injectIntl(SubmitPage);
