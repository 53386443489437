import {view, TextField} from '@sail/ui';
import * as React from 'react';
import {defineMessages, injectIntl} from 'react-intl';

import Message, {IntlProps} from 'gelato/frontend/src/components/Message';
import {IndividualNameFields} from 'gelato/frontend/src/controllers/states/IndividualState';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

const {useCallback} = React;

const Messages = defineMessages({
  firstNameLabel: {
    id: 'form.individual.name.firstName.label',
    description: `Label for the form asking for individual’s first name`,
    defaultMessage: 'First name',
  },
  lastNameLabel: {
    id: 'form.individual.name.lastName.label',
    description: `Label for the form asking for individual’s last name`,
    defaultMessage: 'Last name',
  },
  firstNamePlaceholder: {
    id: 'form.individual.name.firstName.placeholder',
    description: `Placeholder for the individual’s first name field`,
    defaultMessage: 'Jenny',
  },
  lastNamePlaceholder: {
    id: 'form.individual.name.lastName.placeholder',
    description: `Placeholder for the individual’s last name field`,
    defaultMessage: 'Rosen',
  },
});

const NameInput = injectIntl(({intl}: IntlProps) => {
  const {appController} = useAppController();
  const {session, individual} = appController.state;

  const handleChange = useCallback(
    (type: IndividualNameFields, value: string) => {
      appController.setIndividualCollectedData({name: {[type]: value}});
    },
    [appController],
  );

  const handleBlur = useCallback(
    (type: IndividualNameFields) => {
      return () => {
        appController.validateIndividualData(
          {name: {[type]: individual.name?.[type]}},
          intl,
        );
      };
    },
    [appController, individual.name, intl],
  );

  if (!session) {
    return null;
  }
  const {firstName, lastName} = session.collectedData.individual.name;
  const {validationMsg} = individual.name || {};
  let firstNameError;
  let lastNameError;
  if (validationMsg) {
    // Handle nested error messages
    if (typeof validationMsg === 'string' || validationMsg instanceof String) {
      firstNameError = validationMsg;
      lastNameError = validationMsg;
    } else if ('firstName' in validationMsg || 'lastName' in validationMsg) {
      firstNameError = validationMsg?.firstName;
      lastNameError = validationMsg?.lastName;
    }
  }

  return (
    <view.div css={{stack: 'y', gap: 'medium'}}>
      <TextField
        label={<Message {...Messages.firstNameLabel} />}
        defaultValue={firstName || ''}
        required
        name="name.firstName"
        placeholder={intl.formatMessage(Messages.firstNamePlaceholder)}
        onChange={(e) => handleChange('firstName', e.target.value)}
        onBlur={handleBlur('firstName')}
        error={firstNameError}
      />
      <TextField
        label={<Message {...Messages.lastNameLabel} />}
        defaultValue={lastName || ''}
        name="name.lastName"
        required
        placeholder={intl.formatMessage(Messages.lastNamePlaceholder)}
        onChange={(e) => handleChange('lastName', e.target.value)}
        onBlur={handleBlur('lastName')}
        error={lastNameError}
      />
    </view.div>
  );
});

export default NameInput;
