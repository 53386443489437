import {splitBalanced, createCustomProperty} from '@sail/engine';
import type {Style} from '@sail/engine';

export const paddingTopProp = createCustomProperty('--padding-top', {
  initialValue: '0px',
});
const paddingRightProp = createCustomProperty('--padding-right', {
  initialValue: '0px',
});
export const paddingBottomProp = createCustomProperty('--padding-bottom', {
  initialValue: '0px',
});
const paddingLeftProp = createCustomProperty('--padding-left', {
  initialValue: '0px',
});

const paddingTopRef = createCustomProperty('--padding-top-ref', {
  initialValue: '0px',
});
const paddingRightRef = createCustomProperty('--padding-right-ref', {
  initialValue: '0px',
});
const paddingBottomRef = createCustomProperty('--padding-bottom-ref', {
  initialValue: '0px',
});
const paddingLeftRef = createCustomProperty('--padding-left-ref', {
  initialValue: '0px',
});

function parseBoxModelShorthand(
  value: string,
): [string, string, string, string] {
  const [top, right, bottom, left] = splitBalanced(value);
  return [top, right ?? top, bottom ?? top, left ?? right ?? top];
}

function marginX(value: string, set: Style.PluginAPI): void {
  set.property('marginLeft', value);
  set.property('marginRight', value);
}

function marginY(value: string, set: Style.PluginAPI): void {
  set.property('marginTop', value);
  set.property('marginBottom', value);
}

function paddingX(value: string, set: Style.PluginAPI): void {
  set.property('paddingLeft', value);
  set.property('paddingRight', value);
}

function paddingY(value: string, set: Style.PluginAPI): void {
  set.property('paddingTop', value);
  set.property('paddingBottom', value);
}

const paddingProps = {
  paddingTop: [paddingTopProp, paddingTopRef],
  paddingRight: [paddingRightProp, paddingRightRef],
  paddingBottom: [paddingBottomProp, paddingBottomRef],
  paddingLeft: [paddingLeftProp, paddingLeftRef],
};

function setPaddingProperty(
  set: Style.PluginAPI,
  property: keyof typeof paddingProps,
  value: string,
) {
  const [paddingProp] = paddingProps[property];
  set.property(paddingProp, value);
}

function createPaddingPlugin(property?: keyof typeof paddingProps) {
  return function paddingPlugin(value: string, set: Style.PluginAPI) {
    // `value` can sometimes be the string 'undefined'; bail out if so
    // TODO(koop): Figure out why this is happening
    if (value === 'undefined') {
      return;
    }

    if (property) {
      const paddingStr = [
        set.var(paddingTopProp),
        set.var(paddingRightProp),
        set.var(paddingBottomProp),
        set.var(paddingLeftProp),
      ].join(' ');
      set.property(property, () => {
        set.property('padding', paddingStr, {skipPlugin: true});
        setPaddingProperty(set, property, value);
      });
    } else {
      // Clear out existing longhand values so the shorthand takes precedence
      set.property('paddingTop', undefined);
      set.property('paddingRight', undefined);
      set.property('paddingBottom', undefined);
      set.property('paddingLeft', undefined);

      set.property('padding', () => {
        const [top, right, bottom, left] = parseBoxModelShorthand(value);
        set.property('padding', value);
        setPaddingProperty(set, 'paddingTop', top);
        setPaddingProperty(set, 'paddingRight', right);
        setPaddingProperty(set, 'paddingBottom', bottom);
        setPaddingProperty(set, 'paddingLeft', left);
      });
    }
  };
}

function insetX(value: string, set: Style.PluginAPI): void {
  set.property('left', value);
  set.property('right', value);
}

function insetY(value: string, set: Style.PluginAPI): void {
  set.property('top', value);
  set.property('bottom', value);
}

function createInsetPlugin() {
  return function insetPlugin(value: string, set: Style.PluginAPI) {
    // shorthand value may be calc or var, so we need to be careful to match
    // parens when splitting
    const [top, right, bottom, left] = splitBalanced(value);
    set.property('top', top);
    set.property('right', right ?? top);
    set.property('bottom', bottom ?? top);
    set.property('left', left ?? right ?? top);
  };
}

const negative = (value: string) => `calc(-1 * ${value})`;

type MarginProperty =
  | 'marginTop'
  | 'marginBottom'
  | 'marginLeft'
  | 'marginRight';

function createBleedPlugin(...marginProperties: MarginProperty[]) {
  return function bleedPlugin(value: string, set: Style.PluginAPI) {
    marginProperties.forEach((marginProperty) => {
      if (value === 'auto') {
        if (process.env.NODE_ENV !== 'production') {
          throw new Error(
            `Bleed 'auto' is not supported. Please use fixed values instead.'`,
          );
        }
      } else {
        set.property(marginProperty, negative(value));
      }
    });
  };
}

export const pluginsBoxModel = {
  marginX,
  marginY,
  padding: createPaddingPlugin(),
  paddingTop: createPaddingPlugin('paddingTop'),
  paddingBottom: createPaddingPlugin('paddingBottom'),
  paddingLeft: createPaddingPlugin('paddingLeft'),
  paddingRight: createPaddingPlugin('paddingRight'),
  paddingX,
  paddingY,
  bleed: createBleedPlugin(
    'marginTop',
    'marginBottom',
    'marginLeft',
    'marginRight',
  ),
  bleedX: createBleedPlugin('marginLeft', 'marginRight'),
  bleedY: createBleedPlugin('marginTop', 'marginBottom'),
  bleedTop: createBleedPlugin('marginTop'),
  bleedBottom: createBleedPlugin('marginBottom'),
  bleedLeft: createBleedPlugin('marginLeft'),
  bleedRight: createBleedPlugin('marginRight'),
  inset: createInsetPlugin(),
  insetX,
  insetY,
};
