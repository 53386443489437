import {css, Badge, Toggle, ToggleItem, view, ButtonGroup} from '@sail/ui';
import * as React from 'react';
import {FormattedMessage, defineMessages, injectIntl} from 'react-intl';

import Button from 'gelato/frontend/src/components/ButtonV2';
import {ErrorState} from 'gelato/frontend/src/components/ContentStateV2';
import {
  labelMessages,
  descriptionMessages,
  SLAMessages,
} from 'gelato/frontend/src/components/FaceUpload/SelfieVerificationMethodSelectBox';
import Message from 'gelato/frontend/src/components/Message';
import PageCard from 'gelato/frontend/src/components/PageCardV2';
import {responsiveCss} from 'gelato/frontend/src/components/stylesV2';
import TopNavigationBar from 'gelato/frontend/src/components/TopNavigationBar';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

import type {SelfieVerificationMethod} from '@stripe-internal/data-gelato/schema/types';

const Styles = {
  title: css({
    font: 'heading.small',
  }),
  toggleItemContainer: css({
    marginY: 'large',
  }),
  toggleItem: css({
    marginBottom: 'medium',
  }),
  toggleItemLabel: css({
    marginBottom: 'medium',
    stack: 'x',
    gap: 'small',
  }),
  footer: css({
    paddingX: 'space.250',
  }),
  footerButtons: responsiveCss({
    compact: {
      padding: '8px 0',
    },
    regular: {
      padding: '16px 0',
    },
  }),
  footerResponsive: responsiveCss({
    compact: {
      paddingBottom: '8px',
      paddingTop: '8px',
    },
  }),
};

const selfieVerificationMethods: ReadonlyArray<SelfieVerificationMethod> = [
  'automated',
  'manual',
];

const messages = defineMessages({
  selfieVerificationMethodTitle: {
    id: 'pages.selfie_verification_method.selfie_verification_method_header',
    description: 'Header label in selfie verification select page',
    defaultMessage: `Select how you’d like to be verified`,
  },
  nextButton: {
    id: 'pages.selfie_verification_method.nextButton',
    description: 'Next button text',
    defaultMessage: 'Next',
  },
  goBack: {
    id: 'pages.selfie_verification_method.goBack',
    description: 'Allow the user to go back a page',
    defaultMessage: 'Go back',
  },
  genericErrorTitle: {
    id: 'error.generic.title',
    description:
      'Error title when when there is a generic error when submitting individual data',
    defaultMessage: 'Something went wrong',
  },
  genericErrorDescription: {
    id: 'error.generic.description',
    description:
      'Error message for when there is a generic error when submitting individual data',
    defaultMessage: 'Please try again.',
  },
});

const {useCallback} = React;

const SelfieVerificationMethodSelectBox = () => {
  const {appController} = useAppController();
  const {config} = appController.state;
  const {selfieVerificationMethod} = config;
  const onSelectionChange = useCallback(
    (value: string) => {
      appController.setSelfieVerificationMethod(
        value as SelfieVerificationMethod,
      );
    },
    [appController],
  );

  return (
    <Toggle
      label={<Message {...messages.selfieVerificationMethodTitle} />}
      size="medium"
      uses={[Styles.toggleItemContainer]}
      layout="vertical"
      selectedValue={selfieVerificationMethod || undefined}
      hiddenElements={['label']}
      onSelectionChange={(value: string) => onSelectionChange(value)}
    >
      {selfieVerificationMethods.map((choice) => {
        const label = (
          <view.div uses={[Styles.toggleItemLabel]}>
            <Message {...labelMessages[choice]} />
            <Badge>
              <FormattedMessage {...SLAMessages[choice]} />
            </Badge>
          </view.div>
        );
        return (
          <ToggleItem
            uses={[Styles.toggleItem]}
            key={choice}
            value={choice}
            label={label}
            data-testid={`idprod-${choice}-toggle`}
            description={<Message {...descriptionMessages[choice]} />}
          />
        );
      })}
    </Toggle>
  );
};

const SelfieVerificationMethodPage = () => {
  const {appController} = useAppController();
  const {config} = appController.state;
  const {selfieVerificationMethod} = config;

  const handleNext = useCallback(async () => {
    await appController.saveSelfieVerificationMethod();
    appController.routeToNextPage({
      reason: 'leave_document_types',
      caller: 'SelfieVerificationMethodPage',
    });
  }, [appController]);

  if (appController.state.error) {
    const errorBody = (
      <ErrorState
        title={<Message {...messages.genericErrorTitle} />}
        description={<Message {...messages.genericErrorDescription} />}
      />
    );
    return <PageCard body={errorBody} />;
  }

  // Only show the loading page when there is a pending mutation request
  // Or when the request has succeeded and value?.selfieVerificationMethod has been defined
  if (
    appController.state.mutation.consent.pending ||
    !!appController.state.mutation.consent.value?.selfieVerificationMethod
  ) {
    return <PageCard loading />;
  }

  const body = (
    <view.div>
      <view.div uses={[Styles.title]}>
        <Message {...messages.selfieVerificationMethodTitle} />
      </view.div>
      <SelfieVerificationMethodSelectBox />
    </view.div>
  );

  const footer = (
    <view.div uses={[Styles.footer, Styles.footerResponsive]}>
      <ButtonGroup direction="column" uses={[Styles.footerButtons]}>
        <Button
          data-testid="idprod-selfie-select-next-button"
          disabled={selfieVerificationMethod === null}
          onPress={handleNext}
        >
          <Message {...messages.nextButton} />
        </Button>
      </ButtonGroup>
    </view.div>
  );
  const header = <TopNavigationBar />;

  return <PageCard body={body} header={header} footer={footer} />;
};

export default injectIntl(SelfieVerificationMethodPage);
