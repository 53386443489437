import {ApolloClient, ApolloLink} from '@apollo/client';
import {getEnvironment} from '@sail/utils';
import getCompatibleMap from 'src/internal/apollo/getCompatibleWeakMap';
import createDeduplicationLink from 'src/internal/prefetching/createDeduplicationLink';
import createPrefetchLink from 'src/internal/prefetching/createPrefetchLink';
import createRuntimePermissionsCheckLink from 'src/internal/prefetching/createRuntimePermissionsCheckLink';
import createCache from 'src/public/graphql/createCache';
import createDirectivesToContextLink from 'src/public/links/createDirectivesToContextLink';
import createObservabilityLink from 'src/public/links/createObservabilityLink';
import createSplitLink from 'src/public/links/createSplitLink';
import createTimeoutLink from 'src/public/links/createTimeoutLink';

import type {InMemoryCache} from '@apollo/client';
import type {GraphQlConfig} from 'src/internal/config/types';

export const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-first',
  },
} as const;

const CompatibleMap = getCompatibleMap();

const sharedApolloCaches = new CompatibleMap<symbol, InMemoryCache>();

const createApolloCache = (graphqlConfig: GraphQlConfig): InMemoryCache => {
  if (graphqlConfig.sharedCache && graphqlConfig.sharedCacheId) {
    throw new Error('Cannot specify both sharedCache and sharedCacheId');
  }

  if (graphqlConfig.sharedCache) {
    return graphqlConfig.sharedCache;
  }

  if (graphqlConfig.sharedCacheId) {
    const existingCache = sharedApolloCaches.get(graphqlConfig.sharedCacheId);

    if (existingCache) {
      return existingCache;
    }
  }

  const cache = createCache(graphqlConfig.schemas);

  if (graphqlConfig.sharedCacheId) {
    sharedApolloCaches.set(graphqlConfig.sharedCacheId, cache);
  }

  return cache;
};

function createApolloLink(graphqlConfig: GraphQlConfig): ApolloLink {
  return ApolloLink.from([
    createDirectivesToContextLink(),
    createObservabilityLink(),
    createTimeoutLink(),
    createRuntimePermissionsCheckLink(),
    createPrefetchLink(
      graphqlConfig.prefetch ?? {enablePrefetchCounterInRefetch: false},
    ),
    createDeduplicationLink(),
    createSplitLink(graphqlConfig),
  ]);
}

export const createApolloClient = (
  graphqlConfig: GraphQlConfig,
): ApolloClient<any> => {
  return new ApolloClient({
    connectToDevTools: getEnvironment() === 'development',
    link: createApolloLink(graphqlConfig),
    cache: createApolloCache(graphqlConfig),
    defaultOptions,
    assumeImmutableResults: true,
    queryDeduplication: false,
  });
};
