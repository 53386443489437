import {css} from '@sail/ui';
import * as React from 'react';
import {defineMessages} from 'react-intl';

import BottomSheet, {
  Messages as BottomSheetMessages,
} from 'gelato/frontend/src/components/BottomSheetV2';
import Button from 'gelato/frontend/src/components/ButtonV2';
import Heading from 'gelato/frontend/src/components/HeadingV2';
import Link from 'gelato/frontend/src/components/LinkV3';
import ListItem from 'gelato/frontend/src/components/ListItemV2';
import List from 'gelato/frontend/src/components/ListV2';
import Message from 'gelato/frontend/src/components/Message';
import {
  STRIPE_CONTACT_URL,
  STRIPE_PRIVACY_CENTER_URL,
} from 'gelato/frontend/src/lib/constants';
import getBrandingPlatformName from 'gelato/frontend/src/lib/getBrandingPlatformName';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

const Styles = {
  h2: css({
    font: 'heading.large',
  }),
  list: css({
    font: 'body.medium',
  }),
  secondaryLink: css({
    textDecorationColor: '#99a5b8',
    font: 'body.small',
    fontWeight: 'normal',
  }),
};

// Sort alphabetically by id.
const Messages = defineMessages({
  dataEncryption: {
    defaultMessage: 'Your data is encrypted',
    description: `Statement about the encryption of user’s data`,
    id: 'verification.varificationDataSheet.dataEncryption',
  },
  deleteDataRequest: {
    defaultMessage: 'You can delete your data by contacting {deleteDataLink}.',
    description: `Instruction to delete user’s data`,
    id: 'verification.varificationDataSheet.deleteDataRequest',
  },
  manageData: {
    defaultMessage: 'Manage your data',
    description: `Instruction to manage user’s data`,
    id: 'verification.varificationDataSheet.manageData',
  },
  merchantAccess: {
    defaultMessage: '{platformName} access',
    description: 'Reference to access related to the merchant',
    id: 'verification.varificationDataSheet.merchantAccess',
  },
  merchantAccesssDetails: {
    defaultMessage:
      '{platformName} will have access to the information you submit and ' +
      'the status of your verification, and may use your information under ' +
      'its privacy policy.',
    description:
      'Statement about the access of the merchant to the submitted information and verification status',
    id: 'verification.varificationDataSheet.merchantAccessDetails',
  },
  dataEncryptionDescription: {
    defaultMessage:
      'Stripe handles billions of dollars in payments annually. The same ' +
      'infrastructure keeps identity verification data safe as well.',
    description: 'Explanation of the encryption of user data',
    id: 'verification.varificationDataSheet.dataEncryptionDescription',
  },
  dataEncryptionDescriptionInternalLink: {
    defaultMessage:
      'Link handles billions of dollars in payments annually. The same ' +
      'infrastructure keeps identity verification data safe as well.',
    description: 'Explanation of the encryption of user data',
    id: 'verification.varificationDataSheet.dataEncryptionDescriptionForLink',
  },
  yourData: {
    defaultMessage: 'Your data',
    description: `Reference to the user’s data`,
    id: 'verification.varificationDataSheet.yourData',
  },
  yourDataV2: {
    defaultMessage: 'Your verification data',
    description: `Reference to the user’s data`,
    id: 'verification.varificationDataSheet.yourDataV2',
  },

  stripeDataUse: {
    defaultMessage: 'Stripe data use',
    description: `Title for Stripe’s identity privacy policy`,
    id: 'verification.varificationDataSheet.stripeDataUse',
  },
  stripeDataUseDescription: {
    defaultMessage:
      'Stripe will use and store your data under Stripe’s privacy policy, ' +
      'including to manage loss and for legal compliance. ' +
      '{learnMoreLink}.',
    description: `Statement about user’s data usage within Stripe.`,
    id: 'verification.varificationDataSheet.stripeDataUseDescription',
  },

  linkDataUse: {
    defaultMessage: 'Link data use',
    description: `Title for Link’s identity privacy policy`,
    id: 'verification.varificationDataSheet.linkDataUse',
  },
  linkDataUseDescription: {
    defaultMessage:
      'Link will use and store your data under Link’s privacy policy, ' +
      'including to manage loss and for legal compliance. ' +
      '{learnMoreLink}.',
    description: `Statement about user’s data usage within Link.`,
    id: 'verification.varificationDataSheet.linkDataUseDescription',
  },

  learnMore: {
    defaultMessage: 'Learn more',
    description: 'Reference to learn more',
    id: 'verification.varificationDataSheet.learnMore',
  },
});

/**
 * The flyout sheet that displays information about the user's data in the
 * verification flow.
 */
export default function VarificationDataSheet(): JSX.Element {
  const {appController, appState} = useAppController();
  const branding = appState.session?.branding;
  const platformName = getBrandingPlatformName(branding);

  const onClose = () => appController.closeLayer(VarificationDataSheet);

  let deleteDataLink;
  if (branding?.isStripe) {
    deleteDataLink = (
      <Link
        uses={[Styles.secondaryLink]}
        type="secondary"
        target="_blank"
        href={STRIPE_CONTACT_URL}
      >
        {platformName}
      </Link>
    );
  } else {
    // TODO: How do we handle this for non-Stripe merchants to let user to
    // contact the merchant?
    deleteDataLink = platformName;
  }

  const learnMoreLink = (
    <Link
      uses={[Styles.secondaryLink]}
      type="secondary"
      target="_blank"
      href={STRIPE_PRIVACY_CENTER_URL}
    >
      <Message {...Messages.learnMore} />
    </Link>
  );

  const items = [
    <ListItem icon="lock" key="dataEncryption" useDefaultIconColor>
      <Heading level={3}>
        <Message {...Messages.dataEncryption} />
      </Heading>
      <Message {...Messages.dataEncryptionDescription} />
    </ListItem>,
    <ListItem icon="lock" key="stripeDataUse" useDefaultIconColor>
      <Heading level={3}>
        <Message {...Messages.stripeDataUse} />
      </Heading>
      <Message
        {...Messages.stripeDataUseDescription}
        values={{learnMoreLink}}
      />
    </ListItem>,
  ];

  if (!branding?.isStripe) {
    items.push(
      <ListItem icon="moved" key="merchantAccess" useDefaultIconColor>
        <Heading level={3}>
          <Message
            {...Messages.merchantAccess}
            values={{
              platformName,
            }}
          />
        </Heading>
        <Message
          {...Messages.merchantAccesssDetails}
          values={{
            platformName,
          }}
        />
      </ListItem>,
    );
  }

  items.push(
    <ListItem icon="customerPortal" key="deleteDataLink" useDefaultIconColor>
      <Heading level={3}>
        <Message {...Messages.manageData} />
      </Heading>
      <Message {...Messages.deleteDataRequest} values={{deleteDataLink}} />
    </ListItem>,
  );

  const footer = (
    <Button onPress={onClose} type="primary">
      <Message {...BottomSheetMessages.close} />
    </Button>
  );

  const yourDataMsg =
    appState.locale.currentValue[0] === 'en-US'
      ? Messages.yourDataV2
      : Messages.yourData;

  return (
    <BottomSheet data-testid="verification-data-sheet" footer={footer}>
      <Heading level={2} uses={[Styles.h2]}>
        <Message {...yourDataMsg} />
      </Heading>
      <List uses={[Styles.list]}>{items}</List>
    </BottomSheet>
  );
}
