import {css, tokens} from '@sail/ui';

export const Styles = {
  calculateActionInnerDynamic: (selected: boolean, safeButtonColor: string) =>
    css({
      boxShadow: selected ? 'small' : 'none',
      borderColor: selected ? safeButtonColor : 'border',
      borderWidth: selected ? 'medium' : 'small',
      padding: selected
        ? 'medium'
        : `calc(${tokens.space.medium} + ${tokens.border.width.medium} - ${tokens.border.width.small})`,
    }),
  largeLabel: css({
    font: 'label.large.emphasized',
  }),
  mediumLabel: css({
    color: 'subdued',
    font: 'label.medium',
  }),
  selectContent: css({
    stack: 'y',
  }),
  selectWrapper: css({
    gap: 'space.150',
    stack: 'y',
  }),

  action: css({
    '&:focus&': {
      '--focus-ring': 'none',
    },
  }),
  actionInnerStatic: css({
    alignY: 'center',
    borderColor: 'border',
    borderRadius: 'large',
    borderStyle: 'solid',
    borderWidth: 'medium',
    gap: 'medium',
    // padding: 'medium',
    stack: 'x',
  }),
  text: css({color: 'onAction.secondary'}),
  typeIconWrapperStatic: css({
    alignX: 'center',
    alignY: 'center',
    borderRadius: 'large',
    height: 'space.700',
    stack: 'x',
    width: 'space.700',
    backgroundColor: tokens.color.neutral[25],
  }),
};
