import {Cookies, LocalStorage} from '@stripe-internal/stripe-cookies';

import UAParser from 'ua-parser-js';
import type {AnalyticsConfig, RequestFailedType} from './types';
import {defaultAnalyticsConfig, prodUrl, qaUrl} from './types';
import {isBot} from '../utils/botDetect';
import {generateID, snakeCase, toQueryString} from '../utils/string';
import {injectGoogleAnalytics} from '../utils/googleAnalytics';
import {memoizeForHalfSecond} from '../utils/memoizeForHalfSecond';
import {BatchLogger} from '../utils/batchLogger';
import {deepMerge} from '../utils/deepMerge';
import {cleanPagePath, scrubValues} from '../utils/piiScrubber';
import {warn} from '../utils/warn';
import {
  Reporter,
  TrackResult,
  TrackingIssue,
  ExtendedEventName,
} from './reporter';

type UAParams = {
  ua_browser_name?: string;
  ua_browser_version?: string;
  ua_os_name?: string;
  ua_os_version?: string;
  ua_device_model?: string;
  ua_device_type?: string;
  ua_device_vendor?: string;
  ua_engine_name?: string;
  ua_engine_version?: string;
};

type GlobalParams = {
  domain: string;
  page: string;
  referrer: string;
  cid: string;
  lsid: string;
  viewport_height: number;
  viewport_width: number;
  analytics_ua: string;
  sid?: string;
  bot?: boolean;
} & UAParams;

type TrackingFunction = (
  arg1: string,
  onAnalyticsRequestFailed?: (
    type: RequestFailedType,
    numEvents: number,
    error?: Error,
  ) => void,
) => void;

type State = {
  lastModalEventName?: string;
  lastViewedEventName?: string;
  trackCounter: number;
};

type ClientIDs = {
  cid: string;
  lsid: string;
};

// Internal state for things we've called once.
// This is intentionally shared between all instances of the AnalyticsReporter class.
const ONCE_CACHE: {
  track: {[key: string]: unknown};
  viewed: {[key: string]: unknown};
  modal: {[key: string]: unknown};
  action: {[key: string]: unknown};
  link: {[key: string]: unknown};
} = {
  track: {},
  viewed: {},
  modal: {},
  action: {},
  link: {},
};

// Takes a url and fires a request via an Image element.
const injectPixel: TrackingFunction = (
  url: string,
  onAnalyticsRequestFailed?: (
    type: RequestFailedType,
    numEvents: number,
    error?: Error,
  ) => void,
) => {
  try {
    const pxl = new Image();
    pxl.onerror = function () {
      warn('pixel_onerror_event', url);

      onAnalyticsRequestFailed?.('ae-pixel-error', 1);
    };
    pxl.src = url;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    warn('pixel_thrown_error', e);
    onAnalyticsRequestFailed?.('ae-pixel-throws', 1, e);
  }
};

const SK_REGEX = new RegExp('(sk|rk)_live_[a-zA-Z0-9]*', 'gm');

const cleanObjectInner = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any,
  onTrackingIssue: (arg1: TrackingIssue) => void,
  depth: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  let newObject = object;
  // set a limit on depth to prevent recursive objects from going on infinitely
  if (newObject !== null && depth < 100) {
    switch (typeof newObject) {
      case 'string':
        newObject = newObject.replace(SK_REGEX, '[REDACTED_LIVE_KEY]');
        break;
      case 'object':
        if (newObject instanceof Array) {
          // Prevent modifying the original array reference
          newObject = [...newObject];
          const length = newObject.length;
          for (let i = 0; i < length; i++) {
            newObject[i] = cleanObjectInner(
              newObject[i],
              onTrackingIssue,
              depth + 1,
            );
          }
        } else {
          // Prevent modifying the original object reference
          newObject = {...newObject};
          for (const i in newObject) {
            if (Object.prototype.hasOwnProperty.call(newObject, i)) {
              newObject[i] = cleanObjectInner(
                newObject[i],
                onTrackingIssue,
                depth + 1,
              );
            }
          }
        }
        break;
    }
  } else if (depth >= 100) {
    onTrackingIssue('recursive_object');
  }

  return newObject;
};

const cleanObject = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any,
  onTrackingIssue: (arg1: TrackingIssue) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  const params = cleanObjectInner(object, onTrackingIssue, 0);

  if ('page' in params) {
    params.page = cleanPagePath(`${params.page}`);
  }

  if ('referrer' in params) {
    params.referrer = cleanPagePath(`${params.referrer}`);
  }

  return params;
};

// This returns the path if we're missing a name for the page
export const getBackupViewedName = <T extends string>(
  eventName: T,
  lastViewedEventName?: string | null,
): string => {
  // If the user didn't pass in a viewed_name, but we saved the last call
  // to `Analytics.viewed`. So we can just use that value with some
  // confidence.
  if (lastViewedEventName) {
    return lastViewedEventName;
  } else {
    // In this case, we don't have any `viewed_name` information to send
    // along with the request. Warn, but don't lose the event entirely.
    warn('event_with_no_viewed', eventName);
    // Lets send the URL path when we don't have the `viewed_name` so
    // we can identify where its missing and fix those cases
    return document.location.pathname;
  }
};

const ua = new UAParser().getResult();

const userAgentParams: UAParams = {
  ua_browser_name: ua.browser.name,
  ua_browser_version: ua.browser.version,
  ua_os_name: ua.os.name,
  ua_os_version: ua.os.version,
  ua_device_model: ua.device.model,
  ua_device_type: ua.device.type,
  ua_device_vendor: ua.device.vendor,
  ua_engine_name: ua.engine.name,
  ua_engine_version: ua.engine.version,
};

Object.entries(userAgentParams).forEach(([key, value]) => {
  if (!value) {
    // Delete undefined values so they are not reported as "undefined" strings
    delete userAgentParams[key as keyof UAParams];
  }
});

// This returns global parameters which are generated for each event.
export const getGlobalParams = (
  config: AnalyticsConfig,
  clientIDs: ClientIDs,
  sessionID?: string,
): GlobalParams => {
  // The page is the path of the page following the domain
  const documentPage =
    document.location.pathname +
    document.location.search +
    document.location.hash;

  const page = config.maxPageLength
    ? documentPage.substr(0, config.maxPageLength)
    : documentPage;

  const referrer = document.referrer;

  const params: GlobalParams = {
    domain: window.location.host,
    page: cleanPagePath(page),
    referrer: cleanPagePath(referrer),
    cid: clientIDs.cid,
    lsid: clientIDs.lsid,
    viewport_height: window.innerHeight,
    viewport_width: window.innerWidth,
    analytics_ua: 'analytics.js-CURRENT_VERSION',
    ...userAgentParams,
  };

  if (config.sessionization && sessionID) {
    params.sid = sessionID;
  }

  if (config.botDetection && isBot()) {
    // Only add if true to reduce payload size for non-bot traffic
    params.bot = true;
  }

  return params;
};

// We store cachedClientIDs as a singleton that can be shared across multiple AnalyticsReporter instances.
// This avoids race conditions during async I/O to cookies/localStorage when using multiple reporters. If
// you need unique IDs per client, disable set the 'memoizeCookieID' config to 'false'.
let cachedClientIDs: Promise<ClientIDs> | null = null;

let cachedSessionID: Promise<string> | null = null;

export class AnalyticsReporter<T extends string> implements Reporter<T> {
  config: AnalyticsConfig;

  cookies?: Cookies;

  localStorage?: LocalStorage;

  state: State;

  trackingFunctions: Array<TrackingFunction>;

  _namedUrls: {
    [key: string]: string;
  } = {
    prod: prodUrl,
    qa: qaUrl,
  };

  prefix?: string;

  batchLogger?: BatchLogger;

  _extendSessionExpirationPromise: Promise<void> | null;

  constructor(config: AnalyticsConfig = defaultAnalyticsConfig) {
    const {stripeCookiesEnforcementMode: enforcementMode} = config;
    this.config = {...defaultAnalyticsConfig, ...config};
    this.state = {trackCounter: 0};
    this.trackingFunctions = [injectPixel];
    this.prefix = config.namespace ?? '';
    if (config.batchSettings?.enableBatching) {
      this.batchLogger = new BatchLogger(this.config);
    }

    if (config.useCookies !== 'disabled') {
      this.cookies = new Cookies({enforcementMode});
      this.localStorage = new LocalStorage({enforcementMode});
    }

    this._extendSessionExpirationPromise = null;

    this.configure(this.config);
  }

  configure(config: Partial<AnalyticsConfig>): Promise<void> {
    if (config.googleAnalytics) {
      injectGoogleAnalytics(config);
    }
    // Since we have some nested objects here use recursive merge to prevent overriding already set objects
    this.config = deepMerge<AnalyticsConfig>(this.config, config);

    if (this.config.inProduction === undefined) {
      this.config.inProduction = true;
    }

    if (config.namespace) {
      this.prefix = config.namespace;
    }

    if (this.config.useCookies === 'newCookies') {
      return this.setConfigCookieData();
    } else {
      return Promise.resolve();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async deprecatedSetConfigCookieData(): Promise<any> {
    if (!this.cookies) {
      return;
    }

    // Which config parameters to append to the cookie
    const allowlistedCookieParams = ['merchant', 'user'];

    const cookies = this.cookies;

    return Promise.all(
      allowlistedCookieParams.map((k) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'AnalyticsConfig'.
        const value = this.config[k];
        if (typeof value === 'string') {
          return cookies.set(k, value);
        } else {
          return null;
        }
      }),
    ).catch((e) => {
      // Similar to the comment above, let's see where we are failing
      warn('unable_to_set_cookie_data', e);
    });
  }

  async setConfigCookieData(): Promise<void> {
    if (!this.cookies) {
      return;
    }
    const cookies = this.cookies;

    try {
      if (this.config.cookies) {
        if (
          this.config.cookies.merchant &&
          typeof this.config.cookies.merchant === 'string'
        ) {
          const domain = `.${window.location.hostname}`.endsWith('.stripe.com')
            ? '.stripe.com'
            : undefined;

          await cookies.set('merchant', this.config.cookies.merchant, {
            domain,
            secure: true,
          });
        }
        if (
          this.config.cookies.user &&
          typeof this.config.cookies.user === 'string'
        ) {
          await cookies.set('user', this.config.cookies.user);
        }
      }
    } catch (e) {
      warn('unable_to_set_cookie_data', e);
    }
  }

  /**
   * Method to retrieve the unique event ID from the most recently fired event.
   * Used for when we need to push the event ID into other sources to correate
   * event and action.
   */
  getPreviousEventId(): string {
    // If there isn't a pageviewtoken already set, this was called before
    // an analytics event was fired on this page
    let pageviewtoken = this.config.pageviewtoken;
    if (!pageviewtoken) {
      pageviewtoken = generateID();
      this.config.pageviewtoken = pageviewtoken;
      warn('no_previous_event_id');
    }
    // Unique id of the previous analytics event
    return `${pageviewtoken}_${this.state.trackCounter}`;
  }

  /**
   * Call `trackOnce` as many times as you want, but it will only actually send
   * events once. Passes the first set of values through to `track` and ignores
   * all the rest.
   */
  trackOnce(
    eventName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
    onTrackingIssue?: (arg1: TrackingIssue) => void,
  ): Promise<TrackResult> | null | undefined {
    if (!ONCE_CACHE.track[eventName]) {
      ONCE_CACHE.track[eventName] = true;
      return this.track(eventName, params, options, undefined, onTrackingIssue);
    }
  }

  _getPrivacyConsentAdvertising = memoizeForHalfSecond(async () => {
    if (!this?.cookies) {
      throw new Error('Cookies must be defined at this point');
    }

    return this.cookies.isCategoryAllowed('advertising');
  });

  _getPrivacyConsentFunctional = memoizeForHalfSecond(async () => {
    if (!this?.cookies) {
      throw new Error('Cookies must be defined at this point');
    }

    const values = await Promise.all([
      this.cookies.isCategoryAllowed('preferences'),
      this.cookies.isCategoryAllowed('statistics'),
    ]);

    return values.some((v) => v);
  });

  /**
   * The `track` method is the generic analytics method. It should only
   * be used when none of the other more specific tracking methods do not
   * fit your use case. This is so events can be more easily compared across
   * implementations. Feel free to ask if there's a "best practice" for your
   * unique event type.
   */
  track(
    eventName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
    doubleSnakeCase: boolean = true,
    onTrackingIssue: (arg1: TrackingIssue) => void = () => {},
  ): Promise<TrackResult> {
    // The event param is the primary event name.
    // Only track() needs to add the prefix as all the other
    // functions call track() and we don't want duplicate prefixes
    const formattedEventName = doubleSnakeCase
      ? snakeCase(eventName)
      : eventName;
    const prefixedEventName = this.prefix
      ? `${this.prefix}.${formattedEventName}`
      : formattedEventName;
    params.event = prefixedEventName;

    // Bump the count before we set it on the params
    this.state.trackCounter += 1;

    // Number of events fired on this page_view_id
    params['event_count'] = this.state.trackCounter;

    // Set the page view id of the view
    if (this.config.pageviewtoken) {
      params['page_view_id'] = this.config.pageviewtoken;
    } else {
      // Set a uuid for page_view_id if not provided
      const uuid = generateID();
      params['page_view_id'] = uuid;
      this.config.pageviewtoken = uuid;
    }

    // Unique id of the event
    if (typeof params.page_view_id === 'string') {
      params[
        'event_id'
      ] = `${params['page_view_id']}_${this.state.trackCounter}`;
    }

    // Stripe user information
    const merchant = this.config.cookies?.merchant ?? null;
    const user = this.config.cookies?.user ?? null;

    if (options.merchant || merchant) {
      params['merchant_id'] = options.merchant || merchant;
    }

    if (options.user || user) {
      if (!params['merchant_id']) {
        warn('missing_merchant_token', prefixedEventName);
      }
      params['user_id'] = options.user || user;
    }

    // Set the app version globally
    // TODO: allow local overrides? unsure how, but seems
    // weird to not allow for some reason?
    if (!params.version && (options.version || this.config.version)) {
      params.version = options.version || this.config.version;
    }

    // Set the flags using the this.config, but merge in local overrides
    if (params.flags || this.config.googleAnalyticsFlags) {
      // @ts-expect-error - TS2698 - Spread types may only be created from object types.
      params.flags = {...this.config.googleAnalyticsFlags, ...params.flags};
    }

    // TODO: this is a good example of a thing that could change
    // mid-app usage, based on user interaction, and so could imply
    // that we should allow calling configure again, and just "mix-in"
    // the new options. But it's unclear if that causes weirder state
    // that's unexpected, and that we should force end-developers to
    // maintain their ideal config state.
    if (options.locale || this.config.locale) {
      params['stripe_locale'] = options.locale || this.config.locale;
    }

    const setCookieConsent = Promise.resolve().then(() => {
      if (!this.cookies) {
        params['privacy_consent_loaded'] = false;
        params['privacy_consent_functional'] = false;
        params['privacy_consent_advertising'] = false;
        return;
      }

      params['privacy_consent_loaded'] = true;

      const advertisingCheck = this._getPrivacyConsentAdvertising().then(
        (privacyConsentAdvertising) => {
          params['privacy_consent_advertising'] = privacyConsentAdvertising;
        },
      );

      const functionalCheck = this._getPrivacyConsentFunctional().then(
        (privacyConsentFunctional) => {
          params['privacy_consent_functional'] = privacyConsentFunctional;
        },
      );

      return Promise.all([advertisingCheck, functionalCheck]);
    });

    // Grab our global parameters

    return Promise.all([
      setCookieConsent,
      this.getClientIDs(),
      this.getSessionID(),
    ]).then(([_cookieConsent, clientIDs, sessionID]) => {
      const globalParams = getGlobalParams(this.config, clientIDs, sessionID);
      // Merge the local and global params, preferring the local ones for overrides
      // Flow throws a `cannot-spread-indexer` error if we try to spread here
      const mergedParams = Object.assign({}, globalParams, params);
      const cleanedParams = cleanObject(mergedParams, onTrackingIssue);

      if (!this.config.overrideTrackingUrl) {
        // Send post request to AEL
        if (this.config.batchSettings?.enableBatching) {
          this._postBatchAnalyticsEventLogger({...cleanedParams});
        } else {
          this._postAnalyticsEventLogger(
            {...cleanedParams},
            options.sendIframeMessage as
              | ((message: {body: string}) => void)
              | null,
          );
        }
      } else {
        // Send event to endpoint using pixel injection method

        // TODO: remove these when deleting legacy analytics code to keep event
        // slim enough to fit in nginx log
        delete cleanedParams.performance_events;
        delete cleanedParams.treatments;

        // Generate the query string based on the set of parameters
        const qs = `?${toQueryString(cleanedParams)}`;
        const trackingUrl =
          (this.config.inProduction
            ? this.config.overrideTrackingUrl.prod
            : this.config.overrideTrackingUrl.qa) + qs;

        if (this.config.inProduction) {
          // Call the tracking function, which is usually the pixel injection.
          this.trackingFunctions.forEach(function (fn) {
            fn(trackingUrl);
          });
        }
      }

      this.extendSessionExpiration();

      return cleanedParams;
    });
  }

  /**
   * Call `viewedOnce` as many times as you want, but it will only actually send
   * events once. Passes the first set of values through to `viewed` and ignores
   * all the rest.
   */
  viewedOnce(
    viewedName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
  ): Promise<TrackResult> | null | undefined {
    if (!ONCE_CACHE.viewed[viewedName]) {
      ONCE_CACHE.viewed[viewedName] = true;
      return this.viewed(viewedName, params, options);
    }
  }

  /**
   * `viewed` calls are made on pageload, or javascript history change, or
   * similar instance where a predominantly new set of content is now being
   * shown to users.
   */
  viewed(
    pageName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
  ): Promise<TrackResult> {
    // Clean up the event name
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const eventName: any = snakeCase(pageName);

    // Add the additional `viewed_name` param, which is expected with this
    // type of event
    // TODO: is it ever possible to have a user pass in a `viewed_name` here
    // that *doesn't* match the pageName?
    params['viewed_name'] = eventName;

    // Remember the most recent page that was viewed, for adding context
    // to other requests.
    this.state.lastViewedEventName = eventName;

    // Pass along the event to the generic `track` function
    return this.track(
      `${eventName}.viewed`,
      params,
      options,
      /* doubleSnakeCase */ false,
    );
  }

  /**
   * Call `modalOnce` as many times as you want, but it will only actually send
   * events once. Passes the first set of values through to `modal` and ignores
   * all the rest.
   */
  modalOnce(
    modalName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
  ): Promise<TrackResult> | null | undefined {
    if (!ONCE_CACHE.modal[modalName]) {
      ONCE_CACHE.modal[modalName] = true;
      return this.modal(modalName, params, options);
    }
  }

  /**
   * `modal` events are to be fired when a modal is opened. It is required
   * that a `viewed` call has been made prior to calling any `modal` call,
   * since this is important contextual information.
   */
  modal(
    modalName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
  ): Promise<TrackResult> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const eventName: any = snakeCase(modalName);

    params['modal_name'] = eventName;

    // Set the viewed_name based on the params or else the last stored viewed_name
    if (!params['viewed_name']) {
      params['viewed_name'] = getBackupViewedName(
        modalName,
        this.state.lastViewedEventName,
      );
    }

    this.state.lastModalEventName = eventName;

    return this.track(
      `${eventName}.modal`,
      params,
      options,
      /* doubleSnakeCase */ false,
    );
  }

  /**
   * Call `actionOnce` as many times as you want, but it will only actually send
   * events once. Passes the first set of values through to `action` and ignores
   * all the rest.
   */
  actionOnce(
    actionName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
  ): Promise<TrackResult> | null | undefined {
    if (!ONCE_CACHE.action[actionName]) {
      ONCE_CACHE.action[actionName] = true;
      return this.action(actionName, params, options);
    }
  }

  /**
   * `action` calls are made when the user interacts with the page. Button
   * clicks are very common use cases. It is required that a `viewed` call
   * has been made, and if applicable, that a `modal` call has been made if
   * the action is taking place inside of a modal.
   */
  action(
    actionName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
  ): Promise<TrackResult> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const eventName: any = snakeCase(actionName);

    params['action_name'] = eventName;

    // Set the viewed_name based on the params or else the last stored viewed_name
    if (!params['viewed_name']) {
      params['viewed_name'] = getBackupViewedName(
        actionName,
        this.state.lastViewedEventName,
      );
    }

    // Because we cannot tell if a modal was dismissed prior to the action,
    // we expect the implementation to include a modalName in the params instead
    // of using the stored modal name.
    return this.track(
      `${eventName}.action`,
      params,
      options,
      /* doubleSnakeCase */ false,
    );
  }

  /**
   * Call `linkOnce` as many times as you want, but it will only actually send
   * events once. Passes the first set of values through to `link` and ignores
   * all the rest.
   */
  linkOnce(
    linkName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
  ): Promise<TrackResult> | null | undefined {
    if (!ONCE_CACHE.link[linkName]) {
      ONCE_CACHE.link[linkName] = true;
      return this.link(linkName, params, options);
    }
  }

  /**
   * Link events are fired when a link leaves the current scope of the domain
   */
  link(
    linkName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
  ): Promise<TrackResult> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const eventName: any = snakeCase(linkName);

    params['link_name'] = eventName;

    // Set the viewed_name based on the params or else the last stored viewed_name
    if (!params['viewed_name']) {
      params['viewed_name'] = getBackupViewedName(
        linkName,
        this.state.lastViewedEventName,
      );
    }

    return this.track(
      `${eventName}.link`,
      params,
      options,
      /* doubleSnakeCase */ false,
    );
  }

  _getClientIDs = memoizeForHalfSecond(async (): Promise<ClientIDs> => {
    if (!this.cookies || !this.localStorage) {
      return {cid: '', lsid: ''};
    }

    const cid = this.cookies.get('cid');
    const lsid = this.localStorage.get('lsid');

    // The common case: both values are present
    if (cid && lsid) {
      return Promise.resolve({cid, lsid});
    } else if (cid && !lsid) {
      // We just have a cookie, so set the localstorage.
      return this.localStorage.set('lsid', cid).then(() => ({cid, lsid: cid}));
    } else if (lsid && !cid) {
      // We just have a localstorage value, so set the cookie.
      return this.cookies.set('cid', lsid).then(() => ({lsid, cid: lsid}));
    }

    // If we're here, there were no IDs to be found and reused

    const id = generateID();
    // TODO: in the past, if both cookies and localstorage threw errors, I
    // think this might have returned 'NA', but it seems like even single
    // page sessions, in memory, would be more useful than that.
    return Promise.all([
      this.cookies.set('cid', id),
      this.localStorage.set('lsid', id),
    ]).then(() => ({cid: id, lsid: id}));
  });

  /**
   * Get analytics ID from cookie or localStorage, else mint one.
   * This memoizes _getClientIds().
   */
  async getClientIDs(): Promise<ClientIDs> {
    if (!this.config.memoizeCookieID) {
      return this._getClientIDs();
    }

    // Ensure that we don't try to refetch client IDs (or fetch multiple
    // concurrently if there are more than one on the page, which can cause
    // a race condition):
    if (cachedClientIDs === null) {
      cachedClientIDs = this._getClientIDs();
    }

    return cachedClientIDs;
  }

  _getSessionID = memoizeForHalfSecond(async (): Promise<string> => {
    if (!this.cookies) {
      return '';
    }

    const sid = this.cookies.get('__Secure-sid');

    if (sid) {
      return Promise.resolve(sid);
    }

    const id = generateID();

    return Promise.resolve(this.cookies.set('__Secure-sid', id)).then(() => id);
  });

  async getSessionID(): Promise<string> {
    if (!this.config.sessionization) {
      return '';
    }

    if (!this.config.memoizeCookieID) {
      return this._getSessionID();
    }

    if (cachedSessionID === null) {
      cachedSessionID = this._getSessionID();
    }

    return cachedSessionID;
  }

  extendSessionExpiration = memoizeForHalfSecond(async () => {
    if (this.config.sessionization && this.cookies) {
      const sid = await this.getSessionID();
      this.cookies.set('__Secure-sid', sid);
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cleanAelParams = (params: {[key: string]: any}): any => {
    // some slight renaming for AEL endpoints' schema.
    params.event_name = params.event;
    delete params.event;
    params.created = new Date().getTime() / 1000;

    scrubValues(params, this.config.safeParams);
    return params;
  };

  /**
   * This takes the mergedParams from Analytics.track and sends a POST request to the
   * Analytics Event Logger service at r.stripe.com/0.
   */
  async _postAnalyticsEventLogger(
    params: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    },
    sendIframeMessage: ((message: {body: string}) => void) | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    const trackingUrl = this.config.inProduction
      ? this._namedUrls.prod
      : this._namedUrls.qa;

    if (this.config.clientId) {
      params.client_id = this.config.clientId;
    } else {
      warn('no_client_id');
      return;
    }

    this.cleanAelParams(params);

    const body = toQueryString(params);

    if (sendIframeMessage) {
      sendIframeMessage({body});
    } else {
      try {
        const result = await fetch(trackingUrl, {
          method: 'POST',
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          body,
          credentials: 'omit',
        });

        if (!result.ok) {
          this.config.onAnalyticsRequestFailed?.('ael-single-error', 1);
        }

        return result;
      } catch (err) {
        this.config.onAnalyticsRequestFailed?.(
          'ael-single-throws',
          1,
          err as Error,
        );
      }
    }
  }

  /**
   * This takes the mergedParams from Analytics.track and adds the event to a
   * buffer, to be sent in a batched POST request to r.stripe.com/b.
   */
  _postBatchAnalyticsEventLogger(params: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }): void {
    if (params.client_id) {
      delete params.client_id;
    }

    this.cleanAelParams(params);

    if (this.batchLogger) {
      this.batchLogger.pushEvent({...params});
    } else {
      warn('batching_disabled');
    }
  }
}
