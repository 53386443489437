/* eslint-disable react/no-multi-comp */

/* polyfills - must come first */
import 'gelato/frontend/src/polyfills';
import {Provider as ThemeProvider} from '@sail/theme-classic';
import {RootLayer} from '@sail/ui'; /* normal imports */
/* normal imports */
import qs from 'qs';
import * as React from 'react';
import {getFID, getFCP, getTTFB} from 'web-vitals';

import AppControllerContextProvider from 'gelato/frontend/src/components/AppControllerContextProvider';
import ErrorProvider from 'gelato/frontend/src/components/ErrorProvider';
import Head from 'gelato/frontend/src/components/head';
import PageComponent from 'gelato/frontend/src/components/PageComponent';
import SailNextThemeProvider from 'gelato/frontend/src/components/theming/SailNextThemeProvider';
import WithLocaleContext from 'gelato/frontend/src/components/WithLocaleContext';
import WithSessionContext from 'gelato/frontend/src/components/WithSessionContext';
import calculateUserAgentData, {
  UserAgentData,
} from 'gelato/frontend/src/lib/analytics/calculateUserAgentData';
import {getComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import getRouter, {buildRouter} from 'gelato/frontend/src/lib/localRouter';
import {reportMetric} from 'gelato/frontend/src/lib/metricsBatcher';
import {routes, defaultComponent} from 'gelato/frontend/src/lib/routes';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import Storage from 'gelato/frontend/src/lib/Storage';

import GelatoSdkProvider from './components/GelatoSdkProvider';

import './styles/app.module.css';

import type {
  RouteComponent,
  PageProps,
} from 'gelato/frontend/src/lib/localRouter';

export type WrapperProps = {
  pageProps: PageProps;
  userAgentData?: UserAgentData;
};

function reportPageLoadMetric() {
  const slugParts = window.location.pathname.split('/');
  if (slugParts.length > 1) {
    // Pick first part of path name
    const slug = slugParts[1];

    reportMetric({
      metric: 'gelato_frontend_page_load',
      operation: 'count',
      value: 1,
      tags: [{key: 'page', value: slug}],
    });
  }
}

const getCurrentComponent = async (): Promise<RouteComponent> => {
  // @ts-expect-error - TS2322 - Type 'RouteComponent | typeof Index' is not assignable to type 'RouteComponent'.
  return (await getRouter().getRoute()) || defaultComponent;
};

type State = {
  Component: RouteComponent | null | undefined;
  userAgentData?: UserAgentData;
};

class Wrapper extends React.Component<WrapperProps, State> {
  _isMounted: boolean;

  constructor(props: any) {
    super(props);
    const {pathname} = window.location;
    // @ts-expect-error - TS2345 - Argument of type '{ readonly '/start': { (): JSX.Element; skipApp: boolean; }; readonly '/testing': React.FC<WithIntlProps<PageProps & { intl: IntlShape; }>> & { ...; }; ... 18 more ...; readonly '/selfie_verification_method': React.FC<...> & { ...; }; }' is not assignable to parameter of type 'RouteMap'.
    buildRouter(pathname, this.onRouteChange, routes);
    this.state = {Component: getRouter().getSyncRoute()};
    this._isMounted = false;
    Storage.setVisitedPath(pathname);
    getCurrentComponent().then((Component) => {
      const {Component: currentComponent} = this.state;
      if (currentComponent === Component && !this._isMounted) {
        // Should not call setState on a component that is not yet mounted.
        // This is a no-op.
        return;
      }
      this.setState({Component});
    });
  }

  componentDidMount(): void {
    this._isMounted = true;
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  onRouteChange = () => {
    getCurrentComponent().then((Component) => {
      const {Component: currentComponent} = this.state;
      if (currentComponent === Component && !this._isMounted) {
        // Should not call setState on a component that is not yet mounted.
        // This is a no-op.
        return;
      }
      this.setState({Component});
    });
    const router = getRouter();
    Storage.setVisitedPath(router.currentPath);
    reportPageLoadMetric();
  };

  render() {
    const router = getRouter();
    const {pageProps, userAgentData} = this.props;
    const params = {...router.query()};
    const {Component} = this.state;
    if (!Component) {
      return <div />;
    }

    const {ignoreExpiredSession, isTerminal, skipApp, v2} =
      getComponentConfig(Component);

    if (skipApp && v2) {
      // This Component has declared itself to be self-managed. WithSessionContext will not be injected.
      // This component has also declared itself to be v2, so we will use PageComponent directly.
      return (
        <>
          <Head />
          <ErrorProvider isTerminal={!!isTerminal} router={router}>
            <GelatoSdkProvider>
              <SailNextThemeProvider>
                <AppControllerContextProvider>
                  <PageComponent
                    Component={Component}
                    pageProps={pageProps}
                    params={params}
                    router={router}
                    userAgentData={userAgentData}
                  />
                </AppControllerContextProvider>
              </SailNextThemeProvider>
            </GelatoSdkProvider>
          </ErrorProvider>
        </>
      );
    } else if (skipApp) {
      // This Component has declared itself to be self-managed. WithSessionContext will not be injected.
      return (
        <>
          <Head />
          <ErrorProvider isTerminal={!!isTerminal} router={router}>
            <GelatoSdkProvider>
              <SailNextThemeProvider>
                <AppControllerContextProvider>
                  <Component {...pageProps} router={router} params={params} />
                </AppControllerContextProvider>
              </SailNextThemeProvider>
            </GelatoSdkProvider>
          </ErrorProvider>
        </>
      );
    } else {
      // For pages that are not self-managed, inject v1/v2 transitional
      // context using WithSessionContext. This context will block the
      // rendering of PageComponent until GetSessionQuery has been called so
      // that flags and experiments are guarenteed to be available during
      // initial render of all pages.
      return (
        <>
          <Head />
          <ErrorProvider isTerminal={!!isTerminal} router={router}>
            <GelatoSdkProvider>
              <WithSessionContext ignoreExpiredSession={!!ignoreExpiredSession}>
                <SailNextThemeProvider>
                  <AppControllerContextProvider>
                    <PageComponent
                      Component={Component}
                      pageProps={pageProps}
                      params={params}
                      router={router}
                      userAgentData={userAgentData}
                    />
                  </AppControllerContextProvider>
                </SailNextThemeProvider>
              </WithSessionContext>
            </GelatoSdkProvider>
          </ErrorProvider>
        </>
      );
    }
  }
}

const handleLoad = () => {
  // Send load message to Stripe.js Lightbox iframe
  postIframeEvent('STRIPE_IDENTITY_APP_LOAD');
  postIframeEvent('load');
  reportMetric({
    metric: 'gelato_frontend_app_load',
    operation: 'count',
    value: 1,
  });
};

const reportPageLoadMetrics = () => {
  getTTFB((metric) =>
    reportMetric({
      metric: 'gelato_frontend_time_to_first_byte_ms',
      operation: 'timing',
      // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
      value: parseInt(metric.value, 10),
    }),
  );
  getFCP((metric) =>
    reportMetric({
      metric: 'gelato_frontend_first_contentful_paint_ms',
      operation: 'timing',
      // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
      value: parseInt(metric.value, 10),
    }),
  );
  getFID((metric) =>
    reportMetric({
      metric: 'gelato_frontend_first_input_delay_ms',
      operation: 'timing',
      // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
      value: parseInt(metric.value, 10),
    }),
  );
};

class App extends React.Component<WrapperProps, State> {
  constructor() {
    // @ts-expect-error - TS2554 - Expected 1-2 arguments, but got 0.
    super();
    this.state = {Component: undefined};
  }

  async componentDidMount() {
    window.addEventListener('load', handleLoad);
    try {
      reportPageLoadMetrics();
    } catch (err: any) {
      handleException(err, 'Error reporting page load metric', {level: 'info'});
    }
    const userAgentData = await calculateUserAgentData();

    if (userAgentData) {
      this.setState({
        userAgentData,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('load', handleLoad);
  }

  render() {
    const {userAgentData} = this.state;
    const params = window.location.search
      ? qs.parse(window.location.search.split('?')[1])
      : {};

    return (
      <ThemeProvider>
        <RootLayer>
          {/* @ts-expect-error - TS2322 - Type 'string | string[] | ParsedQs | ParsedQs[] | undefined' is not assignable to type 'string | null | undefined'. */}
          <WithLocaleContext locale={params.locale}>
            <Wrapper {...this.props} userAgentData={userAgentData} />
          </WithLocaleContext>
        </RootLayer>
      </ThemeProvider>
    );
  }
}

export default App;
