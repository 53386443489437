import {defineMessages} from 'react-intl';

const messages = defineMessages({
  passport: {
    id: 'document.type.passport',
    description: 'Label for the passport document type.',
    defaultMessage: 'Passport',
  },
  driversLicense: {
    id: 'document.type.driversLicense',
    description: 'Label for the driving_license document type.',
    defaultMessage: `Driver’s license`,
  },
  idCard: {
    id: 'document.type.idCard',
    description: 'Label for the id_card document type.',
    defaultMessage: 'ID card',
  },
});

export default messages;
