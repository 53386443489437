import type Headerz from 'src/public/headers/headers';
import type {FetchOptions, GraphQlResponseError} from 'src/types';

export type MockStore<TOptions, TResponse> = Map<
  string,
  Map<string, (options: TOptions) => TResponse>
>;

export type FetchMockResponse = {
  body: string;
  headers?: Headerz;
  status?: number;
};

export type GraphQlMockOptions<TVariables> = {
  variables: TVariables;
};

export type GraphQlMockResponse<TData> = {
  data?: TData | null;
  error?: never; // Prevent a common mistake
  errors?: Array<GraphQlResponseError>;
};

type StorageCallback = (name: string, value: string | null) => void;

export const fetchMockStore: MockStore<
  FetchOptions,
  FetchMockResponse | Promise<FetchMockResponse>
> = new Map();

export const graphQlMockStore: MockStore<
  GraphQlMockOptions<any>,
  GraphQlMockResponse<any> | Promise<GraphQlMockResponse<any>>
> = new Map();

export const unmockedGraphQlOperationsStore: Set<string> = new Set();

export const unmockedFetchCallsStore: Set<string> = new Set();

export const readCookies: Map<string, Set<StorageCallback>> = new Map();
export const readLocalStorage: Map<string, Set<StorageCallback>> = new Map();
export const readSessionStorage: Map<string, Set<StorageCallback>> = new Map();

declare global {
  // eslint-disable-next-line no-var, vars-on-top
  var __sdkAfterDataStore: boolean;
}

if (typeof afterEach === 'function' && !globalThis.__sdkAfterDataStore) {
  globalThis.__sdkAfterDataStore = true;

  afterEach(() => {
    fetchMockStore.clear();
    graphQlMockStore.clear();
    unmockedGraphQlOperationsStore.clear();
    unmockedFetchCallsStore.clear();
    readCookies.clear();
    readLocalStorage.clear();
    readSessionStorage.clear();
  });
}
