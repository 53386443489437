import {dispute} from '@sail/icons/react/Icon';
import * as React from 'react';

import messages from 'gelato/frontend/src/components/Invalid/messages';
import Message from 'gelato/frontend/src/components/Message';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';

const Footer = () => {
  React.useEffect(() => {
    postIframeEvent('STRIPE_IDENTITY_ERROR', {
      type: 'device_error',
      code: 'device_unsupported',
    });
  });
  return null;
};

export const generateUnsupportedHandoffDeviceContent = () => {
  return {
    icon: dispute,
    footer: <Footer />,
    title: <Message {...messages.unsupportedHandoffDeviceTitle} />,
    description: <Message {...messages.unsupportedHandoffDeviceBody} />,
  };
};
