/**
 * The states of the document capture process.
 */

import ImageFrame from 'gelato/frontend/src/lib/ImageFrame';
import shallowMergeInto from 'gelato/frontend/src/lib/shallowMergeInto';

import type {GraphQlField} from '@sail/data';
import type {
  DocumentTypes,
  DocumentSide,
} from '@stripe-internal/data-gelato/schema/types';
import type {ValueOf} from 'gelato/frontend/src/controllers/types';
import type {SendDocumentImageMutationData} from 'gelato/frontend/src/graphql/mutations/useSendDocumentImageMutation';
import type {
  Rectangle,
  ModelProbabilities,
} from 'gelato/frontend/src/ML/IDDetectorAPI';

export type DocumentImageValidationError = GraphQlField<
  SendDocumentImageMutationData,
  'captureDocumentImageFrame',
  'validationErrors'
>;

export type DocumentState = {
  document: {
    autoCaptureIsSupported: boolean;
    autoCaptureStatus: AutoCaptureStatusValue;
    back: {
      inspectionState: InspectionState | null;
      reviewState: ReviewState | null;
      uploadState: UploadState | null;
      uploadedCount: number;
    };
    front: {
      inspectionState: InspectionState | null;
      reviewState: ReviewState | null;
      uploadState: UploadState | null;
      uploadedCount: number;
    };
    workingStep: UserStepValue;
    workingInputMethod: InputMethodValue;
    workingSide: DocumentSide;
  };
};

type BaseInspectorResult = {
  // The detected document type.
  documentType: DocumentTypes | null;
  // The feedback that is generated during the inspection process.
  feedback: FeedbackValue | null;
  // The source image that is used for the inspection process.
  inspectedImage: ImageFrame | null;
  // The rectangle that is used to crop the document from the input image.
  location: Rectangle | null;
  // Whether the document is valid.
  isValid: boolean;
};

export type IDInspectorResult = BaseInspectorResult & {
  probability: ModelProbabilities | null;
};

export type MicroBlinkCaptureInspectorResult = BaseInspectorResult & {
  detectedImage: ImageFrame | null;
};

export type TestmodeJsQRDetectionResult = BaseInspectorResult & {
  detectedImage: ImageFrame | null;
};

// Details about the camera used in the CaptureDocumentImageFrame mutation
// (See gelato/api/graphql/schema/mutations/capture_document_image_frame.rb),
// specifically for the input parameter "camera_info."
export type CameraInfo = {
  capabilities?: MediaTrackCapabilities | null;
  error?: Error | null;
  cameraLabel?: string | null;
};

/**
 * The document inspection state.
 */
export type InspectionState = {
  /**
   * Whether the inspection process is done. If true, it means the image is
   * good and it can be used to upload to the backend.
   */
  approved: boolean;

  /**
   * The info of the camera that is used for the inspection process.
   */
  cameraInfo: CameraInfo | null;

  /**
   * The timestamp when the detection process started.
   */
  createdAt: number;

  /**
   * Whether the inspection state is disposed. If true, it means the inspection
   * state is no longer in use and it could be garbage collected.
   */
  disposed: boolean;

  /**
   * The score that indicates the blur level of the document.
   * It's computed by the gelato/frontend/src/ML/detectors/BlurInspector.ts
   */
  documentBlurScore: number;

  /**
   * The detected document image.
   */
  documentImage: ImageFrame | null;

  /**
   * Whether the document is valid.
   */
  documentIsValid: boolean;

  /**
   * The rectangle that is used to crop the document from the input image.
   */
  documentLocation: Rectangle | null;

  /**
   * The detected document type.
   */
  documentType: DocumentTypes | null;

  /**
   * The error that occurred during the inspection process. It could an network
   * or API error. If the error is not null, it means the inspection should stop
   * and the app should handle the error.
   */
  error: Error | null;
  /**
   * The feedback that is generated during the inspection process. It could be
   * used to display the feedback to the user.
   */
  feedback: FeedbackValue | null;
  /**
   * The unique ID assigned when the inspection process started.
   */
  id: string;

  /**
   * The result of the IDInspector.
   */
  idInspectorResult: IDInspectorResult | null;

  /**
   * The image that is used for the inspection process. It could be the image
   * from the camera or the image from the upload.
   */
  inputImage: ImageFrame;

  /**
   * The counter that indicates the number of iterations that have been
   * processed since the inspection process started.
   */
  iterationCount: number;

  /**
   * The result of the MicroBlinkCaptureInspector.
   */
  microBlinkCaptureInspectorResult: MicroBlinkCaptureInspectorResult | null;

  /**
   * The result of JSQR detection, for testmode only
   */
  testmodeJsQRDetectionResult: TestmodeJsQRDetectionResult | null;

  /**
   * The feedbacks from the previous iteration. The feedbacks are sorted
   * descendingly by the timestamp (e.g. new feedbacks are at the beginning of
   * the array).
   */
  previousFeedbacks: Array<{
    createdAt: number;
    feedback: FeedbackValue;
  }>;

  /**
   * The side of the document that is being detected.
   */
  side: DocumentSide;

  /**
   * The timestamp when the inspection process started.
   */
  startedAt: number;
  /**
   * The timestamp when the inspection process will timeout.
   */
  timeoutAt: number;
};

/**
 * This image frame has zero pixels.
 * It's used as a placeholder when the image is not available.
 */
const PLACEHOLDER_IMAGE_FRAME = ImageFrame.createPlaceholder();

/**
 * Creates an inspection state.
 * @param partialState [optional] The values to be merged into the inspection state.
 * @returns The inspection state.
 */
export function createInspectionState(
  partialState: Partial<InspectionState> | null | undefined,
): InspectionState {
  const defaultState: InspectionState = {
    approved: false,
    cameraInfo: null,
    createdAt: Date.now(),
    disposed: false,
    documentBlurScore: 0,
    documentImage: null,
    documentIsValid: false,
    documentLocation: null,
    documentType: null,
    error: null,
    feedback: null,
    id: createInspectionStateId(),
    idInspectorResult: null,
    inputImage: PLACEHOLDER_IMAGE_FRAME,
    iterationCount: 0,
    microBlinkCaptureInspectorResult: null,
    previousFeedbacks: [],
    side: 'front',
    startedAt: Date.now(),
    testmodeJsQRDetectionResult: null,
    timeoutAt: Date.now(),
  };

  return partialState
    ? shallowMergeInto(defaultState, partialState)
    : defaultState;
}

/**
 * The state that indicates the status of the upload process. The upload process
 * makes network requests to the backend to upload the document.
 */
export type UploadState = {
  // Whether the upload process is done.
  done: boolean;
  // The error that occurred during the upload process. It could an network or
  // API error.
  error: Error | null;
  // The image that is used for the upload process.
  image: ImageFrame | null;
  // Whether the upload process is pending.
  pending: boolean;
  // The preview of the uploading image.
  previewImage: ImageFrame | null;
  // The timestamp when the upload process started.
  startedAt: number | null;
};

/**
 * The state that indicates the status of the reviewing process. The reviewing
 * process might make network requests to the backend to change the properties
 * of the document.
 */
export type ReviewState = {
  // The timestamp when the reviewing process started.
  createdAt: number | null;
  // The error that occurred during the reviewing process. It could an network
  // or API error.
  error: Error | null;
  // Whether the reviewing process is pending.
  pending: boolean;
  // The validation errors that are returned from the backend after uploading
  // the captured image.
  validationErrors: ReadonlyArray<DocumentImageValidationError>;
  // The previous validation errors that were returned from the backend after
  // uploading the captured image.
  previousValidationErrors: ReadonlyArray<DocumentImageValidationError>;
};

export type AutoCaptureStatusValue = ValueOf<typeof AutoCaptureStatus>;

export type FeedbackValue = ValueOf<typeof Feedback>;

export type InputMethodValue = ValueOf<typeof InputMethod>;

export type UserStepValue = ValueOf<typeof UserStep>;

/**
 * The status of the auto capture process.
 */
export const AutoCaptureStatus = {
  // The auto capture process failed. The user should switch to manual capture
  // or upload mode. User could also retry the auto capture process.
  failed: 'failed',
  // The auto capture process has started.
  started: 'started',
  // The auto capture process has stopped.
  stopped: 'stopped',
} as const;

/**
 * The step of the document that user is currently working on.
 * The order of the steps are:
 * 1. collectImage > front
 * 2. uploadImage > front
 * 3. reviewImage > front
 * 4. wamrup > back
 * 5. collectImage > back
 * 6. uploadImage > back
 * 7. reviewImage > back
 */
export const UserStep = {
  // User need to collect the image of the document either by camera or file
  // upload.
  collectImage: 'collect_image',
  // User need to review the image of the document and maybe fix any issues
  // reported by the service APIs, or just confirm the image is good.
  reviewImage: 'review_image',
  // User need to wait for the image of the document to be uploaded to the
  // service APIs so she could review the result.
  uploadImage: 'upload_image',
  // The interim step that show additional information to user before she could
  // proceed to the next step.
  warmup: 'warmup',
} as const;

/**
 * The input method that user is currently using.
 */
export const InputMethod = {
  // The detector automatically captures images with camera.
  cameraAutoCapture: 'auto_capture',
  // The user manually captures images with camera.
  cameraManualCapture: 'manual_capture',
  // The user manually upload images with file upload UI.
  fileUpload: 'file_upload',
} as const;

/**
 * The constructive feedbacks that user could receive and act upon.
 */
export const Feedback = {
  alignment: 'alignment',
  angleTooSteep: 'angle_too_steep',
  cameraVideoIsNotReady: 'camera_is_not_ready',
  fingerObstruction: 'finger_obstruction',
  holdSteady: 'hold_steady',
  invalidDocument: 'invalid_document',
  moveCloser: 'move_closer',
  moveFarther: 'move_farther',
  moveIntoView: 'move_into_view',
  moveToCenter: 'move_to_center',
  noDocument: 'no_document',
  orientationUnsuitable: 'orientation_unsuitable',
  tooBlurry: 'too_blurry',
  tooBright: 'too_bright',
  tooDark: 'too_dark',
  tooMuchGlare: 'too_much_glare',
  useBackSide: 'use_back_side',
  useDrivingLicense: 'needs_driving_license',
  useFrontSide: 'use_front_side',
  useFrontSideBlock: 'use_front_side_block',
  usePassport: 'needs_passport',
  wrongSide: 'wrong_side',
  wrongSideBlock: 'wrong_side_block',
} as const;

// Feedback items that prevent file uploads.
// Include only feedback items addressable without camera movement.
// For example, we cannot request users to move closer during a file upload,
// a process not involving the camera.
export const HARD_BLOCK_FEEDBACKS: Readonly<Set<FeedbackValue>> = new Set([
  Feedback.fingerObstruction,
  Feedback.invalidDocument,
  Feedback.noDocument,
  Feedback.useBackSide,
  Feedback.useDrivingLicense,
  Feedback.useFrontSide,
  Feedback.useFrontSideBlock,
  Feedback.usePassport,
  Feedback.wrongSide,
  Feedback.wrongSideBlock,
]);

// The feedbacks that require user to move the document or camera in real time.
// Note that this should only contain the feedbacks that could be addressed
// by explictly moving the document or camera in real time.
export const LIVE_MOVEMENT_FEEDBACKS: Readonly<Set<FeedbackValue>> = new Set([
  Feedback.alignment,
  Feedback.holdSteady,
  Feedback.moveCloser,
  Feedback.moveFarther,
  Feedback.moveIntoView,
  Feedback.moveToCenter,
]);

/**
 * Creates a new document state.
 * @returns The new document state.
 */
export function createDocumentState(): DocumentState {
  return {
    document: {
      autoCaptureIsSupported: true,
      autoCaptureStatus: AutoCaptureStatus.stopped,
      back: {
        inspectionState: null,
        reviewState: null,
        uploadState: null,
        uploadedCount: 0,
      },
      front: {
        inspectionState: null,
        reviewState: null,
        uploadState: null,
        uploadedCount: 0,
      },
      workingInputMethod: InputMethod.cameraAutoCapture,
      workingSide: 'front',
      workingStep: UserStep.warmup,
    },
  };
}

let inspectionStateId = 0;

/**
 * Helper function to create a unique inspection state ID.
 * @returns The unique inspection state ID.
 */
export function createInspectionStateId(): string {
  inspectionStateId += 1;
  return `dinspection_state_${inspectionStateId}`;
}

export function getValidationErrors(
  state: DocumentState,
): ReadonlyArray<DocumentImageValidationError> {
  const {workingSide} = state.document;
  const errors = state.document[workingSide].reviewState?.validationErrors;
  return errors ?? [];
}

export function getPreviousValidationErrors(
  state: DocumentState,
): ReadonlyArray<DocumentImageValidationError> {
  const {workingSide} = state.document;
  const errors =
    state.document[workingSide].reviewState?.previousValidationErrors;
  return errors ?? [];
}

export function isHardBlockValidationError(
  error: DocumentImageValidationError,
): boolean {
  return error.hardBlock || error.type.endsWith('_hard_block');
}

export function isMicroBlinkDecodeError(
  error: DocumentImageValidationError,
): boolean {
  return error.type === 'microblink_decode_failed';
}
