import {css, view, Link} from '@sail/ui';
import * as React from 'react';
import {defineMessages} from 'react-intl';

import BottomSheet from 'gelato/frontend/src/components/BottomSheetV2';
import Button from 'gelato/frontend/src/components/ButtonV2';
import Message from 'gelato/frontend/src/components/Message';
import analytics from 'gelato/frontend/src/lib/analytics';
import getBrandingPlatformName from 'gelato/frontend/src/lib/getBrandingPlatformName';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import Storage from 'gelato/frontend/src/lib/Storage';

const {useCallback, useEffect} = React;

const Styles = {
  wrapper: css({
    paddingX: 'large',
    paddingBottom: 'medium',
  }),
  heading: css({
    font: 'heading.large',
    marginTop: 'space.0',
    marginBottom: 'medium',
  }),
  bodyText: css({
    font: 'body.medium',
  }),
};

const messages = defineMessages({
  title: {
    defaultMessage: 'You are verifying your identity with {platformName}',
    description: 'Title for handoff warning banner',
    id: 'handoffWarningSheet.title',
  },
  description: {
    defaultMessage:
      'If you are not familiar with {platformName} or have not requested to handoff from another device to verify yourself, please return to {platformReturnUrl} and contact their support.',
    description: 'Description for handoff warning banner',
    id: 'handoffWarningSheet.description',
  },
  continueButton: {
    defaultMessage: 'Continue',
    description: 'Continue button for handoff warning banner',
    id: 'handoffWarningSheet.continueButton',
  },
});

export default function HandoffWarningSheet(): JSX.Element {
  const {appState, appController} = useAppController();
  const platformName = getBrandingPlatformName(appController.runtime?.branding);
  // `returnUrl` could have been redacted. Use `cachedReturnUrl` instead.
  const returnUrl = appState.session?.returnUrl || Storage.getReturnUrl();

  useEffect(() => {
    analytics.viewed('handoffWarningSheet');
  }, []);

  return (
    <BottomSheet
      data-testid="handoff-warning-sheet"
      footer={
        <Button
          data-testid="handoff-warning-sheet--continue-button"
          onPress={() => appController.closeLayer(HandoffWarningSheet)}
          type="primary"
        >
          <Message {...messages.continueButton} />
        </Button>
      }
    >
      <view.h2 uses={[Styles.heading]}>
        <Message {...messages.title} values={{platformName}} />
      </view.h2>
      <view.div uses={[Styles.bodyText]}>
        <Message
          {...messages.description}
          values={{
            platformName,
            platformReturnUrl: returnUrl ? (
              <Link
                data-testid="handoff-warning-sheet--return-url-link"
                href={returnUrl}
                type="primary"
                // Using onPressStart because changing routes might result in
                // the analytics event not being emitted.
                onPressStart={() =>
                  analytics.action('handoffWarningSheetContinue')
                }
              >
                {platformName}
              </Link>
            ) : (
              platformName
            ),
          }}
        />
      </view.div>
    </BottomSheet>
  );
}
