import {SimpleEventEmitter} from '@sail/utils';
import patchBrowserStorage from 'src/internal/browserStorage/patchBrowserStorage';

import type {IReports} from '@sail/observability';
import type {EventCallback} from '@sail/utils';
import type {StorageEvent} from 'src/internal/browserStorage/patchBrowserStorage';

export type StorageEventCallback = EventCallback<[StorageEvent]>;

export default function createLocalStorageEventListener(
  reports: IReports,
): SimpleEventEmitter<[StorageEvent]> {
  const simpleEventEmitter = new SimpleEventEmitter<[StorageEvent]>();

  patchBrowserStorage(
    'localStorage',
    simpleEventEmitter.notify.bind(simpleEventEmitter),
    reports,
  );

  // Listen to storage changes in other windows (i.e. this won't fire on the same page
  // that is making the change).
  if (
    typeof globalThis !== 'undefined' &&
    typeof globalThis.addEventListener === 'function'
  ) {
    addEventListener('storage', function storageChangeListener(evt) {
      const {key, newValue, storageArea} = evt;

      if (storageArea !== localStorage) {
        return;
      }

      // Clear event
      if (key == null) {
        return simpleEventEmitter.notify({
          type: 'clear',
        });
      }

      // Remove event
      if (newValue == null) {
        return simpleEventEmitter.notify({
          type: 'remove',
          key,
        });
      }

      // Set event
      simpleEventEmitter.notify({
        type: 'set',
        key,
        value: newValue,
      });
    });
  }

  return simpleEventEmitter;
}
