import {useEffect} from 'react';

import {errorInDev, logInDev, warnInDev} from 'gelato/frontend/src/lib/assert';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import BlurInspector from 'gelato/frontend/src/ML/detectors/BlurInspector';
import FocusInspector from 'gelato/frontend/src/ML/detectors/FocusInspector';
import IDInspector from 'gelato/frontend/src/ML/detectors/IDInspector';
import MicroBlinkCaptureInspector from 'gelato/frontend/src/ML/detectors/MicroBlinkCaptureInspector';

import type {
  ApplicationState,
  ApplicationRuntime,
} from 'gelato/frontend/src/controllers/types';
import type {InspectorProvider} from 'gelato/frontend/src/controllers/utils/inspectDocument';

// The delay time in milliseconds before processing the next inspector.
// Make sure this is long enough to not block the page loading and interaction.
// Make sure this is tested on low-end devices.
const LAZY_PROCESS_DELAY = 3000;

// The maximum time in milliseconds to spend on building and warming up
// the ML models. If the time spent exceeds this value, the inspector
// will be skipped and tried again later.
const MAX_PROCESSING_TIME = 1000;

// Preload inspector list.
//
// Excludes "MicroBlinkCaptureInspector" due to its large size, which could
// negatively affect network speed, animation performance, or memory usage.
// To include this inspector, use "preloadMicroBlinkCaptureInspector()"
// directly as needed.

const INSPECTORS: InspectorProvider[] = [
  IDInspector,
  BlurInspector,
  FocusInspector,
  IDInspector,
];

let preloading = false;

/**
 * Check if we should preload the inspectors based on the current state.
 * @param state The current application state.
 * @param runtime The current application runtime.
 */
function shouldPreloadInspectors(
  state: ApplicationState,
  runtime: ApplicationRuntime | null,
): boolean {
  const sessionId = state?.session?.id || '';

  if (!sessionId.startsWith('vs_')) {
    // Do not preload unless a valid session id is present. This prevents
    // the page from loading any ML models unless the server had provided
    // a real session id.
    return false;
  }

  const needsIDImage =
    state.session?.missingFields?.includes('id_document_images');

  if (!needsIDImage) {
    // We only preload if an ID image is required.
    return false;
  }

  const {workingStep} = state.document;
  const currentPath = runtime?.router.currentPath || '';

  if (/^\/document_upload/.test(currentPath) && workingStep !== 'warmup') {
    // Do not preload if we are already on the document upload page that is
    // capturing the image.
    return false;
  }

  return true;
}

/**
 * Preload the MicroBlinkCaptureInspector.
 */
function usePreloadMicroBlinkCaptureInspector(enabled: boolean) {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    let aborted = false;

    const process = async () => {
      const inspector = MicroBlinkCaptureInspector.getInstance();

      if (aborted || inspector.ready || inspector.error) {
        return;
      }

      await inspector.build();

      if (aborted || inspector.ready || inspector.error) {
        return;
      }

      await inspector.warmUp();
    };

    process();

    return () => {
      aborted = true;
    };
  });
}

/**
 * Preload ID detectors as soon as we know that an ID document is required.
 */
export default function usePreloadInspectors(enabled: boolean) {
  const {appController} = useAppController();

  const shouldPreload = shouldPreloadInspectors(
    appController.state,
    appController.runtime,
  );

  useEffect(() => {
    if (!enabled || preloading || !shouldPreload) {
      return;
    }

    // Mark preloading as started.
    preloading = true;

    const inspectors = INSPECTORS.filter((provider) => provider.isSupported());

    // Load inspectors in sequence.
    const loadNext = async () => {
      if (
        // Check the live state again and decide if we should continue.
        !shouldPreloadInspectors(appController.state, appController.runtime)
      ) {
        return;
      }

      const inspector = inspectors.shift();
      if (inspector) {
        const instance = inspector.getInstance();
        try {
          const buildStartTime = Date.now();

          await instance.build();

          if (
            // Check the live state again and decide if we should continue.
            !shouldPreloadInspectors(appController.state, appController.runtime)
          ) {
            return;
          }

          const buildDuration = Date.now() - buildStartTime;
          if (buildDuration > MAX_PROCESSING_TIME) {
            warnInDev(`${instance.name} build took ${buildDuration}ms`);
            // This inspector took too long to build, we'd try again later for
            // wamrup.
            inspectors.unshift(inspector);
            loadNextLazy();
            return;
          }

          const warmUpStartTime = Date.now();
          await instance.warmUp();
          const warmUpDuration = Date.now() - warmUpStartTime;
          if (warmUpDuration > MAX_PROCESSING_TIME) {
            warnInDev(`${instance.name} build took ${warmUpDuration}ms`);
            // This inspector took too long to warm up, we'd try again later.
            inspectors.unshift(inspector);
            loadNextLazy();
            return;
          }

          logInDev(`${instance.name} warmup success`);
          loadNextLazy();
        } catch (ex) {
          errorInDev(`${instance.name} warmup failed`, ex);
        }
      } else {
        logInDev(`all inspectors warmup complete`);
      }
    };

    const loadNextLazy = async () => {
      const {requestIdleCallback, setTimeout} = window;
      if (requestIdleCallback) {
        // ML models should be loaded as low priority work on the main event
        // loop, without impacting latency-critical events such as animation
        // and input response.
        setTimeout(() => {
          requestIdleCallback(loadNext, {timeout: 10000});
        }, LAZY_PROCESS_DELAY);
      } else {
        // fallback to setTimeout if requestIdleCallback is not available.
        setTimeout(loadNext, LAZY_PROCESS_DELAY);
      }
    };

    loadNextLazy();
  }, [appController, enabled, shouldPreload]);

  // MicroBlinkCaptureInspector is not preloaded by default to conserve
  // resources for network, animation, and camera performance. However, if the
  // user reaches the file upload or manual capture step, where real-time live
  // capture feedback is not required, MicroBlinkCaptureInspector will be
  // preloaded to guarantee the best capture results.
  const {workingInputMethod} = appController.state.document;

  usePreloadMicroBlinkCaptureInspector(
    workingInputMethod === 'file_upload' ||
      workingInputMethod === 'manual_capture',
  );
}
