import * as React from 'react';
import {useIntl} from 'react-intl';

import Button from 'gelato/frontend/src/components/ButtonV2';
import {messages} from 'gelato/frontend/src/components/Link/messages';
import {BottomSheetStep} from 'gelato/frontend/src/controllers/states/NetworkedIdentityState';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

export default function NotNow() {
  const {appController} = useAppController();
  const intl = useIntl();

  const handleClick = async () => {
    appController.setLinkSaveIdBottomStep(BottomSheetStep.AreYouSure);
  };

  /*
    Because this is a bottom sheet, and because of how the background "click outside" functionality works, this
    must be an onClick and not an onPress.
  */
  return (
    <Button type="secondary" onClick={handleClick} data-testid="link-not-now">
      {intl.formatMessage(messages.linkSkipSave)}
    </Button>
  );
}
