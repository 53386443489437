import {assignProps} from '@sail/engine';
import {CountryCode, PhoneNumberField} from '@sail/ui';
import {parsePhoneNumber} from 'libphonenumber-js';
import * as React from 'react';
import {IntlShape, defineMessages, injectIntl} from 'react-intl';

import Message from 'gelato/frontend/src/components/Message';
import {PHONE_COUNTRY_WHITELIST} from 'gelato/frontend/src/lib/constants';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

import type {Country} from '@stripe-internal/data-gelato/schema/types';

const {useCallback} = React;

const Messages = defineMessages({
  phoneLabel: {
    id: 'form.individual.phone.label',
    description: `Label for the form asking for individual’s phone number`,
    defaultMessage: 'Phone number',
  },
});

type PhoneInputProps = {
  intl: IntlShape;
  handleKeyDown?: React.KeyboardEventHandler<HTMLFieldSetElement>;
};

const PhoneInput = ({intl, handleKeyDown}: PhoneInputProps) => {
  const {appController} = useAppController();
  const {session, individual} = appController.state;
  const {validationMsg, country, userProvidedPhoneNumber} =
    individual.phone || {};
  // TODO: We are intentionally ignoring the session?.phoneSupportedCountries
  // while we figure out our supportability approach
  const countryAllowlist: CountryCode[] = PHONE_COUNTRY_WHITELIST.slice();
  const {phoneNumber} = session?.collectedData?.individual || {};
  const {
    merchantProvidedPhoneNumber: sessionMerchantProvidedPhone,
    userProvidedPhoneNumber: sessionUserProvidedPhone,
  } = phoneNumber || {};
  const sessionPhone =
    sessionMerchantProvidedPhone || sessionUserProvidedPhone || '';
  let defaultCountry: Country = 'US';
  if (sessionPhone) {
    // get default from session to better guess in case a number is unclear
    const parsed = parsePhoneNumber(sessionPhone, 'US');
    defaultCountry = (parsed.country as Country) || defaultCountry;
  }

  const handleCountryChange = useCallback(
    (selectedCountry) => {
      appController.setIndividualCollectedData({
        phone: {country: selectedCountry, userProvidedPhoneNumber},
      });
    },
    [appController, userProvidedPhoneNumber],
  );
  const handleChange = useCallback(
    (value: string) => {
      appController.setIndividualCollectedData({
        phone: {
          country: country || defaultCountry,
          userProvidedPhoneNumber: value,
        },
      });
    },
    [appController, country, defaultCountry],
  );
  const handleBlur = useCallback(() => {
    appController.validateIndividualData(
      {phone: {country, userProvidedPhoneNumber}},
      intl,
    );
  }, [appController, intl, country, userProvidedPhoneNumber]);

  if (!session) {
    return null;
  }

  return (
    <PhoneNumberField
      label={<Message {...Messages.phoneLabel} />}
      // Value only accepts undefined and not null
      value={userProvidedPhoneNumber || ''}
      countryAllowlist={countryAllowlist}
      disabled={!!sessionMerchantProvidedPhone}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      error={validationMsg}
      subviews={{
        countryInput: {
          uses: [
            assignProps({
              onChange: (e: React.ChangeEvent<HTMLSelectElement>) =>
                handleCountryChange(e.target.value as Country),
            }),
          ],
        },
      }}
    />
  );
};

export default injectIntl(PhoneInput);
