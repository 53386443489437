export const APP_VERSION_PATTERN = /headless|electron|slimerjs/i;

export const UA_PATTERNS = [
  ' daum[ /]',
  ' deusu/',
  ' yadirectfetcher',
  '(?:^|[^g])news(?!sapphire)',
  '(?<! (?:channel/|google/))google(?!(app|/google| pixel))',
  '(?<! cu)bots?(?:\\b|_)',
  '(?<!(?:lib))http',
  '(?<![hg]m)score',
  '@[a-z][\\w-]+\\.',
  '\\(\\)',
  '\\.com\\b',
  '\\btime/',
  '^<',
  '^[\\w \\.\\-\\(?:\\):]+(?:/v?\\d+(?:\\.\\d+)?(?:\\.\\d{1,10})*?)?(?:,|$)',
  '^[^ ]{50,}$',
  '^\\d+\\b',
  '^\\w*search\\b',
  '^\\w+/[\\w\\(\\)]*$',
  '^active',
  '^ad muncher',
  '^amaya',
  '^avsdevicesdk/',
  '^biglotron',
  '^bot',
  '^bw/',
  '^clamav[ /]',
  '^client/',
  '^cobweb/',
  '^custom',
  '^ddg[_-]android',
  '^discourse',
  '^dispatch/\\d',
  '^downcast/',
  '^duckduckgo',
  '^facebook',
  '^getright/',
  '^gozilla/',
  '^hobbit',
  '^hotzonu',
  '^hwcdn/',
  '^jeode/',
  '^jetty/',
  '^jigsaw',
  '^microsoft bits',
  '^movabletype',
  '^mozilla/5\\.0\\s[a-z\\.-]+$',
  '^mozilla/\\d\\.\\d \\(compatible;?\\)$',
  '^mozilla/\\d\\.\\d \\w*$',
  '^navermailapp',
  '^netsurf',
  '^offline',
  '^owler',
  '^php',
  '^postman',
  '^python',
  '^rank',
  '^read',
  '^reed',
  '^rest',
  '^rss',
  '^snapchat',
  '^space bison',
  '^svn',
  '^swcd ',
  '^taringa',
  '^thumbor/',
  '^track',
  '^valid',
  '^w3c',
  '^webbandit/',
  '^webcopier',
  '^wget',
  '^whatsapp',
  '^wordpress',
  '^xenu link sleuth',
  '^yahoo',
  '^yandex',
  '^zdm/\\d',
  '^zoom marketplace/',
  '^{{.*}}$',
  'adscanner/',
  'analyzer',
  'archive',
  'ask jeeves/teoma',
  'bit\\.ly/',
  'bluecoat drtr',
  'browsex',
  'burpcollaborator',
  'capture',
  'catch',
  'check\\b',
  'checker',
  'chrome-lighthouse',
  'chromeframe',
  'classifier',
  'cloudflare',
  'convertify',
  'crawl',
  'cypress/',
  'dareboost',
  'datanyze',
  'dejaclick',
  'detect',
  'dmbrowser',
  'download',
  'evc-batch/',
  'exaleadcloudview',
  'feed',
  'firephp',
  'functionize',
  'gomezagent',
  'headless',
  'httrack',
  'hubspot marketing grader',
  'hydra',
  'ibisbrowser',
  'images',
  'infrawatch',
  'insight',
  'inspect',
  'iplabel',
  'ips-agent',
  'java(?!;)',
  'jsjcw_scanner',
  'library',
  'linkcheck',
  'mail\\.ru/',
  'manager',
  'measure',
  'neustar wpm',
  'node',
  'nutch',
  'offbyone',
  'optimize',
  'pageburst',
  'pagespeed',
  'parser',
  'perl',
  'phantomjs',
  'pingdom',
  'powermarks',
  'preview',
  'proxy',
  'ptst[ /]\\d',
  'reputation',
  'resolver',
  'retriever',
  'rexx;',
  'rigor',
  'rss\\b',
  'scanner\\.',
  'scrape',
  'server',
  'sogou',
  'sparkler/',
  'speedcurve',
  'spider',
  'splash',
  'statuscake',
  'supercleaner',
  'synapse',
  'synthetic',
  'tools',
  'torrent',
  'trace',
  'transcoder',
  'url',
  'virtuoso',
  'wappalyzer',
  'webglance',
  'webkit2png',
  'whatcms/',
  'zgrab',
];
