import {stableStringify} from '@sail/utils';

import type {OperationVariables} from '@apollo/client';

export const KEY_VARIABLES_SEPARATOR = '---';

export const getPrefetchCacheKey = ({
  operationName,
  variables,
}: {
  operationName: string;
  variables: OperationVariables;
}) => `${operationName}${KEY_VARIABLES_SEPARATOR}${stableStringify(variables)}`;
