import initLocalStorage from 'src/internal/localStorage/initLocalStorage';
// TODO: pending to be properly migrated away from using test store.
import {readLocalStorage} from 'src/test/store';

import type {IReports} from '@sail/observability';
import type {DataConfig} from 'src/internal/config/types';
import type {StorageEventCallback} from 'src/internal/localStorage/createLocalStorageEventListener';

type BrowserStorageConfig = DataConfig['browserStorage'];

/**
 * Imperative API for managing local storage items.
 *
 * Allows reading and writing of local storage items while honoring a user's
 * permissions. Also, provides methods for listening to local storage events.
 *
 * When accessing the local storage inside a React Component, better use the
 * [useLocalStorage](./useLocalStorage.md) hook.
 *
 * @example Basic {{include "./examples/localStorage.basic.tsx"}}
 */
export default {
  /**
   * Reads the value of the specified item from local storage.
   */
  get(
    /** The localStorage config object for `@sail/data`. */
    config: BrowserStorageConfig,
    /** The name of the item to be read. */
    name: string,
    /** Temporal way to send a observability reports instance with the proper obs configuration */
    reports: IReports,
  ): string | null {
    const result =
      initLocalStorage(config, reports).localStorage.get(name) ?? null;

    readLocalStorage.get(name)?.forEach((callback) => {
      callback(name, result);
    });

    return result;
  },

  /**
   * Attempts to write the specified value to local storage. This will only
   * succeed if it complies with the user permissions.
   */
  set(
    /** The localStorage config object for `@sail/data`. */
    config: BrowserStorageConfig,
    /** The name of the item to be set. */
    name: string,
    /**
     * The new value to be set. If value is `null`, the item will be deleted.
     */
    value: string | null,
    /** Temporal way to send a observability reports instance with the proper obs configuration */
    reports: IReports,
  ): Promise<boolean> {
    if (value == null) {
      // Returning a promise to match the return value of the `set` function.
      return Promise.resolve(
        initLocalStorage(config, reports).localStorage.remove(name),
      );
    }

    return initLocalStorage(config, reports).localStorage.set(name, value);
  },

  /**
   * Removes the specified item from local storage.
   */
  remove(
    /** The localStorage config object for `@sail/data`. */
    config: BrowserStorageConfig,
    /** The name of the item to be removed. */
    name: string,
    /** Temporal way to send a observability reports instance with the proper obs configuration */
    reports: IReports,
  ): boolean {
    return initLocalStorage(config, reports).localStorage.remove(name);
  },

  /**
   * Listens to local storage events. The method accepts a callback that will be
   * called whenever an item is updated or deleted.
   */
  subscribe(
    /** The localStorage config object for `@sail/data`. */
    config: BrowserStorageConfig,
    callback: StorageEventCallback,
    /** Temporal way to send a observability reports instance with the proper obs configuration */
    reports: IReports,
  ): void {
    initLocalStorage(config, reports).eventListener.addListener(callback);
  },

  /**
   * Unsubscribes from local storage events. The callback should be the same
   * one used for subscribing to local storage events.
   */
  unsubscribe(
    /** The localStorage config object for `@sail/data`. */
    config: BrowserStorageConfig,
    callback: StorageEventCallback,
    /** Temporal way to send a observability reports instance with the proper obs configuration */
    reports: IReports,
  ): void {
    initLocalStorage(config, reports).eventListener.removeListener(callback);
  },
};
