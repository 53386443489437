import {css, view} from '@sail/ui';
import * as React from 'react';

import {
  deviceCss,
  filterBrightness80,
  outlineNone,
} from 'gelato/frontend/src/components/stylesV2';
import {findActiveLayer} from 'gelato/frontend/src/controllers/states/LayerState';
import addDOMPointerEventDragHandlers from 'gelato/frontend/src/lib/addDOMPointerEventDragHandlers';
import analytics from 'gelato/frontend/src/lib/analytics';
import {errorInDev} from 'gelato/frontend/src/lib/assert';
import getDOMEventClientPosition from 'gelato/frontend/src/lib/getDOMEventClientPosition';
import getReactElementTestId from 'gelato/frontend/src/lib/getReactElementTestId';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import useScrollableArea from 'gelato/frontend/src/lib/hooks/useScrollableArea';
import shallowMergeInto from 'gelato/frontend/src/lib/shallowMergeInto';

import type {LayerItem} from 'gelato/frontend/src/controllers/states/LayerState';

const {useEffect, useLayoutEffect, useRef} = React;

// The animation function is copied from https://easings.net/#easeInOutQuart
// <transition-duration> <transition-timing-function> <transition-delay>.
const TRANSITION = `350ms cubic-bezier(0.76, 0, 0.24, 1) 200ms`;

const TRANSITION_NONE = {
  transitionDelay: '0ms',
  transitionDuration: '0ms',
  transitionProperty: 'none',
  transitionTimingFunction: 'ease-in',
};

const TRANSITION_INITIAL = {
  transitionDelay: '',
  transitionDuration: '',
  transitionProperty: '',
  transitionTimingFunction: '',
};

const TRANSITION_FAST = {
  transitionDelay: '0ms',
  transitionDuration: '200ms',
  transitionProperty: 'transform',
  transitionTimingFunction: 'ease-in',
};

const Styles = {
  backdrop: css({
    backgroundColor: '#000',
    bottom: 'space.0',
    left: 'space.0',
    opacity: 0,
    position: 'absolute',
    right: 'space.0',
    top: 'space.0',
    transition: `opacity ${TRANSITION}`,
    zIndex: 0,
  }),
  layer: css({
    left: 'space.0',
    maxHeight: 'fill',
    overflow: 'auto',
    position: 'absolute',
    right: 'space.0',
    top: '100%',
    transition: `transform ${TRANSITION}, filter ${TRANSITION}`,
  }),
  layerHandleBar: css({
    alignX: 'center',
    alignY: 'center',
    cursor: 's-resize',
    gap: 'medium',
    height: '30px',
    left: 'space.0',
    position: 'absolute',
    right: 'space.0',
    stack: 'y',
    top: 'space.0',
    zIndex: 100,
    visibility: 'hidden',
  }),
  layerHandleBarLine: css({
    backgroundColor: '#C0C8D2',
    borderRadius: 'xsmall',
    height: 'xsmall',
    maxWidth: 'xlarge',
    width: '2/5',
    visibility: 'hidden',
  }),
  layerWithBottomShadow: css({
    boxShadow: '0 var(--viewport-height) 0 var(--viewport-height) #fff',
    clipPath:
      'inset(0 0 calc(-1 * var(--viewport-height)) 0 round 4px 4px 0 0)',
  }),
  root: css({
    bottom: 'space.0',
    left: 'space.0',
    overflow: 'hidden',
    pointerEvents: 'none',
    position: 'absolute',
    right: 'space.0',
    top: 'space.0',
    zIndex: 1000,
  }),
};

/**
 * Helper function to translate the DOM position of the layer.
 */
function transformDOM(dom: HTMLElement, x: number, y: number): void {
  const backgrounded = dom.getAttribute('data-backgrounded');
  let top = y;
  if (backgrounded) {
    // When the layer is backgrounded, we scale it down a bit to make it look
    // like it's behind the top layer.

    // Find the active layer and move the backgrounded layer to the position
    // where top of the backgrounded layer is partially visible behind the
    // active layer.
    const layerRoot = dom.closest('[data-layer-root]');
    const activeLayer = layerRoot?.querySelector('[data-layer-active]');
    if (activeLayer instanceof HTMLElement) {
      top = -activeLayer.offsetHeight - 30;
    }

    dom.style.transform = `translate3d(${x}px, ${top}px, 0) scale(0.9)`;
  } else {
    dom.style.transform = `translate3d(${x}px, ${top}px, 0)`;
  }
}

/**
 * Get the current transform position of a DOM element.
 */
function getDOMTransformPosition(dom: HTMLElement): [number, number] {
  const regex = /\(([^,]*),([^,]*)/;
  const matches = String(dom.style.transform).match(regex);
  return matches && matches.length >= 2
    ? [parseFloat(matches[1]), parseFloat(matches[2])]
    : [0, 0];
}

/**
 * Helper function to stop the propagation of a DOM event.
 * @param event The DOM event to stop.
 */
function stopDOMEvent(event: Event) {
  event.preventDefault();
  event.stopPropagation();
}

/**
 * The handler that is called when the layer content changes size.
 */
function handlerLayerSizeObserver(entries: ResizeObserverEntry[]): void {
  entries.forEach((entry) => {
    const {target, contentRect} = entry;
    transformDOM(target as HTMLDivElement, 0, -contentRect.height);
  });
}

/**
 * The ResizeObserver instance that observes the size of the layer content.
 */
const LayerSizeObserver: ResizeObserver | null = window.ResizeObserver
  ? new ResizeObserver(handlerLayerSizeObserver)
  : null;

/**
 * The side-effect hook that animates the layer into view or out of view.
 */
function useLayerAnimation(item: LayerItem): React.RefObject<HTMLDivElement> {
  const {active, opened} = item;
  // We'd update the DOM styles directly instead of using React state to avoid
  // re-rendering the whole app when the layer is opened or closed. This
  // helps improve the performance of animating the layer.
  const domRef = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    const el = domRef.current;
    if (!el) {
      // The layer is not mounted.
      return;
    }
    if (opened) {
      const reflow = () => {
        transformDOM(el, 0, -el.offsetHeight);
      };
      // Animate the layer into view.
      if (LayerSizeObserver) {
        // Use ResizeObserver to detect when the content changes size.
        LayerSizeObserver.observe(el);
        reflow();
        return () => LayerSizeObserver.unobserve(el);
      } else {
        // Fallback for browsers that don't support ResizeObserver.
        window.addEventListener('resize', reflow, true);
        reflow();
        return () => window.removeEventListener('resize', reflow, true);
      }
    } else {
      // Animate the layer out of view.
      el.style.transform = '';
    }
  }, [active, opened]);
  return domRef;
}

/**
 * The side-effect hook that handles the dragging of the layer.
 */
function useLayerHandleBar(item: LayerItem): React.RefObject<HTMLDivElement> {
  const {active, contentRenderer} = item;
  const {appController} = useAppController();

  const handleBarDom = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const handleBarEl = handleBarDom.current;
    if (!handleBarEl || !active || !handleBarEl.setPointerCapture) {
      // Feature isn't supported.
      return;
    }

    const layerEl = handleBarEl.closest('[data-layer="true"]');
    const layerRootEl = handleBarEl.closest('[data-layer-root="true"]');

    if (
      !(layerEl instanceof HTMLElement) ||
      !(layerRootEl instanceof HTMLElement)
    ) {
      // DOM is not mounted inside a layer.
      return;
    }

    let dragging = false;
    let startClientY = 0;
    let fromPos = [0, 0];
    let toPos = [0, 0];
    let previousPos = [0, 0];
    let layerRootHeight = 0;
    let movesCount = 0;

    const updateLayerPosition = () => {
      transformDOM(layerEl, toPos[0], toPos[1]);
    };

    // The layer is dragged by moving the handle bar.
    // This handler is called when the handle bar is pressed.
    const handleDragStart = (event: PointerEvent) => {
      fromPos = [0, 0];
      toPos = [0, 0];
      movesCount = 0;

      const pointerId = event.pointerId;

      if (typeof pointerId !== 'number') {
        return;
      }

      const eventPos = getDOMEventClientPosition(event);
      if (!eventPos) {
        return;
      }

      if (!couldDragToMoveLayer(layerEl, event)) {
        return;
      }

      stopDOMEvent(event);
      // Pause the transition property while the layer is being dragged.
      shallowMergeInto(layerEl.style, TRANSITION_NONE);
      handleBarEl.setPointerCapture(event.pointerId);
      handleBarEl.style.filter = 'brightness(70%)';
      fromPos = getDOMTransformPosition(layerEl);
      startClientY = eventPos[1];
      layerRootHeight = layerRootEl.offsetHeight;
      dragging = true;
    };

    // This handler is called when the handle bar is dragged.
    const handleDragMove = (event: PointerEvent) => {
      if (!dragging) {
        return;
      }
      const eventPos = getDOMEventClientPosition(event);
      if (!eventPos) {
        return;
      }

      stopDOMEvent(event);
      const deltaY = eventPos[1] - startClientY;
      const x = fromPos[0];
      const y = fromPos[1] + deltaY;
      previousPos = toPos;
      toPos = [x, Math.max(-layerRootHeight + 24, Math.min(-24, y))];
      movesCount++;
      updateLayerPosition();
    };

    // This handler is called when the handle bar is released.
    const handleDragEnd = (event: PointerEvent) => {
      if (!dragging) {
        return;
      }
      const pointerId = event.pointerId;
      if (typeof pointerId !== 'number') {
        return;
      }

      dragging = false;
      stopDOMEvent(event);
      handleBarEl.releasePointerCapture(pointerId);
      handleBarEl.style.filter = '';

      let intent;

      if (movesCount < 2 || Math.abs(toPos[1] - fromPos[1]) < 3) {
        // The layer did not move enough, it's likely a click.
        if (event.offsetY <= handleBarEl.offsetHeight) {
          // Clicked inside the handle bar.
          intent = 'close';
        } else {
          // Clicked below the handle bar.
          intent = 'resume';
        }
      } else if (
        // moved downward.
        previousPos[1] <= toPos[1] &&
        // moved beflow the start position
        fromPos[1] <= toPos[1]
      ) {
        intent = 'close';
      } else {
        intent = 'resume';
      }

      // Use the faster transition to resume or close the layer.
      shallowMergeInto(layerEl.style, TRANSITION_FAST);

      layerEl.ontransitionend = () => {
        // After the transition ends, reset the transition to its default
        // values.
        layerEl.ontransitionend = null;
        shallowMergeInto(layerEl.style, TRANSITION_INITIAL);
      };

      if (intent === 'resume') {
        // Reset the layer to the initial position.
        transformDOM(layerEl, fromPos[0], fromPos[1]);
      } else {
        appController.closeLayer(contentRenderer);
      }
    };

    // This makes the handle bar draggable.
    return addDOMPointerEventDragHandlers(layerEl, {
      handleDragStart,
      handleDragMove,
      handleDragEnd,
    });
  }, [active, appController, contentRenderer, handleBarDom]);
  return handleBarDom;
}

/**
 * The side-effect hook that closes the layer when the user clicks outside of
 * the layer.
 * @returns A DOM ref that should be attached to the layer's backdrop.
 */
function useClickBackDropToCloseLayer(
  isBackdropVisible: boolean,
): React.RefObject<HTMLDivElement> {
  const domRef = useRef<HTMLDivElement | null>(null);
  const {appState, appController} = useAppController();
  const activeLayer = findActiveLayer(appState);

  useEffect(() => {
    const dom = domRef.current;
    if (dom && activeLayer && isBackdropVisible) {
      const handleClick = (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        appController.closeLayer(activeLayer.contentRenderer);
      };
      dom.tabIndex = 0;
      dom.addEventListener('click', handleClick, false);
      return () => {
        dom.tabIndex = -1;
        dom.removeEventListener('click', handleClick, false);
      };
    }
  }, [activeLayer, appController, domRef, isBackdropVisible]);
  return domRef;
}

/**
 * This side-effect hook makes the DOM element interactive or non-interactive.
 * @param domRef The React reference to the DOM element.
 * @param interactive Whether the DOM element should be interactive.
 */
function useInteractivePointerEvent(
  domRef: React.RefObject<HTMLDivElement>,
  interactive: boolean,
) {
  useEffect(() => {
    const dom = domRef.current;
    if (dom) {
      if (interactive) {
        // The dom is immediately interactive.
        dom.style.pointerEvents = 'auto';
      } else {
        // The dom remains interactive until its animation fully ends.
        const timeoutId = setTimeout(() => {
          dom.style.pointerEvents = 'none';
        }, 350);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [domRef, interactive]);
}

/**
 * The handle bar is a draggable area that allows the user to drag the layer.
 */
function LayerHandleBar(props: {item: LayerItem}): JSX.Element {
  const {item} = props;
  const domRef = useLayerHandleBar(item);
  return (
    <view.div ref={domRef} uses={[Styles.layerHandleBar]}>
      <view.div uses={[Styles.layerHandleBarLine]} />
    </view.div>
  );
}

/**
 * This side-effect hook that tracks the visibility change of the layer.
 * @param layerName The name of the layer, like "document-upload-help-sheet".
 * @param opened Whether the layer is opened.
 * @param active Whether the layer is active.
 */
function useLayerAnalytics(
  layerName: string | null,
  active: boolean,
  opened: boolean,
) {
  const isActivatedRef = useRef(false);

  useEffect(() => {
    if (!layerName) {
      errorInDev('The layer name is not set.');
      return;
    }

    // If active=false and opened=true, the layer is in the background.

    if (active) {
      // Track the layer only when it is open and in the foreground.
      isActivatedRef.current = true;
      analytics.track('layerActivated', {layerName});
    } else if (isActivatedRef.current && !opened) {
      // The layer is fully closed.
      isActivatedRef.current = false;
      analytics.track('layerClosed', {layerName});
    }
  }, [active, isActivatedRef, layerName, opened]);
}

/**
 * The layer is a container that can be opened and closed. It is used to display
 * a modal dialog, a menu, or a popover.
 */
function Layer(props: {item: LayerItem}): JSX.Element {
  const {item} = props;
  const {active, contentRenderer, opened, zIndex} = item;
  const domRef = useLayerAnimation(item);
  useInteractivePointerEvent(domRef, active);
  useScrollableArea(domRef);

  const content = contentRenderer();

  const dataTestId = getReactElementTestId(content);
  // The content of the layer is rendered by <BottomSheet /> whcih
  // always contains a data-testid attribute that we can use to track the
  // visibility of the layer.
  useLayerAnalytics(dataTestId, active, opened);

  const styles = [Styles.layer, Styles.layerWithBottomShadow];

  // An inactive & opened layer is backgrounded.
  const backgrounded = opened && !active;

  if (backgrounded) {
    // When the layer is backgrounded, make it look a bit dimmer.
    styles.push(filterBrightness80);
  }

  return (
    <view.div
      data-backgrounded={backgrounded ? 'true' : undefined}
      data-layer="true"
      data-layer-active={active ? 'true' : undefined}
      css={{
        zIndex,
      }}
      ref={domRef}
      uses={styles}
    >
      <LayerHandleBar item={item} />
      {content}
    </view.div>
  );
}

/**
 * The backdrop is a semi-transparent overlay that covers the entire screen and
 * prevents interaction with the content behind it while a layer is active.
 * Instead user should interact with the ative layer.
 */
function Backdrop(props: {show: boolean}): JSX.Element {
  const {show} = props;
  const domRef = useClickBackDropToCloseLayer(show);
  useInteractivePointerEvent(domRef, show);
  return (
    <view.div
      data-backdrop="true"
      ref={domRef}
      uses={[Styles.backdrop, outlineNone]}
      css={{
        opacity: show ? 0.5 : 0,
      }}
    />
  );
}

/**
 * The root component for all layers.
 */
export default function LayerRoot() {
  const {appState, appController} = useAppController();
  const {runtime} = appController;
  const {items, showBackdrop} = appState.layer;
  const layers = items.map((item) => <Layer item={item} key={item.key} />);

  useLayoutEffect(() => {
    if (runtime) {
      const onRouteChange = () => {
        // By default, all layers should be closed when the route changes.
        appController.closeAllLayers();
      };
      return runtime.router.subscribe(onRouteChange);
    }
  }, [appController, runtime]);

  return (
    <view.div data-layer-root="true" uses={[Styles.root]}>
      <Backdrop show={showBackdrop} />
      {layers}
    </view.div>
  );
}

const CLICKABLE_ELEMENT_REGEX = /^(a|button|input|option|select|textarea)$/i;

/**
 * Whether the drag gesture could move the layer.
 * @param layerEl The layer element to check.
 * @param event The pointer event.
 * @returns Returns true if the drag gesture could move the layer.
 */
function couldDragToMoveLayer(
  layerEl: HTMLElement,
  event: PointerEvent,
): boolean {
  const {target} = event;
  if (!(target instanceof HTMLElement)) {
    return false;
  }
  let el: HTMLElement | null = target;

  // Place a limit on the number of iterations to guard against long loops.
  let iteration = 0;
  // Check if the element is clickable or scrollable.
  while (el && el !== layerEl && iteration < 50) {
    if (CLICKABLE_ELEMENT_REGEX.test(el.nodeName)) {
      // The element is clickable. The drag gesture might end up clicking the
      // element instead of moving the layer.
      return false;
    }

    if (el.scrollHeight > el.offsetHeight) {
      // The element is scrollable (i.e scrollbar is visible). The drag gesture
      // should scroll the content instead of moving the layer.
      return false;
    }
    iteration++;
    el = el.parentElement;
  }
  return true;
}
