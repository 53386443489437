import * as React from 'react';

import HandoffWarningSheet from 'gelato/frontend/src/components/HandoffWarningSheet/HandoffWarningSheet';
import {isLayerOpened} from 'gelato/frontend/src/controllers/states/LayerState';
import flags from 'gelato/frontend/src/lib/flags';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

const {useState} = React;

const PATHS_TO_EXCLUDE = ['/success', '/submit'];

export default function useHandoffWarningSheet() {
  const [hasBeenOpened, setHasBeenOpened] = useState(false);
  const {appState, appController} = useAppController();
  const path = appController.runtime?.router.currentPath || '';

  if (
    !flags.isActive('idprod_secondary_session_handoff_warning_sheet') ||
    appState.session?.operatingMode !== 'secondary' ||
    isLayerOpened(appState, HandoffWarningSheet) ||
    hasBeenOpened ||
    // We'll be extra careful to not show this on the certain pages
    // because we don't expect users to land on those pages, but if they do
    // it doesn't make sense to show the warning.
    PATHS_TO_EXCLUDE.includes(path)
  ) {
    return;
  }

  appController.openLayer(HandoffWarningSheet);
  setHasBeenOpened(true);
}
