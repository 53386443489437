import {css, view, ButtonGroup} from '@sail/ui';
import * as React from 'react';
import {
  IntlShape,
  MessageDescriptor,
  defineMessages,
  injectIntl,
} from 'react-intl';

import Button from 'gelato/frontend/src/components/ButtonV2';
import NameInput from 'gelato/frontend/src/components/IndividualV2/NameInputV2';
import OTPPageContent from 'gelato/frontend/src/components/IndividualV2/OTPVerificationV2';
import PhoneInput from 'gelato/frontend/src/components/IndividualV2/PhoneInputV2';
import ValidationMsg from 'gelato/frontend/src/components/IndividualV2/ValidationMsg';
import Message from 'gelato/frontend/src/components/Message';
import PageCard from 'gelato/frontend/src/components/PageCardV2';
import {p200} from 'gelato/frontend/src/components/stylesV2';
import TopNavigationBar from 'gelato/frontend/src/components/TopNavigationBar';
import {
  IndividualStateFields,
  OTPMode,
} from 'gelato/frontend/src/controllers/states/IndividualState';
import {ApplicationState} from 'gelato/frontend/src/controllers/types';
import isFieldNeeded from 'gelato/frontend/src/controllers/utils/isFieldNeeded';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

const Styles = {
  container: css({
    gap: 'xsmall',
    stack: 'y',
  }),
  body: css({
    stack: 'y',
    font: 'body.small',
    gap: 'medium',
  }),
  title: css({
    font: 'heading.medium.subdued',
  }),
  description: css({
    stack: 'y',
    gap: 'medium',
  }),
};

const messages = defineMessages({
  phoneHeader: {
    id: 'otp.phone.header',
    description: 'Header for SMS verification page',
    defaultMessage: 'Verify your phone number',
  },
  phoneInputLabel: {
    id: 'otp.phone.input.label',
    description: 'Label for input box for user provided phone number',
    defaultMessage: 'Phone number',
  },
  sendOTPButton: {
    id: 'otp.phone.send.otp',
    description: 'Text on button to send OTP code via SMS',
    defaultMessage: 'Send code',
  },
  codeResend: {
    id: 'otp.code.resend',
    description: 'Text on button to resend OTP code via SMS',
    defaultMessage: 'Send again',
  },
  pageDescriptionEnterNoName: {
    id: 'otp.phone.description.enter.no_name',
    description:
      'Text explaining that we will send an OTP code to your phone number',
    defaultMessage:
      'Please enter your phone number. You will receive an SMS with a verification code.',
  },
  pageDescriptionEnterWithName: {
    id: 'otp.phone.description.enter.with_name',
    description:
      'Text explaining that you will enter a name and phone number and we will send an OTP code to your phone number',
    defaultMessage:
      'Please enter your phone number and name. You will receive an SMS with a verification code.',
  },

  pageDescriptionConfirm: {
    id: 'otp.phone.description.confirm',
    description:
      'Text explaining that we will send an OTP code to your phone number',
    defaultMessage:
      'Confirm your phone number ending in {phoneLastFour}. You will receive an SMS with a verification code.',
  },
  pageDescriptionConfirmWithName: {
    id: 'otp.phone.description.confirm.with_name',
    description:
      'Text explaining that you will enter a name and we will send an OTP code to your phone number',
    defaultMessage:
      'Please enter your name and confirm your phone number ending in {phoneLastFour}. You will receive an SMS with a verification code.',
  },

  decline: {
    id: 'otp.phone.decline',
    description:
      'Text for link allowing user to say that they cannot verify the given phone number',
    defaultMessage: 'I cannot verify this phone number',
  },

  errorSend: {
    id: 'otp.phone.error.send',
    description:
      'Error message saying that the one time password verification code could not be sent (or resent)',
    defaultMessage:
      'Error sending verification code. Check the phone number entered.',
  },
});

const {useCallback, useRef, useEffect, useState} = React;

const collectName = (appState: ApplicationState) => {
  const hasNameRequirement = isFieldNeeded(appState, 'name');
  const hasOtherKeyedInRequirement =
    isFieldNeeded(appState, 'dob') ||
    isFieldNeeded(appState, 'address') ||
    isFieldNeeded(appState, 'id_number');
  // don't collect name here if we are also going to use the individual page later to collect more keyed in dta
  return hasNameRequirement && !hasOtherKeyedInRequirement;
};

const Description = () => {
  const {appController} = useAppController();
  const {session} = appController.state;
  const needsName = collectName(appController.state);
  const {phoneNumber} = session?.collectedData?.individual || {};
  const merchantPhone = phoneNumber?.merchantProvidedPhoneNumber;
  const userPhone = phoneNumber?.userProvidedPhoneNumber;
  const givenPhone = merchantPhone || userPhone;
  const phoneLastFour = givenPhone?.slice(givenPhone?.length - 4);
  const canUpdatePhone = !merchantPhone && !!session;

  if (canUpdatePhone) {
    if (needsName) {
      return <Message {...messages.pageDescriptionEnterWithName} />;
    } else {
      return <Message {...messages.pageDescriptionEnterNoName} />;
    }
  } else if (needsName) {
    return (
      <Message
        {...messages.pageDescriptionConfirmWithName}
        values={{phoneLastFour}}
      />
    );
  } else {
    return (
      <Message
        {...messages.pageDescriptionConfirm}
        values={{
          phoneLastFour: merchantPhone?.slice(merchantPhone.length - 4),
        }}
      />
    );
  }
};

const SendButton = ({send}: {send: () => void}) => {
  const {appController} = useAppController();
  const {session, mutation, individual} = appController.state;
  const needsName = collectName(appController.state);
  const pending = mutation.validateOTP.pending || mutation.generateOTP.pending;
  const hasSuccessfulOTPSend = mutation.generateOTP.value?.phone;
  const hasName =
    !needsName || (!!individual.name?.firstName && !!individual.name?.lastName);
  const {phoneNumber} = session?.collectedData?.individual || {};
  const hasPhoneNumber =
    !!phoneNumber?.merchantProvidedPhoneNumber ||
    !!individual?.phone?.userProvidedPhoneNumber;
  const disableButton = !session || pending || !hasPhoneNumber || !hasName;

  const message = hasSuccessfulOTPSend
    ? messages.codeResend
    : messages.sendOTPButton;

  return (
    <Button
      id="send-otp"
      data-testid="send-otp"
      disabled={disableButton}
      onPress={send}
    >
      <Message {...message} />
    </Button>
  );
};

const DeclineButton = ({intl}: {intl: IntlShape}) => {
  const {appController} = useAppController();
  const {session, mutation} = appController.state;
  const merchantPhone =
    session?.collectedData?.individual?.phoneNumber
      ?.merchantProvidedPhoneNumber;
  const canUpdatePhone = !merchantPhone && !!session;
  const {pending} = mutation.validateOTP;

  const decline = useCallback(async () => {
    await appController.setIndividualCollectedData({
      phoneOtp: {otpDeclined: true},
    });
    await appController.validateOTP({mode: OTPMode.phone});
  }, [appController]);

  if (canUpdatePhone) {
    return <></>;
  }
  return (
    <Button
      id="decline-otp"
      data-testid="decline-otp"
      type="secondary"
      onPress={decline}
      disabled={pending}
    >
      <Message {...messages.decline} />
    </Button>
  );
};

const PhoneVerificationPage = ({intl}: {intl: IntlShape}) => {
  const {appController} = useAppController();
  const {session, mutation, individual} = appController.state;
  const {name, phone} = individual;
  const {firstName, lastName} = name || {};
  const {validateOTP, generateOTP, updateIndividual} = mutation;
  const {phoneNumber} = session?.collectedData?.individual || {};
  const merchantPhone = phoneNumber?.merchantProvidedPhoneNumber;
  const canUpdatePhone = !!session && !merchantPhone;
  const needsOTP = !!session && isFieldNeeded(appController.state, 'phone_otp');
  const needsName = collectName(appController.state);

  const initializeRef = useRef(false);
  const checkNeedsRedirect = useRef(false);
  const initialSendOTP = useRef(false);

  const {
    error: generateOTPError,
    pending: generateOTPPending,
    value: otpCodeGenerated,
  } = mutation.generateOTP;
  const phoneOtpCodeGenerated = otpCodeGenerated?.phone;
  const hasValidatedOTP = validateOTP.value?.phone;
  const autosendOTP = !needsName && !canUpdatePhone && needsOTP;
  // Show a loading screen
  // - while session is loading
  // - while validate OTP request is pending
  // - after a successful validate OTP request while routing is in progress
  const isLoading = !session || validateOTP.pending || hasValidatedOTP;
  const [errorMessage, setErrorMessage] = useState<MessageDescriptor | null>(
    null,
  );
  const showPhoneInputContent =
    !autosendOTP &&
    phoneOtpCodeGenerated === undefined &&
    !updateIndividual.value;
  const send = useCallback(async () => {
    const partialData: Partial<IndividualStateFields> = {};
    let canGenerateOTP = true;

    if (needsName) {
      partialData.name = {firstName, lastName};
    }
    if (canUpdatePhone) {
      partialData.phone = {
        userProvidedPhoneNumber: phone?.userProvidedPhoneNumber,
      };
    }
    if (needsName || canUpdatePhone) {
      const response = await appController.updateIndividual({
        intl,
        partialData,
      });
      canGenerateOTP = !!response;
    }

    if (canGenerateOTP) {
      await appController.setIndividualCollectedData({
        phoneOtp: {shouldGenerateOTP: true},
      });
      await appController.generateOTP({mode: OTPMode.phone});
    }
  }, [
    appController,
    intl,
    needsName,
    canUpdatePhone,
    firstName,
    lastName,
    phone?.userProvidedPhoneNumber,
  ]);

  const handleKeyDown = useCallback(
    // this onKeyDown handler is just to support pressing enter to submit email
    (event) => {
      if (generateOTP?.pending || validateOTP?.pending) {
        return;
      }
      // Pressed the Enter key
      if (event.keyCode === 13) {
        send();
      }
    },
    [generateOTP, validateOTP, send],
  );

  useEffect(() => {
    if (initializeRef.current) {
      return;
    }
    // Ensure we initialize the individual state on first load of the page
    if (session) {
      appController.initializeIndividualState();
      initializeRef.current = true;
    }
  }, [appController, session]);

  useEffect(() => {
    // Autosend OTP at most once on load if we already have a phone number for the user
    if (!initialSendOTP.current && autosendOTP) {
      send();
      initialSendOTP.current = true;
    }
  }, [autosendOTP, send]);

  useEffect(() => {
    if (generateOTPError) {
      setErrorMessage(messages.errorSend);
    }
  }, [generateOTPPending, generateOTPError]);

  useEffect(() => {
    if (!session) {
      // Wait for session to be ready
      return;
    }
    if (!needsOTP && !checkNeedsRedirect.current) {
      // Automatically route to the next page if we don't need to verify phone
      // for the initial state of session.
      appController.routeToNextPage({
        reason: 'phone_otp_submitted',
        caller: 'PhoneVerificationPage',
      });
    }
    checkNeedsRedirect.current = true;
  }, [needsOTP, session, appController, validateOTP.value?.phone]);

  if (isLoading) {
    return <PageCard loading />;
  }

  const header = <TopNavigationBar />;
  const footer = (
    <view.div uses={[p200]}>
      <ButtonGroup direction="column">
        <SendButton send={send} />
        <DeclineButton intl={intl} />
      </ButtonGroup>
    </view.div>
  );
  let body;
  if (showPhoneInputContent) {
    body = (
      <view.div uses={[Styles.container]}>
        <view.div>
          <Message {...messages.phoneHeader} />
        </view.div>
        <view.div uses={[Styles.body]}>
          <Description />
          {canUpdatePhone && <PhoneInput handleKeyDown={handleKeyDown} />}
          {needsName && <NameInput />}
          <view.div css={{width: 'fill', alignX: 'center', stack: 'y'}}>
            {errorMessage && (
              <ValidationMsg>
                <Message {...errorMessage} />
              </ValidationMsg>
            )}
          </view.div>
        </view.div>
      </view.div>
    );
  } else {
    body = (
      <view.div uses={[Styles.container]}>
        <view.div uses={[Styles.body]}>
          <OTPPageContent mode={OTPMode.phone} />
          <view.div css={{width: 'fill', alignX: 'center', stack: 'y'}}>
            {errorMessage && (
              <ValidationMsg>
                <Message {...errorMessage} />
              </ValidationMsg>
            )}
          </view.div>
        </view.div>
      </view.div>
    );
  }
  return <PageCard body={body} header={header} footer={footer} />;
};

export default injectIntl(PhoneVerificationPage);
