import {useRef} from 'react';
import useIsomorphicEffect from 'src/internal/useIsomorphicEffect';

/**
 * A declarative hook to provide the previous state of a value.
 *
 * The return value for the first invoke will be undefined since the value did
 * not change.
 *
 * @example Basic {{include "./examples/usePrevious.basic.tsx"}}
 *
 * @see https://sail.stripe.me/apis/react/usePrevious
 */
export default function usePrevious<T>(
  /** The value to be remember for the next execution. */
  value: T,
): T | undefined {
  const valueRef = useRef<T | undefined>(undefined);

  useIsomorphicEffect(() => {
    valueRef.current = value;
  }, [value]);

  return valueRef.current;
}
