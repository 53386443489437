import {noop} from '@sail/utils';
import * as React from 'react';

function useIsomorphicEffect(callback: () => void, deps: unknown[]): void;

function useIsomorphicEffect(
  callback: () => void,
  cleanup: () => void,
  deps: unknown[],
): void;

function useIsomorphicEffect(
  callback: () => void,
  cleanupOrDeps: (() => void) | unknown[],
  maybeDeps?: unknown[],
): void {
  const hasCleanup = typeof cleanupOrDeps === 'function';
  const cleanup = hasCleanup ? cleanupOrDeps : noop;
  const deps = (hasCleanup ? maybeDeps : cleanupOrDeps) || [];

  React.useEffect(() => {
    callback();

    return cleanup;
  }, deps); // eslint-disable-line @stripe-internal/stripe/exhaustive-deps
}

export default useIsomorphicEffect;
