import {defineMessages} from 'react-intl';

const welcomePageMessages = defineMessages({
  documentTitle: {
    id: 'welcome_consent_page.document_title',
    description: 'Title for the welcome page with consent',
    defaultMessage: `Let’s verify your identity`,
  },
  intro: {
    id: 'welcome_consent_page.intro',
    description: 'Intro for the welcome page with consent',
    defaultMessage:
      'Stripe will verify your identity using images of you, your ID card and other data sources.',
  },
  idDocVerification: {
    id: 'welcome_consent_page.id_doc_verification',
    description: 'Prompt for id verification',
    defaultMessage: 'Have a form of identification ready.',
  },
  idDocAndSelfieVerification: {
    id: 'welcome_consent_page.id_doc_and_selfie_verification',
    description: 'Prompt for id + selfie verification',
    defaultMessage:
      'Have a form of identification ready and be prepared to take a selfie.',
  },
  deviceHandoff: {
    id: 'welcome_consent_page.device_handoff',
    description: 'Text for device handoff butoon',
    defaultMessage: 'Complete on a mobile device',
  },
  startButton: {
    id: 'welcome_consent_page.startButton',
    description: 'Text for start/accept button',
    defaultMessage: 'Get started',
  },
  declineButton: {
    id: 'welcome_consent_page.decline_button',
    description: 'Text for decline and exit button',
    defaultMessage: 'Decline and exit',
  },
  internalInformedConsent: {
    id: 'welcome_consent_page.internal_informed_consent',
    description: 'Consent wording for internal use cases',
    defaultMessage: `The images and results may be used according to Stripe’s Privacy Policy. {learnMoreLink}`,
  },
  externalInformedConsent: {
    id: 'welcome_consent_page.external_informed_consent',
    description: 'Consent wording for external integrators',
    defaultMessage:
      'The images and results will be shared with {platformName} and with Stripe,' +
      ` and may be used according to the privacy policy provided by {platformName} and Stripe's Privacy Policy. {learnMoreLink}`,
  },
  externalInformedConsentWithPP: {
    id: 'welcome_consent_page.external_informed_consent_with_pp',
    description:
      'Consent wording for external integrators, if they provided a privacy policy',
    defaultMessage:
      'The images and results will be shared with {platformName} and with Stripe' +
      ` and may be used according to the <PrivacyPolicy>privacy policy</PrivacyPolicy> provided by {platformName} and Stripe's Privacy Policy. {learnMoreLink}`,
  },
  learnMore: {
    id: 'welcome_consent_page.learnMore',
    description: 'Link label to learn more about privacy.',
    defaultMessage: 'Learn more',
  },
});

export default welcomePageMessages;
