import {createViewConfig} from '@sail/engine';
import {Button, ButtonProps, css} from '@sail/ui'; // eslint-disable-line no-restricted-imports
import * as React from 'react';

import {
  LINK_GREEN,
  LINK_HOVER_GREEN,
  LINK_PRESSED_GREEN,
  LINK_DISABLED_GREEN,
  LINK_DISABLED_FONT_GREEN,
} from 'gelato/frontend/src/components/Link/constants';

const ButtonConfig = createViewConfig({
  name: 'LinkButton',
  props: {} as ButtonProps,
  flattens: true,
  defaults: {
    size: 'large',
    type: 'primary',
  },
});

const Styles = {
  button: css({
    backgroundColor: LINK_GREEN,
    color: 'text',
    borderWidth: 'small',
    borderStyle: 'solid',
    borderColor: LINK_GREEN,
    width: 'fill',
    textAlign: 'center',
    '::after': {
      opacity: 0,
    },
    ':hover:not(:active):not([aria-disabled="true"])': {
      backgroundColor: LINK_HOVER_GREEN,
      color: 'text',
      borderColor: LINK_GREEN,
    },
    ':hover:not([aria-disabled="true"])': {
      backgroundColor: LINK_HOVER_GREEN,
      color: 'text',
      borderColor: LINK_GREEN,
    },
    ':active:not([aria-disabled="true"])': {
      backgroundColor: LINK_HOVER_GREEN,
      color: 'text',
      borderColor: LINK_GREEN,
    },
    '[aria-disabled="true"]': {
      backgroundColor: LINK_DISABLED_GREEN,
      color: LINK_DISABLED_FONT_GREEN,
    },
  }),
};

const LinkButton = ButtonConfig.createView(({...props}: ButtonProps) => {
  return (
    <Button
      style={{
        boxShadow: '0px 1px 1px 0px rgba(20, 19, 78, 0.32)',
      }}
      uses={[Styles.button]}
      {...props}
    />
  );
});

export default LinkButton;
