import * as React from 'react';
import {useRef, useState, useCallback} from 'react';
import type {ReactNode} from 'react';
import {
  useIsomorphicLayoutEffect,
  useForceUpdate,
  createViewConfig,
  view,
  dynamic,
} from '@sail/engine';
import {earlyFraudWarning, warning, cancel} from '@sail/icons/react/Icon';
import type {IconAsset} from '@sail/icons/types';
import type {View} from '../view';
import {useResizeObserver} from './hooks/useResizeObserver';
import type {IconProps} from './Icon';
import {Icon, IconConfig} from './Icon';
import {css} from '../css';
import {Action} from './Action';
import {LinkConfig} from './Link';

export type BannerProps = View.IntrinsicElement<
  'div',
  {
    /**
     * Vary the type of banner to indicate the severity of the message.
     */
    type: 'default' | 'caution' | 'critical';
    /**
     * A short message communicating the reason for the banner.
     */
    description?: ReactNode;
    /**
     * An optional, concise title for when the description is longer than a single line.
     */
    title?: ReactNode;
    /**
     * An optional callback that is invoked when the banner is dismissed.
     */
    onDismiss?: () => void;
    /**
     * Every banner should provide a clear call-to-action button.
     */
    actions?: ReactNode;

    subviews?: View.Subviews<{
      content: 'span';
      actions: 'div';
      icon: typeof BannerIcon;
      title: 'span';
    }>;
  }
>;

export const BannerConfig = createViewConfig({
  props: {} as BannerProps,
  name: 'Banner',
  defaults: {
    type: 'default',
  },
  flattens: true,
});

const iconMap: Record<BannerProps['type'], IconAsset> = {
  caution: warning,
  critical: warning,
  default: earlyFraudWarning,
};

const getLinkColor = (type: 'default' | 'caution' | 'critical') => {
  if (type === 'caution') {
    return 'onFeedback.attention.subdued';
  } else if (type === 'critical') {
    return 'onFeedback.critical.subdued';
  } else {
    return 'text';
  }
};

// add up the border, padding, and height of the title + description + actions
// container when there is only 1 line of text each and determine if the
// Banner is taller than that baseline height (meaning it has wrapped). This
// approach is pretty verbose because it needs to account for the default heights of items
// in the banner changing due to theming.
//
// Sidenote: We could just compare offsetTop of the title/description container and
// the actions container instead, but it's technically possible for them to have
// different offsetTops even when the actions are on the same line, if the content passed
// into actions is shorter than the height of the title/description container.
function checkIfWraps(
  titleEl: HTMLSpanElement | null,
  descriptionEl: HTMLSpanElement | null,
  containerEl: HTMLDivElement,
  actionsEl: HTMLDivElement | null,
) {
  // Use parseFloat to parse the height styles rather than parseInt because
  // parseInt will round down to the nearest integer, which can cause the
  // height to be less than the actual height of the text. For example,
  // something that's 27.986px will be floored to 27, but the height should
  // really be 28.
  const titleLineHeight = titleEl
    ? Math.round(parseFloat(getComputedStyle(titleEl).lineHeight))
    : 0;
  const descriptionLineHeight = descriptionEl
    ? Math.round(parseFloat(getComputedStyle(descriptionEl).lineHeight))
    : 0;

  const actionsStyles = actionsEl ? getComputedStyle(actionsEl) : null;
  const actionsHeight = actionsEl
    ? Math.round(
        parseFloat(actionsStyles?.height ?? '0') +
          parseInt(actionsStyles?.marginTop ?? '0', 10) +
          parseInt(actionsStyles?.marginBottom ?? '0', 10),
      )
    : 0;
  const {borderWidth, padding} = getComputedStyle(containerEl);

  // if the button height is larger than the title + description, use that height
  // this can happen when there is a missing title or description and there is only one
  // line of text
  const innerContainerHeight =
    actionsHeight > titleLineHeight + descriptionLineHeight
      ? actionsHeight
      : titleLineHeight + descriptionLineHeight;

  return (
    containerEl.offsetHeight >
    innerContainerHeight +
      parseInt(padding, 10) * 2 +
      parseInt(borderWidth, 10) * 2
  );
}

export const BannerIconConfig = createViewConfig({
  props: {} as IconProps,
  name: 'BannerIcon',
  flattens: true,
});

const BannerIcon = BannerIconConfig.createView((props) => {
  return <Icon {...props} />;
});

/**
 * Banners let users know how to take action on unexpected, system-level requirements, changes, or issues.
 */
export const Banner = BannerConfig.createView(
  ({description, title, actions, onDismiss, type, subviews, ...props}) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const titleRef = useRef<HTMLSpanElement | null>(null);
    const descriptionRef = useRef<HTMLSpanElement | null>(null);
    const actionsRef = useRef<HTMLDivElement | null>(null);
    const [isWrapped, setIsWrapped] = useState(false);
    const forceUpdate = useForceUpdate();

    // The line requires a two-pass render because it's using a resize
    // observer on a parent node. When this component renders, that
    // ref is `null` is the resize observer doesn't work. The ref will
    // have a node during the second render, but note that (while
    // unlikely) if the ref changes node this component won't change
    // resize observers
    useIsomorphicLayoutEffect(() => {
      forceUpdate();
    }, []);

    const onResize = useCallback(() => {
      if (containerRef.current) {
        setIsWrapped(
          checkIfWraps(
            titleRef.current,
            descriptionRef.current,
            containerRef.current,
            actionsRef.current,
          ),
        );
      }
    }, []);

    useIsomorphicLayoutEffect(onResize, [onResize]);

    useResizeObserver({
      node: containerRef,
      handler: onResize,
      // The layout effect above already handles the initial render
      avoidInitialEvent: true,
    });

    return (
      <view.div {...props}>
        <BannerIcon icon={iconMap[type]} aria-hidden inherits={subviews.icon} />
        <view.div
          css={{
            stack: 'x',
            wrap: 'wrap',
            alignY: 'center',
            distribute: 'space-between',
          }}
          ref={containerRef}
        >
          <view.span
            css={{
              stack: 'y',
              wrap: 'wrap',
              width: 'fit',
              marginRight: 'space.125',
            }}
            inherits={subviews.content}
          >
            {title ? (
              <view.span
                css={{fontWeight: 'semibold', font: 'heading.small'}}
                ref={titleRef}
                inherits={subviews.title}
              >
                {title}
              </view.span>
            ) : null}
            {description ? (
              <view.span
                uses={[
                  css({
                    overflowWrap: 'anywhere',
                  }),
                ]}
                ref={descriptionRef}
                css={{font: 'body.small'}}
              >
                {description}
              </view.span>
            ) : null}
          </view.span>
          {actions ? (
            <view.div
              css={{
                stack: 'x',
                gap: 'small',
                wrap: 'wrap',
                marginTop: isWrapped ? 'small' : 0,
                marginLeft: isWrapped ? 0 : 'auto',
                width: 'fit',
                font: 'label.medium.emphasized',
              }}
              ref={actionsRef}
              inherits={subviews.actions}
            >
              {actions}
            </view.div>
          ) : null}
        </view.div>
        {onDismiss ? (
          <Action
            onPress={onDismiss}
            css={{
              alignSelfY: isWrapped ? 'baseline' : 'center',
              width: 'space.250',
              // ensures line height doesn't get reset by normalize styles in sailpen SAIL-2663
              lineHeight: 'normal',
              stack: 'x',
              alignY: 'center',
              alignX: 'center',
            }}
          >
            {/* TODO: SAIL-3215 no hardcoded strings */}
            <Icon icon={cancel} aria-label="close" />
          </Action>
        ) : null}
      </view.div>
    );
  },
  {
    css: {
      stack: 'x',
      alignY: 'baseline',
      padding: 'medium',
      border: '1px solid',
      borderColor: 'border',
      borderRadius: 'small',
      font: 'heading.small',
      gap: 'space.150',
    },

    forward: {type: true},

    uses: [
      IconConfig.customize({
        defaults: {
          size: 'xsmall',
        },
      }),
      dynamic(({type}: BannerProps) => [
        LinkConfig.customize({
          css: {
            color: getLinkColor(type),
            textDecoration: 'underline',
            textUnderlineOffset: '1.5px',
            ':hover': {
              color: getLinkColor(type),
              textDecoration: 'none',
            },
          },
        }),
      ]),
    ],

    variants: {
      type: {
        default: css({
          color: 'text',
          backgroundColor: 'surface',
          fill: 'subdued',
        }),
        caution: css({
          color: 'onFeedback.attention.subdued',
        }),
        critical: css({
          color: 'onFeedback.critical.subdued',
        }),
      },
    },
  },
);
