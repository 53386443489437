import {view} from '@sail/ui';
import * as React from 'react';
import {Helmet} from 'react-helmet';
import {defineMessages, IntlShape, injectIntl} from 'react-intl';

import LoadingBox from 'gelato/frontend/src/components/LoadingBox';
import Message from 'gelato/frontend/src/components/Message';
import TestingBanner from 'gelato/frontend/src/components/TestingBanner';
import ThemableButton from 'gelato/frontend/src/components/ThemableButton';
import useGenerateOneTimePasswordMutation from 'gelato/frontend/src/graphql/mutations/useGenerateOneTimePasswordMutation';
import useValidateOneTimePasswordMutation from 'gelato/frontend/src/graphql/mutations/useValidateOneTimePasswordMutation';
import {PAGE_TITLE_MESSAGE} from 'gelato/frontend/src/lib/constants';
import {useRouter, useSession} from 'gelato/frontend/src/lib/hooks';
import {AlignBox} from 'sail/Align';
import Box from 'sail/Box';
import {ButtonLink} from 'sail/Button';
import CodePuncher from 'sail/CodePuncher';
import Icon from 'sail/Icon';
import {BodyAlt, BodyAltTextProps, Title} from 'sail/Text';

import type {MessageDescriptor} from 'react-intl';

const messages = defineMessages({
  errorSend: {
    id: 'otp.error.send',
    description:
      'Error message saying that the one time password verification code could not be sent (or resent)',
    defaultMessage: 'Error sending verification code',
  },
  errorCodeInvalid: {
    id: 'otp.error.code.invald',
    description:
      'Error message indicating that the code entered could not be validated',
    defaultMessage: 'Error confirming verification code',
  },
  emailInstructions: {
    id: 'otp.email.instructions',
    description:
      'Instructions to open the email and enter the code found there in the input on this page',
    defaultMessage: 'Enter the code sent to your email {email} to continue.',
  },
  phoneInstructions: {
    id: 'otp.phone.instructions',
    description:
      'Instructions to open the sms and enter the code found there in the input on this page',
    defaultMessage:
      'Enter the code sent to your phone number ending in {phoneLastFour} to continue.',
  },
  codeResend: {
    id: 'otp.code.resend',
    description: 'Text on button to resend code',
    defaultMessage: 'Send again',
  },
  emailDecline: {
    id: 'otp.email.decline',
    description:
      'Text allowing the user to decline verification of this email address and continue.',
    defaultMessage: 'I cannot verify this email',
  },
  phoneDecline: {
    id: 'otp.phone.decline',
    description:
      'Text allowing the user to decline verification of this phone number and continue.',
    defaultMessage: 'I cannot verify this phone number',
  },
  pageHeader: {
    id: 'otp.header',
    description:
      'Header text to indicate that we are sending an OTP verification code',
    defaultMessage: `Confirm it’s you`,
  },

  testmodeBannerTitle: {
    id: 'otp.testmodeBanner.title',
    description: 'title text to indicate that no otp code is sent in testmode',
    defaultMessage: 'No codes sent in testmode',
  },
  testmodeBannerDescription: {
    id: 'otp.testmodeBanner.description',
    description:
      'description text to inform users to use the special code 000000 in testmode',
    defaultMessage:
      'You can use the code 000-000 to mimic success and any other code to mimic failure.',
  },
});

type ErrorTagProps = {
  errorMessage: MessageDescriptor;
};

const ErrorTag = (props: ErrorTagProps) => {
  const {errorMessage} = props;
  return (
    <Box style={{paddingLeft: '1px'}} padding={{top: 4}}>
      <AlignBox reference={BodyAltTextProps}>
        <Box padding={{right: 8}}>
          <Icon color="red" icon="warning" size={12} />
        </Box>
        <BodyAlt color="red">
          <Message {...errorMessage} />
        </BodyAlt>
      </AlignBox>
    </Box>
  );
};

export enum OTPMode {
  email = 'email',
  phone = 'phone',
}

type OTPPageProps = {
  mode: OTPMode;
  skipping: boolean;

  phone?: string;
  email?: string;
};

const OTPPageContent = (props: OTPPageProps & {intl: IntlShape}) => {
  // this component renders the OTP page for either email or sms verification
  // handling both of these concerns means:
  // 1. that graphql mutations accept payloads depending on the props.mode
  // 2. some message descriptors depend on props.mode to have the right language
  const {
    mode,
    phone = '',
    skipping,
    email = '',
    intl: {formatMessage},
  } = props;

  const session = useSession();
  const livemode = session?.livemode;
  const providedPhoneNumber = React.useMemo(
    () =>
      session?.collectedData?.individual?.phoneNumber
        ?.merchantProvidedPhoneNumber ||
      session?.collectedData?.individual?.phoneNumber?.userProvidedPhoneNumber,
    [session],
  );
  const providedEmail = React.useMemo(
    () =>
      session?.collectedData?.individual?.email?.merchantProvidedAddress ||
      session?.collectedData?.individual?.email?.userProvidedAddress,
    [session],
  );

  const [sent, setSent] = React.useState(false);
  const [sending, setSending] = React.useState(skipping);
  const [validating, setValidating] = React.useState(false);
  const [declining, setDeclining] = React.useState(false);
  const router = useRouter();

  const [otpValue, setOtpValue] = React.useState('');

  const [errorMessage, setErrorMessage] = React.useState<any>(null);

  const [generateOTPMutation, {data: sendData, error: sendError}] =
    useGenerateOneTimePasswordMutation();

  const [validateOTPMutation, {data: validateData, error: validateError}] =
    useValidateOneTimePasswordMutation();

  const generateOTP = React.useCallback(async () => {
    setErrorMessage(null);
    if (skipping) {
      return;
    }

    setSending(true);
    setOtpValue('');
    if (mode === OTPMode.email) {
      await generateOTPMutation({
        variables: {
          email: true,
        },
      });
    } else {
      await generateOTPMutation({
        variables: {
          phone: true,
        },
      });
    }
    setSending(false);
  }, [skipping, mode, generateOTPMutation]);

  const validateOTP = async (value: string) => {
    setErrorMessage(null);
    setValidating(true);
    if (mode === OTPMode.email) {
      await validateOTPMutation({
        variables: {
          emailCode: value,
        },
      });
    } else {
      await validateOTPMutation({
        variables: {
          phoneCode: value,
        },
      });
    }
    setValidating(false);
  };

  const declineOTP = async () => {
    setDeclining(true);
    let variables = null;
    if (mode === OTPMode.email) {
      variables = {
        emailDecline: true,
      };
    } else {
      variables = {
        phoneDecline: true,
      };
    }
    const declineResponse = await validateOTPMutation({variables});
    // in these cases, we know that the user just declined to otp verify and yet
    // they still have the requirement so we can send them to the invalid page
    const missingFields =
      declineResponse.data?.validateOneTimePassword?.session?.missingFields ||
      [];
    if (mode === OTPMode.email && missingFields.includes('email_otp')) {
      router.push('/invalid');
    } else if (mode === OTPMode.phone && missingFields.includes('phone_otp')) {
      router.push('/invalid');
    }
    setDeclining(false);
  };

  React.useEffect(() => {
    if (!sent) {
      // we send a code automatically on page load and this happens here
      if (
        (mode === OTPMode.email && providedEmail) ||
        (mode === OTPMode.phone && providedPhoneNumber)
      ) {
        // ensure that phone number has been provided to ensure that we can actually generate the otp code
        generateOTP();
        setSent(true);
      }
    } else if (
      sendError ||
      (sendData && sendData.generateOneTimePassword.success === false)
    ) {
      // if we have sent a code, then here we set an error message to indicate we had trouble sending the code
      setErrorMessage(messages.errorSend);
    } else if (
      validateError ||
      (validateData && validateData.validateOneTimePassword.success === false)
    ) {
      // set an error message to say that we could not validate the code
      // user can input code again to correct an error
      setErrorMessage(messages.errorCodeInvalid);
      setOtpValue('');
    }
  }, [
    sent,
    sendData,
    sendError,
    validateData,
    validateError,
    generateOTP,
    providedPhoneNumber,
    providedEmail,
    mode,
  ]);

  const onCodePuncherChange = (value: string) => {
    setOtpValue(value);
    if (value.length === 6) {
      // we submit the code for validation automatically as soon as the 6th character has been input
      validateOTP(value);
    }
  };

  const renderInstructions = () => {
    if (mode === OTPMode.email) {
      return (
        <Message
          {...messages.emailInstructions}
          values={{email}}
          /* annoying that this configuration is not more lightweight to provide.
           * we just want to disable linkifying the user provided email here and
           * all of this junk is needed to satisfy the typing */
          config={{
            linkify: false,
            inline: false,
            rules: {core: [], block: [], inline: []},
          }}
        />
      );
    } else {
      const phoneLastFour = phone.slice(phone.length - 4);

      return (
        <Message {...messages.phoneInstructions} values={{phoneLastFour}} />
      );
    }
  };

  const declineMessage =
    mode === OTPMode.email ? messages.emailDecline : messages.phoneDecline;

  if (sending) {
    return <LoadingBox />;
  }

  return (
    <>
      <Helmet>
        <title>{formatMessage(PAGE_TITLE_MESSAGE)}</title>
      </Helmet>
      <view.div
        css={{paddingY: 'space.150', paddingX: 'space.250', gap: 'medium'}}
      >
        <view.div>
          <Title>
            <Message {...messages.pageHeader} />
          </Title>
        </view.div>
        <view.div css={{stack: 'y', gap: 'medium'}}>
          {/* Instructions on how to punch in the OTP code */}
          <view.div>{renderInstructions()}</view.div>
          <view.div css={{stack: 'y', gap: 'xsmall', font: 'body.small'}}>
            <CodePuncher
              aria-label="OTP code"
              value={otpValue}
              autoFocus
              onChange={onCodePuncherChange}
            />
            <view.div css={{width: 'fill', alignX: 'center', stack: 'y'}}>
              {errorMessage && <ErrorTag errorMessage={errorMessage} />}
            </view.div>
            {!livemode && (
              <TestingBanner
                titleMessage={messages.testmodeBannerTitle}
                descriptionMessage={messages.testmodeBannerDescription}
              />
            )}
          </view.div>
        </view.div>
      </view.div>
      {/* Button to request re-sending the code */}
      <>
        <view.div css={{height: 'fill'}} />
        <view.div
          css={{
            padding: 'space.250',
            stack: 'y',
            gap: 'medium',
            alignX: 'center',
          }}
        >
          <view.div css={{width: 'fill'}}>
            <ThemableButton
              label={<Message {...messages.codeResend} />}
              size="large"
              width="maximized"
              color="blue"
              onClick={generateOTP}
              pending={sending}
              disabled={sending || validating || declining}
            />
            <view.div
              css={{stack: 'x', paddingTop: 'medium', alignX: 'center'}}
            >
              <ButtonLink
                id="decline-otp"
                label={<Message {...declineMessage} />}
                size="large"
                width="maximized"
                color="blue"
                onClick={declineOTP}
                pending={declining}
                disabled={sending || validating || declining}
              />
            </view.div>
          </view.div>
        </view.div>
      </>
    </>
  );
};

export default injectIntl(OTPPageContent);
