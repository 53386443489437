import {getTargetWindow, isSafeUrl, SafeLinkOptions, UrlLike} from './utils';

/**
 * Redirects the current page to the provided URL in a safe manner. It only accepts HTTP(s) protocols
 * Use this instead of these native methods: `location.assign()`, `location.href`, `location`.
 *
 * _NOTE: Use `urlReplace` if you want to replace instead_
 * @example
 * ```
 * urlRedirect("https://amp.corp.stripe.com"); // equivalent to location.assign("https://amp.corp.stripe.com")
 * ```
 * @param url A string containing the URL of the page to navigate to
 */
export const urlRedirect = (url: UrlLike, options?: SafeLinkOptions) => {
  if (!isSafeUrl(url, options)) {
    return;
  }

  return getTargetWindow(options).location.assign(url);
};

/**
 * Redirects the current page to the provided URL in a safe manner. It only accepts HTTP(s) protocols
 * Use this instead of these native methods: `location.replace()`.
 *
 * _NOTE: Use `urlRedirect` if you don't want to replace_
 * @example
 * ```
 * urlReplace("https://amp.corp.stripe.com"); // equivalent to location.replace("https://amp.corp.stripe.com")
 * ```
 * @param url A string containing the URL of the page to navigate to
 */
export const urlReplace = (url: UrlLike, options?: SafeLinkOptions) => {
  if (!isSafeUrl(url, options)) {
    return;
  }

  return getTargetWindow(options).location.replace(url);
};
