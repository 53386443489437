import {dispute} from '@sail/icons/react/Icon';
import * as React from 'react';

import Button from 'gelato/frontend/src/components/ButtonV2';
import messages from 'gelato/frontend/src/components/Invalid/messages';
import Message from 'gelato/frontend/src/components/Message';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';

import {useBranding, useReturnUrl} from '../../lib/hooks';

const Footer = () => {
  const branding = useBranding();
  const returnUrl = useReturnUrl();
  const {platformName} = branding || {};

  React.useEffect(() => {
    postIframeEvent('STRIPE_IDENTITY_ERROR');
  });

  if (returnUrl && platformName) {
    return (
      <Button href={returnUrl}>
        <Message {...messages.failureLink} values={{platformName}} />
      </Button>
    );
  } else {
    return null;
  }
};

export const generateGenericInvalidContent = () => {
  return {
    title: <Message {...messages.heading} />,
    icon: dispute,
    description: <Message {...messages.body} />,
    footer: <Footer />,
  };
};
