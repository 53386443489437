import {pluginsAnimation} from './pluginsAnimation';
import {pluginsBoxModel as controlPluginsBoxModel} from './pluginsBoxModel';
import {pluginsBoxModel as experimentalPluginsBoxModel} from './pluginsBoxModel.experimental';
import {pluginsBoxShadow} from './pluginsBoxShadow';
import {pluginsFont} from './pluginsFont';
import {pluginsLayout} from './pluginsLayout';
import {pluginsTransform} from './pluginsTransform';

declare global {
  const SAIL_EXPERIMENTAL_CSS_PLUGIN_BOX_MODEL: boolean | void;
}

const pluginsBoxModel =
  typeof SAIL_EXPERIMENTAL_CSS_PLUGIN_BOX_MODEL !== 'undefined' &&
  SAIL_EXPERIMENTAL_CSS_PLUGIN_BOX_MODEL
    ? experimentalPluginsBoxModel
    : controlPluginsBoxModel;

export const plugins = {
  ...pluginsAnimation,
  ...pluginsBoxModel,
  ...pluginsBoxShadow,
  ...pluginsFont,
  ...pluginsLayout,
  ...pluginsTransform,
};
