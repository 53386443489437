import DetectRTC from 'detectrtc';
import {get} from 'lodash';
import * as React from 'react';
import {Helmet} from 'react-helmet';
import {defineMessages, injectIntl, MessageDescriptor} from 'react-intl';

import LoadingBox from 'gelato/frontend/src/components/LoadingBox';
import Message from 'gelato/frontend/src/components/Message';
import ThemableButton from 'gelato/frontend/src/components/ThemableButton';
import ThemableToggleBox from 'gelato/frontend/src/components/ThemableToggleBox';
import useClearDataFieldsMutation from 'gelato/frontend/src/graphql/mutations/useClearDataFieldsMutation';
import analytics from 'gelato/frontend/src/lib/analytics';
import {
  BreakpointContext,
  SetPageCardPropsContext,
} from 'gelato/frontend/src/lib/contexts';
import {nextDataPageForSession} from 'gelato/frontend/src/lib/dataRouting';
import {isMobileDevice} from 'gelato/frontend/src/lib/device';
import {
  useExperiments,
  useFeatureFlags,
  useRouter,
  useSession,
} from 'gelato/frontend/src/lib/hooks';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import Storage from 'gelato/frontend/src/lib/Storage';
import {hasCamera} from 'gelato/frontend/src/lib/userMedia';
import {ensure} from 'gelato/frontend/src/lib/utils';
import Notice from 'sail/Notice';
import {Title} from 'sail/Text';
import {ToggleBoxItem} from 'sail/ToggleBox';

import styles from './verify_options.module.css';

import type {IntlProps} from 'gelato/frontend/src/components/Message';
import type {Session} from 'gelato/frontend/src/lib/contexts';
import type {PageProps} from 'gelato/frontend/src/lib/localRouter';
import type {VerifyOptions} from 'gelato/frontend/src/lib/Storage';

export const messages = defineMessages({
  browserOrDeviceUnsupportedLabel: {
    id: 'form.individual.verifyOptions.browserOrDeviceUnsupported',
    description:
      'Label giving user option of switching to a different browser or device.',
    defaultMessage: 'Use a different browser or device',
  },
  documentTitle: {
    id: 'form.individual.verifyOptions.title',
    description: 'Title in the verify options page',
    defaultMessage: 'Select how to verify your ID',
  },
  selfieTitle: {
    id: 'form.individual.verifyOptions.selfieTitle',
    description:
      'Title in the verify options page when only selfie is required',
    defaultMessage: 'Select how to take your selfie',
  },
  fromCameraPhoneLabel: {
    id: 'form.individual.verifyOptions.fromCameraPhone',
    description:
      'Label giving user option of uploading from camera (while on a mobile device)',
    defaultMessage: 'Take a picture of your ID',
  },
  fromWebcamLabel: {
    id: 'form.individual.verifyOptions.fromWebcamLabel',
    description:
      'Label giving user option of uploading from their webcam (while on a desktop device)',
    defaultMessage: 'Take a picture with your webcam',
  },
  handoffToMobileLabel: {
    id: 'form.individual.verifyOptions.handoffToMobile',
    description: 'Label giving user option of uploading from a mobile device',
    defaultMessage: 'Take a picture with your phone',
  },
  handoffToEmailLabel: {
    id: 'form.individual.verifyOptions.handoffToEmail',
    description:
      'Label giving user option of uploading from a different device via email',
    defaultMessage: 'Email a verification link',
  },
  uploadDocumentLabel: {
    id: 'form.individual.verifyOptions.uploadDocumentLabel',
    description: 'Label giving user option of uploading an exitsing photo.',
    defaultMessage: 'Upload a file',
  },
  unsupportedBrowserWarning: {
    id: 'form.individual.verifyOptions.unsupportedBrowserWarning',
    description:
      'Explanation that the current browser cannot complete identity verification and they need to switch',
    defaultMessage:
      'Unfortunately, due to technical limitations, this browser can’t be used to complete the verification.',
  },
  instacartErrorCopy: {
    id: 'form.individual.verifyOptions.instacartErrorCopy',
    description:
      'Explanation that the Social Security Number verification failed and Instacart users need to upload an ID.',
    defaultMessage:
      'Unfortunately, we couldn’t verify your information with the last 4 digits of your Social Security Number. Please upload a photo of your ID to try again.',
  },
  nextButton: {
    id: 'pages.welcome.identity_document.nextButton',
    description: 'Next button text',
    defaultMessage: 'Next',
  },
});

type GetAvailableVerificationOptionsParams = {
  session: Session | null | undefined;
  networkedDocumentId?: string;
};

type VerifyOptionsType = {
  icon: string;
  message: MessageDescriptor;
  type: VerifyOptions;
};

const getAvailableVerificationOptions = async ({
  session,
  networkedDocumentId,
}: GetAvailableVerificationOptionsParams): Promise<VerifyOptionsType[]> => {
  const verifyOptions = [];
  const cameraExists = await hasCamera();
  const cameraMessage = isMobileDevice()
    ? messages.fromCameraPhoneLabel
    : messages.fromWebcamLabel;
  const {rLCapture: requireLiveCapture, missingFields} = session ?? {};
  const operatingMode = get(session, 'operatingMode');
  const forceMobile = get(session, 'fMobile');
  const isPrimary = operatingMode === 'primary';
  const disableDesktopUpload = !isMobileDevice() && forceMobile;

  if (isPrimary && !isMobileDevice()) {
    verifyOptions.push({
      icon: 'mobile',
      message: messages.handoffToMobileLabel,
      type: 'mobile' as VerifyOptions,
    });
  }
  if (cameraExists && !disableDesktopUpload) {
    verifyOptions.push({
      icon: 'camera',
      message: cameraMessage,
      type: 'webcam' as VerifyOptions,
    });
  }
  const canTakeSelfie: boolean =
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    !missingFields.includes('face') || cameraExists;

  const documentImagesNotMissing =
    !missingFields?.includes('id_document_images');

  // If a networked document is provided, we do not expect to have
  // id_document_images as missing fields. However, since these
  // fields are not explicitly tied together, we'll be safe and
  // check for both.
  const networkedIdentityBlocksUpload =
    documentImagesNotMissing && !!networkedDocumentId;

  // We disable file upload when:
  // 1. live documet capture is required
  // 2. we are experimenting with disabling upload
  // 3. if a selfie is required and we cannot take one on this device
  //   - when a selfie is required, we must have a support camera, if not
  //     we'll push to handoff to a mobile device
  // 4. Networked Identity blocks file uploads. This happens when a user
  //    shares a networked document and selfie is requested.
  if (
    !requireLiveCapture &&
    !disableDesktopUpload &&
    canTakeSelfie &&
    !networkedIdentityBlocksUpload
  ) {
    verifyOptions.push({
      icon: 'document',
      message: messages.uploadDocumentLabel,
      type: 'upload' as VerifyOptions,
    });
  }
  return verifyOptions;
};

const VerifyOptionsPageInner = (props: PageProps) => {
  const router = useRouter();
  const experiments = useExperiments();
  const breakpoint = React.useContext(BreakpointContext);
  const flags = useFeatureFlags();
  const session = useSession();
  const [clearDataFields] = useClearDataFieldsMutation();
  const setPageCardProps = React.useContext(SetPageCardPropsContext);
  const [detectRTCLoaded, setDetectRTCLoaded] = React.useState<boolean>(false);
  const [verifyOption, setVerifyOption] =
    React.useState<VerifyOptions>('mobile');
  const [availableOptions, setAvailableOptions] =
    React.useState<VerifyOptionsType[]>();

  const skipWelcomePage = session && session.skipWelcomePage;

  const networkedDocumentId = Storage.getNetworkedDocumentId();

  React.useEffect(() => {
    setPageCardProps({
      showBackLink: !skipWelcomePage,
      showPageHeader: true,
      size: 'single',
    });
  }, [setPageCardProps, skipWelcomePage]);

  React.useEffect(() => {
    let isMounted = true;
    DetectRTC.load(() => {
      isMounted && setDetectRTCLoaded(true);
    });
    return () => {
      // prevent state errors by modifying unmounted components
      isMounted = false;
    };
  }, []);

  const handleSelection = React.useCallback(
    async (selectedOption: VerifyOptions) => {
      Storage.setVerifyOption(selectedOption);
      analytics.action('choseVerifyOption', {selectedOption});
      // Clear out any documents or faces that have already been captured.
      // This allows the user to re-upload a document and/or selfie instead of triggering
      // a submission if the information has already been collected.

      const {data} = await clearDataFields({
        variables: {
          // Only clear document images if we are not re-using a networked document
          clearIdDocumentFront: !networkedDocumentId,
          clearIdDocumentBack: !networkedDocumentId,
          clearFace: true,
        },
      });

      if (data && data.clearDataFields) {
        if (selectedOption === 'upload' || selectedOption === 'webcam') {
          const {missingFields, requiredFields} = data.clearDataFields.session;
          const nextPage = await nextDataPageForSession({
            missingFields,
            requiredFields,
            rLCapture: ensure(session).rLCapture,
          });
          router.push(nextPage);
        } else {
          // selectedOption === 'mobile' (i.e. "Take a picture with your phone.").
          router.push({pathname: '/handoff'});
        }
      }
    },
    [clearDataFields, router, session, networkedDocumentId],
  );

  React.useEffect(() => {
    const populateOptions = async () => {
      const verificationOptions = await getAvailableVerificationOptions({
        session,
        networkedDocumentId,
      });
      const firstOption = verificationOptions[0];
      if (!firstOption) {
        analytics.action('unsupportedBrowser');
        setVerifyOption('unsupported');
      } else if (
        !verificationOptions.find((opt) => opt.type === verifyOption)
      ) {
        // The current verify option isn't viable, so we'll default to the
        // first option.
        setVerifyOption(firstOption.type);
      }

      setAvailableOptions(verificationOptions);
    };
    if (detectRTCLoaded) {
      populateOptions();
    }
  }, [
    breakpoint,
    detectRTCLoaded,
    experiments,
    flags,
    handleSelection,
    session,
    verifyOption,
    networkedDocumentId,
  ]);

  if (!(session && flags && detectRTCLoaded && availableOptions)) {
    return null;
  }

  postIframeEvent('load');

  const missingFields = session.missingFields || [];
  const selfieMissingButNotDocument =
    missingFields.includes('face') &&
    !missingFields.includes('id_document_images');
  const titleToRender = selfieMissingButNotDocument ? (
    <Message {...messages.selfieTitle} />
  ) : (
    <Message {...messages.documentTitle} />
  );

  return (
    <div className={styles.verifyOptions}>
      <div>
        <Title className={styles.title}>{titleToRender}</Title>
        {availableOptions.length === 0 && (
          <Notice
            className={styles.unsupportedNotice}
            color="offset"
            icon="warning"
            title={<Message {...messages.unsupportedBrowserWarning} />}
          />
        )}
        <ThemableToggleBox
          className={styles.toggleBox}
          direction="vertical"
          connected={false}
          name="VerifyOptions"
        >
          {availableOptions.map(({icon, message, type}) => (
            <ToggleBoxItem
              // @ts-expect-error - TS2322 - Type '{ name: string; id: string; key: any; onChange: () => void; checked: boolean; label: Element; }' is not assignable to type 'IntrinsicAttributes & Props'.
              name="verifyOption"
              id={`verifyOption-${type}`}
              key={type}
              onChange={() => setVerifyOption(type)}
              checked={verifyOption === type}
              label={<Message {...message} />}
            />
          ))}
          {availableOptions.length === 0 && (
            <ToggleBoxItem
              // @ts-expect-error - TS2322 - Type '{ name: string; onChange: () => void; checked: boolean; label: Element; }' is not assignable to type 'IntrinsicAttributes & Props'.
              name="verifyOption"
              onChange={() => setVerifyOption('mobile')}
              checked={verifyOption === 'mobile'}
              label={<Message {...messages.browserOrDeviceUnsupportedLabel} />}
            />
          )}
        </ThemableToggleBox>
      </div>
      <div className={styles.ctaButtonContainer}>
        <ThemableButton
          label={<Message {...messages.nextButton} />}
          data-testid="next-button"
          // @ts-expect-error - TS2322 - Type '{ label: Element; "data-testid": string; id: string; color: "blue"; size: "large"; onClick: () => Promise<void>; width: "maximized"; }' is not assignable to type 'IntrinsicAttributes & { className?: string | undefined; color?: ButtonColor | undefined; disabled?: boolean | undefined; disabledWhenPending?: boolean | undefined; ... 14 more ...; to?: undefined; } & LinkBehaviorProps'.
          id="next"
          color="blue"
          size="large"
          onClick={() => handleSelection(verifyOption)}
          width="maximized"
        />
      </div>
    </div>
  );
};

export function VerifyOptionsPage({intl, ...props}: PageProps & IntlProps) {
  const flags = useFeatureFlags();
  const session = useSession();
  const router = useRouter();

  const requiredFields = (session && session.requiredFields) || [];
  const needsPhoto =
    requiredFields.includes('face') ||
    requiredFields.includes('id_document_images');

  React.useEffect(() => {
    if (session && flags && !needsPhoto) {
      let cancelled = false;

      (async () => {
        // We don't need any camera, redirect to the next route.
        const nextRoute = await nextDataPageForSession(session);
        !cancelled && router.replace(nextRoute);
      })();

      return () => {
        cancelled = true;
      };
    }
  }, [flags, needsPhoto, router, session]);

  if (!needsPhoto || !session || !flags) {
    return <LoadingBox />;
  }

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.documentTitle)}</title>
      </Helmet>
      <VerifyOptionsPageInner {...props} />
    </>
  );
}

export default injectIntl(VerifyOptionsPage);
