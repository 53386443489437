import * as Sentry from '@sentry/browser';
import {produce} from 'immer';
import jsQR from 'jsqr';

import {logInDev} from 'gelato/frontend/src/lib/assert';
import {getPixelSourceDimensions} from 'gelato/frontend/src/lib/canvas';
import flags from 'gelato/frontend/src/lib/flags';
import timedFunction from 'gelato/frontend/src/lib/timedFunction';
import BaseInspector from 'gelato/frontend/src/ML/detectors/BaseInspector';

import type {InspectionState} from 'gelato/frontend/src/controllers/states/DocumentState';
import type {ApplicationState} from 'gelato/frontend/src/controllers/types';

let instance: TestmodeJsQrCodeInspector | null = null;

export default class TestmodeJsQrCodeInspector extends BaseInspector<
  [Readonly<ApplicationState>, Readonly<InspectionState>],
  Readonly<InspectionState>
> {
  static displayName = 'TestmodeJsQrCodeInspector';

  static instance: TestmodeJsQrCodeInspector | null = null;

  constructor() {
    super(TestmodeJsQrCodeInspector.displayName);
  }

  /**
   * Whether the inspector is supported in the current environment.
   * This check should be performed before calling build() to avoid loading
   * unnecessary resources.
   */
  static isSupported(): boolean {
    return true;
  }

  /**
   * Get the singleton instance of the inspector.
   */
  static getInstance(): TestmodeJsQrCodeInspector {
    if (!instance) {
      instance = new TestmodeJsQrCodeInspector();
    }
    return instance;
  }

  /**
   * @implements {BaseInspector}
   */
  protected async buildImpl(): Promise<void> {}

  /**
   * @implements {BaseInspector}
   */
  protected async warmUpImpl(): Promise<void> {}

  /**
   * @implements {BaseInspector}
   */
  protected async detectImpl(
    appState: Readonly<ApplicationState>,
    inspectionState: Readonly<InspectionState>,
  ): Promise<Readonly<InspectionState>> {
    if (
      !appState.session ||
      appState.session?.livemode ||
      (appState.session?.branding && !appState.session.branding.isStripe) ||
      !flags.isActive('idprod_enable_testmode_qrcodes')
    ) {
      return {
        ...inspectionState,
        testmodeJsQRDetectionResult: null,
      };
    }

    const imageFrame = inspectionState.inputImage;

    // imageData is the uint8 array
    const {sourceWidth, sourceHeight} = getPixelSourceDimensions(imageFrame);
    const imageData = (await imageFrame.toImageData())?.data;

    let qrDetectTime = 0;

    logInDev('Detecting...');
    if (imageData) {
      logInDev('Got image data...');
      const startTime = Date.now();
      const timedJSQR = timedFunction('jsqr', jsQR);

      qrDetectTime = Date.now() - startTime;

      const code = timedJSQR(imageData, sourceWidth, sourceHeight);

      const qrcodeData = code?.data;
      try {
        logInDev('Got code...', qrcodeData);
        const json = qrcodeData && JSON.parse(qrcodeData);
        if (code && json?.type === 'stripe_connect_test') {
          const codeLocation = code.location;
          const minX = Math.max(
            0,
            Math.min(
              codeLocation.bottomLeftCorner.x,
              codeLocation.topLeftCorner.x,
              codeLocation.topRightCorner.x,
              codeLocation.bottomRightCorner.x,
            ),
          );
          const minY = Math.max(
            0,
            Math.min(
              codeLocation.bottomLeftCorner.y,
              codeLocation.topLeftCorner.y,
              codeLocation.topRightCorner.y,
              codeLocation.bottomRightCorner.y,
            ),
          );
          const maxX = Math.min(
            sourceWidth,
            Math.max(
              codeLocation.bottomLeftCorner.x,
              codeLocation.topLeftCorner.x,
              codeLocation.topRightCorner.x,
              codeLocation.bottomRightCorner.x,
            ),
          );
          const maxY = Math.min(
            sourceHeight,
            Math.max(
              codeLocation.bottomLeftCorner.y,
              codeLocation.topLeftCorner.y,
              codeLocation.topRightCorner.y,
              codeLocation.bottomRightCorner.y,
            ),
          );
          const dimensions = [maxX - minX, maxY - minY];
          // Pad by an additional 20%, so that we adequately capture the quiet
          // zones
          const dimensionsPadded: [number, number] = [
            dimensions[0] * 1.2,
            dimensions[1] * 1.2,
          ];
          const topLeft: [number, number] = [
            minX - dimensionsPadded[0] * 0.1,
            minY - dimensionsPadded[1] * 0.1,
          ];

          // validate the values
          topLeft[0] = Math.max(0, topLeft[0]);
          topLeft[1] = Math.max(0, topLeft[1]);
          dimensionsPadded[0] = Math.min(
            sourceWidth - topLeft[0],
            dimensionsPadded[0],
          );
          dimensionsPadded[1] = Math.min(
            sourceHeight - topLeft[1],
            dimensionsPadded[1],
          );

          const croppedImage = await imageFrame.crop(
            topLeft[0],
            topLeft[1],
            dimensionsPadded[0],
            dimensionsPadded[1],
          );

          return produce(inspectionState, (draft) => {
            draft.testmodeJsQRDetectionResult = {
              documentType: 'passport',
              feedback: null,
              inspectedImage: imageFrame,
              location: {
                topLeft,
                dimensions: dimensionsPadded,
              },
              detectedImage: croppedImage,
              isValid: true,
            };
          });
        }
      } catch (e) {}
    }

    return produce(inspectionState, (draft) => {
      draft.testmodeJsQRDetectionResult = {
        documentType: null,
        feedback: null,
        inspectedImage: imageFrame,
        location: null,
        detectedImage: null,
        isValid: false,
      };
    });
  }

  /**
   * @implements {BaseInspector}
   */
  protected async disposeImpl(): Promise<void> {}
}
