import {getPrefetchCacheKey} from 'src/internal/prefetching/getPrefetchCacheKey';
import {globalPrefetchingCounter} from 'src/internal/prefetching/usePrefetchCounter';
import {getRequestSourceInformation} from 'src/internal/requestSource';

import type {OperationVariables} from '@apollo/client';
import type {ProjectName, ServiceName} from '@sail/observability';

/**
 * Reduces the prefetch counter for a given query by 1.
 * Its purpose is to maintain the current prefetch counter logic while also
 * providing a possibility for imperative fetches (useRefetchQueries, useQuery.refetch)
 * to reduce the prefetch counter if it exists.
 */
export const decrementPrefetchCounter = ({
  service,
  project,
  component,
  operationName,
  variables,
}: {
  service?: ServiceName;
  project?: ProjectName;
  component?: string;
  operationName: string;
  variables: OperationVariables | undefined;
}) => {
  const cacheKey = getPrefetchCacheKey({
    operationName,
    variables: variables || {},
  });
  const requestInformation = getRequestSourceInformation({
    service,
    project,
    component,
    operation: operationName,
  });

  const firstTimeMounted =
    typeof globalPrefetchingCounter[cacheKey] === 'undefined';

  /**
   * This is a very improbable edge case, but if it happens it could
   * break things unnecessarily
   */
  if (firstTimeMounted) {
    globalPrefetchingCounter[cacheKey] = {
      count: 0,
      queue: [],
      mountedInRenderMode: true,
      service: requestInformation.service,
      project: requestInformation.project,
      component: requestInformation.component,
      reported: false,
    };
  }

  const prefetchStateForKey = globalPrefetchingCounter[cacheKey];

  // Reduce counter by one and remove one callback from the queue
  prefetchStateForKey.count -= 1;
  prefetchStateForKey.queue.shift();
  prefetchStateForKey.mountedInRenderMode = true;
};
