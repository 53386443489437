import {dispute} from '@sail/icons/react/Icon';
import {ButtonGroup} from '@sail/ui';
import * as React from 'react';

import Button from 'gelato/frontend/src/components/ButtonV2';
import messages from 'gelato/frontend/src/components/Invalid/messages';
import Message from 'gelato/frontend/src/components/Message';
import {nextDataPageForSession} from 'gelato/frontend/src/lib/dataRouting';
import {
  useRouter,
  useReturnUrl,
  useBranding,
  useSession,
} from 'gelato/frontend/src/lib/hooks';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import {Text} from 'sail/Text';

const Footer = () => {
  const router = useRouter();
  const session = useSession();
  const proceedCallback = React.useCallback(async () => {
    if (session) {
      const nextPage = await nextDataPageForSession(session);
      router.push(nextPage);
    }
  }, [router, session]);
  const branding = useBranding();
  const returnUrl = useReturnUrl();
  const {platformName} = branding || {};
  const title = (
    <Text color="dark">
      <Message {...messages.unsupportedCountryHeading} />
    </Text>
  );
  const description = (
    <Text color="default">
      <Message {...messages.unsupportedCountryBody} />
    </Text>
  );

  let bodyLinkMessage = null;
  React.useEffect(() => {
    postIframeEvent('STRIPE_IDENTITY_ERROR', {
      type: 'validation_error',
      code: 'sanctioned_country',
    });
  });

  if (session?.requiredFields.includes('address')) {
    bodyLinkMessage = (
      <Message {...messages.unsupportedCountryAddressBodyLink} />
    );
  } else if (session?.requiredFields.includes('id_number')) {
    bodyLinkMessage = (
      <Message {...messages.unsupportedCountryIdNumberBodyLink} />
    );
  } else {
    bodyLinkMessage = (
      <Message {...messages.unsupportedCountryDefaultBodyLink} />
    );
  }

  return (
    <ButtonGroup direction="column" css={{gap: 'space.150'}}>
      <Button
        id="unsupported-country-retry-link"
        onClick={proceedCallback}
        style={{cursor: 'pointer'}}
      >
        {bodyLinkMessage}
      </Button>

      {returnUrl && (
        <Button
          id="unsupported-country-return-url-link"
          href={returnUrl}
          type="secondary"
        >
          <Message {...messages.failureLink} values={{platformName}} />
        </Button>
      )}
    </ButtonGroup>
  );
};

export const generateUnsupportedCountryContent = () => {
  return {
    icon: dispute,
    footer: <Footer />,
    title: (
      <Text color="dark">
        <Message {...messages.unsupportedCountryHeading} />
      </Text>
    ),
    description: (
      <Text color="default">
        <Message {...messages.unsupportedCountryBody} />
      </Text>
    ),
  };
};
