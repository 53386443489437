// These are the same as in /plugins, so we avoid copying them.
import {pluginsAnimation} from '../plugins/pluginsAnimation';
import {pluginsBoxShadow} from '../plugins/pluginsBoxShadow';
import {pluginsTransform} from '../plugins/pluginsTransform';

// These are customized.
import {pluginsBoxModel} from './pluginsBoxModel';
import {pluginsFont} from './pluginsFont';
import {pluginsLayout} from './pluginsLayout';

export const notUniversalPlugins = {
  ...pluginsAnimation,
  ...pluginsBoxModel,
  ...pluginsBoxShadow,
  ...pluginsFont,
  ...pluginsLayout,
  ...pluginsTransform,
};
