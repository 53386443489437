import {getEnvironment} from '@sail/utils';
import {useEffect, useMemo} from 'react';
import {useDataConfig} from 'src/internal/config/DataConfigContext';
import {defaultExpireAfterMs} from 'src/internal/prefetching/PrefetchCache';
import {
  KEY_VARIABLES_SEPARATOR,
  getPrefetchCacheKey,
} from 'src/internal/prefetching/getPrefetchCacheKey';
import {getRequestSourceInformation} from 'src/internal/requestSource';
import {getOperationName} from 'src/internal/utils/gql';

import type {OperationVariables, WatchQueryFetchPolicy} from '@apollo/client';
import type {
  IAnalytics,
  IMetrics,
  ProjectName,
  ServiceName,
} from '@sail/observability';
import type {GraphQlDocument} from 'src/internal/apollo/types';
import type {InternalOperationContext} from 'src/internal/apollo/useGetApolloContext';
import type {DataMetricPrefetchTimeout} from 'src/internal/telemetry/types';

type OperationInfo = {
  count: number;
  queue: Array<() => void>;
  mountedInRenderMode: boolean;
  service: ServiceName;
  project: ProjectName | '<unknown>';
  component: string;
  reported: boolean;
};
type Counter = {
  [key: string]: OperationInfo;
};
export const globalPrefetchingCounter: Counter = {};
let timeoutIds: ReturnType<typeof setTimeout>[] = [];
let onAbortCallbacks: Array<() => void> = [];

function processCacheTimeout(
  service: ServiceName,
  project: ProjectName | '<unknown>',
  component: string,
  cacheKey: string,
  metrics: IMetrics | undefined,
  analytics: IAnalytics | undefined,
) {
  const [operationName, variablesAsString] = cacheKey.split(
    KEY_VARIABLES_SEPARATOR,
  );
  const tags: DataMetricPrefetchTimeout = {
    service,
    project,
    component,
    operation_name: operationName,
  };
  metrics && metrics.increment('frontend.data.overprefetch', tags);

  analytics && analytics.track('frontend.data.overprefetch', tags);

  const variablesForToolkit = JSON.parse(variablesAsString);

  sendEventDataToToolkit(
    operationName,
    'overprefetch',
    variablesForToolkit,
    service,
    project,
    component,
  );
}

export function reportUnderPrefetchedQueries(
  metrics: IMetrics | undefined,
  analytics: IAnalytics | undefined,
) {
  for (const [key, value] of Object.entries(globalPrefetchingCounter)) {
    if (value.count < 0 && !value.reported) {
      value.reported = true;
      const [operationName, variablesAsString] = key.split(
        KEY_VARIABLES_SEPARATOR,
      );

      const {service, project, component} = value;
      const tags: DataMetricPrefetchTimeout = {
        service,
        project,
        component,
        operation_name: operationName,
      };

      metrics && metrics.increment('frontend.data.underprefetch', tags);

      analytics && analytics.track('frontend.data.underprefetch', tags);

      const variablesForToolkit = JSON.parse(variablesAsString);

      sendEventDataToToolkit(
        operationName,
        'underprefetch',
        variablesForToolkit,
        service,
        project,
        component,
      );
    }
  }
}

export function resetPrefetchMismatchMetrics() {
  for (const value of Object.values(globalPrefetchingCounter)) {
    value.count = 0;
    value.queue = [];
  }

  timeoutIds.forEach(clearTimeout);
  timeoutIds = [];
  onAbortCallbacks.forEach((cb) => cb());
  onAbortCallbacks = [];
}

export function getWillGoThroughApolloLinks({
  firstTimeMounted,
  fetchPolicy,
}: {
  firstTimeMounted: boolean;
  fetchPolicy: WatchQueryFetchPolicy;
}) {
  switch (fetchPolicy) {
    case 'cache-and-network':
    case 'network-only':
      return true;
    case 'cache-only':
      return false;
    case 'cache-first':
      return firstTimeMounted;
  }
}

function incrementPrefetchCounter({
  firstTimeMounted,
  cacheKey,
  fetchPolicy,
  timeout,
  pruningSignal,
  metrics,
  analytics,
}: {
  firstTimeMounted: boolean;
  cacheKey: string;
  fetchPolicy: WatchQueryFetchPolicy;
  timeout: number;
  pruningSignal: AbortSignal | undefined;
  metrics: IMetrics | undefined;
  analytics: IAnalytics | undefined;
}): void {
  const willGoThroughApolloLinks = getWillGoThroughApolloLinks({
    firstTimeMounted,
    fetchPolicy,
  });

  if (!willGoThroughApolloLinks) {
    return;
  }

  globalPrefetchingCounter[cacheKey].count += 1;

  const callback = () => {
    // This is to prevent metric from being emitted when user
    // performed navigation after prefetching but before rendering.
    // In such cases it is normal for prefetched queries to not be consumed.

    if (globalPrefetchingCounter[cacheKey]?.count > 0) {
      // Look for a reference to this callback in the queue and remove it, in case of manual pruning.
      const itemIndex = globalPrefetchingCounter[cacheKey].queue.findIndex(
        (el) => el === callback,
      );
      if (itemIndex > -1) {
        // If an active pruning signal was called, we do decrement the counter because
        // although pruning an already prefetched request and not using it is "overprefetching",
        // the underlying network request can still be used by other calls to the cache,
        // hence in such a case we don't automatically reset to zero.
        globalPrefetchingCounter[cacheKey].count -= 1;
        globalPrefetchingCounter[cacheKey].queue.splice(itemIndex, 1);
      } else if (globalPrefetchingCounter[cacheKey].queue.length === 0) {
        // Avoid the metric from being emitted more than once per given "moment".
        // This is because even though from perspective of this hook the query was prefetched multiple times
        // the deduplication link prevented the query from emitting multiple network requests.
        globalPrefetchingCounter[cacheKey].count = 0;
      }

      if (globalPrefetchingCounter[cacheKey].count === 0) {
        processCacheTimeout(
          globalPrefetchingCounter[cacheKey].service,
          globalPrefetchingCounter[cacheKey].project,
          globalPrefetchingCounter[cacheKey].component,
          cacheKey,
          metrics,
          analytics,
        );
      }
    }
  };

  globalPrefetchingCounter[cacheKey].queue.push(callback);
  if (pruningSignal) {
    pruningSignal.addEventListener('abort', callback);
    onAbortCallbacks.push(() => {
      pruningSignal.removeEventListener('abort', callback);
    });
  }

  const timeoutId = setTimeout(() => {
    // Empty the queue before invoking the callback since on
    // timeout we unconditionally reset the counter and process
    // potential overprefetching
    globalPrefetchingCounter[cacheKey].queue = [];
    callback();
  }, timeout);

  timeoutIds.push(timeoutId);
}

function decrementPrefetchCounter({
  cacheKey,
  fetchPolicy,
}: {
  cacheKey: string;
  fetchPolicy: WatchQueryFetchPolicy;
}): void {
  const operationInfo = globalPrefetchingCounter[cacheKey];

  switch (fetchPolicy) {
    case 'cache-and-network':
    case 'network-only':
      // These policies always go through apollo links, therefore we should decrement the counter.
      operationInfo.count -= 1;
      operationInfo.queue.shift();
      break;
    case 'cache-first':
      // For cache-first queries we want to decrement the counter only on first mount of this query.
      // This is because on subsequent mounts of such queries data is retrieved
      // from Apollo Cache and therefore not cause underprefetching.
      if (!operationInfo.mountedInRenderMode) {
        operationInfo.count -= 1;
        operationInfo.queue.shift();
      }
      break;
    case 'cache-only':
      // This policy cannot result in network reqeust therefore
      // we neither increment nor decrement the counter
      break;
  }

  operationInfo.mountedInRenderMode = true;
}

/*
  This function communicates with the sail-toolkit chrome extension, used
  to visualize under / over prefetching in real time in the browser.
*/
function sendEventDataToToolkit(
  operationName: string,
  typeOfMetric: 'underprefetch' | 'overprefetch',
  variables: OperationVariables,
  service: ServiceName,
  project: ProjectName | '<unknown>',
  component: string,
) {
  if (typeof window === 'undefined') {
    return;
  }

  const pathname = window.location.pathname;

  const data = {
    typeOfMetric,
    operationName,
    variables,
    service,
    project,
    component,
    pathname,
  };

  window.postMessage(
    {source: 'webpage', type: 'sail_data_prefetch_counter', data},
    '*',
  );
}

export default function usePrefetchCounter({
  operationContext,
  query,
  variables,
  skip,
}: {
  operationContext: InternalOperationContext<any>;
  query: GraphQlDocument<any, any, any>;
  variables: OperationVariables;
  skip: boolean;
}) {
  const {isPrefetchMode, observabilityFns, prefetchCachePruningSignal} =
    operationContext;
  const {metrics, analytics} = observabilityFns || {};
  const dataConfig = useDataConfig();
  const errored = false;

  const timeout: number =
    dataConfig.graphql?.prefetch?.expireAfterMs || defaultExpireAfterMs;

  const operationName = useMemo(() => {
    return getOperationName(query) || '';
  }, [query]);

  const cacheKey = useMemo(
    () => getPrefetchCacheKey({operationName, variables}),
    [operationName, variables],
  );

  const areMismatchMetricsEnabled: boolean =
    dataConfig.graphql?.prefetch?.enableMismatchMetrics || false;

  try {
    if (typeof window !== 'undefined') {
      (window as any).globalPrefetchingCounter = globalPrefetchingCounter;
    }
  } catch (error) {
    if (getEnvironment() !== 'production') {
      throw error;
    }

    operationContext.observabilityFns?.errors.error(
      'Prefetch counter could not be initialized',
      {
        project: 'sail_core',
        extras: {
          query,
          operationName,
          enabled: areMismatchMetricsEnabled,
        },
      },
    );
  }

  // fetchPolicy is always defined but the typing of operationContext is forcing us to do fallback.
  const fetchPolicy = operationContext.fetchPolicy || 'cache-first';

  useEffect(() => {
    if (!cacheKey || skip || !areMismatchMetricsEnabled || errored) {
      return;
    }

    const firstTimeMounted = globalPrefetchingCounter[cacheKey] === undefined;

    if (firstTimeMounted) {
      const requestInformation = getRequestSourceInformation({
        service: operationContext.service,
        project: operationContext.queryProject,
        component: operationContext.component,
        operation: operationName,
      });

      globalPrefetchingCounter[cacheKey] = {
        count: 0,
        queue: [],
        mountedInRenderMode: false,
        service: requestInformation.service,
        project: requestInformation.project,
        component: requestInformation.component,
        reported: false,
      };
    }

    if (isPrefetchMode) {
      incrementPrefetchCounter({
        cacheKey,
        fetchPolicy,
        firstTimeMounted,
        timeout,
        pruningSignal: prefetchCachePruningSignal,
        metrics,
        analytics,
      });
    } else {
      decrementPrefetchCounter({cacheKey, fetchPolicy});
    }
  }, [
    isPrefetchMode,
    cacheKey,
    errored,
    areMismatchMetricsEnabled,
    operationContext.service,
    operationContext.queryProject,
    operationContext.component,
    fetchPolicy,
    operationName,
    prefetchCachePruningSignal,
    metrics,
    analytics,
    timeout,
    skip,
  ]);
}
