import {stripe} from '@sail/icons/react/BrandIcon';
import {
  arrowRightCircle,
  camera,
  convert,
  createIdentityVerification,
  customerPortal,
  disputeProtection,
  document,
  lock,
  moved,
  person,
  phone,
  risk,
  wallet,
} from '@sail/icons/react/Icon';
import {IconAsset} from '@sail/icons/types';
import {Icon, css, view} from '@sail/ui';
import * as React from 'react';

import {
  alignItemsFlexStart,
  tColorSubdued,
} from 'gelato/frontend/src/components/stylesV2';

// Known icon types.
type IconType = keyof typeof IconMap;

type Props = {
  icon?: IconType | 'bullet' | IconAsset;
  iconWrapperStyle?: Array<ReturnType<typeof css>> | null | undefined;
  children?: React.ReactNode;
  useDefaultIconColor?: boolean;
};

// Known icons that can be used in the list item.
const IconMap = {
  arrowRightCircle,
  camera,
  convert,
  createIdentityVerification,
  customerPortal,
  disputeProtection,
  document,
  lock,
  moved,
  person,
  phone,
  risk,
  stripe,
  wallet,
};

const DEFAULT_ICON: IconType = 'arrowRightCircle';

const Styles = {
  bulletedContent: css({
    paddingTop: 'xxsmall',
  }),
  content: css({
    width: 'fill',
  }),
  iconSubdued: css({
    fill: 'subdued',
  }),
  iconDefault: css({
    fill: 'icon',
  }),
  iconWrapper: css({
    alignY: 'center',
    paddingRight: 'space.150',
    position: 'relative',
    stack: 'y',
    top: '0.3em',
  }),
  li: css({
    alignY: 'top',
    display: 'block',
    distribute: 'space-between',
    gridColumns: 2,
    listStyle: 'none',
    margin: 'space.0',
    padding: 'space.0',
    stack: 'x',
    width: 'fill',
  }),
  bullet: css({
    paddingLeft: 'small',
    paddingRight: 'small',
    textAlign: 'center',
    fontSize: 'body.medium',
  }),
};

function getIcon(
  icon: IconType | IconAsset,
  useDefaultIconColor = false,
): JSX.Element {
  const iconAsset = typeof icon === 'string' ? IconMap[icon] : icon;

  return (
    <Icon
      icon={iconAsset}
      uses={useDefaultIconColor ? [Styles.iconDefault] : [Styles.iconSubdued]}
    />
  );
}

export default function ListItem({
  icon = DEFAULT_ICON,
  iconWrapperStyle,
  children,
  useDefaultIconColor = false,
}: Props): JSX.Element {
  const iconUses = [];
  let iconObj;
  if (icon === 'bullet') {
    iconObj = '\u2022';
    iconUses.push(Styles.bullet, tColorSubdued);
  } else {
    iconObj = getIcon(icon, useDefaultIconColor);
    iconUses.push(Styles.iconWrapper);
  }

  const contentUses = [Styles.content];
  if (icon === 'bullet') {
    contentUses.push(Styles.bulletedContent);
  }

  // To ensure we overwrite the styles, we have to call this last
  if (iconWrapperStyle) {
    iconUses.push(...iconWrapperStyle);
  }

  return (
    <view.li uses={[Styles.li, alignItemsFlexStart]}>
      <view.div uses={iconUses}>{iconObj}</view.div>
      <view.div uses={contentUses}>{children}</view.div>
    </view.li>
  );
}
