import {css} from '@sail/ui';
import * as React from 'react';
import {defineMessages} from 'react-intl';

import BottomSheet, {
  Messages as BottomSheetMessages,
} from 'gelato/frontend/src/components/BottomSheetV2';
import Button from 'gelato/frontend/src/components/ButtonV2';
import Heading from 'gelato/frontend/src/components/HeadingV2';
import ListItem from 'gelato/frontend/src/components/ListItemV2';
import List from 'gelato/frontend/src/components/ListV2';
import Message from 'gelato/frontend/src/components/Message';
import {mB200} from 'gelato/frontend/src/components/stylesV2';
import flags from 'gelato/frontend/src/lib/flags';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

// Sort alphabetically by id.
const Messages = defineMessages({
  acceptedIdentificationForms: {
    defaultMessage: 'Accepted forms of identification',
    description: 'Reference to the types of identification accepted',
    id: 'verification.photoIDTypesSheet.acceptedIdentificationForms',
  },
  acceptedIdentificationFormsV2: {
    defaultMessage: 'Accepted forms of ID',
    description: 'Reference to the types of identification accepted',
    id: 'verification.photoIDTypesSheet.acceptedIdentificationFormsV2',
  },
  driversLicense: {
    defaultMessage: `Driver’s License`,
    description: `Reference to a driver’s license as a form of identification`,
    id: 'verification.photoIDTypesSheet.driversLicense',
  },
  governmentIssuedID: {
    defaultMessage:
      'Valid government-issued identification that clearly shows your face',
    description:
      'Requirement for a government-issued identification with a clear depiction of the user’s face',
    id: 'verification.photoIDTypesSheet.governmentIssuedID',
  },
  governmentIssuedIDShopify: {
    defaultMessage: 'government-issued photo ID',
    description:
      'Requirement for a government-issued identification with a clear depiction of the user’s face for Shopify',
    id: 'verification.photoIDTypesSheet.shopify.governmentIssuedID',
  },
  nationalID: {
    defaultMessage: 'National ID',
    description: 'Reference to a national ID as a form of identification',
    id: 'verification.photoIDTypesSheet.nationalID',
  },
  nationalIDSuggestion: {
    defaultMessage: 'Verify identity with a government-issued photo ID.',
    description: 'Suggestion to use a national ID',
    id: 'verification.photoIDTypesSheet.nationalIDSuggestion',
  },
  nonExpIDSuggestion: {
    defaultMessage: 'Accept photo IDs without an expiration date if feasible.',
    description: 'Suggestion to accept non-expiring IDs',
    id: 'verification.photoIDTypesSheet.nonExpIDSuggestion',
  },
  passport: {
    defaultMessage: 'Passport',
    description: 'Reference to a passport as a form of identification',
    id: 'verification.photoIDTypesSheet.passport',
  },
  photoID: {
    defaultMessage: 'photo ID',
    description: 'Text for the control that opens the action sheet',
    id: 'verification.photoIDTypesSheet.photoID',
  },
  photoIDGuidelineTitle: {
    defaultMessage: 'Photo ID Guidelines',
    description: 'Title for the photo ID guidelines  section',
    id: 'verification.photoIDTypesSheet.photoIDGuidelineTitle',
  },
  photoIDSuggestion: {
    defaultMessage:
      'Require photo IDs to include a recent, identifiable photo.',
    description: 'Suggestion to require a photo on the ID',
    id: 'verification.photoIDTypesSheet.photoIDSuggestion',
  },
  typesOfPhotoID: {
    defaultMessage: 'Types of photo ID',
    description: 'Reference to different types of photo identification',
    id: 'verification.photoIDTypesSheet.typesOfPhotoID',
  },
});

const DEFAULT_ALLOWED_TYPES = ['driving_license', 'id_card', 'passport'];

const Styles = {
  subList: css({
    margin: '0 0 0 -6px',
    font: 'body.medium',
  }),
  h2: css({
    font: 'heading.large',
  }),
};

/**
 * The text for the control that opens the <PhotoIDTypesSheet /> layer.
 */
export function PhotoIDTypesSheetOpenerText(): JSX.Element {
  const {appState} = useAppController();
  const allowedTypes =
    appState.session?.documentTypeAllowlist || DEFAULT_ALLOWED_TYPES;

  if (flags.isActive('idprod_shopify_welcome_screen_fork')) {
    return <Message {...Messages.governmentIssuedIDShopify} />;
  }

  if (allowedTypes.length === 1) {
    // If there is only one allowed type, we can be more specific.
    switch (allowedTypes[0]) {
      case 'driving_license':
        return <Message {...Messages.driversLicense} />;
      case 'id_card':
        return <Message {...Messages.nationalID} />;
      case 'passport':
        return <Message {...Messages.passport} />;
    }
  }

  return <Message {...Messages.photoID} />;
}

// TODOs:
// - Add analytics.

/**
 * The flyout sheet that displays information about the types of photo ID
 * accepted.
 */
export default function PhotoIDTypesSheet(): JSX.Element {
  const {appController, appState} = useAppController();
  const allowedTypes =
    appState.session?.documentTypeAllowlist || DEFAULT_ALLOWED_TYPES;

  let content;
  if (allowedTypes.length <= 1) {
    // If there is only one allowed type, we can be more specific.
    content = (
      <>
        <Heading level={2} uses={[Styles.h2]}>
          <Message {...Messages.photoIDGuidelineTitle} />
        </Heading>
        <List gap="xsmall" uses={[mB200]}>
          <ListItem icon="bullet">
            <Message {...Messages.nationalIDSuggestion} />
          </ListItem>
          <ListItem icon="bullet">
            <Message {...Messages.nonExpIDSuggestion} />
          </ListItem>
          <ListItem icon="bullet">
            <Message {...Messages.photoIDSuggestion} />
          </ListItem>
        </List>
      </>
    );
  } else {
    const items = [];
    if (allowedTypes.includes('passport')) {
      items.push(
        <ListItem icon="bullet" key="passport">
          <Message {...Messages.passport} />
        </ListItem>,
      );
    }

    if (allowedTypes.includes('driving_license')) {
      items.push(
        <ListItem icon="bullet" key="dl">
          <Message {...Messages.driversLicense} />
        </ListItem>,
      );
    }

    if (allowedTypes.includes('id_card')) {
      items.push(
        <ListItem icon="bullet" key="nid">
          <Message {...Messages.nationalID} />
        </ListItem>,
        <ListItem icon="bullet" key="gid">
          <Message {...Messages.governmentIssuedID} />
        </ListItem>,
      );
    }

    let headingMsg;
    if (appState.locale.currentValue[0] === 'en-US') {
      headingMsg = Messages.acceptedIdentificationFormsV2;
    } else {
      headingMsg = Messages.acceptedIdentificationForms;
    }

    content = (
      <>
        <Heading level={2}>
          <Message {...headingMsg} />
        </Heading>
        <List gap="none" uses={[Styles.subList]}>
          {items}
        </List>
      </>
    );
  }

  const onClose = () => appController.closeLayer(PhotoIDTypesSheet);
  const footer = (
    <Button onPress={onClose} type="primary">
      <Message {...BottomSheetMessages.close} />
    </Button>
  );
  return (
    <BottomSheet data-testid="photo-id-types-sheet" footer={footer}>
      {content}
    </BottomSheet>
  );
}
