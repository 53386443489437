import {business} from '@sail/icons/react/Icon';
import {IconAsset} from '@sail/icons/types';
import {view, css, Icon} from '@sail/ui';
import * as React from 'react';

type Props = {
  animation?: 'rtl' | 'ltr' | undefined;
  color: string | null | undefined;
  size?: 'medium' | 'xlarge' | undefined;
  customSize?: {height: string; width: string};
  src: string | IconAsset | null | undefined;
  padIcon?: boolean;
  additionalStyles?: Array<ReturnType<typeof css>> | null | undefined;
  hideIfFailed?: boolean;
};

const {useLayoutEffect, useEffect, useRef, useState} = React;

const TRANSITION = '500ms cubic-bezier(0.76, 0, 0.24, 1)';

const Styles = {
  animation: css({
    transition: `opacity ${TRANSITION}, transform ${TRANSITION}`,
  }),
  animationRtl: css({
    opacity: 0.2,
    transform: 'translate3d(10px, 0, 0)',
  }),
  animationLtr: css({
    opacity: 0.2,
    transform: 'translate3d(-10px, 0, 0)',
  }),
  logo: css({
    backgroundColor: 'offset',
    borderRadius: '100%',
    boxSizing: 'content-box',
    left: 'xxsmall',
    marginX: '-6px',
    overflow: 'hidden',
    position: 'relative',
    borderWidth: 'medium',
    borderStyle: 'solid',
    borderColor: 'transparent',
    zIndex: 1,
  }),
  logoImage: css({
    height: 'fill',
    width: 'fill',
  }),
};

// The pixel size of the logo should match the size of the icon as
// described in https://sail.stripe.me/components/icon/
const IconSize = {
  xlarge: '64px',
  medium: '20px',
};

/**
 * This hook animates the logo when it is first rendered.
 * @param animation The animation type.
 * @param src The logo source.
 * @returns The logo ref.
 */
function useLogoAnimation(
  animation: Props['animation'],
): React.RefObject<HTMLDivElement> {
  const logoRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const style = logoRef.current?.style;
    if (animation && style) {
      const tids = [
        // Fade in the logo.
        setTimeout(() => (style.opacity = '1'), 100),
        // Slide the logo in.
        setTimeout(() => (style.transform = 'translate3d(0, 0, 0)'), 1000),
      ];
      return () => tids.forEach(clearTimeout);
    }
  }, [animation, logoRef]);
  return logoRef;
}

/**
 * The logo for merchant.
 */
export default function Logo({
  animation,
  color,
  additionalStyles,
  src,
  size,
  customSize,
  padIcon,
  hideIfFailed,
}: Props): JSX.Element {
  const bgRef = useRef<HTMLDivElement>(null);
  const logoRef = useLogoAnimation(animation);
  const logoSize = size && IconSize[size];
  const logoCss = logoSize ? {height: logoSize, width: logoSize} : customSize;
  const logoStyles = [Styles.logo];
  const [failed, setFailed] = useState(false);

  // Side effect to check if the logo is broken.
  useEffect(() => {
    const dom = bgRef.current;
    if (dom && src && typeof src === 'string') {
      let cancelled = false;
      const img = new Image();
      img.onerror = () => !cancelled && setFailed(true);
      img.src = src;
      return () => {
        img.onerror = null;
        cancelled = true;
      };
    }
  }, [src, bgRef]);

  if (animation === 'ltr') {
    logoStyles.push(Styles.animation, Styles.animationLtr);
  } else if (animation === 'rtl') {
    logoStyles.push(Styles.animation, Styles.animationRtl);
  }

  if (failed && hideIfFailed) {
    return <></>;
  }

  // This needs to be last to ensure that the style passed overwrites the default Logo styling
  if (additionalStyles) {
    logoStyles.push(...additionalStyles);
  }

  // src is a URL string.
  if (src && typeof src === 'string' && !failed) {
    const bgImage = css({
      backgroundSize: 'contain',
      backgroundColor: failed ? 'offset' : color || 'transparent',
      backgroundImage: `url(${src})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    });

    return (
      <view.div css={logoCss} ref={logoRef} uses={logoStyles}>
        <view.div
          css={logoCss}
          uses={[bgImage, Styles.logoImage]}
          ref={bgRef}
        />
      </view.div>
    );
  }

  // src is a icon asset.
  if (src && typeof src === 'object') {
    return (
      <view.div css={logoCss} ref={logoRef} uses={logoStyles}>
        <Icon
          icon={src}
          css={{padding: padIcon ? 'medium' : undefined, ...logoCss}}
        />
      </view.div>
    );
  }

  // Logo isn't available. Use the default business icon.
  return (
    <view.div uses={logoStyles} css={logoCss} ref={logoRef}>
      <Icon icon={business} css={{padding: 'medium', ...logoCss}} />
    </view.div>
  );
}
