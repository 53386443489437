import {lightningBolt, lock} from '@sail/icons/react/Icon';
import {Icon, view, css} from '@sail/ui';
import * as React from 'react';
import {useIntl} from 'react-intl';

import {messages} from 'gelato/frontend/src/components/Link/messages';
import getBrandingPlatformName from 'gelato/frontend/src/lib/getBrandingPlatformName';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

export default function LinkDescription() {
  const {appController} = useAppController();
  const intl = useIntl();
  const branding = appController.runtime?.branding;
  const platformName = getBrandingPlatformName(branding);
  return (
    <view.div uses={[Styles.bodyContent]}>
      <view.div uses={[Styles.bodyContentLine]}>
        <Icon
          icon={lightningBolt}
          size="small"
          uses={[Styles.bodyContentLineIcon]}
        />
        <view.div uses={[Styles.bodyContentLineText]}>
          {intl.formatMessage(messages.loginBodyConvenient, {
            merchantName: platformName,
          })}
        </view.div>
      </view.div>
      <view.div uses={[Styles.bodyContentLine]}>
        <Icon icon={lock} size="small" uses={[Styles.bodyContentLineIcon]} />
        <view.div uses={[Styles.bodyContentLineText]}>
          {intl.formatMessage(messages.loginBodySecureBottomSheet)}
        </view.div>
      </view.div>
    </view.div>
  );
}

const Styles = {
  bodyContent: css({
    gap: 'medium',
    stack: 'y',
  }),
  bodyContentLine: css({
    alignY: 'top',
    gap: 'space.150',
    stack: 'x',
    width: 'fill',
  }),
  bodyContentLineIcon: css({
    marginY: 'xxsmall',
  }),
  bodyContentLineText: css({
    font: 'body.small',
    width: 'fill',
  }),
};
