import {
  Country,
  SelfieVerificationMethod,
} from '@stripe-internal/data-gelato/schema/types';
import {produce} from 'immer';
import {IntlShape} from 'react-intl';

import {OTPMode} from 'gelato/frontend/src/components/OTPVerification';
import {
  pauseCameraAction,
  selectCameraDeviceAction,
  startCameraAction,
  stopCameraAction,
} from 'gelato/frontend/src/controllers/actions/cameraActions';
import {
  captureDocumentImageAction,
  clearInspectionErrorAction,
  confirmWorkingDocumentImageAction,
  proceedToNextDocumentUploadStepAction,
  restartDocumentUploadStepAction,
  retakeWorkingDocumentImageAction,
  setDocumentInputMethodAction,
  startDocumentAutoCaptureAction,
  stopDocumentAutoCaptureAction,
  uploadWorkingDocumentImageAction,
} from 'gelato/frontend/src/controllers/actions/documentActions';
import {
  updateFlowsEmailAction,
  handleFlowsContinueAction,
  initializeFlowsContinueAction,
  finalizeFlowsContinueAction,
  updateClientReferenceIdAction,
  handleFlowsSessionInitializationAction,
  getFlowsStaticDataFromLocationAction,
} from 'gelato/frontend/src/controllers/actions/flowsActions';
import {
  setIdNumberDataAction,
  setIndividualCollectedDataAction,
  setDeclineOTPAction,
  setOTPCodeAction,
  validateIndividualDataAction,
} from 'gelato/frontend/src/controllers/actions/individualActions';
import {
  openLayerAction,
  closeLayerAction,
  closeAllLayersAction,
} from 'gelato/frontend/src/controllers/actions/layerActions';
import {
  changeLocaleAction,
  localeDidChangeAction,
} from 'gelato/frontend/src/controllers/actions/localeActions';
import {
  cancelVerificationAction,
  makeHandoffUrlAction,
  resetEmailHandoffAction,
  resetHandoffAction,
  resetSMSHandoffAction,
  sendHandoffEmailAction,
  sendHandoffSMSAction,
  startAction,
  updateConsentAction,
  submitVerificationAction,
  updateIndividualAction,
  submitIndividualAction,
  generateOTPAction,
  validateOTPAction,
  initializeIndividualMutationAction,
} from 'gelato/frontend/src/controllers/actions/mutationActions';
import {
  lookupConsumerAccountAction,
  updateConsumerOtpInputAction,
  resendConsumerOtpAction,
  reuseConsumerDocumentAction,
  signUpConsumerAccountAction,
  signInConsumerAccountAction,
  updateConsumerEmailAction,
  updateConsumerPhoneAction,
  skipNetworkingAction,
  addNewDocument,
  setValidOrInvalidIdentityDocument,
  clearInvalidDocument,
  setConsumerAccountLoadingAction,
  setConsumerPhoneCountryAction,
  setOtpRequiredAction,
} from 'gelato/frontend/src/controllers/actions/networkedIdentityActions';
import {
  signUpConsumerAccountAction as signUpConsumerAccountActionV2,
  signInConsumerAccountAction as signInConsumerAccountActionV2,
  startLinkLoginFlow,
  lookupConsumerAccountAction as lookupConsumerAccountActionV2,
  updateConsumerOtpInputAction as updateConsumerOtpInputActionV2,
  resendConsumerOtpAction as resendConsumerOtpActionV2,
  setBottomSheetStepAction,
} from 'gelato/frontend/src/controllers/actions/networkedIdentityActionsV2';
import {
  recordInvalidStateAction,
  handleSessionInitializationAction,
} from 'gelato/frontend/src/controllers/actions/sessionAction';
import {
  handleAutocompleteSettingChange,
  handleSubmitAutocompleteAction,
  handleSubmitManualAction,
} from 'gelato/frontend/src/controllers/actions/testModeActions';
import {createCameraState} from 'gelato/frontend/src/controllers/states/CameraState';
import {
  createConfigState,
  setSelfieVerificationMethod,
} from 'gelato/frontend/src/controllers/states/ConfigState';
import {createDocumentState} from 'gelato/frontend/src/controllers/states/DocumentState';
import {createErrorState} from 'gelato/frontend/src/controllers/states/ErrorState';
import {createFlowsState} from 'gelato/frontend/src/controllers/states/FlowsState';
import {
  createIndividualState,
  getIdNumberInputConfig,
  getInvalidIndividualData,
  IndividualStateFieldKeys,
  IndividualStateFields,
  initializeIndividualState,
} from 'gelato/frontend/src/controllers/states/IndividualState';
import {createLayerState} from 'gelato/frontend/src/controllers/states/LayerState';
import {createLocaleState} from 'gelato/frontend/src/controllers/states/LocaleState';
import {createMutationState} from 'gelato/frontend/src/controllers/states/MutationState';
import {
  createNetworkedIdentityState,
  setConsumerEmail,
  setConsumerPhone,
  setLookupError,
} from 'gelato/frontend/src/controllers/states/NetworkedIdentityState';
import {createSessionState} from 'gelato/frontend/src/controllers/states/SessionState';
import {
  AutocompleteOptions,
  createTestModeState,
} from 'gelato/frontend/src/controllers/states/TestModeState';
import routeToNextPage from 'gelato/frontend/src/controllers/utils/routeToNextPage';
import analytics from 'gelato/frontend/src/lib/analytics';
import asError from 'gelato/frontend/src/lib/asError';
import shallowMergeInto from 'gelato/frontend/src/lib/shallowMergeInto';
import IDInspector from 'gelato/frontend/src/ML/detectors/IDInspector';
import MicroBlinkCaptureInspector from 'gelato/frontend/src/ML/detectors/MicroBlinkCaptureInspector';

import type {GraphQlField} from '@sail/data';
import type {Locale} from 'gelato/frontend/src/controllers/states/LocaleState';
import type {
  ApplicationActionPayloadType,
  ApplicationController,
  ApplicationPublishStrategy,
  ApplicationRouterPath,
  ApplicationRuntime,
  ApplicationState,
  ApplicationUpdater,
} from 'gelato/frontend/src/controllers/types';
import type {RouteChangeReason} from 'gelato/frontend/src/controllers/utils/routeToNextPage';
import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';

/**
 * The application state.
 */
type State = ApplicationState;

/**
 * The callback function that is called when the application state changes.
 */
type Callback = (state: Readonly<State>) => void;

/**
 * The singleton instance of the application controller. The is the only
 * instance of the application controller that is used in Butter 2.0.
 */
let _instance: AppController | null = null;

/**
 * The application controller that manages the global state of the application.
 *
 * In Butter 2.0, we follow the standard MVC pattern:
 *
 * - Model: Represents the application state.
 * - View: Comprises the React components. The View is responsible for
 *     displaying the data to the user. It receives updates from the Controller
 *     and renders the data accordingly.
 * - Controller: It acts as an intermediary between the Model and the View. It
 *     receives user interactions, such as button clicks or form submissions,
 *     and translates them into actions that the Model can understand. The
 *     Controller also updates the View based on the changes in the Model.
 *
 * +----- ----------+            +---------------+             +-------------+
 * |      Model     |   Deliver  |     View      |   Connect   | Controller  |
 * |                | ---------> |               | ----------> |             |
 * |  Business data |            | Displays data |             | Handles     |
 * |                |            | to the user   |             | actions &   |
 * |                |            |               |             | business    |
 * |                |            |               |             | logic       |
 * +----------------+            +---------------+             +-------------+
 */
export default class AppController implements ApplicationController {
  /**
   * The list of callbacks that are subscribed to the application controller.
   */
  _callbacks: Callback[];

  _actionIdSeed: number;

  /**
   * Whether the application controller is batching updates.
   */
  _isBatchingUpdates: boolean;

  /**
   * The current state of the application. The value is immutable.
   */
  state: Readonly<ApplicationState>;

  /**
   * The runtime that teh application controller is running in.
   */
  runtime: ApplicationRuntime | null;

  constructor() {
    this._callbacks = [];
    this._actionIdSeed = 0;
    this._isBatchingUpdates = false;
    this.runtime = null;

    // Initialize the state as immutable value.
    this.state = produce(
      {
        ...createCameraState(),
        ...createConfigState(),
        ...createDocumentState(),
        ...createErrorState(),
        ...createIndividualState(),
        ...createLayerState(),
        ...createLocaleState(),
        ...createMutationState(),
        ...createNetworkedIdentityState(),
        ...createFlowsState(),
        ...createSessionState(),
        ...createTestModeState(),
      },
      (draft) => {
        // Set up the initial state of the application.
        draft.document.autoCaptureIsSupported =
          IDInspector.isSupported() || MicroBlinkCaptureInspector.isSupported();
      },
    );
  }

  /**
   * Get the singleton instance of the application controller.
   */
  static getSingleton() {
    if (!_instance) {
      _instance = new AppController();
    }
    return _instance;
  }

  /**
   * Reset the singleton instance of the application controller.
   */
  static resetSingleton() {
    _instance = new AppController();
  }

  /**
   * Set the application controller runtime.
   * @param runtime The runtime to set.
   */
  setRuntime = (runtime: ApplicationRuntime) => {
    if (runtime !== this.runtime) {
      this.runtime = runtime;
      this.publish();
    }
  };

  /**
   * Subscribes to the application controller.
   * @param callback The callback function to be called when the state changes.
   * @returns A function that can be called to unsubscribe from the controller.
   */
  subscribe = (callback: Callback) => {
    this._callbacks.push(callback);
    return () => {
      // remove the callback from the list of callbacks.
      const index = this._callbacks.indexOf(callback);
      if (index !== -1) {
        this._callbacks.splice(index, 1);
      }
    };
  };

  /**
   * Publishes the current state to all subscribers.
   */
  publish = () => {
    const {state} = this;
    this._callbacks.forEach((callback) => callback(state));
  };

  // Actions start here. =======================================================

  recordInvalidState = async () => {
    await recordInvalidStateAction(this);
  };

  cancelVerification = async () => {
    await cancelVerificationAction(this);
  };

  handleSessionInitialization = async (
    payload: ApplicationActionPayloadType<
      typeof handleSessionInitializationAction
    >,
  ) => {
    await handleSessionInitializationAction(this, payload);
  };

  // Config actions

  /**
   * Sets the selfie verification method and updates the consent
   * @param selfieVerificationMethod The selfie verification method chosen by the user
   */
  setSelfieVerificationMethod = async (
    selfieVerificationMethod: SelfieVerificationMethod,
  ) => {
    this.update((draft: State) => {
      setSelfieVerificationMethod(draft, selfieVerificationMethod);
    });
  };

  saveSelfieVerificationMethod = async () => {
    await updateConsentAction(this, {
      selfieVerificationMethod: this.state.config.selfieVerificationMethod,
    });
  };

  // Locale actions.

  /**
   * Change the locale of the application runtime.
   * @param locale The locale to be changed to.
   */
  changeLocale = (locale: Locale) => {
    // Update the current locale.
    changeLocaleAction(this, {locale});
  };

  // Individual actions

  /**
   * Initialize the individual state field
   */
  initializeIndividualState = async () => {
    initializeIndividualMutationAction(this);
    this.update((draft) => {
      shallowMergeInto(draft, initializeIndividualState(this.state));
    });
  };

  /**
   * Change the state of the individual collected data
   * @param value The individual collected data values
   */
  setIndividualCollectedData = async (
    individualData: Partial<IndividualStateFields>,
    intl?: IntlShape,
  ) => {
    await setIndividualCollectedDataAction(this, {individualData});
    // intl is currently required for validation as some of Sail
    // components expect to receive and handle error messages
    // instead of returning a list of invalid fields. For some,
    // of the fields this automatica validation on set makes sense
    // (e.g. DOB and Address where it is not possible to select the field
    // being validated) but does not for others.
    if (intl) {
      await validateIndividualDataAction(this, {individualData, intl});
    }
  };

  validateIndividualData = async (
    individualData: Partial<IndividualStateFields>,
    intl: IntlShape,
  ) => {
    await validateIndividualDataAction(this, {
      individualData,
      intl,
    });
  };

  /**
   * Change the state of the individual collected data ID number
   * @param value The ID number payload
   */
  setIdNumberData = (
    payload: {country?: Country; idValue?: string},
    intl: IntlShape,
  ) => {
    return setIdNumberDataAction(this, {idNumberValue: payload, intl});
  };

  /**
   * Change the state of the OTP decline
   * @param mode Whether phone or email
   */
  setDeclineOTP = ({mode}: {mode: OTPMode}) => {
    return setDeclineOTPAction(this, {mode});
  };

  /**
   * Sets the OTP code value
   * @param mode Whether phone or email
   * @param code The code value
   */
  setOTPCode = ({mode, code}: {mode: OTPMode; code: string}) => {
    return setOTPCodeAction(this, {mode, code});
  };

  /**
   * Get the attributes set on the ID number text field
   * @param value The ID number payload
   */
  getIdNumberInputConfig = () => {
    return getIdNumberInputConfig(this.state);
  };

  /**
   * Returns boolean if any individual state is invalid
   * @param boolean
   */
  hasInvalidIndividualState = (
    individual: IndividualStateFields,
    fieldsToValidate?: IndividualStateFieldKeys[],
  ) => {
    const validationErrors = getInvalidIndividualData(
      individual,
      fieldsToValidate,
    );

    const isInvalid = validationErrors.some(
      (validationMsg) =>
        !!validationMsg &&
        (typeof validationMsg === 'string' ||
          Object.values(validationMsg).some((value) => !!value)),
    );

    analytics.track('individualVerificationInfo', {
      validation_errors: JSON.stringify(validationErrors),
    });

    return isInvalid;
  };

  /**
   * Callback when the runtime locale had changed. This sync the runtime locale
   * to the application state.
   * @param locale The locale to be set.
   */
  localeDidChange = (locale: Locale) => {
    localeDidChangeAction(this, {locale});
  };

  // Session actions.

  /**
   * Sets the session in the application state.
   * @param session The session to be set.
   */
  setSession = (session: GraphQlField<GetSessionQueryData, 'session'>) => {
    const email =
      session.collectedData?.individual?.email?.merchantProvidedAddress ||
      session.emailForHandoff ||
      '';
    const phone =
      session.collectedData?.individual?.phoneNumber
        ?.merchantProvidedPhoneNumber ||
      session.phoneForHandoff ||
      '';

    this.update((draft: State) => {
      draft.session = session;

      // Pre-fill consumer email/phone for Networked Identity
      setConsumerEmail(draft, email);
      setConsumerPhone(draft, phone);
      setLookupError(draft, undefined);
    });
  };

  resetApolloStore = () => {
    // This makes it so all previous caches for queries and mutations are deleted
    this.runtime?.apolloClient.resetStore();
  };

  // Camera actions.

  /**
   * Select the camera device.
   * @param payload The payload to select the camera device.
   */
  selectCameraDevice = async (
    payload: ApplicationActionPayloadType<typeof selectCameraDeviceAction>,
  ) => {
    await selectCameraDeviceAction(this, payload);
  };

  /**
   * Start the camera.
   * @param payload The payload to start the camera.
   */
  startCamera = async (
    payload: ApplicationActionPayloadType<typeof startCameraAction>,
  ) => {
    await startCameraAction(this, payload);
  };

  /**
   * Stop the camera.
   * @param payload The payload to stop the camera. . You may specify the error
   *   that caused the camera to stop.
   */
  stopCamera = async (
    payload?: ApplicationActionPayloadType<typeof stopCameraAction> | null,
  ) => {
    await stopCameraAction(this, payload || {error: undefined});
  };

  /**
   * Pause the camera.
   */
  pauseCamera = async () => {
    await pauseCameraAction(this);
  };

  // Document actions.

  /**
   * Clear the error during the inspection phase of the working document.
   */
  clearInspectionError = async () => {
    await clearInspectionErrorAction(this);
  };

  /**
   * Choose how to upload the document.
   * @param method The method to upload the document.
   */
  setDocumentInputMethod = async (
    payload: ApplicationActionPayloadType<typeof setDocumentInputMethodAction>,
  ) => {
    await setDocumentInputMethodAction(this, payload);
  };

  /**
   * Capture single image of the document.
   */
  captureDocumentImage = async (
    payload: ApplicationActionPayloadType<typeof captureDocumentImageAction>,
  ) => {
    await captureDocumentImageAction(this, payload);
  };

  /**
   * Start the auto capture of the document.
   */
  startDocumentAutoCapture = async () => {
    await startDocumentAutoCaptureAction(this);
  };

  /**
   * Stop the auto capture of the document.
   * @param payload The payload to stop the auto capture of the document. You
   *   may specify the error that caused the auto capture to stop.
   */
  stopDocumentAutoCapture = async (
    payload?: ApplicationActionPayloadType<
      typeof stopDocumentAutoCaptureAction
    > | null,
  ) => {
    await stopDocumentAutoCaptureAction(this, payload || {error: undefined});
  };

  /**
   * Proceed to the next document upload step.
   */
  proceedToNextDocumentUploadStep = async () => {
    await proceedToNextDocumentUploadStepAction(this);
  };

  /**
   * Restart the document upload step.
   */
  resetDocumentUploadStep = async () => {
    await restartDocumentUploadStepAction(this);
  };

  /**
   * Confirm the document image that user is currently working on.
   */
  confirmWorkingDocumentImage = async () => {
    await confirmWorkingDocumentImageAction(this);
  };

  /**
   * Retake the document image that user is currently working on.
   */
  retakeWorkingDocumentImage = async () => {
    await retakeWorkingDocumentImageAction(this);
  };

  /**
   * Upload the document image that user is currently working on.
   */
  uploadWorkingDocumentImage = async () => {
    await uploadWorkingDocumentImageAction(this);
  };

  // Layer actions.

  /**
   * Opens a layer.
   * @param contentRenderer  The content renderer for the layer.
   */
  openLayer = (
    contentRenderer: ApplicationActionPayloadType<typeof openLayerAction>,
  ) => {
    openLayerAction(this, contentRenderer);
  };

  /**
   * Close a layer.
   * @param contentRenderer  The content renderer for the layer.
   */
  closeLayer = async (
    contentRenderer: ApplicationActionPayloadType<typeof closeLayerAction>,
  ) => {
    await closeLayerAction(this, contentRenderer);
  };

  /**
   * Close all layers.
   */
  closeAllLayers = () => {
    closeAllLayersAction(this);
  };

  /**
   * Set the error in the application state.
   * @param anyError The error to be set.
   */
  setError = (anyError: any) => {
    this.update((draft: State) => {
      draft.error = asError(anyError);
    });
  };

  // Networked Identity actions.

  /**
   * Update state for whether or Networked Identity is enabled
   * @param enabled Networked Identity feature is enabled
   * @param reuseEnabled Networked Identity document reuse is available
   */
  setNetworkedIdentityEnabled = (enabled: boolean, reuseEnabled: boolean) => {
    this.update((draft: State) => {
      draft.networkedIdentity.enabled = enabled;
      draft.networkedIdentity.reuseEnabled = reuseEnabled;
    }, 'skip_unchanged_state');
  };

  /**
   * Looks up a Link consumer account by email address.
   * @param payload Action payload
   *   - email: This email address may be merchant proided or entered by the consumer.
   */
  lookupConsumerAccount = async (
    payload: ApplicationActionPayloadType<typeof lookupConsumerAccountAction>,
  ) => {
    await lookupConsumerAccountAction(this, payload);
  };

  /**
   * Looks up a Link consumer account by email address.
   * @param payload Action payload
   *   - email: This email address may be merchant proided or entered by the consumer.
   */
  lookupConsumerAccountV2 = async (
    payload: ApplicationActionPayloadType<typeof lookupConsumerAccountActionV2>,
  ) => {
    return lookupConsumerAccountActionV2(this, payload);
  };

  /**
   * Clears the networked identity consumer session.
   */
  clearConsumerSession = () => {
    this.update((draft: State) => {
      draft.networkedIdentity.consumerSession = undefined;
    });
  };

  /**
   * Updates state after Link consumer account verification OTP is updated
   * @param payload Action payload
   *   - otpInput: user entered OTP
   *   - locale: user locale
   *   - codePuncherRef: reference to the code puncher component
   */
  updateConsumerOtpInput = async (
    payload: ApplicationActionPayloadType<typeof updateConsumerOtpInputAction>,
  ) => {
    await updateConsumerOtpInputAction(this, payload);
  };

  /**
   * Updates state after Link consumer account verification OTP is updated
   * @param payload Action payload
   *   - otpInput: user entered OTP
   *   - locale: user locale
   *   - codePuncherRef: reference to the code puncher component
   */
  updateConsumerOtpInputV2 = async (
    payload: ApplicationActionPayloadType<
      typeof updateConsumerOtpInputActionV2
    >,
  ) => {
    await updateConsumerOtpInputActionV2(this, payload);
  };

  /**
   * Resends Link consumer account verification SMS OTP
   * @param payload Action payload
   *   - locale: user locale
   *   - codePuncherRef: reference to the code puncher component
   */
  resendConsumerOtp = async (
    payload: ApplicationActionPayloadType<typeof resendConsumerOtpAction>,
  ) => {
    await resendConsumerOtpAction(this, payload);
  };

  /**
   * Resends Link consumer account verification SMS OTP
   * @param payload Action payload
   *   - locale: user locale
   *   - codePuncherRef: reference to the code puncher component
   */
  resendConsumerOtpV2 = async (
    payload: ApplicationActionPayloadType<typeof resendConsumerOtpActionV2>,
  ) => {
    await resendConsumerOtpActionV2(this, payload);
  };

  /**
   * Updates state after Link consumer account verification is declined
   */
  consumerOtpDeclined = async () => {
    this.update((draft) => {
      draft.networkedIdentity.consumerSession = undefined;
    });
  };

  /**
   * Updates state after user confirms decision to reuse networked identity document.
   */
  reuseConsumerDocument = async () => {
    await reuseConsumerDocumentAction(this);
  };

  /**
   * Starts sign up for a new Link consumer account.
   * @param payload Action payload
   *   - consumerPhoneCountry: consumer phone number country
   *   - locale: user locale
   */
  signUpConsumerAccount = async (
    payload: ApplicationActionPayloadType<typeof signUpConsumerAccountAction>,
  ) => {
    await signUpConsumerAccountAction(this, payload);
  };

  /**
   * Starts sign up for a new Link consumer account.
   * @param payload Action payload
   *   - consumerPhoneCountry: consumer phone number country
   *   - locale: user locale
   */
  signUpConsumerAccountV2 = async (
    payload: ApplicationActionPayloadType<typeof signUpConsumerAccountActionV2>,
  ) => {
    await signUpConsumerAccountActionV2(this, payload);
  };

  /**
   * Starts sign in for an existing Link consumer account.
   * @param payload Action payload
   *   - locale: user locale
   */
  signInConsumerAccount = async (
    payload: ApplicationActionPayloadType<typeof signInConsumerAccountAction>,
  ) => {
    await signInConsumerAccountAction(this, payload);
  };

  /**
   * Starts sign in for an existing Link consumer account.
   * @param payload Action payload
   *   - locale: user locale
   */
  signInConsumerAccountV2 = async (
    payload: ApplicationActionPayloadType<typeof signInConsumerAccountActionV2>,
  ) => {
    await signInConsumerAccountActionV2(this, payload);
  };

  /**
   * Updates state after consumer updates email address.
   * @param payload Action payload
   *   - email: consumer email address
   */
  updateConsumerEmail = async (
    payload: ApplicationActionPayloadType<typeof updateConsumerEmailAction>,
  ) => {
    await updateConsumerEmailAction(this, payload);
  };

  /**
   * Updates state after consumer updates phone number.
   * @param payload Action payload
   *   - phone: consumer phone number
   */
  updateConsumerPhone = async (
    payload: ApplicationActionPayloadType<typeof updateConsumerPhoneAction>,
  ) => {
    await updateConsumerPhoneAction(this, payload);
  };

  /**
   * Updates state after user decision to skip networking.
   */
  skipNetworking = async () => {
    await skipNetworkingAction(this);
  };

  startLinkLoginFlow = async () => {
    await startLinkLoginFlow(this);
  };

  setLinkSaveIdBottomStep = async (
    payload: ApplicationActionPayloadType<typeof setBottomSheetStepAction>,
  ) => {
    await setBottomSheetStepAction(this, payload);
  };

  setConsumerAccountLoading = async (
    payload: ApplicationActionPayloadType<
      typeof setConsumerAccountLoadingAction
    >,
  ) => {
    await setConsumerAccountLoadingAction(this, payload);
  };

  setConsumerPhoneCountry = async (
    payload: ApplicationActionPayloadType<typeof setConsumerPhoneCountryAction>,
  ) => {
    await setConsumerPhoneCountryAction(this, payload);
  };

  setOtpRequired = async (
    payload: ApplicationActionPayloadType<typeof setOtpRequiredAction>,
  ) => {
    await setOtpRequiredAction(this, payload);
  };

  // Netowkred Identity actions

  setValidOrInvalidIdentityDocument = async (documentId?: string) => {
    if (documentId) {
      await setValidOrInvalidIdentityDocument(this, {documentId});
    } else {
      await addNewDocument(this);
    }
  };

  handleClearInvalidIdentityDocument = async () => {
    await clearInvalidDocument(this);
  };

  // Verification Flows actions

  setFlowsSlug = (slug: string) => {
    this.update((draft: State) => {
      draft.flows.slug = slug;
    });
  };

  updateFlowsEmail = async (
    payload: ApplicationActionPayloadType<typeof updateFlowsEmailAction>,
  ) => updateFlowsEmailAction(this, payload);

  setFlowsClientReferenceId = async (
    payload: ApplicationActionPayloadType<typeof updateClientReferenceIdAction>,
  ) => updateClientReferenceIdAction(this, payload);

  getFlowsStaticDataFromLocation = async (location: Location) =>
    getFlowsStaticDataFromLocationAction(this, {location});

  handleFlowsContinue = async () => handleFlowsContinueAction(this);

  initializeFlowsContinue = async () => initializeFlowsContinueAction(this);

  finalizeFlowsContinue = async () => finalizeFlowsContinueAction(this);

  handleFlowsSessionInitialization = async () =>
    handleFlowsSessionInitializationAction(this);

  // Test Mode actions
  setTestModeAutocompleteSetting = async (value: AutocompleteOptions) =>
    handleAutocompleteSettingChange(this, {value});

  handleTestModeSubmitAutocomplete = async () =>
    handleSubmitAutocompleteAction(this);

  handleTestModeSubmitManual = async () => handleSubmitManualAction(this);

  // Actions end here. =========================================================

  /**
   * Updates the application state.
   * @param updater The function that updates the state.
   * @param strategy The strategy to publish the state.
   */
  update = (
    updater: ApplicationUpdater,
    strategy?: ApplicationPublishStrategy | null | undefined,
  ) => {
    const {state} = this;
    let nextState = state;
    try {
      nextState = produce(state, updater);
    } catch (ex) {
      nextState = produce(state, (draft) => {
        draft.error = asError(ex);
      });
    } finally {
      this.state = nextState;
      if (strategy === 'never' || this._isBatchingUpdates) {
        // Do nothing.
        // Never publish the state.
      } else if (strategy === 'skip_unchanged_state' && nextState === state) {
        // Do nothing.
        // Skip publishing the state if the state had not changed.
      } else {
        // Publish the state.
        this.publish();
      }
    }
  };

  /**
   * Batch updates the application state. This is useful when you want to
   * update the state multiple times and only publish the state once.
   * @param updates The function that updates the state synchronously.
   */
  batchUpdates = (
    updates: () => void,
    strategy?: ApplicationPublishStrategy | null | undefined,
  ) => {
    const state = this.state;
    this._isBatchingUpdates = true;
    updates();
    this._isBatchingUpdates = false;

    const nextState = this.state;
    if (strategy === 'never') {
      // Do nothing.
      // Never publish the state.
    } else if (strategy === 'skip_unchanged_state' && nextState === state) {
      // Do nothing.
      // Skip publishing the state if the state had not changed.
    } else {
      // Publish the state.
      this.publish();
    }
  };

  // Runtime actions start here. ===============================================

  routeToPath = async (
    path: ApplicationRouterPath,
    options?: {redirect?: boolean},
  ) => {
    if (options?.redirect) {
      this.runtime?.router.replace(path);
    } else {
      this.runtime?.router.push(path);
    }
  };

  routeToNextPage = async (data: {
    reason: RouteChangeReason;
    redirect?: boolean;
    caller?: string;
  }) => {
    await routeToNextPage(
      this.state,
      this.runtime!,
      data.reason,
      data.redirect,
      data.caller,
    );
  };

  // Mutation actions start here. ==============================================

  start = async (data: {
    consentAccepted: boolean | null;
    networkedIdentityOtpRequired?: boolean;
    networkedIdentityNetworkingOptIn?: boolean;
    reason?: RouteChangeReason;
  }) => {
    await startAction(this, data);
  };

  submitVerification = async (data: {forceDelay?: boolean}) => {
    await submitVerificationAction(this, data);
  };

  updateIndividual = async ({
    intl,
    partialData,
  }: {
    intl: IntlShape;
    partialData?: Partial<IndividualStateFields>;
  }) => {
    const finalIndividualData = partialData || this.state.individual;
    await validateIndividualDataAction(this, {
      individualData: finalIndividualData,
      intl,
    });
    if (
      !this.hasInvalidIndividualState(
        this.state.individual,
        Object.keys(finalIndividualData) as IndividualStateFieldKeys[],
      )
    ) {
      const response = updateIndividualAction(this, {
        intl,
        partialData: finalIndividualData,
      });
      return response;
    }
    return false;
  };

  submitIndividual = async ({intl}: {intl: IntlShape}) => {
    await validateIndividualDataAction(this, {
      individualData: this.state.individual,
      intl,
    });
    if (!this.hasInvalidIndividualState(this.state.individual)) {
      await submitIndividualAction(this, {intl});
    }
  };

  generateOTP = async (payload: {mode: OTPMode}) => {
    await generateOTPAction(this, payload);
  };

  validateOTP = async (payload: {mode: OTPMode}) => {
    await validateOTPAction(this, payload);
  };

  declineOTP = async (payload: {mode: OTPMode}) => {
    await this.setDeclineOTP(payload);
    await validateOTPAction(this, payload);
  };

  makeHandoffUrl = async () => {
    await makeHandoffUrlAction(this);
  };

  resetHandoff = async () => {
    await resetHandoffAction(this);
  };

  resetEmailHandoff = async () => {
    await resetEmailHandoffAction(this);
  };

  resetSMSHandoff = async () => {
    await resetSMSHandoffAction(this);
  };

  sendHandoffEmail = async (
    data: ApplicationActionPayloadType<typeof sendHandoffEmailAction>,
  ) => {
    await sendHandoffEmailAction(this, data);
  };

  sendHandoffSMS = async (
    data: ApplicationActionPayloadType<typeof sendHandoffSMSAction>,
  ) => {
    await sendHandoffSMSAction(this, data);
  };
}
