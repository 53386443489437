import {defineMessages} from 'react-intl';

const messages = defineMessages({
  documentTitle: {
    id: 'testing.documentTitle',
    description: 'HTML title for testing page',
    defaultMessage: 'Stripe Identity Testing',
  },

  testmodeHeaderTitle: {
    id: 'testing.header.title',
    description: 'title text for header section in testing page',
    defaultMessage: `You’re currently in testmode`,
  },
  testmodeHeaderDescription: {
    id: 'testing.description',
    description: 'Description of testmode UI',
    defaultMessage: 'This page is only shown in testmode.',
  },

  // title for each autocomplete option
  autocompleteVerifiedTitle: {
    id: 'testing.autocomplete.verified.title',
    description: 'title for autocomplete option to use verified test data',
    defaultMessage: 'Verification success',
  },
  autocompleteVerifiedAsyncTitle: {
    id: 'testing.autocomplete.verified.async.title',
    description:
      'title for autocomplete option to use verified test data and introduce a delay before completion',
    defaultMessage: 'Verification success async',
  },
  autocompleteUnverifiedTitle: {
    id: 'testing.autocomplete.unverified.title',
    description: 'title for autocomplete option to use unverified test data',
    defaultMessage: 'Verification failure',
  },
  autocompleteUnverifiedAsyncTitle: {
    id: 'testing.autocomplete.unverified.async.title',
    description:
      'title for autocomplete option to use unverified test data and introduce a delay before completion',
    defaultMessage: 'Verification failure async',
  },

  autocompleteConsentDeclinedTitle: {
    id: 'testing.autocomplete.consentDeclined.title',
    description:
      'title for autocomplete option to mimic user declining consent',
    defaultMessage: 'Consent declined',
  },

  // autocomplete section messages
  autocompleteSectionTitle: {
    id: 'testing.section.autocomplete.title',
    description: 'Title for section offering autocomplete options',
    defaultMessage: 'Complete with test data',
  },

  autocompleteSectionDescription: {
    id: 'testing.section.autocomplete.description',
    description: 'Description for section offering autocomplete options',
    defaultMessage:
      'Save time by choosing a desired result and completing instantly with that outcome.',
  },
  autocompleteSectionButton: {
    id: 'testing.section.autocomplete.button',
    description: 'button confirming autocomplete options',
    defaultMessage: 'Submit',
  },

  // manual section messages
  manualSectionTitle: {
    id: 'testing.section.manual.title',
    description: 'Title for section offering manual option',
    defaultMessage: 'Preview user experience',
  },

  manualSectionDescription: {
    id: 'testing.section.manual.desciption',
    description: 'Description for section offering manual option',
    defaultMessage:
      'Proceed to preview as an end user. Information provided will not be verified.',
  },
  manualSectionButton: {
    id: 'testing.section.manual.button',
    description: 'button confirming manual option',
    defaultMessage: 'Proceed',
  },
});

export default messages;
