import {createKeyframes, createViewConfig} from '@sail/engine';
import {tokens} from '../tokens';

const shimmer = createKeyframes({
  from: {
    backgroundPosition: '200% center',
  },
  to: {
    backgroundPosition: '0 center',
  },
});

export const SkeletonConfig = createViewConfig({
  name: 'Skeleton',
  props: {},
  flattens: true,
});

export const Skeleton = SkeletonConfig.createView('div', {
  css: {
    backgroundImage: `linear-gradient(
      to right,
      ${tokens.color.background.offset} 50%,
      ${tokens.color.background.surface} 80%,
      ${tokens.color.background.offset} 100%
    )`,
    backgroundSize: '200% 100%',
    animation: `${shimmer} 1400ms ease-in-out infinite both`,
  },
  defaults: {'data-testid': 'skeleton'},
});
