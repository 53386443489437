import {useReports} from '@sail/observability';
import {useCallback, useEffect, useState} from 'react';

import type {IReports} from '@sail/observability';
import type {StorageEvent} from 'src/internal/browserStorage/patchBrowserStorage';
import type {DataConfig} from 'src/internal/config/types';
import type {StorageEventCallback} from 'src/internal/localStorage/createLocalStorageEventListener';

type BrowserStorageSetter = (value: string | null) => Promise<boolean>;
export type BrowserStorageHook = [string | null, BrowserStorageSetter];

type Storage<TConfig> = {
  get(
    config: TConfig,
    name: string,
    reports: IReports,
  ): string | null | undefined;
  set(
    config: TConfig,
    key: string,
    value: string | null,
    reports: IReports,
  ): Promise<boolean>;
  remove(
    config: DataConfig['browserStorage'],
    name: string,
    reports: IReports,
  ): boolean;
  subscribe(
    config: TConfig,
    callback: StorageEventCallback,
    reports: IReports,
  ): void;
  unsubscribe(
    config: TConfig,
    callback: StorageEventCallback,
    reports: IReports,
  ): void;
};

/**
 * Hook for persisting data in the browser storage, supports both localStorage and sessionStorage.
 *
 */
export default function useBrowserStorage<TConfig>(
  /* The config object to inject to the storage functions */
  config: TConfig,
  /* Abstraction over the desired browser storage */
  storage: Storage<TConfig>,
  /* Key of storage item to interact with */
  key: string,
): BrowserStorageHook {
  const reports = useReports();
  const [value, setValueInternal] = useState(() =>
    storage.get(config, key, reports),
  );

  const setItem = useCallback(
    (value) => {
      return storage.set(config, key, value, reports);
    },
    [config, key, storage, reports],
  );

  useEffect(() => {
    function onChange(event: StorageEvent): void {
      switch (event.type) {
        case 'clear': {
          setValueInternal(null);
          break;
        }
        case 'remove': {
          if (event.key === key) {
            setValueInternal(null);
          }
          break;
        }
        case 'set': {
          if (event.key === key) {
            setValueInternal(event.value);
          }
          break;
        }
        default:
          break;
      }
    }

    storage.subscribe(config, onChange, reports);

    return (): void => {
      storage.unsubscribe(config, onChange, reports);
    };
  }, [config, key, storage, reports]);

  return [value ?? null, setItem];
}
