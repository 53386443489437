import {warning} from '@sail/icons/react/Icon';
import {view, Icon, css} from '@sail/ui';
import {urlRedirect} from '@stripe-internal/safe-links';
import * as React from 'react';
import {defineMessages} from 'react-intl';

import Background from 'gelato/frontend/src/components/Background';
import invalidMessages from 'gelato/frontend/src/components/Invalid/messages';
import LandscapeMessage from 'gelato/frontend/src/components/LandscapeMessage';
import Link from 'gelato/frontend/src/components/LinkV3';
import Message, {
  FormattedMarkdownMessage,
} from 'gelato/frontend/src/components/Message';
import PageCard from 'gelato/frontend/src/components/PageCard';
import PageLayout from 'gelato/frontend/src/components/PageLayout';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import {LocalRouter} from 'gelato/frontend/src/lib/localRouter';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import {
  isSessionError,
  NoTokenError,
  isExpiredTokenError,
  redirectUrlFromError,
  isSessionExpired,
  isRestrictedIpCountryError,
} from 'gelato/frontend/src/lib/sessionError';
import Storage from 'gelato/frontend/src/lib/Storage';

const messages = defineMessages({
  errorHeading: {
    id: 'errorPage.heading',
    description: 'Heading on the error page',
    defaultMessage: 'Something went wrong',
  },
  genericErrorBody: {
    id: 'errorPage.generic.body',
    description: 'Body text on the error page',
    defaultMessage:
      'An unexpected error has occurred. Please reload the page and try again. If you still need help, please <Link>contact us</Link>',
  },
  noSessionErrorBody: {
    id: 'errorPage.noSession.body',
    description:
      'Body text on the error page when there is an error from not having a session token',
    defaultMessage:
      'We were unable to establish a secure session. If you need help, please <Link>contact us</Link>',
  },
  tokenNotFoundHeading: {
    id: 'tokenNotFound.heading',
    description:
      'Heading on the page telling user that the verification could not be found',
    defaultMessage: 'Invalid link',
  },
  tokenNotFoundBody: {
    id: 'tokenNotFound.body',
    description:
      'Body on the page telling user that the verification could not be found',
    defaultMessage:
      'We were unable to load the verification. The link you followed may be expired, may have already been used, or was invalid. ' +
      'Try returning to the site which sent you here for a new link.',
  },
});

const Styles = {
  contentContainer: css({
    stack: 'y',
    alignY: 'center',
    alignX: 'center',
    padding: 'large',
    textAlign: 'center',
  }),
  iconContainer: css({
    marginBottom: 'large',
  }),
  descriptionContainer: css({
    stack: 'y',
    gap: 'medium',
    font: 'body.medium',
  }),
  header: css({font: 'heading.large'}),
  secondaryLink: css({
    textDecorationColor: '#99a5b8',
    fontWeight: 'normal',
  }),
};

type Props = {
  children: React.ReactNode;
  isTerminal: boolean;
  router: LocalRouter;
};

// Session hack- record the url when we first evaluate JS
const firstLoadUrl =
  typeof window !== 'undefined' ? window.location.href : undefined;

const ErrorStateContainer = (props: {error: Error}) => {
  const {error} = props;

  const getMessageHeading = () => {
    if (error instanceof NoTokenError) {
      return messages.tokenNotFoundHeading;
    } else if (isRestrictedIpCountryError(error)) {
      return invalidMessages.unsupportedIpCountryTitle;
    } else {
      return messages.errorHeading;
    }
  };

  const getMessageDescriptor = () => {
    if (error instanceof NoTokenError) {
      return messages.tokenNotFoundBody;
    } else if (isRestrictedIpCountryError(error)) {
      return invalidMessages.unsupportedIpCountryBody;
    } else if (isSessionError(error)) {
      return messages.noSessionErrorBody;
    } else {
      return messages.genericErrorBody;
    }
  };

  // There's no point in sending an iframe event for restricted IP country errors
  // because we hard block the user anyway. This prevents us from being alerted
  // on unactionable things errors.
  if (!isRestrictedIpCountryError(error)) {
    postIframeEvent('error');
  }

  // the component structure here mimics what is in use in PageComponentContent
  // in order to use that component directly we would have to do some stuff with the
  // `experimentPageRenderer` pattern which feels unsavory
  return (
    <PageLayout>
      <LandscapeMessage />
      <Background />
      <PageCard
        // @ts-expect-error - TS2322 - Type '{ children: Element; hideFooter: true; showBackLink: false; showPageHeader: false; size: "single"; }' is not assignable to type 'IntrinsicAttributes & Props'.
        hideFooter
        showBackLink={false}
        showPageHeader={false}
        size="single"
        showProgressBar={false}
        height="fill"
      >
        <view.div uses={[Styles.contentContainer]}>
          <view.div uses={[Styles.iconContainer]}>
            <Icon css={{color: 'black'}} icon={warning} size="xlarge" />
          </view.div>
          <view.div uses={[Styles.descriptionContainer]}>
            <view.div uses={[Styles.header]}>
              <Message {...getMessageHeading()} />
            </view.div>
            <view.div>
              {
                <FormattedMarkdownMessage
                  {...getMessageDescriptor()}
                  values={{
                    // todo - this link is not really helpful. can we send users in this state somewhere better?
                    Link: (text: string) => (
                      <Link
                        href="https://support.stripe.com"
                        type="secondary"
                        uses={[Styles.secondaryLink]}
                      >
                        {text}
                      </Link>
                    ),
                  }}
                />
              }
            </view.div>
          </view.div>
        </view.div>
      </PageCard>
    </PageLayout>
  );
};

type State = {
  error: Error | null | undefined;
};

class ErrorProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {error: undefined};
  }

  // eslint-disable-next-line @sail/observability-no-error-boundary
  componentDidCatch(error: Error, info: Record<any, any>) {
    const {isTerminal, router} = this.props;
    this.setState({error});

    if (isSessionExpired()) {
      // If session is expired and we are already on invalid page, then we
      // could not render invalid page so just go straight to expiredUrl if
      // present.
      if (window && window.location.pathname === '/invalid') {
        const url = Storage.getRefreshUrl() || Storage.getReturnUrl();
        if (url) {
          urlRedirect(url);
        }
        return;
      }
      router.push('/invalid');
      return;
    }

    // Ignore session errors if this is a terminal page OR
    // If we are on the same page as the JS was loaded. This indicates a tab refresh.
    const ignoreSessionError =
      isSessionError(error) &&
      (isTerminal || firstLoadUrl === window.location.href);

    if (!ignoreSessionError) {
      handleException(error, 'Error caught in componentDidCatch');
    }

    if (isExpiredTokenError(error)) {
      const redirectToUrl = redirectUrlFromError(error);
      if (redirectToUrl) {
        urlRedirect(redirectToUrl);
      }
    }
  }

  render() {
    const {error} = this.state;
    const {children} = this.props;
    return error ? <ErrorStateContainer error={error} /> : children;
  }
}

export default ErrorProvider;
