import {ApolloError, ApolloLink} from '@apollo/client';
import {checkPermissions} from 'src/index';
import {SELF} from 'src/internal/apollo/permissions';

import type {NextLink, RequestHandler} from '@apollo/client';
import type {
  GraphQlDocument,
  InternalOperation,
} from 'src/internal/apollo/types';

export default function createRuntimePermissionsCheckLink(): ApolloLink {
  return new ApolloLink(function runtimePermissionsCheckLink<
    TDocumentNode extends GraphQlDocument<any, any, any>,
  >(operation: InternalOperation<TDocumentNode>, forward: NextLink) {
    const {token, isPrefetchMode, observabilityFns, queryProject} =
      operation.getContext();

    if (!observabilityFns || !token || !operation.query.permissions) {
      return forward(operation);
    }

    const {
      errors: {error, warning},
      metrics,
    } = observabilityFns;

    const refinedToken = checkPermissions(token, operation.query.permissions);
    const successfulCheck = !!refinedToken;

    if (successfulCheck) {
      metrics.increment('frontend.data.permissions_success', {
        operation_name: operation.operationName,
        operation_prefetch: !!isPrefetchMode,
      });
    } else {
      metrics.increment('frontend.data.permissions_error', {
        operation_name: operation.operationName,
        operation_prefetch: !!isPrefetchMode,
      });

      const tokenPermissions = Object.keys(token[SELF]);

      // Query might fail to be executed if token lacks a required permission. In case of
      // prefetching it is expected that some queries fail, therefore we only emit warning (we might
      // fully remove the report in future as it's not actionable). However, in non prefetch mode
      // it's a symptom of a real user-facing bug. It's usually caused by token TypeScript errors
      // being surpressed.
      const reportMethod = isPrefetchMode ? warning : error;

      reportMethod(
        `Permissions check doesn't pass for ${operation.operationName}`,
        {
          project: queryProject,
          tags: {isPrefetchMode: isPrefetchMode?.toString() ?? 'false'},
          extras: {
            missingPermissions: Object.keys(operation.query.permissions).filter(
              (perm) => !tokenPermissions.includes(perm),
            ),
            token,
          },
        },
      );

      if (isPrefetchMode) {
        throw new ApolloError({
          clientErrors: [
            new Error(
              `Provided PermissionToken doesn't contain required permissions to execute operation ${operation.operationName}`,
            ),
          ],
        });
      }
    }

    return forward(operation).map(function runtimePermissionsCheckOnResponse(
      data,
    ) {
      const errorCodes =
        data.errors?.map((error) => error.extensions?.error?.code) ?? [];

      if (!successfulCheck && errorCodes.length === 0) {
        warning(
          `A client side unsuccessful permissions check for ${operation.operationName} didn't result in reported server errors`,
          {
            project: queryProject,
            tags: {isPrefetchMode: isPrefetchMode?.toString() ?? 'false'},
          },
        );
      }

      return data;
    });
  } as RequestHandler);
}
