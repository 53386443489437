import {GraphQlField} from '@sail/data';
import {AddressValue, AddressValueAdapter} from '@sail/ui';
import {PatternType} from '@stripe-internal/sail/lib/autocorrect';

import {ApplicationState} from 'gelato/frontend/src/controllers/types';
import hasRequiredField from 'gelato/frontend/src/controllers/utils/hasRequiredField';
import isFieldNeeded from 'gelato/frontend/src/controllers/utils/isFieldNeeded';
import {
  COUNTRY_LIST,
  COUNTRY_SELECT_OPTION_MESSAGES,
} from 'gelato/frontend/src/lib/countries';
import getCountryIcon from 'gelato/frontend/src/lib/getCountryIcon';
import getDefaultCountryForSMS from 'gelato/frontend/src/lib/getDefaultCountryForSMS';
import {
  ID_NUMBER_COUNTRY_AUTOCORRECT_PATTERNS,
  ID_NUMBER_COUNTRY_PLACEHOLDERS,
} from 'gelato/frontend/src/lib/idNumber';
import {DEFAULT_LOCALE, LocaleToCountry} from 'gelato/frontend/src/lib/locale';

import type {IconAsset} from '@sail/icons/types';
import type {
  Country,
  NameDataInput,
  DateInput,
  IdNumberDataInput,
  EmailDataInput,
  PhoneNumberDataInput,
  IndividualFields,
  AddressDataInput,
} from '@stripe-internal/data-gelato/schema/types';
import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';
import type {MessageDescriptor} from 'react-intl';
import type {AddressValues} from 'sail/AddressInput/types';

/**
 * @fileoverview Actions that manage the state for PII-collected (non-doc and selfie) individual fields
 */

type WithoutTypeName<T> = Omit<T, '__typename'>;

/**
 * To accommodate all of the individual state fields,
 * We allow validationMsg to be generic (i.e. referring to
 * the entire field like dob) or specific (i.e. referring to
 * specific parts of the field like day in dob)
 *
 * Example:
 * type TestValidation = WithValidation<{foo: string; bar: string}>;
 * const a: TestValidation = {
 *   foo: 'asdf',
 *   bar: 'test',
 *   validationMsg: {
 *     foo: 'asdf',
 *   },
 * };
 * const b: TestValidation = {
 *   foo: 'asdf',
 *   bar: 'test',
 *   validationMsg: 'error',
 * };
 */
type WithValidation<T> = T & {
  validationMsg?:
    | Partial<Record<InnerIndividualStateFieldKeys, string>>
    | string;
};

export type IndividualDataVariables = {
  nameData: NameDataInput | null | undefined;
  dobData: DateInput | null | undefined;
  addressData: AddressDataInput | null | undefined;
  idNumberData: IdNumberDataInput | null | undefined;
  emailData: EmailDataInput | null | undefined;
  phoneNumberData: PhoneNumberDataInput | null | undefined;
};

type IdNumberInputsConfig = {
  placeholder: string;
  value?: string;
  type: 'number' | 'text';
  options: {
    id: string;
    flag?: IconAsset;
    value: MessageDescriptor;
  }[];
  autocorrectPattern?: PatternType[];
};

type AddressValueKey = keyof AddressValues;
type AddressDataInputKey = keyof AddressDataInput;

export const ADDRESS_DATA_INPUT_TO_ADDRESS_VALUE_MAP: Partial<
  Record<AddressValueKey | 'country', AddressDataInputKey>
> = {
  address: 'line1',
  'address-line2': 'line2',
  locality: 'city',
  subregion: 'state',
  zip: 'zip',
  country: 'country',
};

export type IndividualAddress = Partial<
  WithoutTypeName<
    GraphQlField<
      GetSessionQueryData,
      'session',
      'collectedData',
      'individual',
      'address'
    >
  >
>;

export type IndividualDob = Partial<
  WithoutTypeName<
    GraphQlField<
      GetSessionQueryData,
      'session',
      'collectedData',
      'individual',
      'dob'
    >
  >
>;

export type IndividualNameFields = keyof IndividualName;

export type IndividualName = Partial<
  WithoutTypeName<
    GraphQlField<
      GetSessionQueryData,
      'session',
      'collectedData',
      'individual',
      'name'
    >
  >
>;

export type IndividualIdNumber = Partial<
  WithoutTypeName<
    GraphQlField<
      GetSessionQueryData,
      'session',
      'collectedData',
      'individual',
      'idNumber'
    >
  > & {
    partialValue: string;
    idNumber: string;
  }
>;

type PhoneNumberField = Partial<
  WithoutTypeName<
    GraphQlField<
      GetSessionQueryData,
      'session',
      'collectedData',
      'individual',
      'phoneNumber'
    >
  > & {
    country: Country;
  }
>;

type EmailField = Partial<
  WithoutTypeName<
    GraphQlField<
      GetSessionQueryData,
      'session',
      'collectedData',
      'individual',
      'email'
    >
  > & {
    country: Country;
  }
>;

export type IndividualPhoneNumber = Omit<PhoneNumberField, 'otpDeclined'>;
export type IndividualEmail = Omit<EmailField, 'otpDeclined'>;
export type IndividualPhoneOtp = Partial<
  Pick<PhoneNumberField, 'otpDeclined'> & {
    code: string;
    shouldGenerateOTP: boolean;
    generatedOTP: boolean;
  }
>;
export type IndividualEmailOtp = Partial<
  Pick<EmailField, 'otpDeclined'> & {
    code: string;
    shouldGenerateOTP: boolean;
    generatedOTP: boolean;
  }
>;

export enum OTPMode {
  email = 'email',
  phone = 'phone',
}

export type DateInnerFields = 'day' | 'month' | 'year';
export type DateShape<T> = Partial<Record<DateInnerFields, T>>;

// Fields that are available on the "/individual" page.
export const IndividualPageFields: Readonly<IndividualFields[]> = [
  'address',
  'dob',
  'email',
  'id_number',
  'name',
  'phone_number',
];

export type IndividualStateFields = {
  address: WithValidation<IndividualAddress> | null;
  dob: WithValidation<IndividualDob> | null;
  name: WithValidation<IndividualName> | null;
  idNumber: WithValidation<IndividualIdNumber> | null;
  phone: WithValidation<IndividualPhoneNumber> | null;
  email: WithValidation<IndividualEmail> | null;
  phoneOtp: WithValidation<IndividualPhoneOtp> | null;
  emailOtp: WithValidation<IndividualEmailOtp> | null;
};

export type IndividualStateFieldKeys = keyof IndividualStateFields;
export type IndividualStateFieldValues = WithValidation<
  | IndividualAddress
  | IndividualDob
  | IndividualName
  | IndividualIdNumber
  | IndividualPhoneNumber
  | IndividualEmail
>;
export type InnerIndividualStateFieldKeys =
  | keyof IndividualAddress
  | keyof IndividualDob
  | keyof IndividualName
  | keyof IndividualIdNumber
  | keyof IndividualPhoneNumber
  | keyof IndividualEmail;

export type IndividualState = {
  individual: IndividualStateFields;
};

export const SailAddressAdapter: AddressValueAdapter<IndividualAddress> = {
  toAddressValue: (value): AddressValue => {
    return {
      address: value.line1 || '',
      address2: value.line2 || '',
      locality: value.city || '',
      subregion: value.state || '',
      postalCode: value.zip || '',
      // Country code is required and since this is nullable in the IndividualAddress type,
      // we default the selection to US
      countryCode: (value.country || 'US') as Country,
    };
  },
  fromAddressValue: (value): AddressDataInput => {
    return {
      line1: value.address || '',
      line2: value.address2 || '',
      city: value.locality || '',
      state: value.subregion || '',
      zip: value.postalCode || '',
      country: value.countryCode || '',
    };
  },
  unsupportedFields: ['dependentLocality', 'sortingCode'],
};

export function createIndividualState(): IndividualState {
  return {
    individual: {
      address: null,
      dob: null,
      email: null,
      name: null,
      phone: null,
      idNumber: null,
      phoneOtp: null,
      emailOtp: null,
    },
  };
}

const getIdNumberCountryAllowlist = (state: ApplicationState) => {
  const {session} = state;
  const {idNumberCountryAllowlist, idNumberSupportedCountries} = session || {};
  return (
    idNumberCountryAllowlist ||
    (idNumberSupportedCountries as Country[]) ||
    COUNTRY_LIST
  );
};

const getSelectedCountry = (
  state: ApplicationState,
  countryAllowlist: readonly Country[],
) => {
  const locale = state.locale.currentValue[0];
  const {session} = state;
  const {ipCountry, defaultCountry} = session || {};

  const localeCountry = LocaleToCountry[locale];
  if (countryAllowlist.includes(localeCountry)) {
    return localeCountry;
  }

  // Default to the session provided info
  if (defaultCountry && countryAllowlist.includes(defaultCountry)) {
    return defaultCountry;
  }
  if (ipCountry && countryAllowlist.includes(ipCountry as Country)) {
    return ipCountry as Country;
  }

  // Otherwise, default to the fallback locale
  return LocaleToCountry[DEFAULT_LOCALE];
};

const getIdNumberSelectedCountry = (state: ApplicationState) => {
  const {individual, session} = state;
  const {country: sessionCountry} =
    session?.collectedData?.individual?.idNumber || {};
  const {country} = individual.idNumber || {};
  const countryAllowlist = getIdNumberCountryAllowlist(state);

  // Return if country is already set
  if (country) {
    return country;
  }

  // Return if somehow it was collected in session already
  if (sessionCountry) {
    return sessionCountry;
  }

  return getSelectedCountry(state, countryAllowlist);
};

/**
 * Get config for the ID number text field
 * @param state The controller state.
 * @returns idNumberInputConfig: IdNumberInputConfig
 */
export const getIdNumberInputConfig = (
  state: ApplicationState,
): IdNumberInputsConfig => {
  const {individual} = state;
  const {partialValue, idNumber, country} = individual.idNumber || {};
  const countryAllowlist = getIdNumberCountryAllowlist(state);
  const selectedCountry = country!;

  const placeholder = ID_NUMBER_COUNTRY_PLACEHOLDERS[selectedCountry] || '';
  const options = countryAllowlist.map((country) => ({
    id: country,
    flag: getCountryIcon(country),
    value: COUNTRY_SELECT_OPTION_MESSAGES[country],
  }));
  const autocorrectPattern =
    ID_NUMBER_COUNTRY_AUTOCORRECT_PATTERNS[selectedCountry];

  return {
    placeholder,
    value: selectedCountry === 'US' ? partialValue : idNumber,
    type: selectedCountry === 'US' ? 'number' : 'text',
    options,
    autocorrectPattern,
  };
};

export const getAddressInputSelectedCountry = (state: ApplicationState) => {
  const {individual, session} = state;
  const {addressSupportedCountries} = session || {};
  const countryAllowlist =
    (addressSupportedCountries as Country[]) || COUNTRY_LIST;
  const {country: sessionCountry} =
    session?.collectedData?.individual?.address || {};
  const {country} = individual.address || {};

  // Return if country is already set
  if (country) {
    return country;
  }

  // Return if somehow it was collected in session already
  if (sessionCountry) {
    return sessionCountry;
  }

  return getSelectedCountry(state, countryAllowlist);
};

/**
 * Function that initializes the individual state based on the required fields
 * @param state The controller state.
 * @returns individualState: IndividualState
 */
export const initializeIndividualState = (state: ApplicationState) => {
  const {session} = state;
  const {firstName, lastName} = session?.collectedData?.individual?.name || {};
  const {
    merchantProvidedPhoneNumber: merchantProvidedPhone,
    userProvidedPhoneNumber: userProvidedPhone,
  } = session?.collectedData?.individual?.phoneNumber || {};
  const {
    merchantProvidedAddress: merchantProvidedEmail,
    userProvidedAddress: userProvidedEmail,
  } = session?.collectedData?.individual?.email || {};

  if (!session) {
    return createIndividualState();
  }

  // We still want to initialize email/phone field even though it is not a required field
  // and allow users to edit it on OTP cases
  const phoneEditAllowed =
    isFieldNeeded(state, 'phone_number') ||
    // Allow users to edit phone for phone OTP if not merchant provided phone
    (hasRequiredField(state, 'phone_otp') && !merchantProvidedPhone);
  const emailEditAllowed =
    isFieldNeeded(state, 'email') ||
    // Allow users to edit phone for phone OTP if not merchant provided phone
    (hasRequiredField(state, 'email_otp') && !merchantProvidedEmail);

  return {
    individual: {
      address: hasRequiredField(state, 'address')
        ? {
            country: getAddressInputSelectedCountry(state),
          }
        : null,
      dob: hasRequiredField(state, 'dob') ? {} : null,
      email: emailEditAllowed ? {userProvidedAddress: userProvidedEmail} : null,
      name: hasRequiredField(state, 'name')
        ? {
            firstName,
            lastName,
          }
        : null,
      phone: phoneEditAllowed
        ? {
            country: getDefaultCountryForSMS(
              state.session,
              state.locale.currentValue[0],
            ) as Country,
            userProvidedPhoneNumber: userProvidedPhone,
          }
        : null,
      idNumber: hasRequiredField(state, 'id_number')
        ? {
            country: getIdNumberSelectedCountry(state),
          }
        : null,
      emailOtp: hasRequiredField(state, 'email_otp') ? {} : null,
      phoneOtp: hasRequiredField(state, 'phone_otp') ? {} : null,
    },
  };
};

/**
 * Function that returns individual data validation messages if any errors are present
 * If a validation message exists, this means we will block on submitting
 * @param state The individual state.
 * @returns boolean
 */
export const getInvalidIndividualData = (
  individual: IndividualStateFields,
  fieldFilter?: IndividualStateFieldKeys[],
) => {
  // Sometimes we want to just partially update individual collected data.
  // In those instances, we want to also just partially check individual data.
  const fieldsToValidate =
    fieldFilter || (Object.keys(individual) as IndividualStateFieldKeys[]);

  return fieldsToValidate.map((field) => {
    const value = individual[field];
    if (
      // OTP is handled on its own so it is okay if it is empty for now
      field !== 'phoneOtp' &&
      field !== 'emailOtp' &&
      // Check for empty fields
      value !== null &&
      Object.values(value).length === 0
    ) {
      return null;
    }
    // Check if individual fields have any existing validationMsg
    return value?.validationMsg;
  });
};

/**
 * Function to convert the individual state to the data variable
 * used by the update mutation
 * @param state The individual state.
 * @returns IndividualDataVariables
 */
export const convertStateToDataVariables = (
  partialData: Partial<IndividualStateFields> | undefined,
  state: ApplicationState,
): IndividualDataVariables => {
  const values = partialData || state.individual;
  let nameData;
  if (values.name && isFieldNeeded(state, 'name')) {
    nameData = {
      firstName: values.name.firstName,
      lastName: values.name.lastName,
    };
  }

  let dobData;
  if (values.dob && isFieldNeeded(state, 'dob')) {
    dobData = {
      day: values.dob.day,
      month: values.dob.month,
      year: values.dob.year,
    };
  }

  let addressData;
  if (values.address && isFieldNeeded(state, 'address')) {
    addressData = {
      line1: values.address.line1,
      line2: values.address.line2,
      city: values.address.city,
      state: values.address.state,
      country: values.address.country,
      zip: values.address.zip,
    };
  }
  let idNumberData;
  if (values.idNumber && isFieldNeeded(state, 'id_number')) {
    idNumberData = {
      country: values.idNumber.country,
      idNumber: values.idNumber.idNumber,
      partialValue: values.idNumber.partialValue,
    };
  }

  let emailData;
  if (values.email && isFieldNeeded(state, 'email')) {
    emailData = {
      address: values.email.userProvidedAddress,
    };
  }

  let phoneNumberData;
  if (values.phone && isFieldNeeded(state, 'phone_number')) {
    phoneNumberData = {
      phoneNumber: values.phone.userProvidedPhoneNumber,
    };
  }

  return {
    nameData,
    dobData,
    idNumberData,
    addressData,
    emailData,
    phoneNumberData,
  };
};
