import {dispute} from '@sail/icons/react/Icon';
import * as React from 'react';

import messages from 'gelato/frontend/src/components/Invalid/messages';
import Message from 'gelato/frontend/src/components/Message';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';

const Footer = () => {
  React.useEffect(() => {
    postIframeEvent('STRIPE_IDENTITY_ERROR', {
      type: 'validation_error',
      code: 'unsupported_ip_country',
    });
  });
  return null;
};

export const generateUnsupportedIpCountryContent = () => {
  return {
    title: <Message {...messages.unsupportedIpCountryTitle} />,
    description: <Message {...messages.unsupportedIpCountryBody} />,
    icon: dispute,
    footer: <Footer />,
  };
};
