import {usePrevious} from '@sail/react';
import key from 'src/internal/user/key';
import useLocalStorage from 'src/public/localStorage/useLocalStorage';

export default function useOnChangedUser(callback: () => void): void {
  const [loggedUser] = useLocalStorage(key);
  const previous = usePrevious(loggedUser);

  if (loggedUser && previous && loggedUser !== previous) {
    callback();
  }
}
