import * as Sentry from '@sentry/browser';
import {produce} from 'immer';

import {
  Feedback,
  InputMethod,
} from 'gelato/frontend/src/controllers/states/DocumentState';
import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import analytics from 'gelato/frontend/src/lib/analytics';
import asError from 'gelato/frontend/src/lib/asError';
import containsRectangle from 'gelato/frontend/src/lib/containsRectangle';
import ImageFrame from 'gelato/frontend/src/lib/ImageFrame';
import {reportMetric} from 'gelato/frontend/src/lib/metricsBatcher';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import BaseInspector from 'gelato/frontend/src/ML/detectors/BaseInspector';
import {Rectangle} from 'gelato/frontend/src/ML/IDDetectorAPI';
import {getAssetPath} from 'gelato/frontend/src/ML/utils';

import type {
  AnalyzerSettings,
  DirectApi,
  DocumentGroup,
  FrameAnalysisError,
  FrameAnalysisResult,
  FrameAnalysisStatus,
  SideCaptureResult,
} from '@microblink/capture';
import type {DocumentTypes} from '@stripe-internal/data-gelato/schema/types';
import type {
  FeedbackValue,
  InspectionState,
  MicroBlinkCaptureInspectorResult,
} from 'gelato/frontend/src/controllers/states/DocumentState';
import type {ApplicationState} from 'gelato/frontend/src/controllers/types';

// The singleton instance.
let instance: MicroBlinkCaptureInspector | null = null;

// To handle licenses nearing expiry:
// 1. Contact MB (slack channel) to extend the expiry of the licenses below
// 2. Go to https://developer.microblink.com/license and search for each license below
// 3. Copy the new license key
// 4. Update the date shown here and the jira ticket https://jira.corp.stripe.com/browse/IDPROD-7533
// Next expiry date: 10/26/2025
// NOTE: To give yourself enough time, make sure to set the expiration/ticket date earlier (1 month or more)
// TODO(cjmisenas, RUN_IDXP, 2025-09-26): Update the Microblink capture SDK license.

// LIC03000060954 (*.stripe.me)
const DEV_LICENSE = `sRwCAAsqLnN0cmlwZS5tZQZsZXlKRGNtVmhkR1ZrVDI0aU9qRTNNakk1TnpBd09ETXlNVEVzSWtOeVpXRjBaV1JHYjNJaU9pSmlZakUzTnpFd055MDJZVEpsTFRRMVpEQXRPVFZtTnkwMVltSTNPVGsxT1RBeE1UQWlmUT09aqB1HUTeg0utAKv9jcjNNZGkykQLFK1ms3gOJ7N9Y7lD/HhB6AVWkiiV0fWGVU4UAEE08Bou4tKNzCEGBIXBL24xfMTmBmcLPF3PW++tTGPrJPqcwuvSCAdlJEhJ`;

// LIC01000059211 (stripe.test)
const TEST_LICENSE = `sRwCAAtzdHJpcGUudGVzdAZsZXlKRGNtVmhkR1ZrVDI0aU9qRTNNakk1TmprNE9EazNPVGNzSWtOeVpXRjBaV1JHYjNJaU9pSmlZakUzTnpFd055MDJZVEpsTFRRMVpEQXRPVFZtTnkwMVltSTNPVGsxT1RBeE1UQWlmUT09dbvfhkMUTyXkmG9bxgDLvpY8NZrShEJ7Str7Erb+/xXCONFcyzBxDMe2+dMsCtEOXzJ2uARSV55wroQX8zc6dnq/bNHx1jdUFsR6F0GBDi8Npo/jKmSSUMF/Go8f`;

// LIC03000060955 (*.stripe.com)
const PROD_LICENSE = `sRwCAAwqLnN0cmlwZS5jb20GbGV5SkRjbVZoZEdWa1QyNGlPakUzTWpJNU56QXhOek0xTURrc0lrTnlaV0YwWldSR2IzSWlPaUppWWpFM056RXdOeTAyWVRKbExUUTFaREF0T1RWbU55MDFZbUkzT1RrMU9UQXhNVEFpZlE9PQ6hotxtYn82/91GNXVuj4oYJMOJa0SERb2knpQdV1C/bRbA9tG8taFkGDtGOXi2mOKiGq0ox1Z9hlcNB8A9qteeCz9KtPsdNI9M87t5PIyCyUpeIX5Wn/TSQeVrtA==`;

// Additional padding around the bounding box returned by the ML model as a
// percentage of the image width and height.
const MARGIN_PERCENTAGE = 0.05;

const ANALYZER_SETTINGS: Partial<AnalyzerSettings> = {
  // The minimum dpi is adjusted to optimal value for the provided input
  // resolution to enable capture of all document groups.
  adjustMinimumDocumentDpi: true,
  captureSingleSide: true,
  captureStrategy: 'single-frame',
  documentFramingMargin: MARGIN_PERCENTAGE,
  // The amount of allowed padding the fingers can take up in the image as a
  // percentage of the image width and height.
  handOcclusionThreshold: 0.05,
  // Use `normal` blur policy to allow for more images to be captured.
  // Note that also have our own BlurInspector to filter out really blurry
  // images.
  blurPolicy: 'normal',
  glarePolicy: 'normal',
  // Additional padding around the bounding box returned by the ML model as a
  // percentage of the image width and height.
  keepMarginOnTransformedDocumentImage: true,
  // Thresholds used to classify the frame as too dark or too bright.
  // Allowed values are from 0 to 1.
  lightingThresholds: {
    tooBrightThreshold: 0.95,
    tooDarkThreshold: 0.9,
  },
  // Whether to return an image of a cropped and perspective-corrected document.
  returnTransformedDocumentImage: true,
  // Some tilt is allowed.
  tiltPolicy: 'normal',
};

// The size of the empty image data. (e.g. (new ImageData(1, 1)).data.length).
const EMPTY_IMAGE_DATA_SIZE = 4;

/**
 * Convert the image to 1080p.
 * @param image The image to convert.
 * @returns The scale to convert the image to 1080p.
 */
export function compute1080pScale(image: ImageFrame): number {
  const {width, height, orientation, placeholder} = image;

  if (placeholder) {
    return 1;
  }

  if (orientation === 'landscape') {
    // 1920x1080.
    if (height >= 1080) {
      return 1;
    }
    return 1080 / height;
  }

  // portrait / 1080x1920.
  if (width >= 1080) {
    return 1;
  }

  return 1080 / width;
}

/**
 * Whether the image should be scaled to 1080p.
 * Microblink SDK requires the image to be at least 1080p otherwise it would
 * return the "analyzer-settings-unsuitable-error" analysis error.
 *
 * @param image The image to check.
 * @returns Whether the image should be scaled to 1080p.
 */
export function shouldScaleTo1080p(image: ImageFrame): boolean {
  const {width, height, orientation, placeholder} = image;
  if (placeholder) {
    return false;
  }

  if (orientation === 'landscape') {
    // 1920x1080.
    return height < 1080;
  }

  // portrait
  // 1080x1920.
  return width < 1080;
}

/**
 * Create the initial input image for the Microblink SDK.
 * @param appState The application state.
 * @param inspectionState The inspection state.
 * @returns The initial input values.
 */
export async function createInitialInputValues(
  appState: Readonly<ApplicationState>,
  inspectionState: Readonly<InspectionState>,
): Promise<{
  inputImage: ImageFrame;
  settings: Partial<AnalyzerSettings>;
}> {
  const {inputImage: originalInputImage} = inspectionState;
  const {workingInputMethod: method} = appState.document;
  const location = inspectionState.idInspectorResult?.location;
  const {fileUpload, cameraManualCapture} = InputMethod;

  let inputImage: ImageFrame;
  let settings = ANALYZER_SETTINGS;

  if (needsMoreMargins(appState, inspectionState)) {
    const {width, height} = originalInputImage;
    // Microblink SDK requires the document to have some margins othewise it
    // would return the status "camera-too-close" without capturing the
    // document. The workaround is to add a margin around the image to ensure
    // that the document is captured.
    inputImage = await originalInputImage.addPadding(
      width * MARGIN_PERCENTAGE,
      height * MARGIN_PERCENTAGE,
      '#ffffff',
    );
    // Ensure that the cropeed image does not include the additional margins
    // added.
    settings = {
      ...ANALYZER_SETTINGS,
      documentFramingMargin: 0,
      keepMarginOnTransformedDocumentImage: false,
    };
  } else if (
    location &&
    (method === fileUpload || method === cameraManualCapture)
  ) {
    // In file upload or manual capture mode, there's no real-time
    // feedback to guide user to move the document into view. Therefore,
    // if the location is provided by the ID inspector, we could
    // programmatically "move to" the location to improve the capture result.

    const {
      topLeft: [x, y],
      dimensions: [w, h],
    } = location;

    const {width, height} = originalInputImage;

    // Add a margin around the location.
    const margin = Math.max(
      50,
      width * MARGIN_PERCENTAGE,
      height * MARGIN_PERCENTAGE,
    );

    inputImage = await originalInputImage.crop(
      x - margin,
      y - margin,
      w + margin * 2,
      h + margin * 2,
    );
  } else {
    inputImage = await originalInputImage.clone();
  }

  return {
    inputImage,
    settings,
  };
}

/**
 * Get the license key based on the environment.
 * @returns The license key.
 */
function getLicenseKey(): string {
  if (process.env.NODE_ENV === 'production') {
    // (*.stripe.com)
    return PROD_LICENSE;
  }
  if (
    process.env.IS_CI ||
    process.env.NODE_ENV === 'test' ||
    process.env.PUPPETEER
  ) {
    // (stripe.test)
    return TEST_LICENSE;
  }
  // (*.stripe.me)
  return DEV_LICENSE;
}

/**
 * Check the input image needs more margins around the document.
 * @param appState - The application state.
 * @param inspectionState - The inspection state.
 * @returns A boolean indicating whether to add margin around the image.
 */
export function needsMoreMargins(
  appState: Readonly<ApplicationState>,
  inspectionState: Readonly<InspectionState>,
): boolean {
  const {document} = appState;
  if (document.workingInputMethod !== 'file_upload') {
    // Only in file upload mode, the user should be allowed to use the cropped
    // image without margins.
    return false;
  }

  const {inputImage, idInspectorResult} = inspectionState;
  const location = idInspectorResult?.location;
  if (!location) {
    // We can't determine if the image is cropped if the location is not
    // provided.
    return false;
  }

  const {width, height} = inputImage;
  const x = width * MARGIN_PERCENTAGE;
  const y = height * MARGIN_PERCENTAGE;

  const container: Rectangle = {
    dimensions: [width - x * 2, height - y * 2],
    topLeft: [x, y],
  };

  if (containsRectangle(container, location)) {
    // The document has enough margin around the location.
    return false;
  }

  // The document does not have enough margin around the location, assuming
  // the image is cropped.
  return true;
}

export default class MicroBlinkCaptureInspector extends BaseInspector<
  [Readonly<ApplicationState>, Readonly<InspectionState>],
  Readonly<InspectionState>
> {
  _sdk: DirectApi | null = null;

  static displayName = 'MicroBlinkCaptureInspector';

  constructor() {
    super(MicroBlinkCaptureInspector.displayName);
  }

  /**
   * Whether the inspector is supported in the current environment.
   * This check should be performed before calling build() to avoid loading
   * unnecessary resources.
   */
  static isSupported(): boolean {
    if (!BaseInspector.isWebGLSupported()) {
      return false;
    }

    const {structuredClone, WebAssembly} = window;

    if (typeof structuredClone !== 'function') {
      return false;
    }

    if (typeof WebAssembly !== 'object') {
      return false;
    }

    return true;
  }

  /**
   * Get the singleton instance of the inspector.
   */
  static getInstance(): MicroBlinkCaptureInspector {
    if (!instance) {
      instance = new MicroBlinkCaptureInspector();
    }
    return instance;
  }

  /**
   * @implements {BaseInspector}
   */
  protected async buildImpl(): Promise<void> {
    const sdk = await loadSDK();
    this._sdk = sdk;
  }

  /**
   * @implements {BaseInspector}
   */
  protected async warmUpImpl(): Promise<void> {
    const sdk = this._sdk;
    if (sdk) {
      // Warm up the SDK by analyzing an empty image.
      const image = await ImageFrame.createEmpty(300, 300, '#000');
      const imageData = await image.toImageData();
      await sdk.analyze(imageData);
      await sdk.resetCapture();
      image.dispose();
    }
  }

  /**
   * @implements {BaseInspector}
   */
  protected async detectImpl(
    appState: Readonly<ApplicationState>,
    inspectionState: Readonly<InspectionState>,
  ): Promise<Readonly<InspectionState>> {
    let inspectedImage: ImageFrame | null = null;
    try {
      const sdk = this._sdk;
      if (!sdk) {
        // detctImpl should not be called if the inspector is not built
        // correctly.
        throw new Error(
          `${ErrorCode.microBlinkInspectorError}: sdk is missing`,
        );
      }

      let imageScale = 1;

      const {inputImage, settings} = await createInitialInputValues(
        appState,
        inspectionState,
      );

      inspectedImage = inputImage;

      if (shouldScaleTo1080p(inspectedImage)) {
        // Image is too small to be analyzed by the Microblink SDK. We'll scale
        // the image to 1080p.
        imageScale = compute1080pScale(inspectedImage);
        const scaledImage = await inspectedImage.scaleTo(imageScale);
        inspectedImage.dispose(); // Clear the old image.
        inspectedImage = scaledImage; // Use the new scaled image.
      }

      const defaultResult: MicroBlinkCaptureInspectorResult = {
        detectedImage: null,
        documentType: null,
        feedback: null,
        inspectedImage,
        isValid: false,
        location: null,
      };

      const imageData = await inspectedImage.toImageData();

      // Remember the length of the image data before the analysis.
      const imageDataLengthStart = imageData.data.length;

      if (
        inspectedImage.placeholder ||
        imageDataLengthStart <= EMPTY_IMAGE_DATA_SIZE
      ) {
        // Return if the frame did not contain any pixels.
        // This rarely happens and this should recover by itself.
        analytics.track('imageDataIsEmpty', {
          height: imageData.height,
          imageFrame: inspectedImage.toString(),
          inspectorName: this.name,
          width: imageData.width,
        });
        return produce(inspectionState, (draft) => {
          draft.microBlinkCaptureInspectorResult = defaultResult;
        });
      }

      // We're doing single-frame capture, so we need to reset the capture
      // before we start.
      await sdk.resetCapture();

      const prevSettings = await sdk.getSettings();
      if (
        prevSettings.keepMarginOnTransformedDocumentImage !==
        settings.keepMarginOnTransformedDocumentImage
      ) {
        // The settings for margins have changed, we need to update the
        // settings.
        await sdk.updateSettings(settings);
      }

      const analysis = (await sdk.analyze(imageData)) as FrameAnalysisError &
        FrameAnalysisResult;

      const analysisError = analysis.error;

      const sentryBreadcrumbData = {
        cameraInfo: String(inspectionState.cameraInfo?.cameraLabel || null),
        imageData: [
          '[',
          Object.prototype.toString.call(imageData),
          imageData.width,
          imageData.height,
          ']',
        ].join('-'),
        imageDataLengthEnd: String(imageData.data.length),
        imageDataLengthStart: String(imageDataLengthStart),
        inspectedImage: inspectedImage.toString(),
        inspectedImageDisposed: String(inspectedImage.disposed),
        inspectionDisposed: String(inspectionState.disposed),
        iterationCount: String(inspectionState.iterationCount),
      };

      if (imageScale !== 1) {
        // Track the image scaling event for debugging purposes.
        analytics.track('imageScaled', {
          after: inspectedImage.toString(),
          before: inputImage.toString(),
          scale: imageScale,
          error: analysisError,
        });
      }

      if (analysisError) {
        // This error means that we're not able to analyze the image.
        // This could be fixed by:
        // 1. Fix the format or size of the image.
        // 2. Fix the settings of the analyzer.
        const message = `analysis.error.${analysisError}`;

        Sentry.addBreadcrumb({
          category: 'MicroBlinkCaptureInspector',
          data: sentryBreadcrumbData,
          level: Sentry.Severity.Error,
          message,
        });

        if (analysisError === 'analyzer-settings-unsuitable-error') {
          // We don't know the exact reason why the settings are unsuitable.
          // We'll send the data to the server for further investigation.
          analytics.track('mbAnalyzerSettingsUnsuitableError', {
            sentryBreadcrumbData: JSON.stringify(sentryBreadcrumbData),
          });
        }

        const error = new Error(
          `${ErrorCode.microBlinkInspectorError}:${message}`,
        );

        // We're not able to analyze the image, we'll return the default result
        // and try to recover by itself.
        handleException(error, message);

        if (imageScale !== 1) {
          // Track the image scaling event for debugging purposes.
          // We wanna know if the image was scaled and the error was
          // caused by the scaling.
          analytics.track('imageScaled', {
            after: inspectedImage.toString(),
            before: inputImage.toString(),
            error: analysisError,
            scale: imageScale,
          });
        }

        return produce(inspectionState, (draft) => {
          draft.microBlinkCaptureInspectorResult = defaultResult;
        });
      }

      const {frameAnalysisStatus, frameCaptured} = analysis;

      const frameFeedback = computeFeedbackByFrameAnalysis(
        appState,
        frameAnalysisStatus,
      );

      if (!frameCaptured || frameFeedback) {
        // Return if the frame is not captured or there's any feedback that
        // should be addressed by the user.
        let feedback = frameFeedback;

        if (imageScale !== 1) {
          // Track the image scaling event for debugging purposes.
          // We wanna know if the image was scaled and the feedback was
          // caused by the scaling.
          analytics.track('imageScaled', {
            after: inspectedImage.toString(),
            before: inputImage.toString(),
            feedback: frameFeedback,
            scale: imageScale,
          });
        }

        if (imageScale && feedback === Feedback.tooBlurry) {
          // If the image was scaled, it's possible that the image could
          // appear blurry. We should ignore the feedback "too_blurry" since
          // we're not certain if the bluriness is caused by the scaling or
          // user's input.
          // If we did not scale the image, MB would have returned the
          // "analyzer-settings-unsuitable-error" error instead, which
          // effectively returns the same default result.
          feedback = null;
        }

        return produce(inspectionState, (draft) => {
          draft.microBlinkCaptureInspectorResult = {
            ...defaultResult,
            feedback,
          };
        });
      }

      const result = await sdk.getResult();
      const {firstCapture, documentGroup, completenessStatus} = result;

      if (!firstCapture) {
        // Image is not captured, this should not happen.
        const message = 'result.first_capture_is_missing';
        Sentry.addBreadcrumb({
          category: 'MicroBlinkCaptureInspector',
          data: {
            ...sentryBreadcrumbData,
            completenessStatus: String(completenessStatus),
            documentGroup: String(documentGroup),
          },
          level: Sentry.Severity.Error,
          message,
        });
        throw new Error(`${ErrorCode.microBlinkInspectorError}:${message}`);
      }

      const capturedImageData = firstCapture.transformedImageResult;
      if (!capturedImageData) {
        // This should not happen.
        const message = 'result.captured_image_data_is_missing';
        Sentry.addBreadcrumb({
          category: 'MicroBlinkCaptureInspector',
          data: {
            ...sentryBreadcrumbData,
            completenessStatus: String(completenessStatus),
            documentGroup: String(documentGroup),
            side: firstCapture.side,
          },
          level: Sentry.Severity.Error,
          message,
        });
        throw new Error(`${ErrorCode.microBlinkInspectorError}:${message}`);
      }

      const detectedImage = await ImageFrame.fromImageData(capturedImageData);
      const documentType = documentGroupToDocumentType(documentGroup);
      const sideFeeback = computeFeedbackBySideCaptureResult(
        appState,
        firstCapture,
      );

      return produce(inspectionState, (draft) => {
        draft.microBlinkCaptureInspectorResult = {
          ...defaultResult,
          detectedImage,
          documentType,
          feedback: sideFeeback,
          isValid: sideFeeback === null,
        };
      });
    } catch (ex) {
      // Release the memory before throwing the error.
      inspectedImage?.dispose();
      throw ex;
    }
  }

  /**
   * @implements {BaseInspector}
   */
  protected async disposeImpl(): Promise<void> {
    const sdk = this._sdk;
    if (sdk) {
      this._sdk = null;
      await sdk.terminateWorker();
    }
  }
}

/**
 * Helper function to load the @microblink/capture module.
 * @returns The @microblink/capture module loaded.
 */
async function loadSDK(): Promise<DirectApi> {
  try {
    const startTime = Date.now();
    const {createDirectApi} = await loadMicroblinkCaptureMoodule();

    // The file of the resources are generated by the build command that runs
    // gelato/frontend/config/copy_npm_resources.js.
    // If you update the version of the @microblink/capture module, you need to
    // update the version in the copy_npm_resources.js file.
    const resourceUrl = getAssetPath('microblink-capture-1.2.2');

    const sdk = await createDirectApi({
      analyzerSettings: ANALYZER_SETTINGS,
      licenseKey: getLicenseKey(),
      resourceUrl,
    });
    reportMetric({
      metric: 'gelato_frontend_mb_wasm_load_time',
      operation: 'timing',
      value: Date.now() - startTime,
    });
    reportMetric({
      metric: 'gelato_frontend_mb_wasm_supported',
      operation: 'count',
      value: 1,
    });
    return sdk;
  } catch (ex) {
    reportMetric({
      metric: 'gelato_frontend_mb_wasm_not_supported',
      operation: 'count',
      value: 1,
    });
    const cause = asError(ex);
    throw new Error(`${ErrorCode.microBlinkInspectorError}: ${cause.message}`, {
      cause,
    });
  }
}

/**
 * Load the @microblink/capture module.
 * @returns The module loaded.
 */
async function loadMicroblinkCaptureMoodule(): Promise<
  typeof import('@microblink/capture')
> {
  return new Promise(async (resolve, reject) => {
    try {
      const module: any = await import('@microblink/capture');
      resolve(module);
    } catch (ex) {
      const cause = asError(ex);
      const message = `${ErrorCode.microBlinkInspectorError}: ${cause.message}`;

      // Throw a custom error that is observed by the application.
      const error = new Error(message, {cause});
      reject(error);
    }
  });
}

/**
 * Convert the detected document group to document type.
 * @param documentGroup The detected document group.
 * @returns The document type converted.
 */
function documentGroupToDocumentType(
  documentGroup: DocumentGroup,
): DocumentTypes | null {
  if (documentGroup === 'dl') {
    return 'driving_license';
  }
  if (documentGroup === 'passport' || documentGroup === 'visa') {
    return 'passport';
  }
  if (documentGroup === 'id' || documentGroup === 'passport-card') {
    return 'id_card';
  }
  return null;
}

/**
 * Convert the frame analysis status to feedback.
 * @param frameAnalysisStatus The frame analysis status.
 * @returns The feedback converted.
 */
function computeFeedbackByFrameAnalysis(
  appState: Readonly<ApplicationState>,
  frameAnalysisStatus: FrameAnalysisStatus,
): FeedbackValue | null {
  const {
    framingStatus,
    lightingStatus,
    blurStatus,
    glareStatus,
    occlusionStatus,
  } = frameAnalysisStatus;

  if (occlusionStatus === 'occluded') {
    return Feedback.fingerObstruction;
  }

  if (framingStatus === 'camera-angle-too-steep') {
    return Feedback.angleTooSteep;
  }

  if (framingStatus === 'no-document') {
    const {workingInputMethod} = appState.document;
    if (workingInputMethod === InputMethod.cameraAutoCapture) {
      // In auto capture mode, tell user to move the document into view when no
      // document is detected.
      return Feedback.moveIntoView;
    } else {
      return Feedback.noDocument;
    }
  }

  if (framingStatus === 'camera-too-far') {
    return Feedback.moveCloser;
  }

  if (framingStatus === 'camera-too-close') {
    return Feedback.moveFarther;
  }

  if (framingStatus === 'camera-orientation-unsuitable') {
    // This is fine. We can still capture the document even the image's
    // orientation is not aligned with the camera's orientation.
    return null;
  }

  if (framingStatus === 'document-too-close-to-frame-edge') {
    return Feedback.moveFarther;
  }

  if (blurStatus === 'blur-detected') {
    return Feedback.tooBlurry;
  }

  if (glareStatus === 'glare-detected') {
    return Feedback.tooMuchGlare;
  }

  if (lightingStatus === 'too-dark') {
    return Feedback.tooDark;
  }

  if (lightingStatus === 'too-bright') {
    return Feedback.tooBright;
  }

  return null;
}

/**
 * Convert the side capture result to feedback.
 * @param appState The application state.
 * @param result The side capture result.
 * @returns The feedback converted.
 */
function computeFeedbackBySideCaptureResult(
  appState: Readonly<ApplicationState>,
  result: SideCaptureResult,
): FeedbackValue | null {
  const {side} = result;
  const {workingSide} = appState.document;

  if (side === 'front' && workingSide === 'back') {
    // Use the wrong side of the document.
    return Feedback.useFrontSide;
  } else if (side === 'back' && workingSide === 'front') {
    // Use the wrong side of the document.
    return Feedback.useBackSide;
  }
  return null;
}
