import clsx from 'clsx';
import * as React from 'react';
import {Helmet} from 'react-helmet';
import {defineMessages, injectIntl} from 'react-intl';

import {SkippableDataCollectionPage} from 'gelato/frontend/src/components/DynamicForm/SkippablePage';
import ErrorContainer from 'gelato/frontend/src/components/ErrorContainer';
import SelfieVerificationMethodSelectBox from 'gelato/frontend/src/components/FaceUpload/SelfieVerificationMethodSelectBox';
import LoadingBox from 'gelato/frontend/src/components/LoadingBox';
import Message from 'gelato/frontend/src/components/Message';
import ThemableButton from 'gelato/frontend/src/components/ThemableButton';
import useUpdateConsentMutation from 'gelato/frontend/src/graphql/mutations/useUpdateConsentMutation';
import {PAGE_TITLE_MESSAGE} from 'gelato/frontend/src/lib/constants';
import {SetPageCardPropsContext} from 'gelato/frontend/src/lib/contexts';
import {nextDataPageForSession} from 'gelato/frontend/src/lib/dataRouting';
import {
  useFeatureFlags,
  useRouter,
  useConnectIframe,
  useSession,
} from 'gelato/frontend/src/lib/hooks';
import getRouter from 'gelato/frontend/src/lib/localRouter';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import Button from 'sail/Button';
import {Title} from 'sail/Text';

import styles from './selfie_verification_method_select.module.css';

import type {
  Flags,
  IndividualFields,
  SelfieVerificationMethod,
} from '@stripe-internal/data-gelato/schema/types';
import type {Session, SetPageCardProps} from 'gelato/frontend/src/lib/contexts';
import type {PageProps} from 'gelato/frontend/src/lib/localRouter';
import type {IntlShape} from 'react-intl';

// These are the fields we are attempting to collect on this page
const PAGE_FIELDS = ['selfie_verification_method'] as Array<IndividualFields>;

const messages = defineMessages({
  selfieVerificationMethodTitle: {
    id: 'pages.selfie_verification_method.selfie_verification_method_header',
    description: 'Header label in selfie verification select page',
    defaultMessage: `Select how you’d like to be verified`,
  },
  nextButton: {
    id: 'pages.selfie_verification_method.nextButton',
    description: 'Next button text',
    defaultMessage: 'Next',
  },
  goBack: {
    id: 'pages.selfie_verification_method.goBack',
    description: 'Allow the user to go back a page',
    defaultMessage: 'Go back',
  },
});

type SelfiePageProps = {
  session: Session;
  flags: ReadonlyArray<Flags> | null | undefined;
  // experiments: ?Experiments,
  setPageCardProps: SetPageCardProps;
  updateConsent: ReturnType<typeof useUpdateConsentMutation>[0];
  isConnectIframe: boolean;
};
type Props = PageProps & SelfiePageProps;

type State = {
  selfieVerificationMethod: SelfieVerificationMethod | null | undefined;
  pending: boolean;
  error: never | null | undefined;
  throwErrorInRender: boolean | null | undefined;
};

class SelfieVerificationMethodSelectPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selfieVerificationMethod: null,
      pending: false,
      error: null,
      throwErrorInRender: null,
    };
  }

  componentDidMount() {
    const {setPageCardProps} = this.props;
    setPageCardProps({
      showBackLink: true,
      showPageHeader: true,
      size: 'single',
    });
  }

  /**
   * Updates & Network Requests
   */

  // tryTo consolidates async error handling. Goals here are:
  // manage `pending` state safely, catch and surface errors
  // appropriately. Caught errors are set as state so they
  // can be rendered as an error notice or rethrown up to ErrorProvider.
  //
  // tryTo params
  // message - error message to post to sentry in case of failure
  // silent - if true, will swallow any errors, not even reporting them to sentry
  // action - tryTo calls this async method and returns the result
  // pending - if true will update pending state on this comment while `action` is being executed
  // throwInRender - in case of error set this state to component to throw in the render thread any
  //   error that was raised calling `action`
  // clearState - if true, then clear error and throwErrorInRender state from component after calling action
  //   you may set this to be false if `action` will have a side effect that causes the component to
  //   unmount like perform a routing change

  tryTo = async ({
    message,
    silent,
    pending,
    throwInRender,
    action,
    clearState,
  }: {
    message: string;
    silent: boolean;
    pending: boolean;
    throwInRender: boolean;
    action: () => any;
    clearState: boolean;
  }) => {
    try {
      this.setState({pending});
      const res = await action();
      if (clearState) {
        this.setState({error: null, throwErrorInRender: null});
      }
      return res;
    } catch (error: any) {
      handleException(error, message);
      !silent && this.setState({error, throwErrorInRender: throwInRender});
      pending && this.setState({pending: false});
      throw error;
    }
  };

  // Update our consent data
  updateVerificationMethod = async ({
    message,
    silent,
    method,
  }: {
    message: string;
    silent: boolean;
    method: SelfieVerificationMethod | null | undefined;
  }) => {
    return this.tryTo({
      message,
      silent,
      pending: !silent,
      throwInRender: false,
      clearState: true,
      action: async () => {
        const {updateConsent} = this.props;
        return updateConsent({
          variables: {
            consentData: {
              selfieVerificationMethod: method,
            },
          },
        });
      },
    });
  };

  // Set state
  setVerificationMethod = (
    selfieVerificationMethod: SelfieVerificationMethod,
  ) => {
    this.setState({selfieVerificationMethod});
  };

  // handleChange is called when the user has updated the country selector
  // or document type selector, but has not clicked Next yet.
  handleChange = async ({method}: {method?: SelfieVerificationMethod}) => {
    await this.updateVerificationMethod({
      message: 'handleChange auto-save',
      silent: true,
      method,
    });
  };

  // handleNext is called when the user clicks a button to proceed
  // with identity document collection.
  handleNext = async () => {
    const {selfieVerificationMethod: method} = this.state;
    const {data} = await this.updateVerificationMethod({
      message: 'handleNext save',
      silent: false,
      method,
    });

    await this.tryTo({
      message: 'handleNext redirect',
      pending: true,
      silent: false,
      throwInRender: true,
      clearState: false,
      action: async () => {
        const {
          updateConsent: {session},
        } = data;
        const nextPage = await nextDataPageForSession(session);
        getRouter().push(nextPage);
      },
    });

    return true;
  };

  handleGoBack = () => window.history.go(-1);

  render() {
    const {selfieVerificationMethod, pending, error, throwErrorInRender} =
      this.state;
    const {isConnectIframe} = this.props;

    if (throwErrorInRender) {
      throw error;
    }
    return (
      <>
        <ErrorContainer error={error} />
        <div className={styles.documentSelect}>
          <div>
            <Title className={clsx(styles.title)}>
              <Message {...messages.selfieVerificationMethodTitle} />
            </Title>
            <SelfieVerificationMethodSelectBox
              value={selfieVerificationMethod}
              onChange={(selfieVerificationMethod) => {
                this.setVerificationMethod(selfieVerificationMethod);
                return this.handleChange({method: selfieVerificationMethod});
              }}
            />
          </div>
          <div className={styles.buttonContainer}>
            <ThemableButton
              color="blue"
              size="large"
              width="maximized"
              disabled={selfieVerificationMethod === null}
              label={<Message {...messages.nextButton} />}
              onClick={this.handleNext}
              pending={pending}
            />
            {isConnectIframe && (
              <Button
                className={styles.goBack}
                label={<Message {...messages.goBack} />}
                icon="arrowLeft"
                iconPosition="left"
                onClick={this.handleGoBack}
                width="maximized"
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

export function SelfieVerificationMethodSelect({
  intl: {formatMessage},
  ...props
}: PageProps & {
  intl: IntlShape;
}) {
  const router = useRouter();
  const session = useSession();
  const setPageCardProps = React.useContext(SetPageCardPropsContext);
  const isConnectIframe = useConnectIframe();
  const flags = useFeatureFlags();
  const [updateConsent] = useUpdateConsentMutation();

  if (!session) {
    return <LoadingBox />;
  }

  return (
    <SkippableDataCollectionPage session={session} pageFields={PAGE_FIELDS}>
      <Helmet>
        <title>{formatMessage(PAGE_TITLE_MESSAGE)}</title>
      </Helmet>
      <SelfieVerificationMethodSelectPage
        {...props}
        flags={flags}
        router={router}
        session={session}
        updateConsent={updateConsent}
        setPageCardProps={setPageCardProps}
        isConnectIframe={isConnectIframe}
      />
    </SkippableDataCollectionPage>
  );
}

export default injectIntl(SelfieVerificationMethodSelect);
