import {dispute} from '@sail/icons/react/Icon';
import {ButtonGroup} from '@sail/ui';
import * as React from 'react';

import Button from 'gelato/frontend/src/components/ButtonV2';
import messages from 'gelato/frontend/src/components/Invalid/messages';
import Message from 'gelato/frontend/src/components/Message';
import {OTPMode} from 'gelato/frontend/src/components/OTPVerification';
import {nextDataPageForSession} from 'gelato/frontend/src/lib/dataRouting';
import {
  useBranding,
  useConnectIframe,
  useReturnUrl,
  useRouter,
  useSession,
} from 'gelato/frontend/src/lib/hooks';
import {isInIframe, postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import {
  shouldShowCloseButton,
  hasiOSSheetCloseWindowHandler,
} from 'gelato/frontend/src/lib/windowHelpers';

const Description = (props: {mode: OTPMode}) => {
  const {mode} = props;
  const branding = useBranding();
  const isConnectIframe = useConnectIframe();
  const returnUrl = useReturnUrl();
  const {platformName} = branding || {};

  if (mode === OTPMode.email) {
    if (!returnUrl && !shouldShowCloseButton(isConnectIframe)) {
      return (
        <Message
          {...messages.otpDeclinedBodyEmailNoReturnUrl}
          values={{platformName}}
        />
      );
    } else {
      return <Message {...messages.otpDeclinedBodyEmailV2} />;
    }
  } else if (!returnUrl && !shouldShowCloseButton(isConnectIframe)) {
    return (
      <Message
        {...messages.otpDeclinedBodyPhoneNoReturnUrl}
        values={{platformName}}
      />
    );
  } else {
    return <Message {...messages.otpDeclinedBodyPhoneV2} />;
  }
};

const Footer = (props: {mode: OTPMode}) => {
  const {mode} = props;
  const branding = useBranding();
  const isConnectIframe = useConnectIframe();
  const returnUrl = useReturnUrl();
  const {platformName} = branding || {};
  const router = useRouter();
  const session = useSession();

  React.useEffect(() => {
    postIframeEvent('STRIPE_IDENTITY_ERROR', {
      type: 'user_action',
      code:
        mode === OTPMode.email
          ? 'email_verification_declined'
          : 'phone_otp_declined',
    });
  });

  const handleClose = () => {
    // tell the Stripe.js iframe to close
    postIframeEvent('STRIPE_IDENTITY_CLOSE');

    // tell the iOS sheet to close
    if (hasiOSSheetCloseWindowHandler()) {
      // @ts-expect-error - TS2339 - Property 'webkit' does not exist on type 'Window & typeof globalThis'.
      window.webkit.messageHandlers.closeWindow.postMessage(null);
    }
  };

  const proceedCallback = React.useCallback(async () => {
    if (session) {
      const nextPage = await nextDataPageForSession(session);
      router.push(nextPage);
    }
  }, [router, session]);

  let exitButton = null;
  let bodyLink = null;

  if (mode === OTPMode.email) {
    bodyLink = (
      <Button onClick={proceedCallback}>
        <Message {...messages.otpDeclinedBodyEmailLink} />
      </Button>
    );
  } else {
    bodyLink = (
      <Button onClick={proceedCallback}>
        <Message {...messages.otpDeclinedBodyPhoneLink} />
      </Button>
    );
  }

  if (returnUrl && !isInIframe()) {
    exitButton = (
      <Button href={returnUrl} type="secondary">
        <Message {...messages.failureLink} values={{platformName}} />
      </Button>
    );
  } else if (shouldShowCloseButton(isConnectIframe)) {
    exitButton = (
      <Button onClick={handleClose} type="secondary">
        <Message {...messages.failureLinkNoReturnUrl} />
      </Button>
    );
  }

  return (
    <ButtonGroup direction="column" css={{gap: 'space.150'}}>
      {bodyLink}
      {exitButton}
    </ButtonGroup>
  );
};
const Title = (props: {mode: OTPMode}) => {
  const {mode} = props;
  if (mode === OTPMode.email) {
    return <Message {...messages.otpDeclinedHeadingEmail} />;
  } else {
    return <Message {...messages.otpDeclinedHeadingPhone} />;
  }
};

export const generateOTPDeclinedContent = (mode: OTPMode) => {
  return {
    icon: dispute,
    description: <Description mode={mode} />,
    footer: <Footer mode={mode} />,
    title: <Title mode={mode} />,
  };
};
