import {view, css} from '@sail/ui';
import Color from 'color';
import * as React from 'react';

import {DEFAULT_BRANDING_BUTTON_COLOR} from 'gelato/frontend/src/lib/constants';
import {useFeatureFlags} from 'gelato/frontend/src/lib/hooks';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

const Styles = {
  account: css({
    alignY: 'center',
    borderColor: 'border',
    borderRadius: 'large',
    borderStyle: 'solid',
    borderWidth: 'small',
    boxSizing: 'border-box',
    gap: 'space.150',
    height: 'space.700',
    paddingX: 'medium',
    stack: 'x',
    width: 'fill',
  }),
  avatar: css({
    alignX: 'center',
    alignY: 'center',
    borderRadius: 'rounded',
    height: 'xlarge',
    stack: 'x',
    width: 'xlarge',
  }),
  email: css({
    color: 'text',
    font: 'body.small',
  }),
  error: css({
    color: 'color.border.form.error',
    font: 'body.small',
  }),
};

type Props = {
  email: string;
  end?: React.ReactNode;
  error?: string;
  showAvatar?: boolean;
};

export default function AccountBox({
  email,
  end,
  error,
  showAvatar = true,
}: Props) {
  const {appState} = useAppController();

  // Use a lighter version of the button color to define the background of
  // the avatar.
  const [h, s] = Color(
    appState.session?.branding?.buttonColor || DEFAULT_BRANDING_BUTTON_COLOR,
  )
    .hsl()
    .array();
  const avatarBackgroundColor = Color.hsl(h, s, 90).toString();

  const avatarBackground = css({
    backgroundColor: avatarBackgroundColor,
  });

  const avatarTextColor = css({
    color:
      appState.session?.branding?.buttonColor || DEFAULT_BRANDING_BUTTON_COLOR,
  });

  return (
    <view.div
      uses={[Styles.account]}
      css={{
        borderColor: error ? 'feedback.critical.emphasized' : 'form',
      }}
    >
      {showAvatar && (
        <view.div uses={[Styles.avatar, avatarBackground]}>
          <view.div uses={[avatarTextColor]}>{email[0].toUpperCase()}</view.div>
        </view.div>
      )}
      <view.div uses={[Styles.email]}>{email}</view.div>
      {end}
    </view.div>
  );
}
