export type EventCallback<T extends Array<unknown>> = (...event: T) => void;

export class SimpleEventEmitter<T extends Array<unknown>> {
  #listeners: Set<EventCallback<T>>;

  constructor() {
    this.#listeners = new Set();
  }

  addListener(listener: EventCallback<T>): void {
    this.#listeners.add(listener);
  }

  removeListener(listener: EventCallback<T>): void {
    this.#listeners.delete(listener);
  }

  notify(...event: T): void {
    // copying the current set of listeners to avoid an inifinite loop
    // that might happen if one of the listners add another one in turn
    // See SimpleEventEmitter.test.tsx for some examples.
    // eslint-disable-next-line no-restricted-syntax
    for (const listener of new Set(this.#listeners)) {
      listener(...event);
    }
  }

  clearListeners(): void {
    this.#listeners.clear();
  }
}
