import type {RefObject, ReactNode} from 'react';
import {
  useRef,
  useMemo,
  useEffect,
  useImperativeHandle,
  useCallback,
  useContext,
} from 'react';
import type {Placement} from '@react-types/overlays';
import {
  FocusScope,
  useControlledState,
  focusSafely,
  useFocusManager,
  getFocusableTreeWalker,
  useFocusWithin,
  useInteractOutside,
  isElementInChildOfActiveScope,
} from '@sail/react-aria';
import * as React from 'react';
import {
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  shift,
  size,
  flip,
  offset,
  hide,
  inline,
  autoUpdate,
  useInteractions,
  useRole,
  useDismiss,
  useClick,
  useFocus,
  useHover,
  safePolygon,
  limitShift,
  FloatingTree,
  FloatingNode,
} from '@floating-ui/react';
import type {
  ElementProps,
  FloatingContext,
  Strategy as FloatingStrategy,
  Placement as FloatingPlacement,
} from '@floating-ui/react';
import type {Token} from '@sail/engine';
import {
  createViewConfig,
  view,
  useTokenValue,
  assignProps,
  createView,
  dynamic,
  animate,
  AnimatePresence,
  media,
} from '@sail/engine';
import type {FocusManager} from '@sail/react-aria';
import type {FocusableElement} from '@react-types/shared';
import type {View} from '../view';
import {css} from '../css';
import {Backdrop} from './Backdrop';
import {Layer} from './layers/Layer';
import {tokens} from '../tokens';
import {toFloatingPlacement} from './utils/toFloatingPlacement';
import {useProps} from '../hooks/useProps';
import {visuallyHidden} from '../utilStyles/visuallyHidden';
import {useIsMobileAndTouchCheck} from './hooks/useIsMobileAndTouchCheck';
import {BottomSheetOverlay} from './BottomSheetOverlay';
import {PopoverHeader} from './PopoverHeader';
import {bottomSheetStyles, bottomSheetAnimation} from '../utilStyles/sheet';
import {useNestedSheets} from '../hooks/useNestedSheets';
import {PopoverLayer as DeprecatedPopoverLayer} from '../deprecated/components/layers/PopoverLayer';
import {ButtonConfig} from './Button';
import {SelectConfig} from './Select';
import {TextFieldConfig} from './TextField';
import {SelectFieldConfig} from './SelectFieldConfig';
import {useUiConfig} from '../providers/UiConfigProvider';
import {PopoverConfig} from './PopoverConfig';

type DeprecatedPopoverProps = {
  /** @deprecated @ignore */
  shouldFlip?: boolean;
  /** @deprecated @ignore */
  shouldUpdatePosition?: boolean;
  /** @deprecated @ignore */
  shouldRespectScrollBoundary?: boolean;
  /** @deprecated @ignore */
  shouldCloseOnBlur?: boolean;
};

export type FlipValue = boolean;
export type OffsetValue = number;
export type HiddenValue = {referenceHidden: boolean};

export type ShiftValue =
  | boolean
  | Partial<{
      /**
       * The margin between the Popover and the containing boundary, typically the viewport.
       */
      padding: number;
      /**
       * Whether to shift the Popover based on the default boundary (the viewport)
       * or an alternate boundary (clipping ancestors of the reference element).
       */
      altBoundary: boolean;
    }>;

export type DismissValue =
  | boolean
  | Partial<{
      /**
       * Enables or disables dismissal of the Popover by interaction events such as
       * clicking outside the Popover or pressing the escape key.
       */
      enabled: boolean;
      /**
       * Whether to dismiss the Popover upon pressing the escape key.
       * @defaultValue true
       */
      escapeKey: boolean;
      /**
       * Whether to dismiss the Popover upon pressing outside of the Popover and reference elements.
       * @defaultValue true
       */
      outsidePress: boolean | ((event: MouseEvent) => boolean);
      /**
       * Whether to dismiss the Popover upon focus moving outside of the Popover and reference elements.
       * @defaultValue true
       */
      outsideFocus: boolean;
      /**
       * When dealing with nested elements, this determines whether the dismissal
       * bubbles or stops at the current node.
       */
      bubbles?:
        | boolean
        | {
            escapeKey?: boolean;
            outsidePress?: boolean;
          };
    }>;

export type RoleValue =
  | 'tooltip'
  | 'dialog'
  | 'alertdialog'
  | 'menu'
  | 'listbox'
  | 'grid'
  | 'tree';
export type ClickValue =
  | boolean
  | Partial<{
      /**
       * Whether to open the Popover when clicking the reference element.
       */
      enabled: boolean;
      /**
       * Whether to toggle the open state with repeated clicks.
       */
      toggle: boolean;
    }>;
export type FocusValue =
  | boolean
  | Partial<{
      /**
       * Whether to open the Popover when the reference element gains focus.
       */
      enabled: boolean;
      /**
       * Whether the open state only changes only if the focus event is considered visible (`:focus-visible` CSS selector).
       */
      visibleOnly: boolean;
    }>;

export type HoverValue =
  | boolean
  | Partial<{
      /**
       * Whether to open the Popover when hovering over the reference element.
       */
      enabled: boolean;
      /**
       * Wait before changing the open state of the popover when hovering over the reference element.
       */
      delay: number | Partial<{open: number; close: number}>;
      /**
       * Wait until the pointer is at rest before changing the open state of the popover.
       */
      restMs: number;
    }>;

export type SizeValue = Partial<{
  /**
   * Whether to limit the Popover's max-width to the available space
   * between the reference element and the viewport.
   */
  maxWidth: 'fill';
  /**
   * Whether to limit the Popover's max-height to the available space
   * between the reference element and the viewport.
   */
  maxHeight: 'fill';
  /**
   * Whether to limit the Popover should match the width of the reference element
   * regardless of its content. Common for menus and dropdowns.
   */
  matchWidth: boolean;
  /**
   * When limiting the popover to the available space, the amount of
   * additional space to leave between the popover and the viewport.
   */
  padding: number;
  /**
   * Whether to resize the Popover based on the default boundary (the viewport)
   * or an alternate boundary (clipping ancestors of the reference element).
   */
  altBoundary: boolean;
}>;

type PopoverControlProps =
  // Controlled
  | {
      /**
       * Controls whether the popover is open or closed (controlled).
       * If not provided, the component will manage its own open/closed state.
       */
      open: boolean;
      showOnPress?: never;
      showOnFocus?: never;
      showOnHover?: never;
    }
  // Uncontrolled
  | {
      open?: never;
      /**
       * Enables or disables whether the popover will open or close when clicking the reference element.
       */
      showOnPress?: ClickValue;
      /**
       * Enables or disables whether the popover will open or close when the reference element gains or loses focus.
       */
      showOnFocus?: FocusValue;
      /**
       * Enables or disables whether the popover will open or close when hovering over the reference element.
       */
      showOnHover?: HoverValue;
    };

export type PopoverProps = View.IntrinsicElement<
  'div',
  {
    /**
     * Where to place the popover relative to its reference element.
     */
    placement: Placement;
    /**
     * How popover should be positioned relative to the reference element.
     * */
    floatingStrategy?: FloatingStrategy;
    /**
     * Controls the distance between the reference element and the popover.
     */
    offset?: OffsetValue;
    /**
     * Allows the popover to move horizontally or vertically away
     * from the requested placement in order to keep it in view.
     */
    shift?: ShiftValue;
    /**
     * Allows the placement of the popover to switch in order to keep it in view.
     */
    flip?: FlipValue;
    /**
     * Constrains the size of the popover to fit the available space
     * between the reference element and the viewport.
     */
    size?: SizeValue;
    /**
     * Allows the popover to be hidden when the reference element is out of view.
     */
    hide?: HiddenValue;
    /**
     * Improves positioning for inline reference elements that span over multiple lines.
     * @defaultValue true
     */
    inline?: boolean;
    /**
     * Controls whether the popover animates in/out.
     * @defaultValue true
     */
    animate?: boolean;
    /**
     * Controls whether the popover is initially open or closed (uncontrolled).
     */
    defaultOpen?: boolean;
    /**
     * A function that is called when the open state should change.
     */
    onOpenChange?: (open: boolean, event?: Event) => void;
    /**
     * A function that is called when the user indicates that they wish to close the popover,
     * typically by clicking outside the popover or pressing the escape key.
     */
    onClose?: () => void;
    /**
     * Enables or disables dismissal of the Popover by interaction events such as
     * clicking outside the Popover or pressing the escape key.
     */
    dismissible?: DismissValue;
    /**
     * Configure whether base screen reader props will be added to the reference and Popover elements for a given WAI-ARIA role.
     */
    role?: RoleValue;
    /**
     * The popover will be anchored to this reference element.
     * */
    targetRef: RefObject<HTMLElement>;
    /**
     * Contents of the popover
     */
    children: ReactNode;
    /** The available subviews */
    subviews?: View.Subviews<{layer: typeof Layer}>;
    /** title to display in bottom sheet presentation on mobile */
    mobileTitle?: ReactNode;
    /**
     * Whether the popover should use react-aria based dismiss logic on outside focus
     *
     * Related to go/jira/RUN_SAILUI-95 issue where pressed react-aria element in
     * `<Chip />` component where "focusable" element is located inside `<span />`
     * and DOM onBlur event is not triggered (can be triggered only on focused elements).
     *
     * When enabled, uses `useInteractOutside` with `useFocusWithin` to dismiss the popover
     * @private
     */
    useReactAriaDismissOutside?: boolean;
  } & DeprecatedPopoverProps &
    PopoverControlProps
>;

/**
 * Popover option props have either a boolean value, or an object with options.
 * This helper function returns a tuple of the boolean value indicating whether the option is enabled,
 * and the option object itself, defaulting to `{}` if not provided.
 */
const option = <T,>(
  value: boolean | T | undefined,
): [boolean | undefined, T] => {
  if (value === undefined) {
    return [undefined, {} as T];
  }
  if (typeof value === 'boolean') {
    return [value, {} as T];
  }
  return [true, value];
};

/**
 * Strips out deprecated props from the Popover props object and returns
 * the rest.
 */
const replaceDeprecatedProps = <T extends View.RenderProps<PopoverProps>>(
  props: T,
) => {
  const {
    shouldFlip,
    shouldUpdatePosition,
    shouldRespectScrollBoundary,
    shouldCloseOnBlur,
    ...rest
  } = props;

  if (shouldCloseOnBlur !== undefined) {
    rest.dismissible = shouldCloseOnBlur;
  }

  if (shouldFlip !== undefined) {
    rest.flip = shouldFlip;
  }

  return rest;
};

const FocusGuard = createView((props: View.IntrinsicElement<'span'>) => {
  return <view.span hidden tabIndex={0} uses={[visuallyHidden]} {...props} />;
});

const moveFocus =
  <T extends HTMLElement>(ref: RefObject<FocusManager>, dir: 'next' | 'prev') =>
  (event: React.FocusEvent<T>) => {
    const manager = ref.current;
    // first try moving focus within the popover
    const node = dir === 'next' ? manager?.focusFirst() : manager?.focusLast();

    // if the manager didn't find a focusable element within scope,
    // focus the next/previous element outside the popover.
    if (!node) {
      const root = event.currentTarget.getRootNode() as Element;
      const walker = getFocusableTreeWalker(root, {
        tabbable: true,
        from: event.currentTarget,
      });

      const el = dir === 'next' ? walker.nextNode() : walker.previousNode();

      if (el) {
        focusSafely(el as FocusableElement);
      }
    }
  };

/**
 * A portal-friendly version of react-aria's `useInteractOutside` hook, allowing
 * the popover to be dismissed when focus moves outside the popover.
 *
 * `useInteractOutside` uses DOM event delegation to detect clicks outside the popover,
 * but this isn't safe for portalled elements because the event target may not be
 * a direct child of the popover.
 *
 * This hook uses the React tree (instead of the DOM tree) to detect when focus moves
 * outside the popover.
 */
const useFocusOutside = (
  callback: () => void,
  targetElement?: HTMLElement | null,
): Pick<React.HTMLProps<Element>, 'onFocus' | 'onBlur'> => {
  const focusMovedRef = useRef(false);
  const timeoutRef = React.useRef<number>();
  useEffect(() => () => cancelAnimationFrame(timeoutRef.current as number), []);

  return useMemo(
    () => ({
      onFocus() {
        focusMovedRef.current = true;
      },
      // blur on the current element fires before focus on the next element,
      // so we use that to determine if the focus is moving outside the popover
      onBlur(e) {
        focusMovedRef.current = false;

        // Ignore blur events default prevented by children
        if (e.defaultPrevented) {
          return;
        }

        cancelAnimationFrame(timeoutRef.current as number);

        const relatedTarget = e.relatedTarget as HTMLElement;

        const isFocusWithinCurrentTarget =
          e.currentTarget?.contains(relatedTarget) ?? false;

        /**
         * In some cases trigger element might not be inside the current target
         */
        const isFocusWithinTargetElement =
          targetElement?.contains(relatedTarget) ?? false;

        const isFocusWithin =
          isFocusWithinCurrentTarget || isFocusWithinTargetElement;

        /**
         * preventDefault onBlur event if focus is within targeted elements,
         * so parent Popovers don't close on internal focus changes
         */
        if (isFocusWithin) {
          e.preventDefault();
        }

        // Give browser time to focus the next element
        timeoutRef.current = requestAnimationFrame(() => {
          // Check if the new focused element is a child of the original container
          if (!focusMovedRef.current && !isFocusWithin) {
            callback();
          }
        });
      },
    }),
    [callback, targetElement],
  );
};

const useDurationValue = <T,>(token: Token.Value<T>) => {
  return parseInt(useTokenValue(token) as string, 10);
};

const useReactAriaFocusOutside = (
  context: FloatingContext<HTMLDivElement>,
  {enabled = false}: {enabled?: boolean},
): ElementProps => {
  const {
    refs,
    open: isOpen,
    onOpenChange,
    elements: {domReference, floating},
  } = context;

  const isElementWithin = useCallback(
    (element: Element) =>
      domReference?.contains(element) || floating?.contains(element),
    [domReference, floating],
  );

  /**
   * This ref keeps track of whether focus is within the overlay or not.
   * It needs to prevent the overlay from closing when focus moves to a child focus scope.
   * Example: Popover -> Popover -> Button, moving focus to Button should not close the popover.
   */
  const isFocusWithinRef = useRef(false);
  const timeoutRef = useRef<number>();
  useEffect(() => () => cancelAnimationFrame(timeoutRef.current as number), []);

  const onInteractOutside = useCallback(
    (e: React.SyntheticEvent) => {
      /**
       * Do not close the overlay if focus is within the overlay.
       */
      if (isFocusWithinRef.current) {
        return;
      }

      const isElementWithinPopover = isElementWithin(e.target as Element);

      if (!isElementWithinPopover) {
        onOpenChange(false, e.nativeEvent);
      }
    },
    [isElementWithin, onOpenChange],
  );

  // Handle clicking outside the overlay to close it
  useInteractOutside({
    ref: refs.floating,
    onInteractOutside: enabled && isOpen ? onInteractOutside : undefined,
  });

  const {focusWithinProps} = useFocusWithin({
    isDisabled: !enabled,
    onFocusWithinChange: (isFocusWithin) => {
      isFocusWithinRef.current = isFocusWithin;
    },
    onBlurWithin: (e) => {
      const relatedTarget = e.relatedTarget as Element | null;
      // Do not close if relatedTarget is null, which means focus is lost to the body.
      // That can happen when switching tabs, or due to a VoiceOver/Chrome bug with Control+Option+Arrow navigation.
      // Clicking on the body to close the overlay should already be handled by useInteractOutside.
      // https://github.com/adobe/react-spectrum/issues/4130
      // https://github.com/adobe/react-spectrum/issues/4922
      //
      // If focus is moving into a child focus scope (e.g. menu inside a dialog),
      // do not close the outer overlay. At this point, the active scope should
      // still be the outer overlay, since blur events run before focus.
      if (!relatedTarget || isElementInChildOfActiveScope(relatedTarget)) {
        return;
      }

      isFocusWithinRef.current = false;

      cancelAnimationFrame(timeoutRef.current as number);

      const isRelatedTargetWithin = isElementWithin(relatedTarget);

      timeoutRef.current = requestAnimationFrame(() => {
        const isFocusWithin = isFocusWithinRef.current;

        if (!isFocusWithin && !isRelatedTargetWithin) {
          onOpenChange(false, e.nativeEvent);
        }
      });
    },
  });

  return useMemo(() => {
    if (!enabled || !isOpen) {
      return {};
    }

    return {
      floating: focusWithinProps,
      reference: focusWithinProps,
    };
  }, [enabled, isOpen, focusWithinProps]);
};

const translate = (placement: FloatingPlacement) => {
  /* eslint-disable no-nested-ternary */
  const [attachedSide] = placement.split('-');
  const offset = `${tokens.space[150].toString()}`;
  const negativeOffset = `calc(-1 * ${offset})`;
  return `translate(${
    attachedSide === 'left'
      ? offset
      : attachedSide === 'right'
      ? negativeOffset
      : '0'
  }, ${
    attachedSide === 'top'
      ? offset
      : attachedSide === 'bottom'
      ? negativeOffset
      : '0'
  })`;
};

export const PopoverLayerConfig = createViewConfig({
  props: {} as View.RenderProps<PopoverProps & {isMobile: boolean}>,
  name: 'PopoverLayer',
});
const PopoverLayer = PopoverLayerConfig.createView(
  (allProps: View.RenderProps<PopoverProps & {isMobile: boolean}>) => {
    const {
      targetRef,
      placement: reactAriaPlacement,
      offset: offsetOptions,
      flip: flipEnabled,
      shift: shouldShift,
      size: sizeOptions,
      inline: inlineEnabled,
      hide: hideOptions,
      onClose,
      subviews,
      dismissible,
      showOnPress,
      showOnFocus,
      showOnHover,
      role,
      defaultOpen,
      open: controlledOpen,
      isMobile,
      mobileTitle,
      onOpenChange: setControlledOpen,
      floatingStrategy,
      animate: shouldAnimate = true,
      useReactAriaDismissOutside = false,
      ...props
    } = replaceDeprecatedProps(allProps);

    const [open, setOpen] = useControlledState(
      // Here we default the controlled state to `true` if neither `open` nor `defaultOpen` are provided.
      // This provides backwards compatibility by continuing to call `onClose` events but not automatically
      // hiding the popover. This feels a little odd as you might reasonably expect the popover to be
      // uncontrolled by default, but it's the best we can do without breaking backwards compatibility. The
      // drawback is that you must explicitly provide the `defaultOpen` prop if you want the popover to be
      // uncontrolled.
      controlledOpen ?? (defaultOpen === undefined ? true : undefined),
      defaultOpen,
      setControlledOpen,
    );

    const isAndroid =
      typeof navigator !== 'undefined' &&
      /(android)/i.test(navigator.userAgent);

    const padding = parseInt(useTokenValue(tokens.space.xsmall), 10);
    const placement = toFloatingPlacement(reactAriaPlacement);

    const [shiftEnabled, shiftOptions] = option(shouldShift);
    // eslint-disable-next-line prefer-const
    let [dismissEnabled, dismissOptions] = option(dismissible);
    const [clickEnabled, clickOptions] = option(showOnPress);
    const [focusEnabled, focusOptions] = option(showOnFocus);
    const [hoverEnabled, hoverOptions] = option(showOnHover);

    if (isMobile) {
      // disable this for mobile devices since Popover handles this with the Backdrop
      // causes issues with the onClick handler in Chip
      dismissOptions = {...dismissOptions, outsidePress: false};
    }

    const onOpenChange = useCallback(
      (isOpen: boolean) => {
        setOpen(isOpen);
        if (!isOpen) {
          // backwards compatibility for onClose
          onClose?.();
        }
      },
      [setOpen, onClose],
    );

    const nodeId = useFloatingNodeId();

    const floating = useFloating<HTMLDivElement>({
      nodeId,
      placement,
      strategy: floatingStrategy,
      whileElementsMounted: autoUpdate,
      middleware: [
        inlineEnabled && inline(),
        offset(offsetOptions ?? padding),
        hideOptions?.referenceHidden && hide(),
        hide({strategy: 'escaped'}),
        flip(({middlewareData}) => ({
          mainAxis: flipEnabled,
          crossAxis: flipEnabled,
          altBoundary: middlewareData?.hide?.escaped,
          /**
           * The `bestFit` strategy doesn't play nicely when comparing
           * overflows that are very close to each other (e.g. less than 1px difference).
           * When paired with the `altBoundary` option, this can cause the popover to
           * flip into a position that is clipped by the viewport.
           */
          fallbackStrategy: middlewareData?.hide?.escaped
            ? 'initialPlacement'
            : 'bestFit',
        })),
        size({
          padding: sizeOptions?.padding || padding,
          altBoundary: sizeOptions?.altBoundary,
          apply({availableHeight, availableWidth, elements, rects}) {
            // intentionally using style here to avoid generating a new class
            // for each pixel value of width|max{width|height}.
            const style = elements.floating?.style || {};
            if (sizeOptions?.maxWidth) {
              Object.assign(style, {maxWidth: `${availableWidth}px`});
            }
            if (sizeOptions?.maxHeight) {
              Object.assign(style, {maxHeight: `${availableHeight}px`});
            }
            if (sizeOptions?.matchWidth) {
              Object.assign(style, {width: `${rects.reference.width}px`});
            }
          },
        }),
        shift({
          mainAxis: shiftEnabled !== false,
          padding: shiftOptions.padding || padding,
          altBoundary: shiftOptions.altBoundary,
          limiter: limitShift(),
        }),
      ],
      open,
      onOpenChange,
    });

    const {refs, floatingStyles, context, middlewareData} = floating;

    const scope = useRef<FocusManager>(null);

    const shouldDismissOnFocusOutside =
      dismissEnabled && dismissOptions.outsideFocus !== false && !isMobile;

    const dismissOnFocusOutside = useFocusOutside(
      useCallback(() => {
        if (!useReactAriaDismissOutside && shouldDismissOnFocusOutside) {
          onOpenChange(false);
        }
      }, [
        onOpenChange,
        shouldDismissOnFocusOutside,
        useReactAriaDismissOutside,
      ]),
      targetRef.current,
    );

    const interactions = useInteractions([
      useRole(context, {
        enabled: Boolean(role),
        role,
      }),
      useDismiss(context, {
        enabled: dismissEnabled,
        escapeKey: dismissOptions.escapeKey,
        outsidePress: dismissOptions.outsidePress,
        // outsidePressEvent needs to be set to 'click' to ensure compatibility with
        // react-aria's Pressable implementation.
        outsidePressEvent: 'click',
        bubbles: dismissOptions.bubbles,
      }),
      useClick(context, {
        enabled: clickEnabled,
        toggle: clickOptions.toggle,
      }),
      useFocus(context, {
        enabled: focusEnabled,
        keyboardOnly: focusOptions.visibleOnly,
      }),
      useHover(context, {
        enabled: hoverEnabled,
        delay: hoverOptions.delay,
        restMs: hoverOptions.restMs,
        handleClose: safePolygon(),
      }),
      // wire up the reference element to dismiss the popover when it loses focus.
      // This logic is only necessary because the `useDismiss` hook relies on
      // event delegation and react-aria "pressable" elements don't propagate mouse/touch events.
      {reference: dismissOnFocusOutside, floating: dismissOnFocusOutside},
      useReactAriaFocusOutside(context, {
        enabled: useReactAriaDismissOutside && shouldDismissOnFocusOutside,
      }),
    ]);

    useProps(targetRef, interactions.getReferenceProps());

    // set up refs for the trigger element for floating-ui
    useEffect(() => {
      const floatingUiEnabled = !isMobile;

      // floating UI will call `calculatePosition` as long as it has both a floating and reference element
      // we can clear this reference (to avoid calculating position) if switching to mobile
      refs.setReference(floatingUiEnabled ? targetRef.current : null);
    }, [targetRef, refs, isMobile]);

    const easing = useTokenValue(tokens.animation.easing) as string;
    const medium = useDurationValue(tokens.animation.duration.medium);
    const short = useDurationValue(tokens.animation.duration.short);
    const ref = React.useRef<HTMLDivElement>(null);

    const layer = (
      <Layer
        inherits={subviews.layer}
        name={isMobile ? 'modal' : undefined}
        uses={[
          assignProps(
            interactions.getFloatingProps() as React.HTMLAttributes<HTMLDivElement>,
          ),
        ]}
        ref={(el: HTMLDivElement) => {
          // don't run positioning logic within JSDOM environment (speeds up tests)
          // JSDOM doesn't support `getBoundingClientRect` anyway, so positioning logic
          // wouldn't be correct there anyway.
          if (typeof jest === 'undefined') {
            refs.setFloating(el);
          }
        }}
        style={isMobile ? {} : floatingStyles}
        css={{
          visibility:
            hideOptions?.referenceHidden && middlewareData.hide?.referenceHidden
              ? 'hidden'
              : undefined,
        }}
      >
        {isMobile ? (
          <Backdrop
            onPress={() =>
              // Temporary disabling backdrop closures on Android devices until we can fix the root
              // cause, which is onClick and onPress events interacting undesirably.
              !isAndroid && onOpenChange(false)
            }
            uses={[
              animate.inout({
                duration: 150,
                opacity: 0,
                easing: 'ease-in-out',
              }),
            ]}
          />
        ) : null}
        {/* used to coordinate nested popovers */}
        <FloatingNode id={nodeId}>
          <FocusScope restoreFocus>
            <view.div
              // declare this before spreading props so user's test id wins out, but we can
              // still use this test id for sail next tests
              data-testid="popover-layer"
              {...props}
              css={sizeOptions?.maxHeight ? {overflow: 'auto'} : undefined}
              ref={ref}
              uses={[
                dynamic(() => {
                  const manager = useFocusManager();
                  useImperativeHandle(scope, () => manager);
                }),
                dynamic(() => {
                  const {sheetStackProvider} = useNestedSheets(
                    ref,
                    'bottom-sheet',
                  );
                  return [sheetStackProvider];
                }),
                bottomSheetAnimation(!isMobile),
                ...(isMobile
                  ? [
                      ButtonConfig.customize({defaults: {size: 'large'}}),
                      SelectConfig.customize({defaults: {size: 'large'}}),
                      SelectFieldConfig.customize({defaults: {size: 'large'}}),
                      TextFieldConfig.customize({defaults: {size: 'large'}}),
                    ]
                  : []),
                ...(shouldAnimate
                  ? [
                      animate.in({
                        opacity: 0,
                        transform: `scale(0.95) ${translate(
                          floating.placement,
                        )}`,
                        easing,
                        duration: medium,
                        disabled: isMobile,
                      }),
                      animate.out({
                        opacity: 0,
                        transform: `scale(0.95) ${translate(
                          floating.placement,
                        )}`,
                        easing,
                        duration: short,
                        disabled: isMobile,
                      }),
                    ]
                  : []),
                isMobile ? bottomSheetStyles : css({maxHeight: 'inherit'}),
              ]}
            >
              {isMobile ? (
                <>
                  <BottomSheetOverlay />
                  <PopoverHeader
                    onClose={() => onOpenChange(false)}
                    title={mobileTitle}
                  />
                </>
              ) : null}
              {props.children}
            </view.div>
          </FocusScope>
        </FloatingNode>
      </Layer>
    );

    return (
      <>
        {/* focus guards to allow focus to move between the page and the popover */}
        {open ? <FocusGuard onFocus={moveFocus(scope, 'next')} /> : null}
        <AnimatePresence>{context.open ? layer : null}</AnimatePresence>
        {open ? <FocusGuard onFocus={moveFocus(scope, 'prev')} /> : null}
      </>
    );
  },
  {
    css: {
      backgroundColor: 'surface',
      border: '1px solid',
      borderColor: 'border',
      borderRadius: 'medium',
      boxShadow: 'overlay',
      maxHeight: 'inherit',
    },
  },
);

// this is a weird place for this to live but hopefully only temporary
// until we get SUS's iframe/media query stuff sorted. Doesn't feel right in
// the deprecated channel either.
//
// used to swap out the old PopoverLayer in SUS
export const DeprecatedPopoverContext = React.createContext<{
  useDeprecatedPopoverComponents: boolean;
}>({
  useDeprecatedPopoverComponents: false,
});

export const Popover = PopoverConfig.createView(({mobileTitle, ...props}) => {
  const isMobileAndTouch = useIsMobileAndTouchCheck();
  const uiConfig = useUiConfig();
  const isMobile = isMobileAndTouch && !!uiConfig?.enableBottomSheets;
  const parentId = useFloatingParentNodeId();

  const popover = (
    <PopoverLayer
      isMobile={isMobile}
      mobileTitle={mobileTitle}
      css={
        isMobile
          ? {
              [media.query(
                `${media.down(
                  tokens.viewport.mobile,
                )} and (any-pointer: coarse)`,
              )]: {
                maxHeight: 'calc(100% - 40px)',
                overflowY: 'scroll',
                bottom: 'var(--sail-layer-margin-bottom, 0px)',
              },
            }
          : undefined
      }
      {...props}
    />
  );
  const {useDeprecatedPopoverComponents} = useContext(DeprecatedPopoverContext);

  // If there is no ancestor popover, wrap the popover in a FloatingTree
  // so that nested popovers can coordinate dismissal behavior.
  return parentId === null ? (
    <FloatingTree>
      {useDeprecatedPopoverComponents ? (
        <DeprecatedPopoverLayer {...props} />
      ) : (
        popover
      )}
    </FloatingTree>
  ) : (
    popover
  );
});
