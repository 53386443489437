import {IconAsset} from '@sail/icons/types';
import {view, css} from '@sail/ui';
import * as React from 'react';
import {injectIntl} from 'react-intl';

import {generateConsentDeclinedPageContent} from 'gelato/frontend/src/components/Invalid/ConsentDeclinedV2';
import {generateDeviceUnsupportedContent} from 'gelato/frontend/src/components/Invalid/DeviceUnsupportedV2';
import {generateExpiredSessionPageContent} from 'gelato/frontend/src/components/Invalid/ExpiredSessionV2';
import {generateGenericInvalidContent} from 'gelato/frontend/src/components/Invalid/GenericV2';
import {generateOTPDeclinedContent} from 'gelato/frontend/src/components/Invalid/OTPDeclinedV2';
import {generateSanctionedDocumentCountryContent} from 'gelato/frontend/src/components/Invalid/SanctionedDocumentCountryV2';
import {generateUnderConsentAgeContent} from 'gelato/frontend/src/components/Invalid/UnderConsentAgeV2';
import {generateUnsupportedCountryContent} from 'gelato/frontend/src/components/Invalid/UnsupportedCountryV2';
import {generateUnsupportedHandoffDeviceContent} from 'gelato/frontend/src/components/Invalid/UnsupportedHandoffDeviceV2';
import {generateUnsupportedIpCountryContent} from 'gelato/frontend/src/components/Invalid/UnsupportedIpCountryV2';
import {OTPMode} from 'gelato/frontend/src/components/OTPVerification';
import PageCard from 'gelato/frontend/src/components/PageCardV2';
import ThemedIcon from 'gelato/frontend/src/components/ThemedIcon';
import TopNavigationBar from 'gelato/frontend/src/components/TopNavigationBar';
import {getInvalidStates} from 'gelato/frontend/src/controllers/utils/getInvalidStates';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import getBrandingPlatformName from 'gelato/frontend/src/lib/getBrandingPlatformName';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

import type {InvalidState} from 'gelato/frontend/src/controllers/utils/getInvalidStates';
import type {IntlShape} from 'react-intl';

type Props = {
  intl: IntlShape;
};

const {useEffect} = React;
const Styles = {
  invalidPage: css({
    stack: 'y',
    textAlign: 'center',
  }),
  body: css({
    stack: 'y',
    padding: 'large',
  }),
};

export function InvalidPage(props: Props) {
  const {appController, appState} = useAppController();
  const {session} = appState;
  const branding = appController.runtime?.branding;
  const platformName = getBrandingPlatformName(branding);

  const [contentState, setContentState] = React.useState<Record<
    InvalidState,
    boolean
  > | null>(null);

  useEffect(() => {
    setContentState(getInvalidStates(appState));
  }, [appState]);

  useEffect(() => {
    if (contentState) {
      appController.recordInvalidState();
    }
  }, [contentState, appController]);

  // If session is expired, session will be null but contentState.isExpired should still return true
  if (!session || !contentState) {
    return <PageCard loading />;
  }

  let icon;
  let title;
  let description;
  let footer;

  if (contentState.consentDeclined) {
    ({icon, title, description, footer} =
      generateConsentDeclinedPageContent(platformName));
  } else if (contentState.isExpired) {
    ({icon, title, description, footer} = generateExpiredSessionPageContent());
  } else if (contentState.emailOTPDeclined) {
    ({icon, title, description, footer} = generateOTPDeclinedContent(
      OTPMode.email,
    ));
  } else if (contentState.phoneOTPDeclined) {
    ({icon, title, description, footer} = generateOTPDeclinedContent(
      OTPMode.phone,
    ));
  } else if (contentState.unsupportedIpCountry) {
    ({icon, title, description, footer} =
      generateUnsupportedIpCountryContent());
  } else if (contentState.underConsentAge) {
    ({icon, title, description, footer} = generateUnderConsentAgeContent());
  } else if (contentState.sanctionedDocumentCountry) {
    ({icon, title, description, footer} =
      generateSanctionedDocumentCountryContent());
  } else if (contentState.unsupportedCountry) {
    ({icon, title, description, footer} = generateUnsupportedCountryContent());
  } else if (contentState.deviceUnsupported) {
    ({icon, title, description, footer} = generateDeviceUnsupportedContent());
  } else if (contentState.unsupportedHandoffDevice) {
    ({icon, title, description, footer} =
      generateUnsupportedHandoffDeviceContent());
  } else {
    ({icon, title, description, footer} = generateGenericInvalidContent());
  }

  return (
    <>
      <InvalidPageLayoutV2
        icon={icon}
        title={title}
        description={description}
        footer={footer}
      />
    </>
  );
}

const LayoutStyles = {
  iconContainer: css({
    stack: 'y',
    alignX: 'center',
  }),
  footerContainer: css({
    paddingX: 'large',
    paddingBottom: 'large',
    paddingTop: 'small',
  }),
  bodyContainer: css({textAlign: 'center', stack: 'y', gap: 'medium'}),
  titleContainer: css({font: 'heading.large'}),
  descriptionContainer: css({font: 'body.medium'}),
  contentContainer: css({
    stack: 'y',
    gap: 'large',
  }),
  pageCardBody: css({padding: 'large'}),
};

const InvalidPageLayoutV2 = (props: {
  icon: IconAsset | undefined;
  title: JSX.Element | undefined;
  description: JSX.Element | undefined;
  footer: JSX.Element | undefined;
}) => {
  const {icon, title, description, footer} = props;

  const showBody = title || description;

  return (
    <PageCard
      header={<TopNavigationBar />}
      body={
        <view.div uses={[LayoutStyles.contentContainer]}>
          {icon && (
            <view.div uses={[LayoutStyles.iconContainer]}>
              <ThemedIcon icon={icon} size="large" />
            </view.div>
          )}
          {showBody && (
            <view.div uses={[LayoutStyles.bodyContainer]}>
              <view.div uses={[LayoutStyles.titleContainer]}>{title}</view.div>
              <view.div uses={[LayoutStyles.descriptionContainer]}>
                {description}
              </view.div>
            </view.div>
          )}
        </view.div>
      }
      footer={
        <view.div uses={[LayoutStyles.footerContainer]}>{footer}</view.div>
      }
      alignBodyItems="center"
      bodySectionStyles={[LayoutStyles.pageCardBody]}
    />
  );
};

setComponentConfig(InvalidPage, {
  componentName: 'invalid_page',
  isTerminal: true,
  ignoreExpiredSession: true,
});

export default injectIntl(InvalidPage);
