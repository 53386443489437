import {TextField, view, css} from '@sail/ui';
import * as React from 'react';
import {useIntl, defineMessages, injectIntl} from 'react-intl';

import Button from 'gelato/frontend/src/components/ButtonV2';
import Heading from 'gelato/frontend/src/components/HeadingV2';
import NetworkedIdentityReuseSheet from 'gelato/frontend/src/components/Link/ReuseSheet';
import Message from 'gelato/frontend/src/components/Message';
import PageCard from 'gelato/frontend/src/components/PageCardV2';
import {responsiveCss} from 'gelato/frontend/src/components/stylesV2';
import TopNavigationBar from 'gelato/frontend/src/components/TopNavigationBar';
import AppController from 'gelato/frontend/src/controllers/AppController';
import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import {isEmailOTPVerificationRequired} from 'gelato/frontend/src/controllers/states/SessionState';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import getBrandingPlatformName from 'gelato/frontend/src/lib/getBrandingPlatformName';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import Storage from 'gelato/frontend/src/lib/Storage';

import type {PageCardProps} from 'gelato/frontend/src/components/PageCardV2';

const {useCallback} = React;

const messages = defineMessages({
  pageTitle: {
    id: 'verify_auth.page.title',
    description: 'Title for the /verify_auth page',
    defaultMessage: 'Enter your email to get started',
  },
  emailAddressLabel: {
    id: 'verify_auth.email_address.input.label',
    description: 'Label for the email address input field',
    defaultMessage: 'Email Address',
  },
  continueButtonLabel: {
    id: 'verify_auth.continue_button.label',
    description:
      'Label on the button that allows the user to continue through the verification UI',
    defaultMessage: 'Continue',
  },
  pageDescription: {
    id: 'verify_auth.page.description',
    description:
      'Introductory text explaining that the page will collect an email address from the user',
    defaultMessage: `We’ll use this email to identify you to {platformName}.`,
  },
  pageDescriptionVerify: {
    id: 'verify_auth.page.description_verify',
    description:
      'Introductory text explaining that the page will collect an email address from the user and we will send an otp code to verify it',
    defaultMessage:
      'Please enter your email address to receive a verification code. We’ll use this email to identify you to {platformName}.',
  },

  emailPlaceholder: {
    id: 'verify_auth.email_address.input.placeholder',
    description: 'Email address placeholder text.',
    defaultMessage: 'someone@example.com',
  },
});

const Styles = {
  body: css({
    gap: 'medium',
    stack: 'y',
    font: 'body.small',
  }),
  emailInput: css({
    width: 'fill',
  }),
  footer: css({
    paddingX: 'space.250',
    paddingTop: 'xsmall',
    paddingBottom: 'space.250',
  }),
  footerResponsive: responsiveCss({
    compact: {
      paddingBottom: '0',
      paddingTop: '0',
    },
  }),
};

// TODO: Replace this with the handleEndToEndFlowAction in flowsActions
async function handleFlowsContinue(appController: AppController) {
  // early exit if we are already continuing
  if (appController.state.flows.continuing) {
    return;
  }

  if (isEmailOTPVerificationRequired(appController.state)) {
    Storage.setEmailOTPConsent(true);
  }

  await appController.initializeFlowsContinue();
  await appController.handleFlowsContinue();
  const userProvidedEmail =
    appController.state.session?.collectedData?.individual?.email
      ?.userProvidedAddress;

  const shouldLookupConsumerAccount =
    userProvidedEmail &&
    appController.state.networkedIdentity.reuseEnabled &&
    !appController.state.networkedIdentity.accountSearchLoading &&
    !appController.state.networkedIdentity.consumerSession;

  if (shouldLookupConsumerAccount) {
    await appController.lookupConsumerAccount({email: userProvidedEmail});
    await appController.openLayer(NetworkedIdentityReuseSheet);
  } else {
    await appController.start({
      consentAccepted: true,
      reason: 'leave_welcome',
    });
  }
  await appController.finalizeFlowsContinue();
}

function Header(): JSX.Element {
  return <TopNavigationBar />;
}

function Body(): JSX.Element {
  const intl = useIntl();
  const {appState, appController} = useAppController();
  const needsEmailOTP = isEmailOTPVerificationRequired(appState);
  const branding = appController.runtime?.branding;
  const platformName = getBrandingPlatformName(branding);

  const onEmailUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      appController.updateFlowsEmail({email: e.target.value});
    },
    [appController],
  );

  const handleKeydown = React.useCallback(
    // this onKeyDown handler is just to support pressing enter to submit email
    async (event) => {
      if (appState.flows.continuing) {
        return;
      }
      // Pressed the Enter key
      if (event.keyCode === 13) {
        await handleFlowsContinue(appController);
      }
    },
    [appController, appState.flows.continuing],
  );
  const makePageDescription = () => {
    if (needsEmailOTP) {
      return (
        <Message
          {...messages.pageDescriptionVerify}
          platformName={platformName}
        />
      );
    } else {
      return (
        <Message {...messages.pageDescription} platformName={platformName} />
      );
    }
  };

  return (
    <>
      <Heading level={2}>
        <Message {...messages.pageTitle} />
      </Heading>
      <view.div uses={[Styles.body]}>
        {makePageDescription()}
        <view.div uses={[Styles.emailInput]}>
          <TextField
            autoFocus
            label={<Message {...messages.emailAddressLabel} />}
            type="email"
            value={appState.flows.email}
            placeholder={intl.formatMessage(messages.emailPlaceholder)}
            onChange={onEmailUpdate}
            onKeyDown={handleKeydown}
            data-testid="flows-email-input"
          />
        </view.div>
      </view.div>
    </>
  );
}

function Footer(): JSX.Element {
  const {appState, appController} = useAppController();
  const [loading, setLoading] = React.useState(false);

  const onClick = useCallback(async () => {
    setLoading(true);
    await handleFlowsContinue(appController);
    setLoading(false);
  }, [appController]);

  return (
    <view.div uses={[Styles.footer, Styles.footerResponsive]}>
      <Button
        onPress={onClick}
        type="primary"
        disabled={
          !appState.flows.emailIsValid || appState.flows.continuing || loading
        }
        pending={appState.flows.continuing}
        data-testid="flows-submit-email"
      >
        <Message {...messages.continueButtonLabel} />
      </Button>
    </view.div>
  );
}

function VerifyAuthPage(): JSX.Element {
  const props: PageCardProps = {
    header: <Header />,
    body: <Body />,
    footer: <Footer />,
  };

  const {appState, appController} = useAppController();
  const errorMessage = appState?.error?.message;

  React.useEffect(() => {
    if (
      errorMessage === ErrorCode.flowsSessionCreationFailedOther ||
      errorMessage === ErrorCode.flowsSessionCreationRateLimited
    ) {
      appController.routeToPath('/verify_inactive');
    }
  }, [errorMessage, appController]);

  return <PageCard {...props} />;
}

setComponentConfig(VerifyAuthPage, {
  componentName: 'verify_auth',
  skipApp: true,
  v2: true,
});

export default injectIntl(VerifyAuthPage);
