import {view, css} from '@sail/ui';
import * as React from 'react';
import {defineMessages, injectIntl} from 'react-intl';

import ValidationMsg from 'gelato/frontend/src/components/IndividualV2/ValidationMsg';
import Message, {IntlProps} from 'gelato/frontend/src/components/Message';
import TestingBanner from 'gelato/frontend/src/components/TestingBanner';
import {OTPMode} from 'gelato/frontend/src/controllers/states/IndividualState';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import CodePuncher from 'sail/CodePuncher';

import type {MessageDescriptor} from 'react-intl';

const Styles = {
  container: css({
    gap: 'xsmall',
    stack: 'y',
  }),
  body: css({
    stack: 'y',
    font: 'body.small',
    gap: 'medium',
  }),
  title: css({
    font: 'heading.medium.subdued',
  }),
  description: css({
    stack: 'y',
    gap: 'medium',
  }),
};

const messages = defineMessages({
  errorSend: {
    id: 'otp.error.send',
    description:
      'Error message saying that the one time password verification code could not be sent (or resent)',
    defaultMessage: 'Error sending verification code',
  },
  errorCodeInvalid: {
    id: 'otp.error.code.invald',
    description:
      'Error message indicating that the code entered could not be validated',
    defaultMessage: 'Invalid code given',
  },
  emailInstructions: {
    id: 'otp.email.instructions',
    description:
      'Instructions to open the email and enter the code found there in the input on this page',
    defaultMessage: 'Enter the code sent to your email {email} to continue.',
  },
  phoneInstructions: {
    id: 'otp.phone.instructions',
    description:
      'Instructions to open the sms and enter the code found there in the input on this page',
    defaultMessage:
      'Enter the code sent to your phone number ending in {phoneLastFour} to continue.',
  },
  pageHeader: {
    id: 'otp.header',
    description:
      'Header text to indicate that we are sending an OTP verification code',
    defaultMessage: `Confirm it’s you`,
  },
  testmodeBannerTitle: {
    id: 'otp.testmodeBanner.title',
    description: 'title text to indicate that no otp code is sent in testmode',
    defaultMessage: 'No codes sent in testmode',
  },
  testmodeBannerDescription: {
    id: 'otp.testmodeBanner.description',
    description:
      'description text to inform users to use the special code 000000 in testmode',
    defaultMessage:
      'You can use the code 000-000 to mimic success and any other code to mimic failure.',
  },
});

type ErrorTagProps = {
  errorMessage: MessageDescriptor;
};

const ErrorMessage = (props: ErrorTagProps) => {
  const {errorMessage} = props;
  return (
    <view.div css={{paddingLeft: 'space.1', paddingTop: 'xsmall'}}>
      <ValidationMsg>
        <Message {...errorMessage} />
      </ValidationMsg>
    </view.div>
  );
};

const Instructions = ({mode}: {mode: OTPMode}) => {
  const {appController} = useAppController();
  const {session, individual} = appController.state;
  const {email, phone} = individual;
  const emailValue =
    session?.collectedData?.individual?.email?.merchantProvidedAddress ||
    email?.userProvidedAddress ||
    '';
  const sessionPhoneNumber = session?.collectedData?.individual?.phoneNumber;
  const phoneValue =
    sessionPhoneNumber?.merchantProvidedPhoneNumber ||
    sessionPhoneNumber?.userProvidedPhoneNumber ||
    phone?.userProvidedPhoneNumber ||
    '';

  if (mode === OTPMode.email) {
    return (
      <Message
        {...messages.emailInstructions}
        values={{email: emailValue}}
        config={{
          linkify: false,
          inline: false,
          rules: {core: [], block: [], inline: []},
        }}
      />
    );
  } else {
    const phoneLastFour = phoneValue.slice(phoneValue.length - 4);

    return <Message {...messages.phoneInstructions} values={{phoneLastFour}} />;
  }
};

const {useState, useCallback, useEffect} = React;

const OTPPageContent = ({mode, intl}: {mode: OTPMode} & IntlProps) => {
  const {appController} = useAppController();
  const {session, mutation, individual} = appController.state;
  const {error: validateOTPError} = mutation.validateOTP;
  const livemode = session?.livemode;

  const [errorMessage, setErrorMessage] = useState<any>(null);

  const setOtpValue = useCallback(
    async (value) => {
      const data =
        mode === OTPMode.email
          ? {emailOtp: {code: value}}
          : {phoneOtp: {code: value}};
      appController.setIndividualCollectedData(data);
    },
    [appController, mode],
  );

  const validateOTP = useCallback(async () => {
    await appController.validateOTP({mode});
  }, [appController, mode]);

  useEffect(() => {
    if (validateOTPError) {
      setErrorMessage(messages.errorCodeInvalid);
    }
  }, [validateOTPError]);

  const onCodePuncherChange = (value: string) => {
    setOtpValue(value);
    if (value.length === 6) {
      validateOTP();
    }
  };

  const otpValue =
    mode === OTPMode.email
      ? individual.emailOtp?.code
      : individual.phoneOtp?.code;

  return (
    <view.div uses={[Styles.container]}>
      <view.div uses={[Styles.title]}>
        <Message {...messages.pageHeader} />
      </view.div>
      <view.div uses={[Styles.body]}>
        <Instructions mode={mode} />
        <view.div css={{stack: 'y', gap: 'xsmall', font: 'body.small'}}>
          <CodePuncher
            data-testid="otp-input-field"
            aria-label="OTP code"
            value={otpValue}
            autoFocus
            onChange={onCodePuncherChange}
          />
          <view.div css={{width: 'fill', alignX: 'center', stack: 'y'}}>
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
          </view.div>
          {!livemode && (
            <TestingBanner
              titleMessage={messages.testmodeBannerTitle}
              descriptionMessage={messages.testmodeBannerDescription}
            />
          )}
        </view.div>
      </view.div>
    </view.div>
  );
};

export default injectIntl(OTPPageContent);
