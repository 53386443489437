import {SafeLinkOptions, UrlLike, getTargetWindow, isSafeUrl} from './utils';

/**
 * Updates the browsser's session history stack
 * Use this instead of these native methods: `history.pushState()`, `history.replaceState()`
 * @example
 * ```
 * historyPushState({}, "/user-feedback"); // equivalent to history.pushState({}, "", "/user-feedback")
 * ```
 * @param data An object associated with the history entry
 * @param url URL of the history entry. The new URL must be of the same origin as the current URL, otherwise an exception is thrown
 */
export const historyPushState = (
  data: unknown,
  url?: UrlLike | null,
  options?: SafeLinkOptions,
) => {
  if (url && !isSafeUrl(url, options)) {
    return;
  }

  return getTargetWindow(options).history.pushState(data, '', url);
};

/**
 * Updates the browsser's session history stack
 * Use this instead of these native methods: `history.replaceState()`
 * @example
 * ```
 * historyReplaceState({}, "/user-feedback"); // equivalent to history.replaceState({}, "", "/user-feedback")
 * ```
 * @param data An object associated with the history entry
 * @param url URL of the history entry. The new URL must be of the same origin as the current URL, otherwise an exception is thrown
 */
export const historyReplaceState = (
  data: unknown,
  url?: UrlLike | null,
  options?: SafeLinkOptions,
) => {
  if (url && !isSafeUrl(url, options)) {
    return;
  }

  return getTargetWindow(options).history.replaceState(data, '', url);
};
