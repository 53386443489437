import {dispute} from '@sail/icons/react/Icon';
import * as React from 'react';

import Button from 'gelato/frontend/src/components/ButtonV2';
import messages from 'gelato/frontend/src/components/Invalid/messages';
import Message from 'gelato/frontend/src/components/Message';
import {useBranding, useSession} from 'gelato/frontend/src/lib/hooks';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import Storage from 'gelato/frontend/src/lib/Storage';

const Footer = () => {
  const branding = useBranding();
  const {platformName} = branding;
  const refreshUrl = Storage.getRefreshUrl();
  const returnUrl = Storage.getReturnUrl();
  const url = refreshUrl || returnUrl;

  React.useEffect(() => {
    Storage.clearSession();
    Storage.setRefreshUrl(refreshUrl);
    Storage.setReturnUrl(returnUrl);
    postIframeEvent('STRIPE_IDENTITY_ERROR', {
      type: 'authentication_error',
      code: 'session_expired',
    });
  });
  if (url && platformName) {
    return (
      <Button id="refresh-session-link" href={returnUrl}>
        <Message {...messages.failureLink} values={{platformName}} />
      </Button>
    );
  } else {
    return null;
  }
};

const Description = () => {
  const session = useSession();
  const operatingMode = session?.operatingMode;
  if (operatingMode === 'secondary') {
    return <Message {...messages.expiredSecondarySessionBody} />;
  } else {
    return <Message {...messages.expiredSessionBody} />;
  }
};

const Title = () => <Message {...messages.expiredHeading} />;

export const generateExpiredSessionPageContent = () => {
  return {
    icon: dispute,
    title: <Title />,
    description: <Description />,
    footer: <Footer />,
  };
};
