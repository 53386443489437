import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  pageTitle: {
    id: 'link.page.title',
    description: 'Title for the Link login page.',
    defaultMessage: 'Continue with Link',
  },
  postUploadPageTitle: {
    id: 'link.page.postUploadPageTitle',
    description: 'Page title when link auth is shown after document upload',
    defaultMessage: 'Save your ID with Link',
  },
  saveYourId: {
    id: 'link.bottomsheet.saveYourId.button.title',
    description: 'Bottom sheet save your id button title',
    defaultMessage: 'Save your ID',
  },
  loginBodyConvenient: {
    id: 'link.login.body.convenient',
    description: 'Body bullet for the Link login page about convenience',
    defaultMessage:
      'Get verified faster at {merchantName} and everywhere Link is accepted.',
  },
  loginBodySecure: {
    id: 'link.login.body.secure',
    description: 'Body bullet for the Link login page about security.',
    defaultMessage:
      'Link encrypts your ID to secure your personal information.',
  },
  loginBodySecureBottomSheet: {
    id: 'link.login.bottomsheet.secure',
    description: 'Body bullet for the Link login page about security.',
    defaultMessage: 'Your ID is encrypted for your protection.',
  },
  loginBodyStripe: {
    id: 'link.login.body.stripe',
    description: 'Body bullet for the Link login page about Stripe.',
    defaultMessage: 'Link is built by Stripe.',
  },
  learnMore: {
    id: 'link.login.learnMore',
    description: 'Learn more link to learn about Link.',
    defaultMessage: 'Learn more',
  },
  linkTerms: {
    id: 'link.login.terms',
    description: 'Hyperlinked terms for Link login.',
    defaultMessage: 'Terms',
  },
  linkPrivacyPolicy: {
    id: 'link.login.privacyPolicy',
    description: 'Hyperlinked privacy policy for Link login.',
    defaultMessage: 'Privacy Policy',
  },
  linkSignInAcknowledgements: {
    id: 'link.login.signin.acknowlegements',
    description: 'Acknowledgement of Link terms and privacy policy.',
    defaultMessage: 'By logging in, your ID will be saved to Link. {learnMore}',
  },
  linkSignUpAcknowledgements: {
    id: 'link.login.signup.acknowlegements',
    description:
      'Acknowledgment of Link terms and privacy policy for signing up for a Link account.',
    defaultMessage:
      'By saving your ID, you agree to the Link{br}{terms} and {privacyPolicy}. {learnMore}',
  },
  linkBottomSheetSignUpAcknowledgements: {
    id: 'link.login.bottomsheet.signup.acknowlegements',
    description:
      'Acknowledgment of Link terms and privacy policy for signing up for a Link account.',
    defaultMessage:
      'By saving your ID, you agree to the {terms} and acknowledge the {privacyPolicy}. {learnMore}',
  },
  linkSignUp: {
    id: 'link.login.signup',
    description: 'Button text to sign up for Link',
    defaultMessage: 'Save with Link', // mentions 'Save' because we only show the signup page when a document can be saved to a new account
  },
  linkSkipSignUp: {
    id: 'link.login.skipSignUp',
    description: 'Button text to skip Link account sign up or login.',
    defaultMessage: 'Continue without Link',
  },
  linkSkipSave: {
    id: 'link.login.skipSave',
    description: 'Button text to skip saving data to a Link account',
    defaultMessage: 'Not now',
  },
  linkSignIn: {
    id: 'link.login.signin',
    description: 'Button text to sign in to Link',
    defaultMessage: 'Log in',
  },
  linkShareContinue: {
    id: 'link.share.continue',
    description: 'Button text to share data from Link account.',
    defaultMessage: 'Share',
  },
  securelyShareID: {
    id: 'link.share.shareId',
    description: 'Button text to share data from Link account.',
    defaultMessage: 'Share ID',
  },
  linkShareContext: {
    id: 'link.shareId.context',
    description: 'Context about how data will be shared from the Link account.',
    defaultMessage: 'Share your ID with {merchantName}.',
  },
  emailAddress: {
    id: 'link.login.emailAddress',
    description: 'Email address input label.',
    defaultMessage: 'Email Address',
  },
  bottomSheetEmailAddress: {
    id: 'link.bottomSheet.login.emailAddress',
    description: 'Email address input label.',
    defaultMessage: 'Email address',
  },
  emailPlaceholder: {
    id: 'link.login.emailAddressPlaceholder',
    description: 'Email address placeholder text.',
    defaultMessage: 'someone@example.com',
  },
  saveYourIdEmailPlaceholder: {
    id: 'link.bottomsheet.saveYourId.emailAddressPlaceholder',
    description: 'Email address placeholder text.',
    defaultMessage: 'youremail@example.com',
  },
  phoneNumber: {
    id: 'link.login.phoneNumber',
    description: 'Phone number input label.',
    defaultMessage: 'Phone number',
  },
  phoneOTPTitle: {
    id: 'link.otp.phone.title',
    description: 'Page title for phone OTP section',
    defaultMessage: 'Confirm it’s you',
  },
  phoneOTPBody: {
    id: 'link.otp.phone.body',
    description: 'Page body text for phone OTP section',
    defaultMessage: 'Enter the code sent to {phone}',
  },
  emailOTPTitle: {
    id: 'link.otp.email.title',
    description: 'Page title for email OTP section',
    defaultMessage: 'Verify your email',
  },
  emailOTPBody: {
    id: 'link.otp.email.body',
    description: 'Page body text for email OTP section',
    defaultMessage: 'Enter the code sent to {email}',
  },
  shareTitle: {
    id: 'link.share.title',
    description: 'Page title for the section to share data.',
    defaultMessage: 'Select an ID',
  },
  shareOptInLabel: {
    id: 'link.share.optIn.label',
    description: 'Checkbox label for Link account data share page opt in.',
    defaultMessage: 'Save my personal info to Link',
  },
  shareOptInDescription: {
    id: 'link.share.optIn.description',
    description:
      'Checkbox description for Link account data share page opt in.',
    defaultMessage:
      'Verify faster at {merchantName} and thousands of merchants.',
  },
  sharePrivacy: {
    id: 'link.share.privacy',
    description: `Text explaining how the user’s data will be used.`,
    defaultMessage:
      'Data will be stored and may be used according to the {merchantName} {formattedPrivacyPolicyUrl}. {learnMoreLink}.',
  },
  otpError: {
    id: 'link.otp.error',
    description: `Error text for when OTP code doesn’t match expected value.`,
    defaultMessage: `The code your provided didn’t match.`,
  },
  resendOTP: {
    id: 'link.resendOtp',
    description: 'Button link label to resend OTP.',
    defaultMessage: 'Resend the code',
  },
  genericError: {
    id: 'link.error.generic',
    description: 'Error text when a generic error has occurred.',
    defaultMessage: 'An unexpected error occurred, please try again.',
  },
  sessionExpiredError: {
    id: 'link.error.sessionExpired',
    description: 'Error text when the Link account session has expired.',
    defaultMessage: 'Your session has expired! Sending you a new code.',
  },
  incorrectOtpError: {
    id: 'link.error.incorrectOtp',
    description: 'Error text when the user inputs the incorrect OTP code.',
    defaultMessage:
      'Incorrect confirmation code. Double-check the code, or send a new code.',
  },
  incorrectOtpCodeError: {
    id: 'link.error.incorrectOtpCodeError',
    description: 'Error text when the user inputs the incorrect OTP code.',
    defaultMessage: `That code didn’t work. Try again.`,
  },
  maxOtpAttemptsError: {
    id: 'link.error.maxOtpAttempts',
    description:
      'Error text when the user has reached the maximum number of OTP attempts.',
    defaultMessage:
      'You have attempted to confirm code too many times. Please try again later.',
  },
  expiredOtpError: {
    id: 'link.error.expiredOtp',
    description: 'Error text when the OTP verification code has expired.',
    defaultMessage:
      'Confirmation code has expired. A new code has been sent to you.',
  },
  rateLimitExceededError: {
    id: 'link.error.rateLimitExceededError',
    description: 'Error text when the Consumer API endpoints are rate limited',
    defaultMessage:
      'Link is temporarily unavailable. Please continue without Link to proceed.',
  },
  toBeCollected: {
    id: 'link.fields.toBeCollected',
    description: 'Label for when a piece of data needs to be collected.',
    defaultMessage: 'Add new ID',
  },
  passport: {
    id: 'link.fields.document.type.passport',
    description: 'Label for the passport document type.',
    defaultMessage: 'Passport',
  },
  driversLicense: {
    id: 'link.fields.document.type.driversLicense',
    description: 'Label for the drivers license document type.',
    defaultMessage: 'Drivers License',
  },
  idCard: {
    id: 'link.fields.document.type.idCard',
    description: 'Label for the ID card document type.',
    defaultMessage: 'ID Card',
  },
  uploadedOn: {
    id: 'link.fields.document.uploadedOn',
    description: 'Label for the uploaded on date.',
    defaultMessage: 'Uploaded on {date}',
  },
  unavailableTitle: {
    id: 'link.unavailable.title',
    description:
      'Title for the unavailable page when Link networking is not available',
    defaultMessage: 'Link is unavailable',
  },
  unavailableBody: {
    id: 'link.unavailable.body',
    description:
      'Body for the unavailable page when Link networking is not available',
    defaultMessage:
      'An unexpected error occurred and Link is currently unavailable. You can still continue without Link.',
  },
  unavailableCta: {
    id: 'link.unavailable.cta',
    description:
      'CTA for the unavailable page when Link networking is not available',
    defaultMessage: 'Continue',
  },
  testmodeOtpBannerTitle: {
    id: 'link.testmode.otpBanneTitler',
    description:
      'Banner title for when Link is in test mode and OTP is requested.',
    defaultMessage: 'Verification codes are not sent in testmode.',
  },
  testmodeOtpBannerDescription: {
    id: 'link.testmode.otpBannerDescription',
    description:
      'Banner description for when Link is in test mode and OTP is requested.',
    defaultMessage: 'Use the code 000000 to proceed successfully.',
  },
  emailOtpDecline: {
    id: 'link.otp.email.decline',
    description:
      'Text allowing the user to decline verification of this email address and continue.',
    defaultMessage: 'I cannot verify this email',
  },
  phoneOtpDecline: {
    id: 'link.otp.phone.decline',
    description:
      'Text allowing the user to decline verification of this phone number and continue.',
    defaultMessage: 'I cannot verify this phone number',
  },
  reuseBody: {
    id: 'link.reuseSheet.body',
    description: 'Body text for the Networked Identity reuse sheet',
    defaultMessage:
      'Use information you previously saved with your Link account.',
  },
  expirationLabel: {
    id: 'link.share.document.expirationDate',
    description:
      'Text indicating the expiration date of the identity document.',
    defaultMessage: 'Expires {expirationDate}',
  },
  expiredLabel: {
    id: 'link.share.document.expiredDate',
    description:
      'Text indicating that the document has expired as well as the expiration date of the document.',
    defaultMessage: 'Expired {expirationDate}',
  },
  notAccepted: {
    id: 'link.share.document.notAccepted',
    description:
      'Text indicating that the document is not accepted because it is not allowed or not live capture when live capture is required.',
    defaultMessage: 'Not accepted',
  },
  change: {
    id: 'link.share.email.change',
    description: 'Text indicating that the user can change the email address.',
    defaultMessage: 'Change',
  },
  continueWithLinkCta: {
    id: 'link.bottomsheet.continueWithLinkCta.button.title',
    description: 'Bottom sheet continue with link button title',
    defaultMessage: 'Continue',
  },
  reuseContinueCta: {
    id: 'link.reuse.addId.cta',
    description: 'Link reuse add ID CTA',
    defaultMessage: 'Continue',
  },
  signUpWithLink: {
    id: 'link.bottomsheet.signUp.title',
    description: 'Bottom sheet title for signing up with Link',
    defaultMessage: 'Sign up with Link',
  },
  continueWithLinkDescription: {
    id: 'link.bottomsheet.continueWithLink.description',
    description: 'Description text for continue with link screen',
    defaultMessage: 'Verify faster using your saved ID.',
  },
  areYouSure: {
    id: 'link.bottomsheet.areYouSure.title',
    description: 'Title text for are you sure screen',
    defaultMessage: 'Are you sure?',
  },
  areYouSureDescription: {
    id: 'link.bottomsheet.areYouSure.description',
    description: 'Description text for are you sure screen',
    defaultMessage: `Your progress won’t be saved.`,
  },
  backToLink: {
    id: 'link.bottomsheet.areYouSure.button.backToLink',
    description: 'Primary button CTA text for are you sure screen',
    defaultMessage: 'Back to Link',
  },
  continueWithoutLink: {
    id: 'link.bottomsheet.areYouSure.button.continueWithoutLink',
    description: 'Secondary button CTA text for are you sure screen',
    defaultMessage: 'Continue without Link',
  },
  enterEmailSubheader: {
    id: 'link.bottomsheet.saveId.subheader',
    description: 'Subheader text for entering email to save ID',
    defaultMessage: 'Enter your email to sign up or log in.',
  },
  enterPhoneNumberSubheader: {
    id: 'link.bottomsheet.signUp.subheader',
    description: 'Subheader text for entering phone number to save ID',
    defaultMessage: 'Enter your phone number to sign up.',
  },
});
