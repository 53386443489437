import {ApolloLink} from '@apollo/client';
import {stableStringify} from '@sail/utils';
import PrefetchCache from 'src/internal/prefetching/PrefetchCache';
import createMulticastObservable from 'src/internal/prefetching/createMulticastObservable';
import {observableFromPromise} from 'src/internal/prefetching/observableUtils';
import {getOperationType} from 'src/internal/utils/gql';

import type {NextLink, Observable, OperationVariables} from '@apollo/client';
import type {InternalOperation} from 'src/internal/apollo/types';
import type {GraphQlConfig} from 'src/internal/config/types';

export type PrefetchLinkConfig = NonNullable<GraphQlConfig['prefetch']>;

export function calculateOperationCacheKey(
  operationHash: string | undefined,
  variables: OperationVariables,
) {
  if (!operationHash) {
    return null;
  }

  return `${operationHash}:${stableStringify(variables)}`;
}

export default function createPrefetchLink({
  expireAfterMs,
  evictCacheAfterMutations = [],
  cache = new PrefetchCache({expireAfterMs}),
}: PrefetchLinkConfig): ApolloLink {
  const allowlist = new Set(evictCacheAfterMutations);
  const prefetchCache = cache;

  return new ApolloLink(function prefetchLink(
    operation: InternalOperation,
    forward: NextLink,
  ) {
    const {isPrefetchMode, fetchPolicy, prefetchCachePruningSignal} =
      operation.getContext();

    const operationType = getOperationType(operation.query);

    if (operationType === 'query' && fetchPolicy === 'cache-first') {
      return forward(operation);
    }

    if (operationType === 'mutation') {
      return forward(operation).map((response) => {
        if (allowlist.has(operation.operationName)) {
          prefetchCache.clear();
        }

        return response;
      });
    }

    if (operationType !== 'query') {
      return forward(operation);
    }

    const operationHash = calculateOperationCacheKey(
      operation.getContext().operationHash,
      operation.variables,
    );

    if (!operationHash) {
      return forward(operation);
    }

    if (isPrefetchMode) {
      const item = prefetchCache.addItem(
        operationHash,
        prefetchCachePruningSignal,
      );
      let isPrefetchRequestFinished = false;

      const listeners = {
        complete() {
          isPrefetchRequestFinished = true;
        },
        unsubscribe: () => {
          if (!isPrefetchRequestFinished) {
            prefetchCache.getItem(operationHash);
          }
        },
      };

      if (item.queue.length === 1) {
        // Handle case the entry in new in the cache
        const subscription = forward(operation).subscribe({
          next: function prefetchLinkNext(result) {
            prefetchCache.resolve(operationHash, result);
          },
          error: function prefetchLinkError(error) {
            prefetchCache.reject(operationHash, error);
          },
        });

        return createMulticastObservable(
          createCachedObservableFromPromise(
            item.promise,
            function cachedObservableUnsubscribe() {
              // If the prefetch request is aborted we reject it to remove it
              // from the cache.
              if (subscription && !isPrefetchRequestFinished) {
                prefetchCache.reject(
                  operationHash,
                  new Error('Aborted request'),
                );
                subscription.unsubscribe();
              }
            },
          ),
          listeners,
        );
      }
      // Handle case the entry was already found in cache
      operation.setContext({isDeduplicated: true});

      return createMulticastObservable(
        createCachedObservableFromPromise(item.promise),
        listeners,
      );
    }

    const promiseOrValue = prefetchCache.getItem(operationHash);

    if (promiseOrValue) {
      operation.setContext({isPrefetchHit: true});

      return createCachedObservableFromPromise(promiseOrValue);
    }

    return forward(operation);
  });
}

type ObservablesCache<T extends object> = WeakMap<
  Promise<T> | T,
  Observable<T>
>;

const observablesCache: ObservablesCache<object> = new WeakMap();

function createCachedObservableFromPromise<T extends object>(
  promiseOrValue: Promise<T> | T,
  unsubscribe?: () => void,
): Observable<T> {
  const typedObservableCache = observablesCache as ObservablesCache<T>;

  const cachedObservable = typedObservableCache.get(promiseOrValue);

  if (cachedObservable) {
    return cachedObservable;
  }

  const observable = createMulticastObservable(
    observableFromPromise(promiseOrValue),
    {unsubscribe},
  );

  typedObservableCache.set(promiseOrValue, observable);

  return observable;
}
