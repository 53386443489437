import type {IReports} from '@sail/observability';

export type StorageEvent =
  | {
      type: 'clear';
    }
  | {
      type: 'remove';
      key: string;
    }
  | {
      type: 'set';
      key: string;
      value: string;
    };

export type StorageEventCallback = (event: StorageEvent) => void;

type StorageType = 'localStorage' | 'sessionStorage';

type StorageMethods = 'clear' | 'getItem' | 'key' | 'removeItem' | 'setItem';

const patchStoragePropertyByType = <Prop extends StorageMethods>(
  storage: Storage,
  type: StorageType,
  property: Prop,
  reports: IReports,
  callback: (...parameters: Parameters<Storage[Prop]>) => void,
): void => {
  const descriptor = Object.getOwnPropertyDescriptor(
    storage.constructor.prototype,
    property,
  );

  if (descriptor && descriptor.configurable) {
    Object.defineProperty(storage.constructor.prototype, property, {
      ...descriptor,
      value(...params: Parameters<Storage[Prop]>) {
        descriptor.value?.call(this, ...params);

        if (this === storage) {
          callback(...params);
        }
      },
    });
  } else {
    reports.warning(`Cannot patch storage, descriptor is not configurable`, {
      project: 'sail_core',
      extras: {type, property},
    });
  }
};

export default function patchBrowserStorage(
  type: StorageType,
  notifier: StorageEventCallback,
  reports: IReports,
): void {
  if (
    typeof globalThis !== 'undefined' &&
    typeof globalThis[type] === 'object'
  ) {
    const storage = globalThis[type];

    if (!storage?.constructor) {
      reports.warning(`Cannot patch storage`, {
        project: 'sail_core',
        extras: {type},
      });

      return;
    }

    patchStoragePropertyByType(
      storage,
      type,
      'setItem',
      reports,
      (key: string, value: string) => {
        notifier({
          type: 'set',
          key,
          value,
        });
      },
    );

    patchStoragePropertyByType(
      storage,
      type,
      'removeItem',
      reports,
      (key: string) => {
        notifier({
          type: 'remove',
          key,
        });
      },
    );

    patchStoragePropertyByType(storage, type, 'clear', reports, () => {
      notifier({
        type: 'clear',
      });
    });
  }
}
