import {LocalStorage} from '@stripe-internal/stripe-cookies';
import createLocalStorageEventListener from 'src/internal/localStorage/createLocalStorageEventListener';

import type {IReports} from '@sail/observability';
import type {SimpleEventEmitter} from '@sail/utils';
import type {StorageEvent} from 'src/internal/browserStorage/patchBrowserStorage';
import type {DataConfig} from 'src/internal/config/types';

type LocalStorageObj = {
  localStorage: LocalStorage;
  eventListener: SimpleEventEmitter<[StorageEvent]>;
};

const emptyConfig = {};
const configMap = new WeakMap<
  NonNullable<DataConfig['browserStorage']>,
  LocalStorageObj
>();

export default function initLocalStorage(
  config: DataConfig['browserStorage'],
  reports: IReports,
): LocalStorageObj {
  const potentialLocalStorageObj = configMap.get(config ?? emptyConfig);

  if (potentialLocalStorageObj) {
    return potentialLocalStorageObj;
  }

  const localStorage = new LocalStorage({
    enforcementMode: config?.enforcementMode,
  });

  const eventListener = createLocalStorageEventListener(reports);

  const cookieObj = {
    localStorage,
    eventListener,
  };

  configMap.set(config ?? emptyConfig, cookieObj);

  return cookieObj;
}
