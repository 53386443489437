import {defineMessages} from 'react-intl';

const welcomeMessages = defineMessages({
  title: {
    id: 'pages.welcome.title',
    description: 'Title on welcome page for external verifications.',
    defaultMessage:
      '{platformName} partners with Stripe for secure identity verification',
  },
  titleStripe: {
    id: 'pages.welcome.titleStripe',
    description:
      'Title on welcome page for internal verifications that does mention Stripe.',
    defaultMessage: 'Verify your identity with Stripe',
  },
  titleNoStripe: {
    id: 'pages.welcome.titleNoStripe',
    description:
      'Title on welcome page for internal verifications that does not mention Stripe.',
    defaultMessage: 'Verify your identity',
  },
  privacy: {
    id: 'pages.welcome.privacy',
    description: `Text explaining how the user’s data will be used.`,
    defaultMessage:
      'Data will be stored and may be used according to the Stripe {stripePrivacyPolicyLink} ' +
      'and the {platformName} {merchantPrivacyPolicyLink}. {learnMoreLink}.',
  },
  privacyConnect: {
    id: 'pages.welcome.privacyConnect',
    description:
      "Text explaining how the user's data will be used when a Connect platform is involved.",
    defaultMessage:
      'Data will be stored and may be used according to the Stripe {stripePrivacyPolicyLink}' +
      ', {additionalPlatformName} {platformPrivacyPolicyLink}, and the {platformName} {merchantPrivacyPolicyLink}. {learnMoreLink}.',
  },
  privacyStripe: {
    id: 'pages.welcome.privacyStripe',
    description:
      "Text explaining how the user's data will be used for internal verifications.",
    defaultMessage: `Your information is used for verification purposes and won’t be used for third-party marketing. {learnMoreLink}.`,
  },
  privacyPolicy: {
    id: 'pages.welcome.privacyPolicy',
    description: 'Link label to privacy policy page',
    defaultMessage: 'Privacy Policy',
  },
  learnMore: {
    id: 'pages.welcome.learnMore',
    description: 'Link label to learn more about privacy.',
    defaultMessage: 'Learn more',
  },
  needIdAndSelfie: {
    id: 'pages.welcome.needIdAndSelfie',
    description:
      "Text explaining to the user what they'll need an identity document and a face picture to complete the verification",
    defaultMessage:
      'Please have a form of identification ready, and be prepared to take a selfie.',
  },
  needId: {
    id: 'pages.welcome.needId',
    description:
      "Text explaining to the user what they'll need an identity document to complete the verification",
    defaultMessage: 'Please have a form of identification ready.',
  },
  needFallback: {
    id: 'pages.welcome.needFallback',
    description:
      "Text explaining to the user what they'll need to share persona information complete the verification",
    defaultMessage:
      "You'll need to share some personal information to complete the verification.",
  },
  getStarted: {
    id: 'pages.welcome.getStarted',
    description: 'Welcome page next button text',
    defaultMessage: 'Get started',
  },
  completeMobileDevice: {
    id: 'pages.welcome.completeMobileDevice',
    description:
      'Button label for completing the verification in a mobile device',
    defaultMessage: 'Complete on mobile device',
  },
  completeOnAnotherDevice: {
    id: 'pages.welcome.completeOnAnotherDevice',
    description:
      'Button label for continuing the verification in another device',
    defaultMessage: 'Complete on another device',
  },
  continueOnThisDevice: {
    id: 'pages.welcome.continueOnThisDevice',
    description: 'Button label for continuing the verification on this device',
    defaultMessage: 'Continue on this device',
  },
});

export default welcomeMessages;
