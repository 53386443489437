import * as React from 'react';

import AnalyticsClientProvider from 'gelato/frontend/src/components/AnalyticsProvider/AnalyticsClientProvider';
import AnalyticsErrorBoundary from 'gelato/frontend/src/components/AnalyticsProvider/AnalyticsErrorBoundary';
import ConnectIframeProvider from 'gelato/frontend/src/components/ConnectIframeProvider';
import PageComponentContent from 'gelato/frontend/src/components/PageComponentContent';
import SentryContextProvider from 'gelato/frontend/src/components/SentryContextProvider';
import ViewportSizeListener from 'gelato/frontend/src/components/ViewportSizeListener';
import calculateUserAgentData, {
  UserAgentData,
} from 'gelato/frontend/src/lib/analytics/calculateUserAgentData';
import {getComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import {DEFAULT_PAGE_CARD_PROPS} from 'gelato/frontend/src/lib/constants';
import {
  BreakpointContext,
  CameraStreamStateContext,
} from 'gelato/frontend/src/lib/contexts';
import {logExperimentSourceComparison} from 'gelato/frontend/src/lib/experiments';
import {
  usePageLayoutPortrait,
  useFeatureFlags,
  useExperiments,
  useSession,
} from 'gelato/frontend/src/lib/hooks';
import {LocalRouter} from 'gelato/frontend/src/lib/localRouter';
import Storage from 'gelato/frontend/src/lib/Storage';
import {WarnOnUnload} from 'gelato/frontend/src/lib/warn';

import type {GraphQlField} from '@sail/data';
import type {ExperimentName} from '@stripe-internal/data-gelato/schema/types';
import type {Props as BackgroundProps} from 'gelato/frontend/src/components/Background';
import type {Props as PageCardProps} from 'gelato/frontend/src/components/PageCard';
import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';
import type {OperatingModePollingQueryData} from 'gelato/frontend/src/graphql/queries/useOperatingModePollingQuery';
import type {
  ConfigurableComponent,
  ExperimentPageRenderer,
} from 'gelato/frontend/src/lib/ComponentConfig';
import type {PageProps} from 'gelato/frontend/src/lib/localRouter';

type Props<T = {}> = {
  Component: React.ComponentType<T & PageProps>;
  pageProps: T & PageProps;
  pageCardProps?: PageCardProps;
  backgroundProps?: BackgroundProps;
  router: LocalRouter;
  params: any;
  userAgentData?: UserAgentData;
};

// The attributes available for the page session should be the union attributes
// fetched by all the GraphQL queries (e.g. GetSessionQuery,
// OperatingModePollingQueryData) that reads the "session".
type PageSession = (
  | GraphQlField<GetSessionQueryData, 'session'>
  | GraphQlField<OperatingModePollingQueryData, 'session'>
) & {
  // Only the static session attributes that do not change during the session
  // can be included here.
  fMobile?: boolean;
};

function getExperimentPageRenderer(
  component: ConfigurableComponent,
): ExperimentPageRenderer {
  const {experimentPageRenderer} = getComponentConfig(component);
  if (experimentPageRenderer) {
    return experimentPageRenderer;
  } else {
    // The component has no experiment, just render it as the control group.
    return {
      experimentName: 'force-control-group-experiment' as ExperimentName,
      control: component,
      treatment: component,
    };
  }
}

function PageComponent<T = {}>({
  Component,
  pageProps,
  pageCardProps: injectedPageCardProps,
  backgroundProps: injectedBackgroundProps,
  router,
  params,
  userAgentData: userAgentDataProp,
}: Props<T>) {
  const [cameraStream, setCameraStream] = React.useState<{
    stream: MediaStream | null | undefined;
    error: Error | null | undefined;
  }>({stream: undefined, error: undefined});

  const pageLayoutPortraitEnabled = usePageLayoutPortrait();
  const flags = useFeatureFlags();
  const experiments = useExperiments();
  const session: PageSession | null | undefined = useSession();
  const loading = !!session;

  const [pageCardProps, setPageCardProps] = React.useState<PageCardProps>({
    ...DEFAULT_PAGE_CARD_PROPS,
    ...injectedPageCardProps,
  });
  const [redirecting, setRedirecting] = React.useState(false);
  const [userAgentData, setUserAgentData] = React.useState<
    UserAgentData | undefined
  >(userAgentDataProp);

  // Default to allowing sessions where IP supportability is unknown
  const ipCountrySupportable = session?.ipCountrySupportable ?? true;
  React.useEffect(() => {
    if (!ipCountrySupportable) {
      router.push('/invalid');
    }
  }, [ipCountrySupportable, router]);

  React.useEffect(() => {
    const sessionExpiresAt = Storage.getSessionExpiresAt();
    if (sessionExpiresAt) {
      const onExpiration = () => {
        router.push('/invalid');
      };
      const sessionExpiresEpoch = parseInt(sessionExpiresAt, 10);
      const delta = sessionExpiresEpoch - Date.now();

      // Session is expired, so forward to /insalid now

      if (delta <= 0) {
        onExpiration();
      } else {
        // Not expired- set a timeout that will fwd to /invalid
        const interval = setTimeout(onExpiration, delta);

        // Remove timeout when this page is torn down.
        return () => clearTimeout(interval);
      }
    }
  }, [router]);

  React.useEffect(() => {
    if (experiments) {
      logExperimentSourceComparison(experiments);
    }
  }, [experiments]);

  const {componentName, isTerminal, skipWarnOnUnload} = getComponentConfig(
    Component as any,
  );

  React.useEffect(() => {
    // All of this checking of isTerminal and compoenentName is done to solve the same problem of
    // wanting to do redirects to specific pages at the PageComponent level but wanting to have some
    // way of doing this redirect only once so we dont get stuck in an infinite redirect loop

    // when the below redirect triggers a re-render with a new child component this will unset
    // redirecting and allow us to actually render the component!
    setRedirecting(false);
    if (!isTerminal && session) {
      if (session.operatingMode === 'waiting' && componentName !== 'handoff') {
        setRedirecting(true);
        router.push('/handoff');
      } else if (session.status === 'canceled') {
        setRedirecting(true);
        router.push('/invalid');
      } else if (
        session.closed &&
        componentName !== 'submit' &&
        // Skip this logic if this page is Butter handoff, which handles this separately
        !(pageLayoutPortraitEnabled && componentName === 'handoff')
      ) {
        setRedirecting(true);
        router.push('/success');
      }
    }
  }, [
    Component,
    componentName,
    experiments,
    flags,
    isTerminal,
    pageLayoutPortraitEnabled,
    router,
    session,
  ]);

  React.useEffect(() => {
    // todo(colinlmcdonald): move this back to app.tsx once validated it works
    async function calculateAndSetUserAgentData() {
      const userAgentData = await calculateUserAgentData();

      if (userAgentData) {
        setUserAgentData(userAgentData);
      }
    }
    calculateAndSetUserAgentData();
  }, [setUserAgentData, flags]);

  const experimentPageRenderer = getExperimentPageRenderer(Component);

  return (
    <CameraStreamStateContext.Provider value={[cameraStream, setCameraStream]}>
      {/* @ts-expect-error - TS2786 - 'WarnOnUnload' cannot be used as a JSX component. */}
      <WarnOnUnload enabled={!isTerminal && !skipWarnOnUnload}>
        <ConnectIframeProvider>
          {(iframe) => (
            <ViewportSizeListener>
              {(breakpoint) => (
                <BreakpointContext.Provider value={breakpoint}>
                  {/* @ts-expect-error - TS2786 - 'SentryContextProvider' cannot be used as a JSX component. */}
                  <SentryContextProvider>
                    <AnalyticsClientProvider userAgentData={userAgentData}>
                      <AnalyticsErrorBoundary>
                        <PageComponentContent
                          experimentPageRenderer={experimentPageRenderer}
                          loading={loading}
                          backgroundProps={injectedBackgroundProps}
                          pageCardProps={pageCardProps}
                          pageProps={pageProps}
                          params={params}
                          redirecting={redirecting}
                          router={router}
                          setPageCardProps={setPageCardProps}
                        />
                      </AnalyticsErrorBoundary>
                    </AnalyticsClientProvider>
                  </SentryContextProvider>
                </BreakpointContext.Provider>
              )}
            </ViewportSizeListener>
          )}
        </ConnectIframeProvider>
      </WarnOnUnload>
    </CameraStreamStateContext.Provider>
  );
}

export default PageComponent;
