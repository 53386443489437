import {FormField, Link, css, view} from '@sail/ui';
import * as React from 'react';
import {useIntl} from 'react-intl';

import AccountBox from 'gelato/frontend/src/components/Link/AccountBox';
import Footer from 'gelato/frontend/src/components/Link/BottomSheet/Footer';
import LinkHeader from 'gelato/frontend/src/components/Link/BottomSheet/LinkHeader';
import NotNow from 'gelato/frontend/src/components/Link/BottomSheet/NotNow';
import {calculateConsumerAccountLookupError} from 'gelato/frontend/src/components/Link/BottomSheet/utils';
import {
  LINK_LINK_GREEN,
  LINK_LINK_HOVER_GREEN,
} from 'gelato/frontend/src/components/Link/constants';
import LinkButton from 'gelato/frontend/src/components/Link/LinkButton';
import {messages} from 'gelato/frontend/src/components/Link/messages';
import Message from 'gelato/frontend/src/components/Message';
import {BottomSheetStep} from 'gelato/frontend/src/controllers/states/NetworkedIdentityState';
import analytics from 'gelato/frontend/src/lib/analytics';
import {LocaleContext} from 'gelato/frontend/src/lib/contexts';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

export default function ContinueWithLink() {
  const {appState, appController} = useAppController();
  const intl = useIntl();
  const {locale} = React.useContext(LocaleContext);
  const [error, setError] = React.useState('');

  const handleChangeConsumerAccount = async () => {
    analytics.action('linkBottomSheetChange');
    appController.setLinkSaveIdBottomStep(BottomSheetStep.SaveId);
  };

  const handleSignIn = async () => {
    setError('');
    try {
      await appController.signInConsumerAccountV2({locale});
    } catch (e: any) {
      setError(e.message);
    }
  };

  const errorMessage = calculateConsumerAccountLookupError(error);

  const formattedError = intl.formatMessage(errorMessage);

  return (
    <>
      <LinkHeader
        title={intl.formatMessage(messages.pageTitle)}
        subtitle={intl.formatMessage(messages.continueWithLinkDescription)}
      />
      <view.div
        css={{
          stack: 'y',
          gap: 'xsmall',
        }}
      >
        <FormField error={error && formattedError}>
          <AccountBox
            showAvatar={false}
            error={error}
            email={
              appState.networkedIdentity.consumerSession?.email_address || ''
            }
            end={
              <Link
                onPress={handleChangeConsumerAccount}
                uses={[Styles.accountBoxEnd]}
                css={{
                  color: LINK_LINK_GREEN,
                  ':hover': {
                    color: LINK_LINK_HOVER_GREEN,
                  },
                }}
              >
                <Message {...messages.change} />
              </Link>
            }
          />
        </FormField>
      </view.div>
      <Footer>
        <Continue handleSignIn={handleSignIn} />
        <NotNow />
      </Footer>
    </>
  );
}

const Styles = {
  accountBoxEnd: css({
    font: 'label.small.emphasized',
  }),
};

type ContinueProps = {
  handleSignIn: () => void;
};

function Continue({handleSignIn}: ContinueProps) {
  const {appState} = useAppController();
  const intl = useIntl();
  return (
    <LinkButton
      onPress={handleSignIn}
      pending={appState.networkedIdentity.consumerAccountLoading}
      data-testid="link-start-sign-in"
    >
      {intl.formatMessage(messages.continueWithLinkCta)}
    </LinkButton>
  );
}
