import * as React from 'react';
import {assignProps, createViewConfig} from '@sail/engine';
import type {View} from '../view';
import {useFieldContexts} from '../hooks/useFieldContexts';

type FormInputTypes = 'input' | 'select' | 'textarea';

// TODO(craigcav): Ideally we'd use View.IntrinsicElement<T> instead of PropsOf<T>,
// but that results in "union type that is too complex to represent" error.
type PropsOf<C extends keyof JSX.IntrinsicElements> =
  JSX.LibraryManagedAttributes<C, React.ComponentPropsWithoutRef<C>>;

type InputProps<T extends FormInputTypes> = PropsOf<T> & {
  /**
   * Allows you to specify the underlying HTML form element to render.
   */
  as: T;
};

export const InputConfig = createViewConfig({
  props: {} as InputProps<FormInputTypes>,
  name: 'Input',
  flattens: true,
  defaults: {as: 'input'},
});

function usePreventWheelInteractionProps(
  props: Omit<InputProps<'input'>, 'as'>,
) {
  const {type, onFocus, onBlur} = props;

  return React.useMemo(() => {
    if (type === 'number') {
      const handler = (event: WheelEvent) => {
        const target = event.target as HTMLInputElement;

        target.blur();

        const activeElementAfterBlur = document.activeElement;

        setTimeout(() => {
          if (document.activeElement === activeElementAfterBlur) {
            target.focus();
          }
        }, 0);
      };

      return {
        onFocus: (event: React.FocusEvent<HTMLInputElement>) => {
          event.currentTarget.addEventListener('wheel', handler, {
            passive: false,
          });
          onFocus?.(event);
        },
        onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
          event.currentTarget.removeEventListener('wheel', handler);
          onBlur?.(event);
        },
      };
    }

    return {};
  }, [type, onFocus, onBlur]);
}

const NativeInput = createViewConfig({
  props: {} as Omit<InputProps<'input'>, 'as'>,
  name: 'Input',
  flattens: true,
}).createView((props) => {
  const wheelProps = usePreventWheelInteractionProps(props);

  return <input {...props} {...wheelProps} />;
});

/**
 * Create interactive controls to accept data from users.
 */
export const Input = InputConfig.createGenericView(
  <T extends FormInputTypes = 'input'>({
    as,
    ...props
  }: View.RenderProps<InputProps<T>>) => {
    const Element = as as React.ElementType;

    if (as === 'input') {
      return <NativeInput {...props} />;
    }

    return <Element {...props} />;
  },
  {
    uses: [assignProps((props) => useFieldContexts(props))],
  },
);
