type ProxyTarget = {
  __ACCESSED_FIELDS__: Set<string>;
  [key: string | number]: unknown;
};

const formatPrefix = (prefix: string, key: string): string => {
  return `${prefix}${prefix && '.'}${key}`;
};

// Recursively wraps the object in a Proxy that keeps track of field accesses
export const wrapWithProxy = (obj: any): any => {
  if (obj == null || typeof obj !== 'object') {
    return obj;
  }

  const proxyTarget: ProxyTarget = Object.defineProperty(
    Array.isArray(obj) ? [] : {},
    '__ACCESSED_FIELDS__',
    {
      value: new Set(),
      enumerable: false,
      configurable: true,
    },
  ) as ProxyTarget;

  const initialKeys = new Set(Object.keys(obj));

  for (const key of initialKeys) {
    proxyTarget[key] = wrapWithProxy(obj[key]);
  }

  return new Proxy(proxyTarget, {
    get: (target: ProxyTarget, key, rec): string[] => {
      // use a set to prevent duplicates, but return an array for external ease of use
      if (key === '__ACCESSED_FIELDS__') {
        return [...target.__ACCESSED_FIELDS__];
      }

      // keep track of accesses
      if (
        key in target &&
        // @ts-expect-error - TS2345 - Argument of type 'string | symbol' is not assignable to parameter of type 'string'.
        initialKeys.has(key) &&
        typeof target.__ACCESSED_FIELDS__.add === 'function'
      ) {
        // @ts-expect-error - TS2345 - Argument of type 'string | symbol' is not assignable to parameter of type 'string'.
        target.__ACCESSED_FIELDS__.add(key);
      }

      return Reflect.get(target, key, rec);
    },
  });
};

export const extractAccesses = (
  proxy: any,
  ignoreFields: Array<string> = [],
  prefix = '',
): string => {
  if (
    proxy == null ||
    typeof proxy !== 'object' ||
    typeof proxy.__ACCESSED_FIELDS__ !== 'object'
  ) {
    return prefix;
  }

  const accesses = new Set();

  for (const key of proxy.__ACCESSED_FIELDS__) {
    if (ignoreFields.includes(key)) {
      accesses.add(formatPrefix(prefix, key));
    } else if (Array.isArray(proxy[key])) {
      let itemsAccessed = false;

      for (let i = 0; i < proxy[key].length; i++) {
        // @ts-expect-error - TS2339: Property '__ACCESSED_FIELDS__' does not exist on type 'any[]'.
        if (proxy[key].__ACCESSED_FIELDS__.includes(i.toString())) {
          accesses.add(
            extractAccesses(
              proxy[key][i],
              ignoreFields,
              formatPrefix(prefix, key),
            ),
          );

          itemsAccessed = true;
        }
      }

      if (!itemsAccessed) {
        accesses.add(formatPrefix(prefix, key));
      }
    } else if (typeof proxy[key] === 'object' && proxy[key] !== null) {
      accesses.add(
        extractAccesses(proxy[key], ignoreFields, formatPrefix(prefix, key)),
      );
    } else {
      accesses.add(formatPrefix(prefix, key));
    }
  }

  return accesses.size !== 0 ? [...accesses].join(',') : prefix;
};
