import * as React from 'react';
import {createViewConfig} from '@sail/engine';
import type {View} from '../view';
import {Input} from './Input';
import type {FormFieldProps} from './FormField';
import {useFormFieldProps, FormField} from './FormField';
import {css} from '../css';

export type NativeTextFieldProps = View.IntrinsicElement<
  'input',
  {
    /**
     * Programmatically mark the value as invalid
     * @external
     */
    invalid?: boolean;
    /**
     * The size of the input
     * @external
     */
    size?: 'small' | 'medium' | 'large';
    /**
     * Choose between the text-alike types on an input
     * @external
     */
    type?: 'text' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'url';
  }
>;

export const NativeTextFieldConfig = createViewConfig({
  props: {} as NativeTextFieldProps,
  name: 'NativeTextField',
  flattens: true,
  defaults: {
    size: 'medium',
    type: 'text',
  },
});

export const NativeTextField = NativeTextFieldConfig.createView(
  (props) => <Input {...props} />,
  {
    css: {
      // Shadows get clipped on ios safari without appearance: none
      appearance: 'none',
      backgroundColor: 'form',
      border: 'none',
      borderRadius: 'form',
      keyline: 'form',
      textColor: 'form',
      font: 'label.medium',
      stack: 'x',
      transition: 'box-shadow 240ms',
      width: 'fill',
      boxSizing: 'border-box',

      '::placeholder': {
        color: 'form.placeholder',
      },

      ':disabled': {
        backgroundColor: 'form.disabled',
        color: 'form.disabled',
      },

      ':focus': {
        focusRing: 'focus',
        keyline: 'form.focused',
      },

      '[aria-invalid="true"]': {
        keyline: 'form.error',
      },
    },
    forward: {
      type: true,
    },
    variants: {
      type: {
        number: [
          css({
            '::-webkit-outer-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
            '::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
            '-moz-appearance': 'textfield',
          }),
        ],
      },
      size: {
        small: css({
          paddingX: 'small',
          paddingY: 'xsmall',
          font: 'label.small',
        }),
        medium: css({
          paddingX: 'small',
          paddingY: 'xsmall',
        }),
        large: css({
          paddingX: 'space.150',
          paddingY: 'small',
          font: 'label.large',
        }),
      },
    },
  },
);

export const TextFieldConfig = createViewConfig({
  props: {} as FormFieldProps<NativeTextFieldProps, typeof NativeTextField>,
  name: 'TextField',
  flattens: true,
});

/**
 * @external
 * @param autoComplete
 * @param autoFocus
 * @param defaultValue
 * @param disabled
 * @param form
 * @param maxLength
 * @param minLength
 * @param name
 * @param onChange
 * @param onKeyPress
 * @param onKeyDown
 * @param onKeyUp
 * @param placeholder
 * @param readOnly
 * @param required
 * @param spellCheck
 * @param tabIndex
 * @param type
 */
export const TextField = TextFieldConfig.createView((props) => {
  const [fieldProps, controlProps] = useFormFieldProps(props);
  return (
    <FormField {...fieldProps}>
      <NativeTextField {...controlProps} inherits={props.subviews.input} />
    </FormField>
  );
});
