import {clock} from '@sail/icons/react/Icon';
import {css, view, Icon, Spinner} from '@sail/ui';
import {SessionOperatingMode} from '@stripe-internal/data-gelato/schema/types';
import {urlRedirect} from '@stripe-internal/safe-links';
import * as React from 'react';
import {defineMessages, injectIntl} from 'react-intl';

import Button from 'gelato/frontend/src/components/ButtonV2';
import Heading from 'gelato/frontend/src/components/HeadingV2';
import Link from 'gelato/frontend/src/components/LinkV3';
import Message from 'gelato/frontend/src/components/Message';
import PageCard from 'gelato/frontend/src/components/PageCardV2';
import {responsiveCss} from 'gelato/frontend/src/components/stylesV2';
import ThemedIcon from 'gelato/frontend/src/components/ThemedIcon';
import TopNavigationBar from 'gelato/frontend/src/components/TopNavigationBar';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import flags from 'gelato/frontend/src/lib/flags';
import getBrandingPlatformName from 'gelato/frontend/src/lib/getBrandingPlatformName';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import {isInIframe, postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import Storage from 'gelato/frontend/src/lib/Storage';
import {hasiOSSheetCloseWindowHandler} from 'gelato/frontend/src/lib/windowHelpers';

import type {IntlShape} from 'react-intl';

type Props = {
  intl: IntlShape;
};

const Styles = {
  bodyTextControl: css({
    font: 'body.small',
  }),
  bodyText: css({
    font: 'body.medium',
    textAlign: 'center',
  }),
  body: css({
    alignX: 'center',
    alignY: 'center',
    gap: 'medium',
    textAlign: 'center',
    minHeight: '360px',
    stack: 'y',
  }),
  bodyWrapper: css({
    stack: 'y',
    padding: 'large',
  }),
  bodyResponsive: responsiveCss({
    compact: {
      minHeight: '250px',
    },
    regular: {
      minHeight: '360px',
    },
  }),
  successPage: css({
    alignX: 'center',
    textAlign: 'center',
    stack: 'y',
    gap: 'medium',
    padding: 'medium',
    boxSizing: 'border-box',
  }),
  pageContent: css({
    stack: 'y',
    paddingX: 'space.250',
    textAlign: 'center',
    alignSelfY: 'center',
    alignX: 'center',
    alignY: 'center',
    font: 'body.medium',
  }),
  pageButtons: css({
    padding: 'space.250',
  }),
  pageHeader: css({
    stack: 'x',
    gap: 'small',
    alignX: 'center',
    alignY: 'center',
    padding: 'space.250',
  }),
  title: css({
    font: 'heading.medium.subdued',
  }),
  secondaryLink: css({
    textDecorationColor: '#99a5b8',
    font: 'body.medium',
    fontWeight: 'normal',
  }),
  iconWrapper: css({
    // The gap between the icon and the header should be 24 but the gap between the header and the body text is 16px.
    // THis value comes from the difference needed for the current row gap value
    marginBottom: 'small',
  }),
  h1: css({
    font: 'heading.large',
    margin: 'space.0',
  }),
};

const messages = defineMessages({
  documentTitle: {
    id: 'success.documentTitle',
    description: 'HTML title for verification submitted page',
    defaultMessage: 'Verification submitted | Stripe',
  },
  successTitle: {
    id: 'pages.success.heading',
    description: 'Heading on page after user submits verification',
    defaultMessage: 'Verification submitted',
  },
  successBodyV2: {
    id: 'pages.success.bodyV2',
    description: 'Body of page explaining verification was submitted',
    defaultMessage: 'Thank you for submitting your information.',
  },
  safeToClose: {
    id: 'pages.success.safeToClose',
    description: 'Message telling the user to close the tab',
    defaultMessage: 'You can now close this tab.',
  },
  successBodySecondaryV2: {
    id: 'pages.success.body.secondaryV2',
    description:
      'Body of page explaining verification was submitted in a mobile handoff session',
    defaultMessage:
      'Thank you for submitting your information. You can return to your other device.',
  },
  successBodySecondaryV3: {
    id: 'pages.success.body.secondaryV3',
    description:
      'Body of page explaining verification was submitted in a mobile handoff session',
    defaultMessage: 'You can return to your other device.',
  },
  redirectBody: {
    id: 'pages.success.redirecting.body',
    description:
      'Body of page explaining verification was submitted and user will be redirected back to the platform.',
    defaultMessage: `We’re redirecting you to {platformName}. If you are not redirected in 5 seconds, {redirectLink}.`,
  },
  redirectBodyInternal: {
    id: 'pages.success.redirecting.bodyInternal',
    description:
      'Body of page explaining verification was submitted and user will be redirected back to Stripe.',
    defaultMessage: `We’re redirecting you back. If you are not redirected in 5 seconds, {redirectLink}.`,
  },
  redirectLink: {
    id: 'pages.success.redirecting.bodyLink',
    description:
      'Text for the link that user can click to redirect to the platform.',
    defaultMessage: 'click here',
  },
  done: {
    id: 'pages.success.done',
    description:
      'Button text that closes the page after successful verification',
    defaultMessage: 'Done',
  },
});

// Only show the close button if we're in an iframe or in an iOS sheet
const shouldShowCloseButton = () =>
  isInIframe() || hasiOSSheetCloseWindowHandler();

const handleDone = () => {
  // tell the Stripe.js iframe to close
  postIframeEvent('STRIPE_IDENTITY_CLOSE');

  // tell the iOS sheet to close
  if (hasiOSSheetCloseWindowHandler()) {
    // @ts-expect-error - TS2339 - Property 'webkit' does not exist on type 'Window & typeof globalThis'.
    window.webkit.messageHandlers.closeWindow.postMessage(null);
  }
};

const ContentText = ({
  platformName,
  operatingMode,
  returnUrl,
  stripeInternal,
  omitCloseMessage,
}: {
  platformName: string;
  operatingMode?: SessionOperatingMode;
  returnUrl?: string | null;
  stripeInternal: boolean;
  omitCloseMessage: boolean;
}) => {
  const {appState} = useAppController();

  if (operatingMode === 'secondary') {
    let secondarySuccessMessage = messages.successBodySecondaryV2;
    // todo(dodgejoel, 2025-01-01) - once the new V3 message has been translated remove the use of the old one and this check
    if (appState.locale.currentValue[0] === 'en-US') {
      secondarySuccessMessage = messages.successBodySecondaryV3;
    }
    return <Message {...secondarySuccessMessage} />;
  } else if (omitCloseMessage) {
    // we have moved away from the "thank you for submitting your information"
    // verbiage but in this case we keep it since there is no close link to show
    // and this acts as fine companion text to the CTA button that is shown in the footer
    return <Message {...messages.successBodyV2} values={{platformName}} />;
  } else if (returnUrl) {
    let redirectMessage = messages.redirectBody;
    if (stripeInternal) {
      redirectMessage = messages.redirectBodyInternal;
    }
    return (
      <Message
        {...redirectMessage}
        values={{
          platformName,
          redirectLink: (
            <Link
              href={returnUrl}
              type="secondary"
              uses={[Styles.secondaryLink]}
              data-testid="success-link-redirect"
            >
              <Message {...messages.redirectLink} />
            </Link>
          ),
        }}
      />
    );
  } else {
    return <Message {...messages.safeToClose} />;
  }
};

const {useState, useCallback, useEffect, useRef} = React;

export function SuccessPage(_: Props) {
  const {appController, appState} = useAppController();
  const {session} = appState;
  const branding = appController.runtime?.branding;
  const platformName = getBrandingPlatformName(branding);
  const {operatingMode, returnUrl, skipSuccessPage, consentConfig} =
    session || {};
  const [redirecting, setRedirecting] = useState<boolean>(false);
  const closeAllLayers = useRef<boolean>(false);
  // `returnUrl` could have been redacted. Use `cachedReturnUrl` instead.
  const cachedReturnUrl = returnUrl || Storage.getReturnUrl();
  const isConnectIframe =
    (Storage.getOverrideUseConnectIframeDesign() ||
      session?.useConnectIframeDesign) === true;

  // 2024-11-26  - as of today, skipSuccessPage is used by RocketRides demo merchant and Account onboarding (including AV)
  const shouldAutomaticallyRedirect =
    skipSuccessPage && cachedReturnUrl && operatingMode !== 'secondary';

  const flaggedAutomaticRedirect =
    flags.isActive('idprod_enable_automatic_redirect') && cachedReturnUrl;

  const showCloseButton = shouldShowCloseButton();

  useEffect(() => {
    postIframeEvent('success');
    postIframeEvent('STRIPE_IDENTITY_SESSION_COMPLETE');
  }, []);

  useEffect(() => {
    if (closeAllLayers.current) {
      return;
    }
    appController.closeAllLayers();
    closeAllLayers.current = true;
  }, [closeAllLayers, appController]);

  const redirectToReturnUrl = useCallback(() => {
    if (cachedReturnUrl && !redirecting) {
      setRedirecting(true);
      urlRedirect(cachedReturnUrl);
    }
  }, [cachedReturnUrl, redirecting]);

  useEffect(() => {
    if (
      (shouldAutomaticallyRedirect || flaggedAutomaticRedirect) &&
      // If a close button is shown, we're in some sort of modal or sheet
      // that should not redirect and instead should close to return the
      // user back to their original context.
      !showCloseButton
    ) {
      redirectToReturnUrl();
    }
  }, [
    shouldAutomaticallyRedirect,
    flaggedAutomaticRedirect,
    redirectToReturnUrl,
    showCloseButton,
  ]);

  useEffect(() => {
    if (
      cachedReturnUrl &&
      !shouldAutomaticallyRedirect &&
      !flaggedAutomaticRedirect &&
      // If a close button is shown, we're in some sort of modal or sheet
      // that should not redirect and instead should close to return the
      // user back to their original context.
      !showCloseButton
    ) {
      setTimeout(() => {
        redirectToReturnUrl();
      }, 5000);
    }
  }, [
    shouldAutomaticallyRedirect,
    flaggedAutomaticRedirect,
    redirectToReturnUrl,
    cachedReturnUrl,
    showCloseButton,
  ]);

  const header = <TopNavigationBar />;
  let footer;
  if (showCloseButton) {
    footer = (
      <view.div uses={[Styles.pageButtons]}>
        <Button onPress={handleDone} data-testid="success-button-close">
          <Message {...messages.done} />
        </Button>
      </view.div>
    );
  }

  const showContent = !redirecting || !isConnectIframe;

  const body = (
    <view.div uses={[Styles.body, Styles.bodyResponsive]}>
      <ThemedIcon icon={clock} size="large" />
      <Heading level={1} uses={[Styles.h1]}>
        <Message {...messages.successTitle} />
      </Heading>
      <view.div uses={[Styles.bodyText]}>
        {showContent && (
          <ContentText
            stripeInternal={!!consentConfig?.stripeInternal}
            returnUrl={cachedReturnUrl}
            operatingMode={operatingMode}
            platformName={platformName}
            omitCloseMessage={showCloseButton}
          />
        )}
      </view.div>
    </view.div>
  );

  return (
    <PageCard
      header={header}
      body={body}
      footer={footer}
      bodySectionStyles={[Styles.bodyWrapper]}
    />
  );
}

setComponentConfig(SuccessPage, {
  isTerminal: true,
  ignoreExpiredSession: true,
});

export default injectIntl(SuccessPage);
